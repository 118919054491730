import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileId: null,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file_ = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file_,
      });
      if(this.props.handleFileChange)
        this.props.handleFileChange(this.state);
    };
    reader.readAsDataURL(file_);
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleDownload() {
    window.open(process.env.API_URL + '/api/project-files/' + this.props.fileId + '/download/', '_blank');
  }
  handleRemove() {
    this.setState({
      file: null,
      fileId: null,
    });
    this.refs.fileInput.value = null;
    if(this.props.handleFileDelete && this.state.fileId === null)
      this.props.handleFileDelete(this.state);
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.resetFile){
      return ({file: null});
    }
    if(nextProps.fileId){
      return ({fileId: nextProps.fileId});
    }
  }

  render() {
    const {
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
        downloadButtonProps,
    } = this.props;
    const {
      fileId,
      file
    } = this.state;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleFileChange} ref="fileInput" />
        <div>
          {file === null && fileId === null ?
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
                Select file
            </Button>
           : null}
          {file !== null? (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Change
              </Button>
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Remove
              </Button>
            </span>
          ):null}
          {file === null && fileId !== null?
              <React.Fragment>
                <Button {...downloadButtonProps} onClick={() => this.handleDownload()}>
                  Download
                </Button>
                <Button
                    {...removeButtonProps}
                    onClick={() => this.handleRemove()}
                >
                  <i className="fas fa-times" /> Remove
                </Button>
              </React.Fragment>
          :null}
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = {
  fileId: PropTypes.string,
  downloadButtonProps: PropTypes.object,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  handleFileChange: PropTypes.func.isRequired,
  handleFileDelete: PropTypes.func.isRequired,
  resetFile: PropTypes.bool,
};

export default FileUpload;
