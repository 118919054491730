const initialState = {
    items: [],
    project_items: [],
    current_project_items: [],
    current_project_files: [],
    current_project_images: [],
    current_project_note_images: [],
    item: null,
    files_next: null,
    files_prev: null,
    images_next: null,
    images_prev: null,
    note_images_next: null,
    note_images_prev: null,
    files_loading: true,
    images_loading: true,
  };
  
  let items = [];
  export default function files(state=initialState, action) {
      switch (action.type) {
          case 'FETCH_FILES':
              return {
                ...state,
                items: action.items,
              };
          case 'FETCH_PROJECT_FILES':
            return {
                ...state,
                current_project_files: action.items,
                count: action.count,
                files_next: action.next,
                files_prev: action.prev,
                files_loading: false,
            };

          case "APPEND_PROJECT_FILES":
              return {
                ...state,
                //current_project_items: [...state.current_project_items, ...action.items],
                current_project_files: [...state.current_project_files, ...action.items],
                count: action.count,
                files_next: action.next,
                files_prev: action.prev,
              }
          
          case "FETCH_PROJECT_IMAGES":
            return {
              ...state,
              current_project_images: action.items,
              images_next: action.next,
              images_prev: action.prev,
              images_loading: false,
            };
          case "APPEND_PROJECT_IMAGES":
            return {
              ...state,
              current_project_images: [...state.current_project_images, ...action.items],
              images_next: action.next,
              images_prev: action.prev,
            };
          case "GET_NOTE_IMAGES":
            return {
                ...state, 
                current_project_note_images: action.items,
                note_images_next: action.next,
                note_images_prev: action.prev,
            }
          case "APPEND_NOTE_IMAGES":
            return {
              ...state, 
              current_project_note_images: [...state.current_project_note_images, ...action.items],
              note_images_next: action.next,
              note_images_prev: action.prev,
          }
          case "FILE_CREATED":
            return {
                ...state,
                current_project_files: [...state.current_project_files, action.item]
            }
          case "IMAGE_CREATED":
            return {
              ...state,
              current_project_images: [...state.current_project_images, action.item],
            }

          case 'GET_FILE':
              const files = state.items.forEach((file) => {
                  if (file.id === action.item.id){
                    file = action.item;
                  }
              })
              return {...state, item: action.item, items: files};
  
          case 'FILE_DELETED':
              items = state.items.filter(item => item.id !== action.item_id);
              return {...state, items};
  
          case 'FILE_SAVED':
              items = state.items.forEach(file => {
                  if(file.id === action.item.id){
                      file = action.item;
                  }
              });
              return {...state, items: items};
          case "LOGOUT_SUCCESSFUL":
              return {
                items: [],
                project_items: [],
                current_project_items: [],
                item: null,
                current_project_note_images: [],
              }
          default:
              return state;
      }
  }
  