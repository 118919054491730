// import PricingPage from "views/Pages/PricingPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import OnboardingPage from "../views/Pages/OnboardingPage";
import OnboardingDemoPage from "../views/Pages/OnboardingDemoPage";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LockOpen from "@material-ui/icons/LockOpen";

const pagesRoutes = [
  {
    path: "/register",
    name: "Register",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    //component: RegisterPage
    component: OnboardingPage,
  },
  {
    path: "/demo",
    name: "Register",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    //component: RegisterPage
    component: OnboardingDemoPage,
  },
  {
    path: "/login",
    name: "Login",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/lock",
    name: "Lock Screen Page",
    short: "Lock",
    mini: "LSP",
    icon: LockOpen,
    component: LockScreenPage
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/login",
    name: "Login"
  },
];

export default pagesRoutes;
