import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import AddFiles from "../AddFiles/AddFiles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FileList from "../FileList/FileList";
import NotesList from "../NotesList/NotesList";
import PhotosList from "../PhotosList/PhotosList";
import ProjectTimeSheet from "../ProjectTimeSheetTab/ProjectTimeSheetTab";
import SafetyMeetingList from "../SafetyMeetingList/SafetyMeetingList";
import GPSEntriesTab from "../GPSEntriesTab/GPSEntriesTab";
import store from "store";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} padding="24px 0">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: "10px",

    backgroundColor: "var(--trade-background)",
    borderRadius: "10px",
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "var(--trade-success)",
    height: "80%",
    zIndex: "1",
    bottom: "10%",
    borderRadius: "10px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "var(--trade-success)",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: "#000000",

    "&.MuiTab-root": {
      minWidth: "120px",
    },
    "&.Mui-selected": {
      color: "var(--trade-font)",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "var(--trade-background)",
    },
  })
);

// this tab pane is only used in dashboard
function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();

  const useStickyState = (key = "tabValue", initialState = null) => {
    const [state, setState] = React.useState(() => {
      const storedState = store.get(key);

      return storedState ?? initialState;
    });

    React.useEffect(() => {
      store.set(key, state);
    }, [state]);

    return [state, setState];
  };

  const [value, setValue] = useStickyState("tabValue", 0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    "files",
    "notes",
    "photos",
    "gps records",
    "time sheet",
    "safety meeting",
  ];

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: "var(--trade-background)",
          boxShadow: "none",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--trade-success)",
            },
          }}
          style={{
            background: "#FFFF",
            color: "var(--trade-font)",
            minHeight: "30px",
            marginTop: "5px",
            borderRadius: "5px",
            padding: "0 5px",
          }}
        >
          {tabs.map((index, key) => {
            return (
              <StyledTab
                key={key}
                label={index}
                {...a11yProps(key)}
                style={{
                  textTransform: "capitalize",
                  fontSize: "0.8rem",
                  minHeight: "30px",
                  zIndex: "2",
                }}
              />
            );
          })}
        </StyledTabs>
      </AppBar>

      <div style={{ display: "flex", marginTop: "24px" }}>
        <AddFiles
          projectID={props.projectID}
          projectName={props.projectName}
          redirect={props.redirect}
          createdFile={props.createdFile}
          createdNote={props.createdNote}
          createdPhoto={props.createdPhoto}
        />
      </div>
      <TabPanel value={value} index={0}>
        <FileList
          currentYear={props.currentYear}
          currentUser={props.currentUser}
          fileCreated={props.fileCreated}
          currentProject={props.currentProject}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotesList
          currentYear={props.currentYear}
          noteCreated={props.noteCreated}
          currentProject={props.currentProject}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PhotosList
          currentYear={props.currentYear}
          photoCreated={props.photoCreated}
          currentProject={props.currentProject}
          createdNote={props.createdNote}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GPSEntriesTab
          currentYear={props.currentYear}
          currentProject={props.currentProject}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ProjectTimeSheet
          currentYear={props.currentYear}
          currentProject={props.currentProject}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <SafetyMeetingList
          currentYear={props.currentYear}
          currentProject={props.currentProject}
        />
      </TabPanel>
    </div>
  );
}

export default ScrollableTabsButtonAuto;
