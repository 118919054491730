import React, { Component, createRef } from "react";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";

import Slide from "@material-ui/core/Slide";
import Spinner from "react-bootstrap/Spinner";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { time_logs, safety_meetings } from "actions";
import CssTextField from "components/CssTextField/CssTextField";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Typography, Box, Grid } from "@material-ui/core";
import moment from "moment/moment";
import {getUserTimezone} from "../../utils";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const axios = require("axios");


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SwitchSite extends Component {
  _isMounted = false;
  safetyMeetingContainer = createRef();

  state = {
    open: false,
    taskID: "",
    buttonValue: true,
    latitude: null,
    longitude: null,
    isLoading: true,
    safetyMeetingInfos: [],
    safetyMeetings: [],
    currentSafetyMeetingInfo: null,
    currentSafetyMeeting: null,
    snackOpen: false,
    snackMessage: "",
    snackSeverity: "error",
    current_index: 0,
    availableTasks: [],
  };

  componentDidMount() {
    this._isMounted = true;
    this.getCoordinates();
    const tasks = this.props.tasks;
    this.setState({
      availableTasks: tasks,
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCoordinates = () => {
    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        // handle success
        if (this._isMounted) {
          this.setState({
            latitude: Number(response.data.latitude),
            longitude: Number(response.data.longitude),
          });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  handleClose = () => {
    this.props.closeSwitchSite();
  };

  deploySafetyMeeting = () => {
    if (this.props.currentProjectID && this.state.taskID) {
      this.getSafetyMeetings();
    }
  };

  getSafetyMeetings = () => {
    const { user } = this.props;
    const { taskID } = this.state;
    const projectID = this.props.currentProjectID;

    this.props.fetchTaskSafetyMeetings(taskID).then((res) => {
      if (res) {
        if (res.items.length === 0) {
          this.setState({
            currentSafetyMeetingInfo: null,
          });
        } else {
          const meetings = [];

          for (var i = 0; i < res.items.length; i++) {
            // create a temp safety meeting for each SafetyMeetingInfo,
            meetings.push({
              safety_meeting_info: res.items[i].id,
              user: user.id,
              created_by: user.id,
              project_id: projectID,
              fields: {},
            })

            // initialize fields of each safety meeting to ""
            var lastMeeting = meetings[meetings.length - 1];
            res.items[i].fields.forEach((elem) => {
              elem.field_value = "";
              lastMeeting.fields[elem.id] = elem;
            })
          }

          this.setState({
            currentSafetyMeetingInfo: res.items[0],
            currentSafetyMeeting: meetings[0],
            safetyMeetingInfos: res.items,
            safetyMeetings: meetings,
            safety_meetings_count: res.items.length,
            current_index: 0,
            items_read: 0,
            worker_id: user.id,
            isLoading: false,
          });
        }
      }
    });
  };

  handleFieldFormChange = (ev) => {
    const meeting = this.state.currentSafetyMeeting;
    meeting.fields[ev.target.name].field_value = ev.target.value;

    this.setState({
      currentSafetyMeeting: meeting,
    })
  }

  displayChoicesFieldForm = (fieldForm) => {
    const optionList = fieldForm.options.split("\r\n").map((elem) => elem.split("|")[0]);
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1} style={{ color: "white" }}>
            {fieldForm.title}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1}>
            <CssTextField
              value={this.state.currentSafetyMeeting.fields[fieldForm.id].field_value}
              onChange={this.handleFieldFormChange}
              name={fieldForm.id}
              variant="outlined"
              select
              style={{ minWidth: "15vw", }}
            >
              {
                optionList.map((elem) => (
                  <MenuItem value={elem} key={elem}>{elem}</MenuItem>
                ))
              }
            </CssTextField>
          </Box>
        </Grid>
      </Grid>
    );
  }

  displayTextField = (fieldForm) => {
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1} style={{ color: "white" }}>
            {fieldForm.title}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1}>
            <CssTextField
              value={this.state.currentSafetyMeeting.fields[fieldForm.id].field_value}
              name={fieldForm.id}
              onChange={this.handleFieldFormChange}
              style={{ minWidth: "15vw" }}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  displayFieldForm = (fieldForm) => {
    switch (fieldForm.type) {
      case "choices":
        return this.displayChoicesFieldForm(fieldForm);
      case "string":
        return this.displayTextField(fieldForm)
      default:
        return null;
    }
  }

  displayFieldForms = (ffs) => {
    return (
      <Grid container>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box m={1} style={{ height: "3px", backgroundColor: "white" }} justifyContent="center" display="flex" alignItems="center"></Box>
        </Grid>
        <Grid item md={2} >
          <Box m={1} justifyContent="center" display="flex" alignItems="center">
            <WhiteTextTypography variant="h5">Q &amp; A</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box m={1} style={{ height: "3px", backgroundColor: "white" }} justifyContent="center" display="flex" alignItems="center"></Box>
        </Grid>
        {ffs.map((elem) => this.displayFieldForm(elem))}
      </Grid>
    );
  }

  displaySafetyMeeting = () => {
    const { currentSafetyMeetingInfo } = this.state;
    const data = (
      <div>
        <p style={{ textTransform: "uppercase" }}>{currentSafetyMeetingInfo.title}</p>
        <p style={{ textTransform: "uppercase" }}>
          Safety Meeting Code: {currentSafetyMeetingInfo.code}
        </p>
        {currentSafetyMeetingInfo.last_update? (
            <WhiteTextTypography variant="subtitle1" style={{ textAlign: "left" }}>
              Last update : {moment(currentSafetyMeetingInfo.last_update).format('YYYY-MM-DD')}
            </WhiteTextTypography>
        ): ""}
        <pre style={{
          whiteSpace: "pre-wrap",
          height: "auto",
          color: "white",
        }}>
          {currentSafetyMeetingInfo.description}
        </pre>
        {currentSafetyMeetingInfo.images.length ? (
          <div>
            {" "}
            <p style={{ textTransform: "uppercase" }}>
              <strong> Images</strong>
            </p>{" "}
            <div className="safetyImageWrapper">
              <div className="safetyImageConatiner">
                {currentSafetyMeetingInfo.images.map((image, index) => {
                  return (
                    <img
                      src={image.image}
                      alt={currentSafetyMeetingInfo.title}
                      key={index}
                      className="safetyImage"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        {
          //displayfieldforms
          this.displayFieldForms(currentSafetyMeetingInfo.fields)
        }
      </div>
    );
    return data;
  };

  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.setState({
        buttonValue: false,
      });
    } else {
      this.setState({
        buttonValue: true,
      });
    }
  };

  startTimer() {
    const { user } = this.props;
    const { taskID, latitude, longitude } = this.state;

    this.props.startTimer(
      this.props.currentProjectID,
      latitude,
      longitude,
      user.id,
      taskID
    );
  }

  stopTimer() {
    const { user, stopTimer } = this.props;
    const { latitude, longitude } = this.state;
    stopTimer(user.last_time_log.id, latitude, longitude);
    this.startTimer();
  }

  showNextMeeting = () => {
    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);
    for (var i = 0; i < fieldKeys.length; i++) {
      if (this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === "") {
        this.setState({
          snackOpen: true,
          snackMessage: "Please Answer all the questions before proceeding."
        })
        return;
      }
    }

    this.props.ackMeeting(this.state.currentSafetyMeeting.safety_meeting_info,
      this.state.currentSafetyMeeting.project_id, this.state.currentSafetyMeeting.user,
      this.state.currentSafetyMeeting.fields, this.state.taskID).then((elem) => {

        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          const next_index = this.state.current_index + 1;
          this.setState({
            currentSafetyMeetingInfo: this.state.safetyMeetingInfos[next_index],
            currentSafetyMeeting: this.state.safetyMeetings[next_index],
            current_index: next_index,
            items_read: this.state.items_read + 1,
          });
          var myDiv = document.getElementById("style-5"); //Scroll to top
          myDiv.scrollTop = 0;
        }
      })
  }

  goToDashboard = () => {
    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);
    for (var i = 0; i < fieldKeys.length; i++) {
      if (this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === "") {
        //show snackbar
        this.setState({
          snackOpen: true,
          snackMessage: "Please Answer all the questions before proceeding."
        })
        return;
      }
    }

    this.props.ackMeeting(this.state.currentSafetyMeeting.safety_meeting_info,
      this.state.currentSafetyMeeting.project_id, this.state.currentSafetyMeeting.user,
      this.state.currentSafetyMeeting.fields, this.state.taskID).then((elem) => {
        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          this.startTimer().then((response) => {
            if (response && response.type === "GET_TIME_LOG") {
              this.setState({ redirectTo: "/dashboard" });
            }
          })
        } else {
          this.setState({
            snackOpen: true,
            snackMessage: "Please Answer all the questions before proceeding."
          })
        }
      })
    this.stopTimer();
    this.props.redirect();
  };

  buttonState() {
    return false;
  }

  handleTaskChange = (event) => {
    this.setState({
      taskID: event.target.value
    },
      () => {
        this.deploySafetyMeeting();
      })
  }

  taskSelect = () => {
    return (
      <React.Fragment>
        {
          (this.props.tasks.length > 0)
            ? <CssTextField
              value={this.state.taskID}
              onChange={this.handleTaskChange}
              variant="outlined"
              label="Select Task"
              select
              style={{ width: "15vw", marginRight: "2rem", marginBottom: "1rem" }}
            >
              {this.props.tasks.map((task) =>
                <MenuItem
                  value={task.id}
                  key={task.id}
                  style={{ textTransform: "capitalize" }}
                >
                  {task.title}
                </MenuItem>)
              }

            </CssTextField>
            : null
        }
      </React.Fragment>
    );
  }

  render() {
    const { switchSiteValue, currentProjectTitle, currentProjectID } =
      this.props;

    const {
      currentSafetyMeeting,
      items_read,
      safety_meetings_count,
    } = this.state;

    const itemString = 'safety meeting';
    return (
      <div>
        <Dialog
          maxWidth="lg"
          open={switchSiteValue}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {currentProjectTitle || currentProjectID ? (
            <div className="loginWorkerWrapper">
              <div className="LoginWorkerContainer">
                <div className="projectSelectionContainer">
                  <div className="projectSelectionWrapper">
                    <CssTextField
                      label="Project"
                      fullWidth={true}
                      variant="outlined"
                      value={currentProjectTitle}
                      onChange={() => console.log("disabled")}
                      name="project"
                      disabled={true}
                      style={{ width: "15vw", marginRight: "2rem", marginBottom: "1rem" }}
                    />
                    {
                      this.taskSelect()
                    }
                  </div>
                </div>
                <div
                  className="safetyMeetingContainer"
                  id="style-5"
                  onScroll={this.handleScroll}
                >
                  {currentSafetyMeeting ? (
                    this.displaySafetyMeeting()
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>Please Select Project & Task.</p>
                    </div>
                  )}

                </div>
                {currentSafetyMeeting ? (
                  items_read === safety_meetings_count - 1 ? (
                    <React.Fragment>
                      <Button
                        round
                        style={{ backgroundColor: "green", color: "white" }}
                        size="lg"
                        disabled={this.buttonState()}
                        className="start_timer"
                        onClick={this.goToDashboard}
                      >
                        I have read and understood
                        <br /> the {itemString}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        round
                        color="success"
                        size="lg"
                        disabled={this.buttonState()}
                        className="start_timer"
                        onClick={this.showNextMeeting}
                      >
                        I have read and understood
                        <br /> the {itemString}, read next item
                      </Button>
                    </React.Fragment>
                  )
                ) : null}
                <Snackbar open={this.state.snackOpen} autoHideDuration={5000} onClose={() => this.setState({ snackOpen: false })}>
                  <Alert onClose={() => this.setState({ snackOpen: false })} severity={this.state.snackSeverity}>
                    {this.state.snackMessage}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          ) : (
            <Spinner
              animation="border"
              variant="warning"
              style={{ height: "4rem", width: "4rem" }}
            />
          )}
        </Dialog>
      </div>
    );
  }
}

SwitchSite.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          false,
          getUserTimezone(),
        )
      );
    },
    stopTimer: (time_log_id, latitude, longitude) => {
      return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    },
    fetchTaskSafetyMeetings: (task_type_id) => {
      return dispatch(
        safety_meetings.fetchSafetyMeetingsInfoForTask(
          task_type_id
        )
      );
    },
    ackMeeting: (meeting_id, project_id, worker_id, fields_values, task_id) => {
      if (fields_values === undefined) fields_values = [];
      return dispatch(
        safety_meetings.acknowledgeMeeting(
          meeting_id,
          project_id,
          worker_id,
          fields_values,
          task_id
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchSite);
