import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssTextField from "components/CssTextField/CssTextField";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Typography, Box } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
//import Spinner from "react-bootstrap/Spinner";

const AmberTypography = withStyles({
  root: {
    color: "#FF9100",
  },
})(Typography);

const FormLabel = withStyles({
  label: {
    fontSize: "1.3rem",
    color: "var(--trade-font)",
  },
})(FormControlLabel);

const GreenTabs = withStyles({
  indicator: {
    color: "#4CAF50",
    backgroundColor: "#4CAF50",
  },
})(Tabs);

const GreyAppBar = withStyles({
  root: {
    backgroundColor: "#000000",
    borderRadius: "4px",
  },
})(AppBar);

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    overflow: "scroll",
    backgroundColor: "var(--trade-body)",
  },
  mainContainer: {
    width: 950,
    height: "100%",
    backgroundColor: "var(--trade-body)",
    padding: "30px",
    overflowY: "scroll",
  },
  inputLabel: {
    marginBottom: "5px",
  },
  inputField: {
    marginTop: "5px",
  },
  sectionUnderline: {
    borderBottom: "1px solid #26292c",
  },
  sectionOverline: {
    borderTop: "1px solid #26292c",
  },
  sectionHeading: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  container: {
    height: "auto",
    backgroundColor: "#000000",
    width: "100%",
    padding: "2rem",
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "#000000",
    marginBottom: "1rem",
  },
  headers: {
    color: "#FF9100",
  },
  description: {
    color: "#fff",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    height: "auto",
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "var(--trade-font)",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

const possibleQuestionTypes = [
  { value: "string", name: "Text" },
  { value: "choices", name: "Choice" },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CreateSafetyMeetingSlider(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [questionsRequired, setQuestionsRequired] = useState("no");
  const [safetyMeeting, setSafetyMeeting] = useState(null);
  const [safetyMeetingActiveTab, setSafetyMeetingActiveTab] = useState(0);

  const classes = useStyles();

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    props.setDrawerOpen(false);
  };

  useEffect(() => {
    setSafetyMeeting(props.meeting);
  }, [props.meeting]);

  const safetyMeetingInfo = () => {
    return (
      <Grid container>
        {/**Section Title */}
        <Grid item md={12} className={classes.sectionUnderline}>
          <Box m={2} margin="10px 16px">
            <WhiteTextTypography variant="h4" style={{ fontWeight: "bold" }}>
              Create new Safety Meeting
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container className={classes.sectionUnderline}>
          <Grid item md={12}>
            <Box m={2} margin="10px 16px 0px">
              <WhiteTextTypography
                variant="h5"
                className={classes.sectionHeading}
              >
                Safety Meeting Information
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid container item md={12}>
            {/**Safety Meeting code */}
            <Grid item md={4}>
              <Box m={2} className={classes.inputLabel}>
                <WhiteTextTypography component={"span"}>
                  Code
                </WhiteTextTypography>
              </Box>
              <Box m={2} className={classes.inputField}>
                <CssTextField
                  variant="outlined"
                  fullWidth={true}
                  value={props.meetingCode}
                  name="meetingCode"
                  onChange={(event) => props.handleTextInputChange(event)}
                ></CssTextField>
              </Box>
            </Grid>
            {/**Safety Meeting Title */}
            <Grid item md={4}>
              <Box m={2} className={classes.inputLabel}>
                <WhiteTextTypography component={"span"}>
                  Title
                </WhiteTextTypography>
              </Box>
              <Box m={2} className={classes.inputField}>
                <CssTextField
                  variant="outlined"
                  fullWidth={true}
                  value={props.meetingTitle}
                  name="meetingTitle"
                  onChange={(event) => props.handleTextInputChange(event)}
                ></CssTextField>
              </Box>
            </Grid>
            {/**Safety Meeting Recipient's Email */}
            <Grid item md={4}>
              <Box m={2} className={classes.inputLabel}>
                <WhiteTextTypography component={"span"}>
                  Safety Meeting Recipient Email
                </WhiteTextTypography>
              </Box>
              <Box m={2} className={classes.inputField}>
                <CssTextField
                  variant="outlined"
                  fullWidth={true}
                  value={props.clientEmail}
                  name="clientEmail"
                  onChange={(event) => props.handleTextInputChange(event)}
                ></CssTextField>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Box m={2} md={4} margin="10px 16px 0px">
              <WhiteTextTypography
                variant="h5"
                className={classes.sectionHeading}
              >
                Questions Required?
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid container item md={12}>
            <Grid item md={4}>
              <Box m={2}>
                <CssTextField
                  variant="outlined"
                  fullWidth={true}
                  select
                  value={questionsRequired}
                  name="currentQuestionType"
                  onChange={(ev) => setQuestionsRequired(ev.target.value)}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </CssTextField>
              </Box>
            </Grid>
          </Grid>
          {questionsRequired === "yes" ? (
            <Grid container item md={12}>
              {/**Safety Meeting code */}
              <Grid item md={4}>
                <Box m={2}>
                  <WhiteTextTypography component={"span"}>
                    Question Title
                  </WhiteTextTypography>
                </Box>
                <Box m={2}>
                  <CssTextField
                    variant="outlined"
                    fullWidth={true}
                    value={props.currentQuestionTitle}
                    name="currentQuestionTitle"
                    onChange={(event) => props.handleTextInputChange(event)}
                  ></CssTextField>
                </Box>
              </Grid>
              {/**Safety Meeting Title */}
              <Grid item md={4}>
                <Box m={2}>
                  <WhiteTextTypography component={"span"}>
                    Question Type
                  </WhiteTextTypography>
                </Box>
                <Box m={2}>
                  <CssTextField
                    variant="outlined"
                    fullWidth={true}
                    select
                    value={props.currentQuestionType}
                    name="currentQuestionType"
                    onChange={(event) => props.handleTextInputChange(event)}
                  >
                    {possibleQuestionTypes.map((elem) => (
                      <MenuItem value={elem.value} key={elem.value}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </Box>
              </Grid>
              {/**Safety Meeting Receipient's Email */}
              <Grid item md={3}>
                <Box m={2}>
                  <WhiteTextTypography component={"span"}>
                    Answers
                  </WhiteTextTypography>
                </Box>
                <Box m={2}>
                  <CssTextField
                    variant="outlined"
                    fullWidth={true}
                    value={props.currentQuestionOption}
                    name="currentQuestionOption"
                    onChange={(event) => props.handleTextInputChange(event)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AddIcon
                            onClick={() => props.addAnswerToFieldForm()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></CssTextField>
                </Box>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddIcon onClick={() => props.addFieldFormToSafetyMeeting()} />
              </Grid>
              {/**List of options*/}
              <Grid container item sm={12} lg={12}>
                <Grid item sm={12} lg={4} md={4}></Grid>
                <Grid item sm={12} lg={4} md={4}></Grid>
                <Grid item sm={12} lg={3} md={3}>
                  {props.currentQuestionOptionList.map((elem) => (
                    <Box
                      m={2}
                      p={1}
                      style={{ borderRadius: "4px", backgroundColor: "#555" }}
                      key={elem}
                    >
                      <WhiteTextTypography>{elem}</WhiteTextTypography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid container item md={12}>
                {props.currentFieldForms.length
                  ? props.questionsHeaderRow(["Question", "Type", "Options"])
                  : null}
                {props.currentFieldForms.map((elem) =>
                  props.questionContentRow(elem)
                )}
              </Grid>
            </Grid>
          ) : null}
          <Grid container className={classes.sectionOverline}>
            <Grid item md={12}>
              <Box m={2} margin="10px 16px 0px">
                <WhiteTextTypography
                  variant="h5"
                  className={classes.sectionHeading}
                >
                  Create Safety Meeting Below
                </WhiteTextTypography>
              </Box>
            </Grid>
            {/** Safety Meeting Description Textarea */}
            <Grid
              container
              item
              md={12}
              style={{ background: "var(--trade-body)" }}
            >
              <Box m={2} style={{ width: "100%" }}>
                <CssTextField
                  variant="outlined"
                  multiline
                  rows={10}
                  fullWidth={true}
                  label="Details"
                  // value={this.state.meetingDescription}
                  name="meetingDescription"
                  onChange={(event) => props.handleTextInputChange(event)}
                ></CssTextField>
              </Box>
            </Grid>
          </Grid>
          {/** create meeting button */}
          <Grid
            container
            item
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5%",
              padding: "20px 0",
            }}
          >
            <Grid item>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
              >
                <button
                  onClick={() => {
                    props.closeMeetingContainer();
                    setDrawerOpen(false);
                    props.setDrawerOpen(false);
                  }}
                  style={{
                    backgroundColor: "#F44336",
                    padding: "0.3rem 1rem",
                    fontWeight: "bold",
                    color: "var(--trade-font)",
                    border: "none",
                    borderRadius: "4px",
                    width: "100px",
                  }}
                >
                  Close
                </button>
              </Box>
            </Grid>
            <Grid item>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
              >
                <button
                  onClick={() => {
                    props.createMeeting();
                    setDrawerOpen(false);
                    props.setDrawerOpen(false);
                  }}
                  style={{
                    backgroundColor: "green",
                    padding: "0.3rem 1rem",
                    fontWeight: "bold",
                    color: "var(--trade-font)",
                    border: "none",
                    borderRadius: "4px",
                    width: "100px",
                  }}
                >
                  Save
                </button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <div className={classes.mainContainer}>
        {safetyMeeting !== null ? (
          safetyMeetingInfo()
        ) : (
          <div> No Info available </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <React.Fragment>
        <Button
          round
          style={{
            width: "100%",
            backgroundColor: "transparent",
            border: "1px solid var(--trade-primary)",
          }}
        >
          <Typography
            onClick={openDrawer}
            style={{
              cursor: "pointer",
              color: "var(--trade-primary)",
              fontWeight: "700",
            }}
          >
            Create New Safety Meeting
          </Typography>
        </Button>
        <Drawer
          anchor="right"
          open={drawerOpen || props.drawerOpen}
          onClose={closeDrawer}
        >
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

// const mapStateToProps = () => {}

// const mapDispatchToProps = () => {}

//export default connect(mapStateToProps, mapDispatchToProps)(SafetyMeetingSlider);
export default CreateSafetyMeetingSlider;
