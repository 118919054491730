import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent";

// @material-ui/icons
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import Avatar from "components/Avatar/Avatar";

// actions
import { auth } from "actions";

// styles
import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";

class LockScreenPage extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden"
        };
    }
    componentDidMount() {
        this.props.lock();
        window.location.reload();
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    login() {
        this.props.login(this.props.user.email, this.state.password);
    }
    render() {
        const { classes } = this.props;
        if (this.props.token === null)
            return <Redirect to="/login" />;
        if (this.props.user === null)
            return null;
        if (this.props.isAuthenticated)
            return <Redirect to="/dashboard" />;
        return (
            <div className={classes.container}>
                <form>
                    <Card
                        profile
                        className={
                            classes.customCardClass + " " + classes[this.state.cardAnimaton] + " " + classes.transparentCard
                        }
                    >
                        {this.props.errors.length > 0 && (
                            this.props.errors.map(function (error, id) {
                                if (error.field === "non_field_errors")
                                    return (
                                        <SnackbarContent
                                            message={error.message}
                                            color="danger"
                                            key={id}
                                        />
                                    );
                                return null;
                            })
                        )}
                        <CardAvatar profile className={classes.cardAvatar}>
                            <Avatar email={this.props.user.email} size={90} avatar_url={this.props.user.avatar} />
                        </CardAvatar>
                        <CardBody profile>
                            <h4 className={classes.cardTitle}>{this.props.user.first_name} {this.props.user.last_name}</h4>
                            <CustomInput
                                formControlProps={{
                                    className: classes.hidden
                                }}
                                inputProps={{
                                    autoComplete: "off",
                                    id: "blank_password_2",
                                    type: 'password',
                                }}
                            />
                            <CustomInput
                                labelText="Password"
                                id="password"
                                type="password"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: (e) => this.setState({ 'password': e.target.value }),
                                    onKeyUp: (e) => {
                                        if (e.keyCode === 13)
                                            this.login(e);
                                    },
                                    type: this.state.showPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {this.state.showPassword ? <Visibility onClick={() => this.setState({ 'showPassword': false })} /> : <VisibilityOff onClick={() => this.setState({ 'showPassword': true })} />}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                            <div className={classes.justifyContentLeft}>
                                <Button color="danger" round component={Link} to='/login'>
                                    Back to login
                                </Button>
                            </div>
                            <div className={classes.justifyContentRight}>
                                <Button color="success" round onClick={() => this.login()} disabled={this.state.password === '' || this.state.password === undefined}>
                                    Unlock
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </form>
            </div>
        );
    }
}

LockScreenPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    let errors = [];
    if (state.auth.errors) {
        errors = Object.keys(state.auth.errors).map(field => {
            return { field, message: state.auth.errors[field] };
        });
    }
    return {
        errors,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        lock: () => {
            return dispatch(auth.lock());
        },
        login: (username, password) => {
            return dispatch(auth.login(username, password));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(lockScreenPageStyle)(LockScreenPage));
