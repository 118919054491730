import { logException, ax } from "utils";

const endpoint = "/api/notes/";

// only in search dashboard, but will not be used later
export const fetchNotesByWorkerId = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (worker_id !== undefined) params.worker_id = worker_id;
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_WORKER_NOTES",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by tabpane in dashboard
export const fetchNotesByProjectId = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (project_id !== undefined) params.project_id = project_id;

    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_PROJECT_NOTES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreNotesByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_NOTES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const createNote = (project_id, description, time_log_id, image) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("project_id", project_id);
    formData.append("time_log_id", time_log_id);
    formData.append("description", description);
    if (image) formData.append("image", image, image.name);
    return ax
      .post(endpoint, formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "CREATE_NOTE",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

export const createNoteImage = (note_id, image) => {
  console.log("=== NOTE IMG: ", note_id, image);
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("note_id", note_id);
    formData.append("image", image, image.name);
    formData.append("is_copy", "False");

    return ax
      .post("/api/notes-images/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        console.log("=== RES", res);
        return dispatch({
          type: "NOTE_IMAGE_CREATED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// still used in old components that are not used anymore
export const deleteNote = (note_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .delete(endpoint + note_id + "/")
      .then((res) => {
        return dispatch({
          type: "DELETED_NOTE",
          item_id: note_id,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components
export const saveNote = (note_id, description) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("description", description);
    return ax
      .put(endpoint + note_id + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_NOTE",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components
export const saveNoteImage = (note_id, image) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("note_id", note_id);
    formData.append("image", image, image.name);
    formData.append("is_copy", "False");

    return ax
      .put("/api/notes-images/" + note_id + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        console.log(res);
        return dispatch({
          type: "NOTE_IMAGE_CREATED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components
export const deleteNoteImage = (image_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax.delete("/api/notes-images/" + image_id + "/").then(() => {
      return dispatch({
        type: "NOTE_IMAGE_DELETED",
        item: image_id,
      });
    });
  };
};

// used by old components (note form)
export const getNote = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_NOTE",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components (can be useful for new ones)
export const rotateImage = (image_id, degrees) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch("/api/notes-images/" + image_id + "/rotate/", { degrees })
      .then((res) => {
        return dispatch({
          type: "ROTATED_NOTE_IMAGE",
          item_id: res.data.id,
          image: res.data.image,
          is_copy: "False",
        });
      });
  };
};
