import { logException, ax } from "utils";

const endpoint = "/api/time-logs/";
// only used by searchdashboard and workertimesheetpage
export const fetchTimeLogsByWorkerId = (worker_id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (worker_id !== undefined) params.worker_id = worker_id;

    if (start !== undefined) {
      params.start = start + " 00:00";
    }
    if (end !== undefined) {
      params.end = end + " 23:59";
    }
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_WORKER_TIME_LOGS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by tabpane in dashboard
export const fetchTimeLogsByProjectId = (project_id, start, end) => {
  return (dispatch) => {
    let params = {};
    if (project_id !== undefined) params.project_id = project_id;

    // if (start !== undefined) {
    //   params.start = start + " 00:00";
    // }
    // if (end !== undefined) {
    //   params.end = end + " 23:59";
    // }
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_PROJECT_TIME_LOGS",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreTimelogsByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_TIME_LOGS",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

////// ------------------------- TO UPDATE TO REFLECT LIVE STATE BELOW --------------------------- /////////

// change type of action
export const approveTimeLog = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .post(endpoint + id + "/approve/")
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};
// change type of action
export const rejectTimelog = (id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .post(endpoint + id + "/reject/", { start, end })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// change type of action
export const updateTimelog = (id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(endpoint + id + "/", { start, end })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

////// ------------------------- TO UPDATE TO REFLECT LIVE STATE ABOVE --------------------------- /////////

// start own timelog
// updates current company and current project
export const startTimeLog = (
  project_id,
  latitude,
  longitude,
  worker_id,
  task_id,
  auto_read_safety_meeting,
  timezone,
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    if (latitude !== null) latitude = latitude.toFixed(7);
    if (longitude !== null) longitude = longitude.toFixed(7);

    if (auto_read_safety_meeting !== undefined && auto_read_safety_meeting !== false) auto_read_safety_meeting = true;

    let data = {
      project: { id: project_id },
      latitude: latitude,
      longitude: longitude,
      auto_read_safety_meeting: auto_read_safety_meeting,
      timezone: timezone,
    };

    if (worker_id !== undefined && worker_id !== "")
      data["user_id"] = worker_id;

    if (task_id !== undefined && task_id !== "") data["task_type_id"] = task_id;

    return ax
      .post(endpoint, data)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// stop own timelog
// updates current company and current project
export const stopTimeLog = (id, latitude, longitude) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(endpoint + id + "/", { latitude, longitude })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        dispatch({
          type: "TIME_LOG_STOPPED",
          item: res.data,
        });
        return dispatch({
          type: "GET_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// another worker timelog (updates last_time_log of object in list of users in store)
// updates users_on_project in respective object in list of projects in store
export const startWorkerTimelog = (
  project_id,
  latitude,
  longitude,
  worker_id,
  task_id
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    if (latitude !== null) latitude = latitude.toFixed(7);
    if (longitude !== null) longitude = longitude.toFixed(7);

    let data = {
      project: { id: project_id },
      latitude: latitude,
      longitude: longitude,
      user_id: worker_id,
      task_type_id: task_id,
    };

    return ax.post(endpoint, data).then((res) => {
      dispatch({ type: "DATA_SAVED" });
      return dispatch({
        type: "GET_WORKER_TIME_LOG",
        item: res.data,
      });
    });
  };
};

// another worker timelog (updates last_time_log of object in list of users in store)
// updates users_on_project in respective object in list of projects in store
export const stopWorkerTimer = (id, latitude, longitude) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(endpoint + id + "/", { latitude, longitude })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        dispatch({
          type: "WORKER_TIME_LOG_STOPPED",
          item: res.data,
        });
        return dispatch({
          type: "GET_WORKER_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// updates current company and current project
export const startBreak = (project_id, latitude, longitude, worker_id, timezone) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    if (latitude !== null) latitude = latitude.toFixed(7);
    if (longitude !== null) longitude = longitude.toFixed(7);

    let data = {
      project: { id: project_id },
      latitude: latitude,
      longitude: longitude,
      type: "break",
      timezone,
    };

    if (worker_id !== undefined && worker_id !== "") {
      data["worker_id"] = worker_id;
    }

    return ax
      .post(endpoint, data)
      .then((res) => {
        dispatch({
          type: "TIME_LOG_STOPPED",
          item: res.data,
        });
        return dispatch({
          type: "GET_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// updates current company and current project
export const startDelivery = (project_id, latitude, longitude, worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    if (latitude !== null) latitude = latitude.toFixed(7);
    if (longitude !== null) longitude = longitude.toFixed(7);

    let data = {
      project: { id: project_id },
      latitude: latitude,
      longitude: longitude,
      type: "delivery",
    };

    if (worker_id !== undefined && worker_id !== "") {
      data["worker_id"] = worker_id;
    }

    return ax
      .post(endpoint, data)
      .then((res) => {
        dispatch({
          type: "TIME_LOG_STOPPED",
          item: res.data,
        });
        return dispatch({
          type: "GET_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components
export const fetchTimeLogsByCompanyId = (company_id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (company_id !== undefined) params.company_id = company_id;

    if (start !== undefined) {
      params.start = start + " 00:00";
    }
    if (end !== undefined) {
      params.end = end + " 23:59";
    }
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_TIME_LOGS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// only used by old components
export const fetchTimeLogs = (worker_id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (worker_id !== undefined) params.worker_id = worker_id;

    if (start !== undefined) {
      params.start = start + " 00:00";
    }
    if (end !== undefined) {
      params.end = end + " 23:59";
    }
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_TIME_LOGS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used by old components
export const getTimeLog = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_WORKER_TIME_LOG",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};
