import React from "react";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from 'react-router-dom';
import GridItem from "components/Grid/GridItem.jsx";
import Moment from 'react-moment';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody";

import Button from "components/CustomButtons/Button.jsx";

import onSiteStyle from "./OnSiteStyle";
import {time_logs, projects, breaks, notes} from 'actions';
import { getUserTimezone } from "utils";
import LoadingCircularIcon from "../LoadingCircularIcon";

class OffSite extends React.Component {
    timer = null;
    state = {
        forceRedirect: null,
        saving: false,
        savingBreak: false,
        savingStop: false,
    };

    componentDidMount() {
        const project_id = this.props.user.last_time_log.project.id;
        this.props.getNotes(project_id);
    }

    stopTimer() {
        const { user, stopTimer, getProjects, latitude, longitude } = this.props;

        this.setState({ projectSelected: user.last_time_log.project.id, saving: true, savingStop: true, savingBreak: false }, () => {
            stopTimer(user.last_time_log.id, latitude, longitude).then(() => {
                this.timer = setTimeout(getProjects, 200);
            });
        });
    }

    startBreak() {
        const last_time_log = this.props.user.last_time_log;
        const { latitude, longitude, startBreak } = this.props;
        this.setState({ saving: true, savingBreak: true, savingStop: false }, () =>
            startBreak(last_time_log.project.id, latitude, longitude, last_time_log.user, getUserTimezone())
        );
    }

    addNote() {
        const { user } = this.props;
        const project_id = user.last_time_log.project.id;
        this.setState({ forceRedirect: "/projects/" + project_id + "/notes/add" });
    }

    componentWillUnmount() {
        if(this.timer !== null){
            clearTimeout(this.timer);
        }
    }

    renderNotesList(project_id) {
        const { notes, user } = this.props;
        const last_time_log = user.last_time_log;

        return (
          <React.Fragment>
              {notes.length > 0?
                notes.length + " note"
              :null}
              {notes.length > 1? 's':null}
              <p>
                  {notes.length > 0?
                  <Button round color="info" component={Link} to={"/projects/" + project_id + "/notes"}>View List</Button>
                    :null}
                  <Button
                    round
                    color="info"
                    onClick={() => this.setState({ forceRedirect: "/projects/" + project_id + "/notes/add?time_log_id=" + last_time_log.id })}
                    disabled={this.state.saving}
                  >
                      Add a note
                  </Button>
              </p>
          </React.Fragment>
        );
    }

    render() {
        const { classes, user, latitude, longitude } = this.props;
        const { saving, savingBreak, savingStop } = this.state;
        const last_time_log = user.last_time_log;
        if(this.state.forceRedirect !== null)
            return <Redirect to={this.state.forceRedirect} />;
        if(user.on_break)
            return null;

        return (
            <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            On-site
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <p>You started working at <b><Moment format={"LT"}>{last_time_log.start}</Moment></b>.</p>
                        <p>You started working <b><Moment fromNow>{last_time_log.start}</Moment></b> on project <b>{last_time_log.project.title}</b></p>
                        {this.renderNotesList(last_time_log.project.id)}
                    </CardBody>
                    <CardFooter stats>
                      <div className={classes.stats}>
                          {user.gps_forced && longitude === null && latitude === null? (
                              <div style={{width: "100%"}}>
                                  <LoadingCircularIcon strokeColor={"#fff"} loading={true} styles={{margin: "-10px 5px -5px 0px"}}/>
                                  <span>
                                        Waiting for GPS lock.
                                    </span>
                              </div>
                          ):null}
                          <Button round color="info" onClick={() => this.startBreak()} disabled={saving || (user.gps_forced && longitude === null && latitude === null)}>
                            <LoadingCircularIcon strokeColor={"#fff"} loading={savingBreak} styles={{margin: "-10px 5px -5px -20px"}}/>
                            Break
                        </Button>
                        <Button round color="danger" size="lg" onClick={() => this.stopTimer()} disabled={saving || (user.gps_forced && longitude === null && latitude === null)}>
                            <LoadingCircularIcon strokeColor={"#fff"} loading={savingStop} styles={{margin: "-10px 5px -5px -20px"}} disabled={user.gps_forced && (longitude === null || latitude === null)}/>
                            Stop
                        </Button>
                      </div>
                    </CardFooter>
                </Card>
            </GridItem>
        );
    }
}

OffSite.propTypes = {
    user: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
};
OffSite.defaultProps = {
    longitude: null,
    latitude: null,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        notes: state.notes.items,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        stopTimer: (time_log_id, latitude, longitude) => {
            return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
        },
        startBreak: (project_id, latitude, longitude, worker_id, timezone) => {
            return dispatch(breaks.startBreak(project_id, latitude, longitude, worker_id, timezone));
        },
        getProjects: () => {
            return dispatch(projects.fetchProjects());
        },
        getNotes: (project_id) => {
            return dispatch(notes.fetchNotesByProjectId(project_id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(onSiteStyle)(OffSite));
