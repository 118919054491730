import Pages from "layouts/Pages.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import dashroutes from "routes/dashboard";
import SiteSelection from "newComponents/SiteSelection/SiteSelection.jsx";
import BreakPage from "newComponents/BreakPage/BreakPage.jsx";

let indexRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    public: false,
  },
];

let filteredDashboard = dashroutes
  .filter((prop) => {
    return !prop.collapse && prop.path !== "/dashboard" && prop.path !== null;
  })
  .map((prop) => {
    return {
      path: prop.path,
      name: prop.name,
      component: Dashboard,
      public: false,
    };
  });

let subroutes = dashroutes.filter((prop) => {
    return prop.children !== undefined
  }).map(parent => {
    return parent.children.map(child => {
      return {
        path: child.path,
        name: child.name,
        component: Dashboard,
        public: false,
      };
    })
  })
subroutes = subroutes.flat()

indexRoutes = [...indexRoutes, ...filteredDashboard, ...subroutes];
indexRoutes.push({ path: "/", name: "Home", component: Pages, public: true });
// indexRoutes += filteredDashboard;

export default indexRoutes;
