import React, { useEffect, useState } from "react";
import "./WorkerTabPane.css";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.jsx";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "var(--trade-body)",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

const SimpleListWorker = (props) => {
  const classes = useStyles();
  const { items, noteImages, nextUrl } = props;
  const [filteredFiles, setFilteredFiles] = useState([]);

  const open_file = (file) => {
    window.open(file.file, "_blank");
  };

  useEffect(() => {
    let temps = [];
    if (props.type === "photos") {
      const note_images_temp = props.noteImages.map((elem, index) => ({
        id: elem.id,
        date_created: elem.date_created,
        file: elem.image,
        name: "note image " + index,
      }));

      temps = [...items, ...note_images_temp];
    } else {
      temps = items.filter((elem) => elem.file_type === "document");
    }

    setFilteredFiles(temps);
  }, [items, noteImages]);

  return (
    <div className="projectTimeSheetWrapper" id="style-5">
      {filteredFiles.length !== 0 ? (
        props.type === "files" ? (
          <div>
            <Grid container spacing={2} className="file-grid">
              <Grid item xs={10}>
                <Box
                  mt={1}
                  justifyContent="center"
                  display="flex"
                  alignContent="center"
                >
                  <ListItem
                    style={{ padding: 0, color: "#FFFFF", fontWeight: "bold" }}
                  >
                    <ListItemText>Name</ListItemText>
                  </ListItem>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  mt={1}
                  justifyContent="center"
                  display="flex"
                  alignContent="center"
                >
                  <ListItem
                    style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
                  >
                    <ListItemText>Date Added</ListItemText>
                  </ListItem>
                </Box>
              </Grid>
            </Grid>
            <List>
              {filteredFiles.map((item, key) => (
                // const dateCreated = moment(item.date_created).format("MM/DD/YYYY");
                <Grid key={key} container spacing={2} className="file-grid">
                  <Grid item xs={10}>
                    <Box
                      mt={1}
                      justifyContent="center"
                      display="flex"
                      alignContent="center"
                    >
                      <ListItem
                        style={{
                          padding: 0,
                          color: "var(--trade-primary)",
                          cursor: "pointer",
                        }}
                        key={key}
                      >
                        <ListItemText key={key} onClick={() => open_file(item)}>
                          {item.name}
                          {item.restricted && (
                            <p className="restricted-notice">
                              <i>Restricted File</i>
                            </p>
                          )}
                        </ListItemText>
                      </ListItem>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      mt={1}
                      justifyContent="center"
                      display="flex"
                      alignContent="center"
                    >
                      <ListItem
                        style={{
                          padding: 0,
                          color: "#ffffff",
                          cursor: "pointer",
                        }}
                      >
                        <ListItemText>
                          {moment(item.date_created).format("MM/DD/YYYY")}
                        </ListItemText>
                      </ListItem>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </List>
          </div>
        ) : (
          <div className="photo-grid-project">
            {filteredFiles.map((item, key) => (
              <Grid key={item.id} container spacing={2} className="file-grid">
                <Grid item xs={10}>
                  <ListItem style={{ padding: 0, color: "#ff6600" }}>
                    <img
                      onClick={() => open_file(item)}
                      src={item.thumbnail ? item.thumbnail : item.file}
                      alt="photo"
                      style={{
                        width: "110px",
                        height: "110px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </ListItem>
                </Grid>
              </Grid>
            ))}
          </div>
        )
      ) : (
        <h1 className="no-files-message">
          No {props.type} Uploaded by the Worker
        </h1>
      )}
      {props.nextUrl || props.noteImagesNext ? (
        <Button
          round
          className={classes.nextButton}
          onClick={() => props.fetchMore()}
        >
          Load More
        </Button>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

//export default connect(mapStateToProps, mapDispatchToProps)(SimpleListWorker);
export default SimpleListWorker;
