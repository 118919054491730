import React from "react";
import {SvgIcon} from "@material-ui/core";

/**
 * @return {null}
 */
function LoadingIcon(props) {
    if (props.loading !== true)
        return null;
    let styles = {};
    if(props.styles !== undefined){
        styles = props.styles;
    }
    let strokeColor = props.strokeColor;
    if(strokeColor === undefined)
        strokeColor = "#fff";

    return (
        <SvgIcon viewBox={"0 0 100 100"} stroke={strokeColor} style={styles}>
            <g transform="rotate(0 50 50)">
                <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.375s" repeatCount="indefinite"></animate>
                </rect>
            </g><g transform="rotate(30 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.25s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(60 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1.125s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(90 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-1s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(120 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.875s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(150 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.75s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(180 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.625s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(210 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.5s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(240 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.375s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(270 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.25s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(300 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="-0.125s" repeatCount="indefinite"></animate>
            </rect>
        </g><g transform="rotate(330 50 50)">
            <rect x="45" y="0" rx="9" ry="0" width="10" height="20" fill={strokeColor}>
                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.5s" begin="0s" repeatCount="indefinite"></animate>
            </rect>
        </g>
        </SvgIcon>
    )
}

export default LoadingIcon;
