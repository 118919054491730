import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardRoutes from "routes/dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import SideNav from "../newComponents/SideNav/SideNav";
import NavBar from "../newComponents/NavBar/Navbar";
import GuidePopover from "../newComponents/GuidePopover/GuidePopover";

import "./DashboardLayout.css";

let subroutes = dashboardRoutes
  .filter((prop) => {
    return prop.children !== undefined;
  })
  .map((parent) => {
    return parent.children.map((child) => {
      return {
        path: child.path,
        name: child.name,
        component: child.component,
      };
    });
  });
subroutes = subroutes.flat();

const switchRoutes = (
  <Switch>
    {subroutes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          component={prop.component}
          key={"dashboard-sub-" + key}
        />
      );
    })}
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return (
          <Redirect
            from={prop.path}
            to={prop.pathTo}
            key={"dashboard-" + key}
          />
        );
      if (prop.path !== null) {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            exact
            key={"dashboard-" + key}
          />
        );
      }
    })}
  </Switch>
);

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      forceRedirect: null,
      generalChatSocket: {},
      timelogSocket: {},
      companySocket: {},
      newWorkerCreated: false,
      workerUpdated: "",
    };
    this.topToolbarRef = React.createRef();
    this.leftNavigationRef = React.createRef();

    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);

    if (this.props.auth.isAuthenticated) {
      //integrate intercom chatbot
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "qwx9ct6t",
        name: this.props.auth.user.first_name,
        email: this.props.auth.user.email, // Email address
        // created_at: "123456" // Signup date as a Unix timestamp
      });
      this.generalChatSocket = this.connectWebSockets(
        this.generalChatSocket,
        process.env.WS_URL +
        "/ws/chat/?type=general&token=" +
        this.props.auth.token,
        "generalChat"
      );

      this.timelogSocket = this.connectWebSockets(
        this.timelogSocket,
        process.env.WS_URL + "/ws/time-logs/?token=" + this.props.auth.token,
        "timelogSocket"
      );

      this.companySocket = this.connectWebSockets(
        this.companySocket,
        process.env.WS_URL +
        "/ws/company/?token=" +
        this.props.auth.token +
        "&company_id=" +
        this.props.auth.user.current_company,
        "companySocket"
      );
      // this.connectWebSockets(this.companyChatSocket, process.env.WS_URL + "/ws/chat/?type=company&company_id=token=");
    }
  }

  connectWebSockets(socket, connectionString, niceName) {
    socket = new WebSocket(connectionString);
    // Try to reconnect if connection is lost
    socket.onclose = () => {
      setTimeout(() => {
        this.connectWebSockets(socket, connectionString);
      }, 5000);
    };

    socket.onmessage = (event) => {
      if (event !== undefined && event.data !== undefined) {
        const data = JSON.parse(event.data);
        const last_time_log = this.props.auth.user.last_time_log;

        if (data.type === "update_worker") {
          this.setState({ workerUpdated: "update_worker" });
        } else if (data.type === "new_worker") {
          this.setState({ workerUpdated: "new_worker" });
        } else if (data.type === "new_worker_user") {
          this.setState({ workerUpdated: "new_worker_user" });
        }
        //console.debug('New message for ' + niceName, data)
        if (data.type === "new_message") {
        } else if (data.type === "room_joined") {
        } else if (data.type === "new_time_log") {
          // No need to redirect if we just started our timer, it should be used in case our state
          // is no longer in sync (timer started from the mobile for example, or another browser)
          if (
            last_time_log?.id !== data.time_log.id ||
            last_time_log?.safety_meeting_read !==
            data.time_log.safety_meeting_read
          ) {
            // We should only refresh the user data, but this will do for now
            //this.redirect();
          }
        } else if (data.type === "time_log_updated" && data.new_time_log === false) {
          if (
            last_time_log?.id !== data.time_log.id ||
            last_time_log?.safety_meeting_read !==
            data.time_log.safety_meeting_read ||
            last_time_log?.end !== data.time_log.end
          ) {
            this.redirect();
          }
        } else if (data.type === "safety_meeting_read") {
          if (
            last_time_log?.id !== data.time_log.id ||
            last_time_log?.safety_meeting_read !==
            data.time_log.safety_meeting_read
          ) {
            // We should only refresh the user data, but this will do for now
            this.redirect();
          }
        }
      }
    };

    socket.onopen = (event) => {
      //console.log('chat socket open for ' + niceName)
    };
    return socket;
  }

  disconnectWebSockets() {
    this.generalChatSocket.onclose = null;
    this.generalChatSocket.close();
    this.timelogSocket.onclose = null;
    this.timelogSocket.close();
    this.companySocket.onclose = null;
    this.companySocket.close();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    this.disconnectWebSockets();
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }

  hideLayout() {
    const dashboard_routes = dashboardRoutes.filter(
      (r) => r.path === this.props.location.pathname
    );
    if (dashboard_routes.length > 0) {
      return dashboard_routes[0].hideLayout;
    }

    // Also looks for some routes, just in case
    const subroutes_matched = subroutes.filter(
      (r) => r.path === this.props.location.pathname
    );
    if (subroutes_matched.length > 0) {
      return subroutes_matched[0].hideLayout;
    }
    return false;
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  redirect = () => {
   window.location.reload();
  };

  // REF SET
  setTopToolbarRef = (ref) => {
    this.topToolbarRef.current = ref;
  };

  setLeftNavigationRef = (ref) => {
    this.leftNavigationRef.current = ref;
  };

  handleNewWorkerCreated = () => {
    this.setState({ newWorkerCreated: !this.state.newWorkerCreated });
  };

  render() {
    const { classes, help_faq } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    if (this.state.forceRedirect !== null)
      return <Redirect to={this.state.forceRedirect} />;

    let ref = null;
    if (help_faq.exStep === 4) {
      ref = this.leftNavigationRef;
    } else if (help_faq.exStep === 2) {
      ref = this.topToolbarRef;
    }
    const hideLayout = this.hideLayout();
    return (
      <div className={classes.wrapper}>
        {hideLayout ? (
          <React.Fragment>
            <div ref="mainPanel">
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <NavBar
              redirect={this.redirect}
              setRef={this.setTopToolbarRef}
              handleNewWorkerCreated={this.handleNewWorkerCreated}
              newWorkerCreated={this.state.newWorkerCreated}
            />
            <SideNav
              setRef={this.setLeftNavigationRef}
              handleNewWorkerCreated={this.handleNewWorkerCreated}
              workerUpdated={this.state.workerUpdated}
            />
            <div
              className={mainPanel}
              ref="mainPanel"
              id="style-5"
              style={{ width: "calc(100% - 85px)" }}
            >
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
            </div>
          </React.Fragment>
        )}
        {/* // temporarily removed FAQ popover
        {(help_faq.exStep === 2 || help_faq.exStep === 4) && (
          <GuidePopover
            id={help_faq.exData[help_faq.exStep]?.id}
            show={help_faq.exShow}
            comp={ref}
            anchorOrigin={help_faq.exData[help_faq.exStep]?.anchorOrigin}
            transformOrigin={help_faq.exData[help_faq.exStep]?.transformOrigin}
            title={help_faq.exData[help_faq.exStep]?.title}
            description={help_faq.exData[help_faq.exStep]?.content}
            arrow={help_faq.exData[help_faq.exStep]?.arrow}
          />
        )} */}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {

  return {
    help_faq: state.help_faq,
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(withStyles(appStyle)(Dashboard));
