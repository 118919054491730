import { logException, ax } from "utils";
const endpoint = "/api/project-files/";

export const fetchFilesByProjectId = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "?project_id=" + project_id + "&file_type=document")
      .then((res) => {
        return dispatch({
          type: "FETCH_PROJECT_FILES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchImagesByProjectId = (projectId) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "?project_id=" + projectId + "&file_type=image")
      .then((res) => {
        return dispatch({
          type: "FETCH_PROJECT_IMAGES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreFilesByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_FILES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreImagesByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_IMAGES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const getImagesFromProjectNotes = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get("/api/notes-images/?project_id=" + project_id)
      .then((res) => {
        return dispatch({
          type: "GET_NOTE_IMAGES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreNoteImagesByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_NOTE_IMAGES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const createFile = (project_id, name, file, restricted) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("project", project_id);
    formData.append("name", name);
    formData.append("restricted", restricted);
    if (file) formData.append("file", file, file.name);

    return ax
      .post(endpoint, formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "FILE_CREATED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

export const createImage = (project_id, name, file) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("project", project_id);
    formData.append("name", name);
    if (file) formData.append("file", file, file.name);

    return ax
      .post(endpoint, formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "IMAGE_CREATED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in old components (in dashboard too, but will be changed)
export const fetchFilesByWorkerId = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "?worker_id=" + worker_id)
      .then((res) => {
        return dispatch({
          type: "FETCH_FILES",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// not used
export const getFile = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_FILE",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in old components
export const saveFile = (id, name, project_id, file) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("project", project_id);
    formData.append("name", name);
    if (file) formData.append("file", file, file.name);

    return ax
      .patch(endpoint + id + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "FILE_SAVED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

export const updateFile = (name, file_id, restricted) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let formData = new FormData();
    formData.append("name", name);
    formData.append("restricted", restricted);

    return ax
      .patch(endpoint + file_id + "/", formData)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "FILE_UPDATED",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in old components
export const deleteFile = (item_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .delete(endpoint + item_id + "/")
      .then((res) => {
        if (res.status === 204) {
          return dispatch({
            type: "FILE_DELETED",
            item_id: item_id,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};
