import React, { useState } from 'react';
import { Typography, Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { help_tickets } from 'actions';
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from "react-router-dom";

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        "& .MuiInputBase-root": {
            color: "white",
            backgroundColor: "transparent",
        },
        "& .MuiFormLabel-root": {
            color: "white",
        },
        "& .MuiInputBase-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #555 inset !important",
            WebkitTextFillColor: "var(--trade-font) !important",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--trade-success)',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    stepDescription: {
        textAlign: "center",
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function HelpTicketForm(props) {
    const classes = useStyles();
    const [question, setQuestion] = useState("");
    const [details, setDetails] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [successfullyCreated, setSuccessfullyCreated] = useState(false);

    const handleQuestionChange = (ev) => {
        setQuestion(ev.target.value);
    }

    const handleDetailsChange = (ev) => {
        setDetails(ev.target.value);
    }

    const ticketCreated = () => {
        //show succcess, redirect to dashboard
        setSnackOpen(true);
        setSeverity("success");
        setErrorMessage("Your ticket was created, we'll get back to you soon.");

        setTimeout(function wait() { setSuccessfullyCreated(true) }, 3000);
    }

    const createTicket = () => {
        if (question !== "" && details !== "") {
            const company = props.user.last_time_log.project.company;
            console.log(company);

            props.createTicket(question, details, props.user.id, company).then((elem) => {
                if (elem.type === "HELP_TICKET_CREATED") {
                    ticketCreated();
                } else {
                    // show error message
                    setSnackOpen(true);
                    setSeverity("error");
                    setErrorMessage("Unable to create ticket, please try again later.");
                }
            })
        } else {
            //show snackbar
            setSnackOpen(true);
            setSeverity("error");
            setErrorMessage("Please make sure to fill both boxes");
        }
    }

    return (
        <div style={{ backgroundColor: "var(--trade-body)", minHeight: "100%", width: "100%", position: "absolute", padding: "2rem" }}>
            {
                (!successfullyCreated)
                    ? <Grid container>
                        <Grid item md={12}>
                            <Box m={1} justifyContent="center"
                                alignItems="center" display="flex">
                                <WhiteTextTypography variant="h4" component={'span'} className={classes.stepDescription}>
                                    Create A Help Ticket
                                </WhiteTextTypography>
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box m={1} justifyContent="center"
                                alignItems="center" display="flex">
                                <WhiteTextTypography component={'span'} className={classes.stepDescription}>
                                    This ticket will be received by our team and one of our members will reach out to help you.
                                </WhiteTextTypography>
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box m={2}></Box>
                        </Grid>
                        <Grid container item md={12}>
                            <Grid item md={3}></Grid>
                            <Grid item md={6}>
                                <Box m={1}>
                                    <CssTextField label="Question" fullWidth={true} variant="outlined" value={question} onChange={handleQuestionChange} name="question" />
                                </Box>
                            </Grid>
                            <Grid item md={3}></Grid>
                        </Grid>
                        <Grid container item md={12}>
                            <Grid item md={3}></Grid>
                            <Grid item md={6}>
                                <Box m={1}>
                                    <CssTextField
                                        label="Details"
                                        fullWidth={true}
                                        variant="outlined"
                                        value={details}
                                        onChange={handleDetailsChange}
                                        name="details"
                                        multiline
                                        rows={5}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3}></Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Box m={2} justifyContent="center"
                                alignItems="center" display="flex">
                                <button
                                    style={{
                                        backgroundColor: "green", padding: "0.7rem 2rem",
                                        fontWeight: "bold", color: "white", border: "none", borderRadius: "4px"
                                    }}
                                    onClick={createTicket}
                                >
                                    Create Ticket
                                </button>
                                <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
                                    <Alert onClose={() => setSnackOpen(false)} severity={severity}>
                                        {errorMessage}
                                    </Alert>
                                </Snackbar>
                            </Box>
                        </Grid>
                    </Grid>
                    : <Redirect to="/dashboard" />
            }

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createTicket: (question, details, created_by, company) => {
            return dispatch(help_tickets.createHelpTicket(question, details, created_by, company));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpTicketForm);