import React from 'react';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

// theme components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// custom code
import {user_positions, workers} from "actions";
import reportStyle from "assets/jss/tradespecifix/report";
import BaseComponent from "../BaseComponent";
import Map from "components/Workers/Map";

class WorkerMapReport extends BaseComponent {
    constructor(props){
        super(props);
        const worker_id = this.props.match.params.id;
        this.state = this.getInitState({
            'worker_id': worker_id,
            'positions':[],
        });
        this.props.getWorker(this.state.worker_id);
        this.props.getUserPositions(
            this.state.worker_id,
            this.state.start.format('YYYY-MM-DD'),
            this.state.end.format('YYYY-MM-DD')
        );
    }

    getInitState(state_) {
        const start = moment().weekday(0);
        const currentDate = moment();
        const end = moment().weekday(6);
        return super.getInitState({
            ...state_,
            'start': start,
            'end': end,
            'currentDate': currentDate,
        });
    }

    changeDate(start, end){
        this.setState({start, end});
        if(start !== null && end !== null){
            this.props.getUserPositions(
                this.state.worker_id,
                start.format('YYYY-MM-DD'),
                end.format('YYYY-MM-DD')
            );
        }
    }

    renderHeader() {
        const { start, end } = this.state;
        return (
            <DateRangePicker
                startDate={start}
                startDateId="START_DATE" // PropTypes.string.isRequired,
                endDate={end}
                endDateId="END_DATE" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.changeDate(startDate, endDate)} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                minimumNights={0}
                isOutsideRange={() => false}
                />
        );

    }

    renderMap() {
        const { classes, positions, user } = this.props;
        if(positions.length === 0 || user === undefined || user.id === undefined)
            return null;

        return (
            <div>
                <GridContainer>
                    <GridItem className={classes.fullWidth} container justify="center">
                        <Map positions={positions}/>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderMap()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        positions: state.user_positions.items,
        user: state.workers.item,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserPositions: (worker_id, start, end) => {
            return dispatch(user_positions.fetchUserPositionsByWorkerId(worker_id, start, end));
        },
        getWorker: (worker_id) => {
            return dispatch(workers.getWorker(worker_id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(reportStyle)(WorkerMapReport));
