import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import "./SafetyMeetingGPSSlider.css";
import LastGPSLocation from "../LastGPSLocation/LastGPSLocation";

var moment = require("moment"); // require

function TemporaryDrawer(props) {
  const [right, setRight] = React.useState(false);

  const toggleDrawer = (open, state) => () => {
    switch (state) {
      case "open":
        setRight(open);
        break;

      case "close":
        setRight(open);
        break;

      default:
        console.log("default ");
        break;
    }
  };

  const list = (type) => {
    if (type === "gps-view") {
      return (
        <div
          role="presentation"
          onKeyDown={toggleDrawer(false, "close")}
          style={{ width: "50vw" }}
        >
          <div className="worker-gps-location-map-container">
            <div className="worker-gps-location-map">
              <LastGPSLocation
                latitude={props.gps_details.latitude}
                longitude={props.gps_details.longitude}
                worker_gps={props.gps_details}
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {" "}
            <span>
              Time In:{" "}
              {moment(props.gps_details.start).format("LT, Do MMMM YYYY")}
            </span>
          </div>
        </div>
      );
    }
    if (type === "gps-view-current") {
      return (
        <div
          role="presentation"
          onKeyDown={toggleDrawer(false, "close")}
          style={{ width: "50vw" }}
        >
          <div className="worker-gps-location-map-container">
            <div className="worker-gps-location-map">
              <LastGPSLocation
                latitude={props.gps_info.last_time_log.latitude}
                longitude={props.gps_info.last_time_log.longitude}
                worker_gps={props.gps_info}
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {" "}
            <span>
              Time In:{" "}
              {moment(props.gps_info.last_time_log.start).format(
                "LT, Do MMMM YYYY"
              )}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <React.Fragment>
        {props.type === "gps-view" || props.type === "safety-meeting-view" ? (
          <Button onClick={toggleDrawer(true, "open")}>View</Button>
        ) : (
          <Button
            style={{ color: "orange" }}
            onClick={toggleDrawer(true, "open")}
          >
            View
          </Button>
        )}
        <Drawer
          anchor="right"
          open={right}
          onClose={toggleDrawer(false, "close")}
        >
          {list(props.type)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);
