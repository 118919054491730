import React, { Component } from "react";
import { Avatar, Popover, Typography } from "@material-ui/core";
import "./ProjectInfoBoard.css";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import CardCarousel from "../../newComponents/CardCarousel/CardCarousel";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Gravatar from "react-gravatar";
import {
  projects,
  workers,
  time_logs,
  notes,
  files,
  user_positions,
  safety_meetings,
  companies,
} from "actions";
import ArchiveIcon from "@material-ui/icons/Archive";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "react-bootstrap/Spinner";
import CurrentProjectMap from "../CurrentProjectMap/CurrentProjectMap";
import TabPane from "../Tabs/TabPane";
import AddFiles from "../AddFiles/AddFiles";
import ProjectListDashboard from "../ProjectListDashboard/ProjectListDashboard";
import ProjectSlider from "newComponents/ProjectSlider/ProjectSlider";
import SideDrawerWorker from "../SideDrawerWorker/SideDrawerWorker";
import SafetyMeetingGPS from "../SafetyMeetingGPSSlider/SafetyMeetingGPSSlider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import WhiteTypography from "newComponents/SharedComponents/WhiteTypography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import GuidePopover from "../GuidePopover/GuidePopover";

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const RedTypography = withStyles({
  root: {
    color: "var(--trade-tertiary)",
  },
})(Typography);

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "transparent",
      margin: "0",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiInputBase-formControl": {
      height: "80%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    fontSize: "15px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const projectStyles = (theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    backgroundColor: "var(--trade-background)",
    margin: "0.5rem",
    height: "3rem",
    alignItems: "center",
    borderTop: "solid 2px var(--trade-neutral)",
  },
  tableHeaders: {
    backgroundColor: "var(--trade-background)",
    // height: "3rem",
    margin: "0.5rem",
    alignItems: "center",
    borderTop: "solid 2px var(--trade-neutral)",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    color: "var(--trade-font)",
  },

  centeredText: {
    margin: "auto",
  },

  spinner: {
    margin: "auto",
    textAling: "center",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red",
  },
});

class ProjectInfoBoard extends Component {
  constructor(props) {
    super(props);
    this.projectNameRef = React.createRef();
    this.geoFenceRef = React.createRef();
    this.fileNotesRef = React.createRef();
    this.otherProjectsRef = React.createRef();

    this.state = {
      progressValue: 0,
      autoCompleteKey: "",
      currentYear: "",
      possibleYears: [],
      fileCreated: false,
      noteCreated: false,
      photoCreated: false,
      activeProject: null,
      dialogOpen: false,
      tempProjectStatus: "",
    };
  }
  classes = this.props.classes;
  intervalID;

  currentUser;

  tableHeaders = [
    "Worker",
    "Task",
    "Time In",
    "Last Break",
    "Hours (H:MM:SS)",
    "GPS",
    "",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  setInputValue = (e) => {
    console.log(e);
  };

  componentDidMount() {
    this.currentUser = this.props.user;
    this.getData();

    this.props.fetchWorkers();
    this.props.fetchSafetyMeetings();

    this.generateYears();
  }

  generateYears() {
    const company_created = new Date(
      this.props.user.last_time_log.project.company_created
    );
    const today = new Date();
    const years = [];
    for (let i = company_created.getFullYear(); i <= today.getFullYear(); i++) {
      years.push(i);
    }
    this.setState(
      {
        possibleYears: years,
      },
      () => {
        this.setState({
          currentYear: today.getFullYear(),
        });
      }
    );
  }

  getData = () => {
    this.props
      .getProjectById(this.props.user.last_time_log.project.id)
      .then((res) => {
        // this.intervalID = setTimeout(this.getData.bind(this), 15000);
      });
  };

  handleChange = (event, project) => {
    let newProject = { ...project };
    newProject.status = event.target.value;
    this.props.updateProjectStatus(newProject);
  };

  handleArchive = (project) => {
    this.setState({
      dialogOpen: true,
      activeProject: project,
    });
  };

  confirmationDialog = () => {
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={() => this.setState({ dialogOpen: false })}
      >
        <DialogTitle>
          Are you sure you want to archive this project?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => this.setState({ dialogOpen: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={(id) => this.archiveProject()}
            color="primary"
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  tableRow = (project) => {
    const startDate = new Date(project.date_created);
    const endDate = new Date(project.expected_completion);
    const formattedStartDate = `${this.months[startDate.getMonth()]
      } ${startDate.getDate()}, ${startDate.getFullYear()}`;
    const formattedEndDate = `${this.months[endDate.getMonth()]
      } ${endDate.getDate()}, ${endDate.getFullYear()}`;

    // After clicking on Archive project, a project will be archived but will still be in our props.projects list
    // Its status is updated though, so let's return nothing if the project is still in the list, but archived.
    if (project.archived) return null;

    return (
      <Grid
        container
        item
        md={12}
        className={this.classes.tableRow}
        key={project.id}
      >
        {/* Project Title */}
        <Grid container item md={2}>
          <Grid item md={11}>
            <Box m={1} margin="0" marginLeft="1rem">
              <HtmlTooltip
                title={
                  <Typography
                    color="inherit"
                    variant="h5"
                    style={{ textTransform: "capitalize" }}
                  >
                    {project.title === "Office Work"
                      ? this.props.user.last_time_log.task_type?.title
                      : project.title}
                  </Typography>
                }
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <AmberTypography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {project.title === "Office Work"
                    ? this.props.user.last_time_log.task_type?.title
                    : project.title}
                </AmberTypography>
              </HtmlTooltip>
            </Box>
          </Grid>
        </Grid>
        {/* Project Schedule */}
        <Grid item md={3}>
          <Box
            // display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {formattedStartDate}
              {project.expected_completion ? `- ${formattedEndDate}` : null}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Manager */}
        <Grid item md={2}>
          <Box
            // display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" variant="h5">
                    {project.project_manager
                      ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                      : "No Project Manager"}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1"> Contact </Typography>
                  <Typography
                    variant="body2"
                    style={{ textTransform: "lowercase" }}
                  >
                    {project.project_manager
                      ? project.project_manager.email
                      : "No email"}
                  </Typography>
                  <Typography variant="body2">
                    Phone Number:
                    {project.project_manager
                      ? `${project.project_manager.phone_number}`
                      : "No Number"}
                  </Typography>
                </React.Fragment>
              }
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <WhiteTextTypography className={this.classes.centeredText}>
                {project.project_manager
                  ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                  : "No Project Manager"}
              </WhiteTextTypography>
            </HtmlTooltip>
          </Box>
        </Grid>
        {/* Project Number */}
        <Grid item md={1}>
          <Box
            // display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography className={this.classes.centeredText}>
              {" "}
              #{project.job_number}{" "}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Status*/}
        <Grid item md={2} style={{ height: "100%" }}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            {this.props.user.role !== "WORKER" ? (
              <CssTextField
                variant="outlined"
                label=""
                value={
                  this.state.tempProjectStatus
                    ? this.state.tempProjectStatus
                    : project.status?.status
                }
                onChange={(e) => {
                  this.setState({ tempProjectStatus: e.target.value });
                  this.handleChange(e, project);
                }}
                select
                style={{ minWidth: 170, justifyContent: "center" }}
              >
                <MenuItem value={"Not Started"}>Not Started</MenuItem>
                <MenuItem value={"In Progress"}>In Progress</MenuItem>
                <MenuItem value={"Closed"}>Closed</MenuItem>
                <MenuItem value={"Lost"}>Lost</MenuItem>
                <MenuItem value={"Awarded"}>Awarded</MenuItem>
                <MenuItem value={"Submitted"}>Submitted</MenuItem>
                <MenuItem value={"Revision"}>Revision</MenuItem>
                <MenuItem value={"Submitted review"}>Submitted review</MenuItem>
                <MenuItem value={"Hold"}>Hold</MenuItem>
                <MenuItem value={"Quotation"}>Quotation</MenuItem>
                <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
              </CssTextField>
            ) : (
              <WhiteTextTypography className={this.classes.centeredText}>
                {project.status ? project.status.status : ""}
              </WhiteTextTypography>
            )}
          </Box>
        </Grid>

        {/*Icon*/}
        {this.props.user.role !== "WORKER" ? (
          <Grid item md={1} style={{ height: "100%" }}>
            <Box
              display="flex"
              // justifyContent="center"
              alignContent="center"
              height="100%"
            >
              {/* Edit Project Icon*/}
              <Box
                component="span"
                className={this.classes.centeredText}
                style={{
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                  margin: "0",
                }}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit" variant="subtitle1">
                        Edit Project
                      </Typography>
                    </React.Fragment>
                  }
                >
                  {
                    //<Button round className={classes.firstButton} color="info" component={Link} to={"/projects/" + item.id} justIcon><Edit /></Button>
                  }
                  <WhiteTextTypography>
                    <Button
                      className={this.classes.iconButton}
                      component={Link}
                      to={"/projects/" + project.id}
                      justIcon
                    >
                      <EditIcon />
                    </Button>
                  </WhiteTextTypography>
                </HtmlTooltip>
              </Box>
            </Box>
          </Grid>
        ) : null}
        {/* Details Icon that shows the slider */}
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Box component="span" className={this.classes.centeredText}>
              <ProjectSlider
                projectId={project.id}
                handleChange={this.handleChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }

  handleYearChange = (ev) => {
    this.setState({
      currentYear: ev.target.value,
    });
  };

  SelectYear = () => {
    return (
      <CssTextField
        variant="outlined"
        label="select year"
        value={this.state.currentYear}
        onChange={(ev) => {
          this.setState({ currentYear: ev.target.value });
        }}
        select
        style={{ minWidth: 200 }}
      >
        {this.state.possibleYears.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </CssTextField>
    );
  };

  redirect = () => {
    //window.location.reload();
  };

  setOtherProjectsRef = (ref) => {
    this.otherProjectsRef.current = ref;
  };

  // set state when a new file was added
  createdFile = (isCreated) => {
    this.setState({
      fileCreated: isCreated,
    });
  };

  // set state when a new note was added
  createdNote = (isCreated) => {
    this.setState({
      noteCreated: isCreated,
    });
  };

  // set state when a new photo was added
  createdPhoto = (isCreated) => {
    this.setState({
      photoCreated: isCreated,
    });
  };

  render() {
    //const { progressValue } = this.state;
    const { currentProject, help_faq } = this.props;

    const styles = {
      avatarStyle: {
        borderRadius: "10px",
        height: "3.125rem",
        width: "3.125rem",
      },
      userAvatar: {
        height: "2.3rem",
        width: "2.3rem",
        border: "solid 2px gray",
      },
      textColor: {
        color: "var(--trade-primary)",
      },
    };

    let ref = null;
    if (help_faq.exStep === 0) {
      ref = this.projectNameRef;
    } else if (help_faq.exStep === 1) {
      ref = this.geoFenceRef;
    } else if (help_faq.exStep === 3) {
      ref = this.fileNotesRef;
    } else {
      ref = this.otherProjectsRef;
    }

    return (
      <div className="project-info-container">
        {currentProject ? (
          <React.Fragment>
            <WhiteTextTypography variant="h5">
              {currentProject.title}
            </WhiteTextTypography>
            <Grid container>
              <Grid
                container
                item
                md={12}
                className={this.classes.tableHeaders}
              >
                {/* Table Headers */}
                <Grid item md={2}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                  // margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>Project</WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                  // margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>
                      Start Date - End Date
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                    margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>Project Manager</WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={1}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                    margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>Number</WhiteTextTypography>
                  </Box>
                </Grid>

                <Grid item md={2}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                    margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>Status</WhiteTextTypography>
                  </Box>
                </Grid>
                {this.props.user.role !== "WORKER" ? (
                  <Grid item md={1}>
                    <Box
                      m={2}
                      display="flex"
                      // justifyContent="center"
                      alignContent="center"
                      margin="0.2rem 1rem"
                    >
                      <WhiteTextTypography>Edit</WhiteTextTypography>
                    </Box>
                  </Grid>
                ) : null}

                <Grid item md={1}>
                  <Box
                    m={2}
                    display="flex"
                    // justifyContent="center"
                    alignContent="center"
                    margin="0.2rem 1rem"
                  >
                    <WhiteTextTypography>Details</WhiteTextTypography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <div className="project-info-table-container">
              {currentProject ? this.tableRow(currentProject) : null}
              <div className="tabContainer" ref={this.fileNotesRef}>
                <TabPane
                  currentYear={this.state.currentYear}
                  fileCreated={this.state.fileCreated}
                  noteCreated={this.state.noteCreated}
                  photoCreated={this.state.photoCreated}
                  projectID={this.props.currentProject.id}
                  projectName={currentProject.title}
                  redirect={this.redirect}
                  createdFile={this.createdFile}
                  createdNote={this.createdNote}
                  createdPhoto={this.createdPhoto}
                  currentProject={currentProject}
                  currentUser={this.currentUser}
                />
                <ProjectListDashboard currentProject={currentProject} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="spinner-container">
            <div className="spinner-text">Loading Project Data</div>
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        )}
        {/* // temporarily removed FAQ popover
        {help_faq.exStep !== 2 && help_faq.exStep !== 4 && (
          <GuidePopover
            id={help_faq.exData[help_faq.exStep]?.id}
            show={help_faq.exShow}
            comp={ref}
            anchorOrigin={help_faq.exData[help_faq.exStep]?.anchorOrigin}
            transformOrigin={help_faq.exData[help_faq.exStep]?.transformOrigin}
            title={help_faq.exData[help_faq.exStep]?.title}
            description={help_faq.exData[help_faq.exStep]?.content}
            arrow={help_faq.exData[help_faq.exStep]?.arrow}
          />
        )} */}
      </div>
    );
  }
}

ProjectInfoBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentProject: state.projects.item,
    workers: state.workers.items,
    help_faq: state.help_faq,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectById: (id) => {
      return dispatch(projects.getProject(id));
    },
    fetchWorkers: () => {
      return dispatch(workers.fetchWorkers());
    },
    stopWorkerTimer: (time_log_id, latitude, longitude) => {
      return dispatch(
        time_logs.stopWorkerTimer(time_log_id, latitude, longitude)
      );
    },
    fetchSafetyMeetings: () => {
      return dispatch(safety_meetings.fetchSafetyMeetingInfos());
    },
    updateProjectStatus: (project) => {
      return dispatch(projects.updateProjectStatus(project.id, project.status));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(projectStyles)(ProjectInfoBoard));
