import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter.jsx";

import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger";
import WarningIcon from "@material-ui/icons/Warning";

import onSiteStyle from "./OnSiteStyle";
import { time_logs } from "actions";
import LoadingCircularIcon from "../LoadingCircularIcon";
import {getUserTimezone} from "utils";

class OnSite extends React.Component {
  state = {
    projectSelected: "",
    taskSelected: "",
    worker: "",
    forceRedirect: null,
    starting: false,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  startTimer() {
    const { history, startTimer, user } = this.props;
    this.setState({ starting: true }, () => {
      startTimer(
        this.state.projectSelected,
        this.props.latitude,
        this.props.longitude,
        this.state.worker,
        this.state.taskSelected
      ).then(() => {
        const user_id = this.state.worker !== "" ? this.state.worker : user.id;

        if (history !== undefined) {
          if (this.state.taskSelected !== "") {
            history.push(
              "/time-logs/start/" +
                this.state.projectSelected +
                "?task_type_id=" +
                this.state.taskSelected +
                "&worker_id=" +
                user_id
            );
          } else {
            history.push(
              "/time-logs/start/" +
                this.state.projectSelected +
                "?worker_id=" +
                user_id
            );
          }
        }
      });
    });
  }
  startEnabled() {
    const { user, gpsEnabled } = this.props;
    if (user.company_subscription_active === false) return false;
    return !(user.gps_forced && gpsEnabled === false);
  }

  startDisabled() {
    const { user, latitude, longitude } = this.props;
    const { starting, projectSelected, taskSelected } = this.state;
    if (starting === true) return true;
    if (projectSelected === "" || user.company_subscription_active === false)
      return true;

    if (user.tasks_types.length > 0 && taskSelected === "") return true;

    return (
      user.gps_forced === true && (latitude === null || longitude === null)
    );
  }

  render() {
    const { classes, projects, workers, user, gpsEnabled } = this.props;
    if (projects === undefined) return null;
    if (this.state.forceRedirect !== null)
      return <Redirect to={this.state.forceRedirect} />;

    return (
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardBody>
            <form className={classes.root}>
              {workers && workers.length > 0 ? (
                <FormControl fullWidth className={classes.formControl}>
                  <label>Select a worker</label>
                  <Select
                    value={this.state.worker}
                    onChange={this.handleChange}
                    classes={{
                      select: "select_worker",
                    }}
                    inputProps={{ name: "worker", id: "worker" }}
                  >
                    {workers.map((worker, index) => {
                      if (
                        worker.id === user.id ||
                        (worker.last_time_log !== null &&
                          worker.last_time_log.end === null)
                      )
                        return null;
                      return (
                        <MenuItem
                          value={worker.id}
                          key={"worker-" + index}
                          className={classes.selectMenuItem}
                        >
                          {worker.first_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
              <FormControl fullWidth className={classes.selectMargin}>
                <label>Select site</label>
                <Select
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: "select_site" }}
                  value={this.state.projectSelected}
                  onChange={(e) => this.handleChange(e)}
                  inputProps={{
                    name: "projectSelected",
                    id: "project",
                  }}
                >
                  {projects
                    .filter(
                      (project) =>
                        project.is_active === true &&
                        project.company === user.company
                    )
                    .sort((a, b) => a.title > b.title)
                    .map((project, index) => {
                      return (
                        <MenuItem
                          value={project.id}
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          {project.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {user.tasks_types !== undefined && user.tasks_types.length > 0 ? (
                <FormControl fullWidth className={classes.selectMargin}>
                  <label>Select a task</label>
                  <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: "select_task" }}
                    value={this.state.taskSelected}
                    onChange={(e) => this.handleChange(e)}
                    inputProps={{
                      name: "taskSelected",
                      id: "task",
                    }}
                  >
                    {user.tasks_types.map((task) => {
                      return (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          {task.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </form>
          </CardBody>
          <CardFooter stats>
            <div className={classes.stats}>
              {user.gps_forced && gpsEnabled === false ? (
                <React.Fragment>
                  <Danger>
                    <WarningIcon />
                  </Danger>
                  <span>
                    You need to enable GPS to start logging your time.
                  </span>
                </React.Fragment>
              ) : null}
              {user.company_subscription_active === false ? (
                <React.Fragment>
                  <Danger>
                    <WarningIcon />
                  </Danger>
                  <span>
                    Your company subscription is currently inactive.
                    {user.is_manager
                      ? " Update your billing details in your company profile."
                      : " Ask your manager to update the company's billing details."}
                  </span>
                </React.Fragment>
              ) : null}

              {this.startEnabled() &&
              this.state.projectSelected !== "" &&
              user.gps_forced &&
              (this.props.longitude === null ||
                this.props.latitude === null) ? (
                <div style={{ width: "100%" }}>
                  <LoadingCircularIcon
                    strokeColor={"#fff"}
                    loading={true}
                    styles={{ margin: "-10px 5px -5px 0px" }}
                  />
                  <span>Waiting for GPS lock.</span>
                </div>
              ) : null}
              {this.startEnabled() ? (
                <React.Fragment>
                  <Button
                    round
                    color="success"
                    size="lg"
                    disabled={this.startDisabled()}
                    className="start_timer"
                    onClick={() => this.startTimer()}
                  >
                    <LoadingCircularIcon
                      strokeColor={"#fff"}
                      loading={this.state.starting}
                      styles={{ margin: "-10px 5px -5px -20px" }}
                    />
                    On-site
                  </Button>
                </React.Fragment>
              ) : null}
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    );
  }
}

OnSite.propTypes = {
  user: PropTypes.object.isRequired,
  workers: PropTypes.array,
  projects: PropTypes.array.isRequired,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  gpsEnabled: PropTypes.bool,
  history: PropTypes.object,
};
OnSite.defaultProps = {
  longitude: null,
  latitude: null,
  gpsEnabled: false,
  history: null,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          false,
          getUserTimezone(),
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(onSiteStyle)(OnSite));
