const initialState = {
  item: {},
  items: [],
  count: 0,
  exStep: 0,
  exShow: true,
  exData: [
    {
      title: "Project Name",
      id: "project-name",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      content: `
            <div>
              <p>This is your project name.</p>
              <p>You can edit your project name at anytime.</p>
              <p>Your project name can be whatever you wish it to be. whether it's the name of the development, the address, or the task you'll be working on</p>
            </div>
          `,
      arrow: "top",
    },
    {
      title: "Geo Fence",
      id: "geo-fence",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      content: `
            <div>
              <p>this is your Geo Fence for this project.</p>
              <p>On this map you can see all your assigned workers that are currently on site.</p>
              <p>Note:<br/>You can only see your works when they are in the Geo Fence location.</p>
            </div>
          `,
      arrow: "top",
    },
    {
      title: "Top Toolbar",
      id: "top-toolbar",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      content: `
            <div>
              <p>In the Top Toolbar, you can login to begin your hours, assign a worker to the project, or login a worker to the project.</p>
              <p>You can also select your break and delivery times</p>
            </div>
          `,
      arrow: "top",
    },
    {
      title: "Files, Notes, And Photos",
      id: "files-notes",
      anchorOrigin: {
        vertical: "center",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "center",
        horizontal: "left",
      },
      content: `
            <div>
              <p>Here, you can display the files related to this project, as well as team notes and photos.</p>
              <p>This is meant to be a spot where all team members can use as a resource for this particular project.</p>
            </div>
          `,
      arrow: "left",
    },
    {
      title: "Left Navigation",
      id: "left-navigation",
      anchorOrigin: {
        vertical: "center",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "center",
        horizontal: "left",
      },
      content: `
            <div>
              <p>All your company details are located here.</p>
              <p>From the projects you're currently working on to the your company and employee information.</p>
              <p>As well, help and business tools are available here.</p>
            </div>
          `,
      arrow: "left",
    },
    {
      title: "Other Projects Cards",
      id: "other-projects",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      content: `
            <div>
              <p>While on a project, you can always view all of your other projects as well on the same page.</p>
            </div>
          `,
      arrow: "bottom",
    },
  ],
};

export default function help_faq(state = initialState, action) {
  switch (action.type) {
    case "FETCH_HELP_FAQ":
      if (state.item.id !== undefined) {
        const updatedItem = action.items.find(
          (item) => item.id === state.item.id
        );
        if (updatedItem !== undefined) state.item = updatedItem;
      }
      return { ...state, items: action.items, count: action.count };
    case "GET_HELP_FAQ":
      state.items = state.items.map((value) => {
        if (value.id === action.item.id) {
          return action.item;
        }
        return value;
      });
      return { ...state, item: action.item };
    case "LOGOUT_SUCCESSFUL":
      return {
        item: {},
        items: [],
        count: 0,
      };
    case "EXPLAIN_STEP":
      return {
        ...state,
        exStep: action.step,
        exShow: action.show,
      };
    default:
      return state;
  }
}
