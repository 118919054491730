import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
//import GeneralSlider from "newComponents/GeneralSlider/GeneralSlider";
import HistoryIcon from "@material-ui/icons/History";
import { projects } from "actions";
import Button from "components/CustomButtons/Button.jsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RedTypography = withStyles({
  root: {
    color: "var(--trade-tertiary)",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-background)",
    marginBottom: "1rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  largerText: {
    fontSize: "1.2rem",
  },
  centeredText: {
    margin: "auto",
  },
  centerIcons: {
    margin: "auto 1rem",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  rowIcon: {},
}));

function ArchivedProjects(props) {
  const classes = useStyles();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [sortKey, setSortKey] = useState("title");
  const [ascending, setAscending] = useState(true);
  const { company_id, getProjects } = props;

  useEffect(() => {
    if (company_id) {
      getProjects(company_id, sortKey, ascending);
    }
  }, [company_id, getProjects, sortKey, ascending]);

  const activateProject = (project) => {
    props.activateProject(project.id);
  };

  const changeOrder = (key) => {
    if (sortKey === key) {
      setAscending(!ascending);
    }
    setSortKey(key);
  };

  const titleSection = () => (
    <Grid container>
      <Grid item md={12}>
        <WhiteTextTypography variant="h4">
          {" "}
          Archived Projects
        </WhiteTextTypography>
      </Grid>
      <Grid item md={12}>
        <WhiteTextTypography>
          All archived projects are listed below.
        </WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const tableRow = (project) => {
    const startDate = new Date(project.date_created);
    const endDate = new Date(project.expected_completion);
    const formattedStartDate = `${months[startDate.getMonth()]
      } ${startDate.getDate()}, ${startDate.getFullYear()}`;
    const formattedEndDate = `${months[endDate.getMonth()]
      } ${endDate.getDate()}, ${endDate.getFullYear()}`;

    return (
      <Grid
        container
        item
        md={12}
        className={classes.tableRow}
        key={project.id}
      >
        <Grid container item md={3}>
          <Grid item md={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="100%"
            >
              <Avatar
                src={project.company_logo}
                className={classes.centeredText}
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            <Box m={2}>
              <AmberTypography>{project.title}</AmberTypography>
              <WhiteTextTypography className={classes.smallerText}>
                {project.address}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={classes.centeredText}>
              {formattedStartDate}{" "}
              {project.expected_completion ? `- ${formattedEndDate}` : null}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" variant="h5">
                    {project.project_manager
                      ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                      : "No Project Manager"}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1"> Contact </Typography>
                  <Typography variant="body2">
                    {project.project_manager
                      ? project.project_manager.email
                      : "No email"}
                  </Typography>
                  <Typography variant="body2">
                    Phone Number:{" "}
                    {project.project_manager
                      ? `${project.project_manager.phone_number}`
                      : "No Number"}
                  </Typography>
                </React.Fragment>
              }
            >
              <AmberTypography className={classes.centeredText}>
                {project.project_manager
                  ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                  : "No Project Manager"}
              </AmberTypography>
            </HtmlTooltip>
          </Box>
        </Grid>
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={classes.centeredText}>
              {" "}
              #{project.job_number}{" "}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <RedTypography className={classes.centeredText}>
              {" "}
              Archived{" "}
            </RedTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Box component="span" className={classes.centeredText}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="subtitle1">
                      {" "}
                      Reactivate Project{" "}
                    </Typography>
                  </React.Fragment>
                }
              >
                <WhiteTextTypography>
                  <Button
                    className={classes.iconButton}
                    justIcon
                    onClick={() => activateProject(project)}
                  >
                    <HistoryIcon />
                  </Button>
                </WhiteTextTypography>
              </HtmlTooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const tableSection = () => (
    <Grid
      container
      style={{
        padding: 0,
        backgroundColor: "var(--trade-background)",
        fontWeight: "bold",
        width: "100%",
      }}
    >
      <Grid container item md={12} className="myclass">
        <Grid item md={3} onClick={() => changeOrder("title")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Project</WhiteTextTypography>
            {sortKey === "title" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2} onClick={() => changeOrder("expected_completion")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Start Date - End Date</WhiteTextTypography>
            {sortKey === "expected_completion" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Project Manager</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1} onClick={() => changeOrder("job_number")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography> Number </WhiteTextTypography>
            {sortKey === "job_number" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Status</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Reactivate</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        item
        md={12}
        style={{
          borderTop: "solid 2px var(--trade-neutral)",
        }}
      >
        {props.projects
          .filter((elem) => elem.archived)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((elem) => tableRow(elem))}
      </Grid>
    </Grid>
  );

  return (
    <div
      style={{
        backgroundColor: "var(--trade-body)",
        minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "0.5rem",
      }}
    >
      {titleSection()}
      {tableSection()}
      <Grid container item md={12} style={{ textAlign: "center" }}>
        <Box
          justifyContent="center"
          alignContent="center"
          display="flex"
          style={{ margin: "1rem auto" }}
        >
          {props.next ? (
            <Button round onClick={() => props.fetchMore(props.next)}>
              Load more
            </Button>
          ) : null}
        </Box>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    activateProject: (projectId) => {
      return dispatch(projects.reactivateProject(projectId));
    },
    getProjects: (company_id, sort_key, asc_order) => {
      let sort_order = "asc";
      if (asc_order === false) {
        sort_order = "desc";
      }
      return dispatch(
        projects.getCompanyProjects(company_id, sort_key, sort_order, true)
      );
    },
    fetchMore: (url) => {
      return dispatch(projects.fetchMoreProject(url));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    projects: state.projects.items,
    company_id: state.companies.current_company,
    next: state.projects.next,
    projects_count: state.projects.count_active,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedProjects);
