import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, MenuItem, Select } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import ProjectSlider from "newComponents/ProjectSlider/ProjectSlider";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import { projects, time_logs } from "actions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArchiveIcon from "@material-ui/icons/Archive";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Spinner from "react-bootstrap/Spinner";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import {getUserTimezone} from "../../utils";

const axios = require("axios");

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RedTypography = withStyles({
  root: {
    color: "var(--trade-tertiary)",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "transparent",
      // margin: "auto 0",
      margin: "0",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiInputBase-formControl": {
      height: "80%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    backgroundColor: "var(--trade-background)",
    height: "3rem",
    alignItems: "center",
    borderTop: "solid 2px var(--trade-neutral)",
  },
  tableHeaders: {
    backgroundColor: "var(--trade-background)",
    margin: "0.5rem",
    height: "3rem",
    alignItems: "center",
  },

  smallerText: {
    fontSize: "0.8rem",
  },

  centeredText: {
    margin: "auto",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
}));

const styles = {
  tablePagination: {
    backgroundColor: "var(--trade-background)",
    color: "white",
    borderTop: "solid 2px var(--trade-neutral)",
  },
};

function ProjectListDashboard(props) {
  const classes = useStyles();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const { company_id, getProjects, user } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(null);
  const [activeProjects, setActiveProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState(0);
  const [sortKey, setSortKey] = useState("title");
  const [ascending, setAscending] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [selectedProject, setSelectedProject] = useState({});
  const [tempProjectStatus, setTempProjectStatus] = useState("");

  const handleChange = (event, project) => {
    let newProject = { ...project };
    newProject.status = event.target.value;
    props
      .updateProjectStatus(newProject)
      .then(() => refreshProjects())
      .then(() => setStatusLoading(false));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setActiveProjects([]);
    setPage(0);
    setLoading(true);
  };

  const getCoordinates = () => {
    let url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        // handle success
        setCoords({
          latitude: Number(response.data.latitude),
          longitude: Number(response.data.longitude),
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const startTimer = (project, user, latitude, longitude, task) => {
    // const { projectID, taskID } = this.state;
    setLoading(true);
    const projectID = project.id;
    const currentTask = project.tasks.filter(
      (task) => task.title === user.last_time_log?.task_type?.title
    )[0]?.id;

    let taskID;
    if (currentTask) {
      taskID = currentTask;
    } else {
      taskID = project.tasks[0].id;
    }

    // const { latitude, longitude } = this.state;
    return props
      .startTimer(projectID, latitude, longitude, user.id, taskID)
      .then((response) => {
        const project_id = response.item.project.id;
        props.getProjectById(project_id);
      });
  };

  useEffect(() => {
    setLoading(false);
  }, [props.currentProject]);

  const refreshProjects = () => {
    getProjects(company_id, sortKey, ascending, rowsPerPage + 1, page).then(
      (response) => {
        setProjectsCount(response.count_active - 2);
        setActiveProjects(response.items);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getCoordinates();
  }, []);

  useEffect(() => {
    if (company_id) {
      setLoading(true);
      setActiveProjects([]);
      refreshProjects();
    }
  }, [company_id, getProjects, sortKey, ascending, page, rowsPerPage]);

  const titleSection = () => (
    <Grid container style={{ textAlign: "initial" }}>
      <Grid item md={12}>
        <WhiteTextTypography variant="h5">Active Projects</WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const handleArchive = (project) => {
    setActiveProject(project);
    setDialogOpen(true);
  };

  const handleLoadMore = () => {
    setLoading(true);
    props.fetchMore(props.next).then(() => setLoading(false));
  };

  const archiveProject = () => {
    if (activeProject && activeProject.id) {
      props.archiveProject(activeProject.id).then(() => setDialogOpen(false));
    }
  };

  const confirmationDialog = () => {
    return (
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Are you sure you want to archive this project?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(id) => archiveProject()} color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const tableRow = (project) => {
    const startDate = new Date(project.date_created);
    const endDate = new Date(project.expected_completion);
    const formattedStartDate = `${months[startDate.getMonth()]
      } ${startDate.getDate()}, ${startDate.getFullYear()}`;
    const formattedEndDate = `${months[endDate.getMonth()]
      } ${endDate.getDate()}, ${endDate.getFullYear()}`;
    // After clicking on Archive project, a project will be archived but will still be in our props.projects list
    // Its status is updated though, so let's return nothing if the project is still in the list, but archived.
    if (project.archived) return null;

    return (
      <Grid
        container
        item
        md={12}
        className={classes.tableRow}
        key={project.id}
      >
        {/* Project Title */}
        <Grid container item md={2} style={{ maxHeight: "3rem" }}>
          <Grid item md={11}>
            <Box m={2} margin="0" marginLeft="1rem">
              <HtmlTooltip
                title={
                  <Typography
                    color="inherit"
                    variant="h5"
                    style={{ textTransform: "capitalize" }}
                  >
                    {project.title === "Office Work"
                      ? this.props.user.last_time_log.task_type?.title
                      : project.title}
                  </Typography>
                }
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <AmberTypography
                  onClick={() =>
                    startTimer(project, user, coords.latitude, coords.longitude)
                  }
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {project.title}
                </AmberTypography>
              </HtmlTooltip>
            </Box>
          </Grid>
        </Grid>
        {/* Project Schedule */}
        <Grid item md={3}>
          <Box
            m={3}
            // display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            margin="0"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {formattedStartDate}{" "}
              {project.expected_completion ? `- ${formattedEndDate}` : null}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Manager */}
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" variant="h5">
                    {project.project_manager
                      ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                      : "No Project Manager"}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1"> Contact </Typography>
                  <Typography
                    variant="body2"
                    style={{ textTransform: "lowercase" }}
                  >
                    {project.project_manager
                      ? project.project_manager.email
                      : "No email"}
                  </Typography>
                  <Typography variant="body2">
                    Phone Number:{" "}
                    {project.project_manager
                      ? `${project.project_manager.phone_number}`
                      : "No Number"}
                  </Typography>
                </React.Fragment>
              }
            >
              <WhiteTextTypography>
                {project.project_manager
                  ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                  : "No Project Manager"}
              </WhiteTextTypography>
            </HtmlTooltip>
          </Box>
        </Grid>
        {/* Project Number */}
        <Grid item md={1}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={`${classes.centeredText}`}>
              #{project.job_number}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Status*/}
        <Grid item md={2} style={{ height: "100%" }}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            {user.role !== "WORKER" ? (
              statusLoading && selectedProject.id === project.id ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem auto",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <CssTextField
                  variant="outlined"
                  label=""
                  value={
                    tempProjectStatus && selectedProject.id === project.id
                      ? tempProjectStatus
                      : project.status?.status
                  }
                  onChange={(e) => {
                    setTempProjectStatus(e.target.value);
                    // setStatusLoading(true);
                    setSelectedProject(project);
                    handleChange(e, project);
                  }}
                  select
                  style={{ minWidth: 170, justifyContent: "center" }}
                >
                  <MenuItem value={"Not Started"}>Not Started</MenuItem>
                  <MenuItem value={"In Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Closed"}>Closed</MenuItem>
                  <MenuItem value={"Lost"}>Lost</MenuItem>
                  <MenuItem value={"Awarded"}>Awarded</MenuItem>
                  <MenuItem value={"Submitted"}>Submitted</MenuItem>
                  <MenuItem value={"Revision"}>Revision</MenuItem>
                  <MenuItem value={"Submitted review"}>
                    Submitted review
                  </MenuItem>
                  <MenuItem value={"Hold"}>Hold</MenuItem>
                  <MenuItem value={"Quotation"}>Quotation</MenuItem>
                  <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
                </CssTextField>
              )
            ) : (
              <WhiteTextTypography className={`${classes.centeredText}`}>
                {project.status ? project.status.status : ""}
              </WhiteTextTypography>
            )}
          </Box>
        </Grid>

        {/*Icon*/}
        {user.role !== "WORKER" ? (
          <Grid item md={1} style={{ height: "100%" }}>
            <Box
              display="flex"
              // justifyContent="center"
              alignContent="center"
              height="100%"
              marginLeft="1rem"
            >
              {/* Edit Project Icon*/}
              <Box
                component="span"
                className={classes.centeredText}
                style={{
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                }}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit" variant="subtitle1">
                        Edit Project
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <WhiteTextTypography>
                    <Button
                      className={classes.iconButton}
                      component={Link}
                      to={"/projects/" + project.id}
                      justIcon
                    >
                      <EditIcon />
                    </Button>
                  </WhiteTextTypography>
                </HtmlTooltip>
              </Box>
              {/* Archive Project Icon*/}
              <Box
                component="span"
                className={classes.centeredText}
                style={{
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                }}
              >
                {project.id === props.user.last_time_log.project.id ? null : (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="subtitle1">
                          Archive Project
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <div>
                      <Button
                        className={classes.iconButton}
                        justIcon
                        onClick={() => handleArchive(project)}
                      >
                        <RedTypography>
                          <ArchiveIcon />
                        </RedTypography>
                      </Button>
                      {confirmationDialog()}
                    </div>
                  </HtmlTooltip>
                )}
              </Box>
            </Box>
          </Grid>
        ) : null}
        {/* Details Icon that shows the slider */}
        <Grid item md={1}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <Box component="span" className={classes.centeredText}>
              <ProjectSlider
                projectId={project.id}
                handleChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const changeOrder = (key) => {
    if (sortKey === key) {
      setAscending(!ascending);
    }
    setSortKey(key);
  };

  const tableSection = (projects) => (
    <Grid container>
      <Grid container item md={12} className={classes.tableHeaders}>
        {/* Table Headers */}
        <Grid item md={2} onClick={() => changeOrder("title")}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Project</WhiteTextTypography>
            {sortKey === "title" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={3} onClick={() => changeOrder("date_created")}>
          <Box
            m={3}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Start Date - End Date</WhiteTextTypography>
            {sortKey === "date_created" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Project Manager</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1} onClick={() => changeOrder("job_number")}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Number</WhiteTextTypography>
            {sortKey === "job_number" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>

        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Status</WhiteTextTypography>
          </Box>
        </Grid>
        {user.role !== "WORKER" ? (
          <Grid item md={1}>
            <Box
              m={2}
              display="flex"
              // justifyContent="center"
              alignContent="center"
              margin="0.2rem 1rem"
            >
              <WhiteTextTypography>Edit/Archive</WhiteTextTypography>
            </Box>
          </Grid>
        ) : null}

        <Grid item md={1}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Details</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
      {/* Content Section table rows */}

      <Grid container item md={12}>
        {projects
          .filter((project) => project.title !== "Office Work")
          .filter((project) => project.id !== user.last_time_log.project.id)
          .map((elem) => tableRow(elem))}
      </Grid>

      {loading ? (
        <div
          className="spinnerContainer"
          style={{
            margin: "1rem auto",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : null}
    </Grid>
  );

  return (
    <div
      style={{
        backgroundColor: "var(--trade-background)",
        // minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "0.5rem",
        marginTop: "2rem",
      }}
    >
      {titleSection()}
      {loading ? (
        <div
          className="spinnerContainer"
          style={{
            margin: "1rem auto",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        tableSection(activeProjects)
      )}
      <TablePagination
        style={styles.tablePagination}
        rowsPerPageOptions={[5, 10, 15, 25]}
        component="div"
        count={projectsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    projects: state.projects.items,
    projects_count: state.projects.count_active,
    company_id: state.companies.current_company,
    companies: state.companies.items,
    next: state.projects.next,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    archiveProject: (id) => {
      return dispatch(projects.archiveProject(id));
    },
    updateProjectStatus: (project) => {
      return dispatch(projects.updateProjectStatus(project.id, project.status));
    },
    getProjectById: (id) => {
      return dispatch(projects.getProject(id));
    },
    getProjects: (company_id, sort_key, asc_order, limit, page) => {
      let sort_order = "asc";
      if (asc_order === false) {
        sort_order = "desc";
      }
      const offset = page * limit;
      return dispatch(
        projects.getCompanyProjects(
          company_id,
          sort_key,
          sort_order,
          false,
          limit,
          offset
        )
      );
    },
    fetchMore: (url) => {
      return dispatch(projects.fetchMoreProject(url));
    },
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          true,
          getUserTimezone(),
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectListDashboard);
