const initialState = {
    item: {},
    items: [],
    current_project_items: [],
    worker_items: [],
    count: 0,
    next: null,
    prev: null,
    loading: true,
  };
  
  export default function user_positions(state = initialState, action) {
    switch (action.type) {
      case "FETCH_USER_POSITIONS":
        if (state.item.id !== undefined) {
          const updatedItem = action.items.find(
            (item) => item.id === state.item.id
          );
          if (updatedItem !== undefined) state.item = updatedItem;
        }
        return { ...state, items: action.items, count: action.count };
  
      case "GET_USER_POSITION":
        state.items = state.items.map((value) => {
          if (value.id === action.item.id) {
            return action.item;
          }
          return value;
        });
        return { ...state, item: action.item };
      case "GET_PROJECT_GPS_ENTRIES":
        return { 
          ...state, 
          current_project_items: action.items,
          count: action.count,
          next: action.next,
          prev: action.prev,
          loading: false,
        };
      case "APPEND_PROJECT_GPS_ENTRIES":
        return {
          ...state, 
          current_project_items: [...state.current_project_items, ...action.items],
          count: action.count,
          next: action.next,
          prev: action.prev,
        }
  
      case "GET_WORKER_GPS_ENTRIES":
        //   console.log(action.item);
        return { ...state, worker_items: action.item.results };
      case "LOGOUT_SUCCESSFUL":
        return {
          item: {},
          items: [],
          current_project_items: [],
          worker_items: [],
          count: 0,
        }
      default:
        return state;
    }
  }
  