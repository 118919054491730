import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import "./SideDrawerWorker.css";
import LastGPSLocation from "../LastGPSLocation/LastGPSLocation";
import Moment from "react-moment";
import WorkerTabPane from "../WorkerTabs/WorkerTabPane";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Gravatar from "react-gravatar";
import AssignWorker from "../AssignWorker/AssignWorker";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import { ax } from "utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CssTextField from "components/CssTextField/CssTextField";
import MenuItem from "@material-ui/core/MenuItem";

const SpinnerProgress = withStyles({
  root: {
    //background: "var(--trade-success)",
    color: "var(--trade-primary)",
    width: "70px",
    height: "70px",
  },
  colorPrimary: {
    color: "var(--trade-primary)",
  },
})(CircularProgress);

function CircularIndeterminate() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={"100vh"}
    >
      <SpinnerProgress color="primary" />
    </Box>
  );
}

const useStyles = makeStyles({
  list: {
    width: 1050,
    backgroundColor: "var(--trade-body)",
    //minHeight: "100vh",
    color: "var(--trade-font)",
  },
  mainContainer: {
    backgroundColor: "var(--trade-body)",
    minHeight: "100vh",
    color: "var(--trade-font)",
  },
});

function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const [right, setRight] = useState(false);
  const [value, setValue] = useState(null);
  const [autoCompleteKey, setAutocompleteKey] = useState("");

  const [worker, setWorker] = useState(null);
  const [projects, setProjects] = useState([]);
  const [possibleYears, setPossibleYears] = useState([]);
  const [currentYear, setCurrentYear] = useState(null);

  const [newProject, setNewProject] = useState(false);

  const styles = {
    avatarStyle: {
      borderRadius: "10px",
      height: "3.125rem",
      width: "3.125rem",
    },
    autocompleteStyle: { width: "350px", color: "white" },
  };

  useEffect(() => {
    generateYears();
    if (props.type === "dashboard") {
      const num = Math.random().toString();
      setAutocompleteKey(num);
    }
  }, [props.workers, props.type]);

  useEffect(() => {
    if (possibleYears.length > 0) {
      const today = new Date();
      const year = today.getFullYear();
      setCurrentYear(year);
    }
  }, [possibleYears]);

  const generateYears = () => {
    const company_created = new Date(
      props.user.last_time_log.project.company_created
    );
    const today = new Date();
    const years = [];
    for (
      let i = company_created.getFullYear() - 2;
      i <= today.getFullYear();
      i++
    ) {
      years.push(i);
    }

    setPossibleYears(years);
  };

  const getWorkerDetails = (worker_id) => {
    // open drawer
    // get full worker object
    ax.get("/api/users/" + worker_id + "/")
      .then((res) => {
        setWorker(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // worker projects
    ax.get(
      "/api/users/" + worker_id + "/projects" + "?company=" + props.user.company
    )
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setRight(true);
  };

  const displayWorkerDetails = () => {
    const { worker_id } = props;
    getWorkerDetails(worker_id);
  };

  const changeNewProjStatus = () => {
    setNewProject(false);
  };

  const handleClose = () => {
    const num = Math.random().toString();
    setAutocompleteKey(num);
    setRight(false);
    setWorker(null);
    setProjects([]);
    setValue(null);
  };

  const ProjectList = () => {
    useEffect(() => {
      if (newProject) {
        displayWorkerDetails();
        changeNewProjStatus();
      }
    }, [newProject]);

    return (
      <div>
        <h6 className="assigned-worker-title">Assigned Jobs</h6>
        {projects.length > 0 ? (
          <div className="worker-assigned-table">
            <List>
              {projects.map((item, key) => (
                <ListItem key={key}>
                  <ListItemText key={key}>
                    <div className="worker-assigned-list-item">
                      <div className="company-avatar-container">
                        {item.company_logo ? (
                          <Avatar
                            variant="square"
                            style={styles.avatarStyle}
                            src={item.company_logo}
                          />
                        ) : (
                          <Gravatar
                            email={item.client_email}
                            size={40}
                            style={{ borderRadius: "10%" }}
                          />
                        )}
                      </div>
                      &nbsp; &nbsp; &nbsp;
                      <div className="project-assigned">
                        <span>{item.title}</span>
                        <span>{item.address}</span>
                      </div>
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <span className="assigned-worker-title">
            Worker has not been assigned to any project.
          </span>
        )}
      </div>
    );
  };

  const handleYearChange = (ev) => {
    setCurrentYear(ev.target.value);
  };

  const SelectYear = () => {
    return (
      <CssTextField
        variant="outlined"
        label="select year"
        value={currentYear}
        onChange={handleYearChange}
        select
        style={{ minWidth: 200 }}
      >
        {possibleYears.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </CssTextField>
    );
  };

  const DrawerContent = () => {
    return worker ? (
      <div
        className={clsx(classes.list)}
        role="presentation"
      // onKeyDown={() => handleClose()}
      >
        <div className="section1">
          <div className="slider-title">
            {worker !== undefined && worker.last_time_log !== undefined ? (
              worker.last_time_log !== null ? (
                worker.last_time_log.end ? (
                  worker.last_time_log.end !== null ? (
                    <span className="dot-red"></span>
                  ) : (
                    <span className="dot-green"></span>
                  )
                ) : (
                  <span className="dot-green"></span>
                )
              ) : (
                <span className="dot-red"></span>
              )
            ) : null}
            &nbsp;
            {worker ? worker.first_name + " " + worker.last_name : null}
          </div>

          {props.user.role !== "WORKER" ? (
            <div className="worker-slider-buttons-container">
              <AssignWorker
                type="worker"
                worker_id={worker.id}
                worker_name={worker.first_name}
                setNewProject={setNewProject}
              />
            </div>
          ) : null}
        </div>

        <div className="worker-contact-details-container">
          <div className="worker-contact-details">
            <span className="txt">Contact</span>

            <div className="worker-contact-details-icons-container">
              <div className="tooltip-con">
                <MailIcon />
                <span className="tooltiptext">{worker.email}</span>
              </div>
              <div className="tooltip-con">
                <PhoneIphoneIcon />
                <span className="tooltiptext">{worker.phone_number}</span>
              </div>
            </div>
          </div>
          <div className="worker-contact-details">
            <span className="txt">Status</span>

            {worker.last_time_log ? (
              worker.last_time_log.end ? (
                worker.last_time_log.end !== null ? (
                  <span>Off-Site</span>
                ) : (
                  <span>On-Site</span>
                )
              ) : (
                <span>On-Site</span>
              )
            ) : (
              <span>Off-Site</span>
            )}
          </div>
        </div>

        {worker.last_time_log ? (
          worker.last_time_log.end !== null ? (
            <div className="worker-offsite-text-container">
              <span className="worker-offsite-text">Worker not on-Site</span>
            </div>
          ) : (
            <div>
              <div className="map-container-worker">
                {" "}
                <div className="worker-location-map">
                  <LastGPSLocation
                    latitude={worker.last_time_log.latitude}
                    longitude={worker.last_time_log.longitude}
                    worker={worker}
                  />
                </div>
              </div>
              <div className="worker-project-info-container">
                <div className="worker-project-info">
                  <div className="project-details">
                    <strong>{worker.last_time_log.project.title}</strong>
                    <span>{worker.last_time_log.project.address}</span>
                  </div>
                  &nbsp;
                  <div className="worker-start-time">
                    <span>Start Time</span>
                    <Moment format={"LT"}>{worker.last_time_log.start}</Moment>
                  </div>
                  &nbsp;
                  <div className="worker-start-time">
                    <span>Duration(HH:MM:SS)</span>
                    <Moment date={worker.last_time_log.start} durationFromNow />
                  </div>
                  &nbsp;
                  <div className="worker-end-time">
                    <span>End Time</span>
                    {/* <Moment format={"LT"}>{worker.last_time_log.start}</Moment> */}
                    -
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="worker-offsite-text-container">
            <span className="worker-offsite-text">New Worker</span>
          </div>
        )}
        <Grid container>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Box
              mt={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              {possibleYears.length === 1 ? null : <SelectYear />}
            </Box>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>

        {worker !== undefined && worker.last_time_log !== undefined ? (
          <div className="worker-tab-pane">
            <WorkerTabPane worker_id={worker.id} currentYear={currentYear} />
          </div>
        ) : null}
        <ProjectList />
      </div>
    ) : (
      <div style={{ width: "1050px", margin: "auto", textAlign: "center" }}>
        <CircularIndeterminate className={classes.spinner} />
      </div>
    );
  };

  const AutocompleteInput = () => {
    return (
      <Autocomplete
        key={autoCompleteKey}
        options={props.workers}
        value={value}
        onChange={(_, newValue) => {
          const worker_id = newValue.user.id;
          if (worker_id) {
            getWorkerDetails(worker_id);
            setValue(newValue);
          }
        }}
        getOptionLabel={(option) =>
          option.user.first_name + " " + option.user.last_name
        }
        style={styles.autocompleteStyle}
        renderInput={(params) => (
          <CssTextField {...params} label="Search Worker" variant="outlined" />
        )}
      />
    );
  };

  const DetailsButton = () => {
    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={displayWorkerDetails}
        title="Display Worker Details"
      >
        {" "}
        <ChevronRightIcon style={{ color: "var(--trade-font)" }} />
      </IconButton>
    );
  };

  return (
    <div>
      <React.Fragment>
        {props.type === "dashboard" ? <AutocompleteInput /> : <DetailsButton />}
        <Drawer anchor="right" open={right} onClose={() => handleClose()}>
          <div className={classes.mainContainer}>
            <DrawerContent />
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workers: state.workers.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwipeableTemporaryDrawer);
