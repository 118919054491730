import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import mapStyle from "./MapStyle";
import ReactDOMServer from 'react-dom/server';

const { compose } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} = require("react-google-maps");

function getMarker(id){
    let fontSize = 20;
    let fontX = 10;
    let fontY = 20;

    if(id.toString().length >= 3){
        fontSize = 12;
        fontX = 5;
    }else if(id.toString().length >= 2){
        fontSize = 15;
        fontX = 7;
    }

    return ReactDOMServer.renderToStaticMarkup(
        <svg xmlns='http://www.w3.org/2000/svg' width={30} height={30}>
            <ellipse ry="15" rx="15" id="svg_1" cy="15" cx="15" stroke="#000" fill="#fff" fillOpacity="0.50"/>
            <text xmlSpace="preserve" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize={fontSize} y={fontY} x={fontX} fillOpacity="null" strokeOpacity="null" strokeWidth="0" stroke="#000" fill="#000000">{id}</text>
        </svg>
    );
}

const WorkerGoogleMap = compose(
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        disableDefaultUI={true}
        defaultZoom={12}
        center={{ lat: props.latitude, lng: props.longitude }}
        defaultOptions={{
            scrollwheel: true,
            disableDefaultUI: false,
            draggable: true,
        }}
    >
        {props.positions.map((position, index) => (
            <Marker
                key={index}
                position={{ lat: parseFloat(position.latitude), lng: parseFloat(position.longitude) }}
                visible={true}
                icon={'data:image/svg+xml,' + encodeURIComponent(getMarker(index+1))}
            />
        ))}
    </GoogleMap>
);


class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight - 150 + 'px',
            width: window.innerWidth + 'px',
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({
            height: window.innerHeight - 150 + 'px',
            width: window.innerWidth + 'px',
        });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const { positions } = this.props;
        const last_item = positions[positions.length -1];

        //return <img src={'data:image/svg+xml,' + encodeURIComponent(getMarker(1))} />;

        return <WorkerGoogleMap
            latitude={parseFloat(last_item.latitude)}
            longitude={parseFloat(last_item.longitude)}
            positions={positions}
            googleMapURL={process.env.GOOGLE_MAPS_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
                <div
                    style={{
                        height: this.state.height,
                        width: this.state.width,
                        borderRadius: "6px",
                    }}
                />
            }
            mapElement={<div style={{ height: `100%` }} />}
        />;
    }
}

Map.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.shape({
        user_id: PropTypes.string.isRequired,
        latitude: PropTypes.string.isRequired,
        longitude: PropTypes.string.isRequired, // Used to display the avatar is no avatar is set
    })),
};

Map.defaultProps = {
    longitude: null,
    latitude: null,
};

export default withStyles(mapStyle)(Map);
