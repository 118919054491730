import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const OnSiteStyle = {
    ...customSelectStyle,
    selectMargin: {
      margin: "20px 0",
    },
    stats: {
        color: "#999999",
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px"
        }
    },
};

export default OnSiteStyle;
