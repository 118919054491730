import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter";

// Stripe
import StripeContainer from "components/Register/StripeContainer";

import registerPageStyle from "./RegisterPageStyle";
import { auth } from "actions";
import BaseComponent from "../BaseComponent";

class RegisterPage extends BaseComponent {
    state = {
        step: 'userInfos',
        //step: 'plan',
        error: { email: null, password: null },
    };

    handleChange(event, value) {
        if (value === undefined) {
            value = event.target.value;
            if (value === '')
                value = null;
            if (value === 'true' || value === 'false')
                value = (value === 'true');
        }
        if (event.target.id) {
            this.setState({ [event.target.id]: value });
        } else {
            this.setState({ [event.target.name]: value });
        }
    }

    handleChangeCheck(e) {
        this.handleChange(e, e.target.checked);
    }

    nextStep() {
        // Needs to validate password, if email already exists, if terms has been read
        const { step } = this.state;
        let nextStep = "plan";
        if (step === 'userInfos')
            nextStep = "plan";

        this.setState({ step: nextStep });
    }

    isNextStepButtonDisabled() {
        const { error, terms, first_name, last_name, email, password, password_confirmation } = this.state;
        if (first_name === '' || first_name === undefined || last_name === '' || last_name === undefined || email === '' || email === undefined || (password === undefined || password_confirmation === undefined) || (password === null || password_confirmation === null))
            return true;

        if (error.email !== null)
            return true;

        if (error.password !== null)
            return true;

        if (!this.validatePasswords())
            return true;

        if (terms !== true)
            return true;

        return false;
    }

    validatePasswords() {
        const { password, password_confirmation, error } = this.state;
        if (password !== null && password_confirmation !== null && password_confirmation !== undefined) {
            if (password !== password_confirmation)
                this.setState({ error: { ...error, password: "Passwords doesn't match." } });
            else if (password.length < 8)
                this.setState({ error: { ...error, password: "Your password should have at least 8 characters." } });

            if (error.password !== null) {
                this.setState({ error: { ...error, password: null } });
                return false;
            }
            return true;
        }
    }
    validateEmail(e) {
        // need to validate if the email already exists
        // this.setState({error: {...error, email: "An account already exists with this email."}});
        fetch(process.env.API_URL + '/api/register/exists', {
            headers: { "Content-Type": "application/json" },
            method: 'POST',
            body: JSON.stringify({ 'email': e.target.value })
        })
            .then(res => res.json())
            .then(e => {
                const { error } = this.state;
                if (e.exists) this.setState({ error: { ...error, email: "An account already exists with this email." } });
                else if (error.email !== null) this.setState({ error: { ...error, email: null } });
                return e;
            });
    }
    onTokenGenerated(e) {
        const { first_name, last_name, email, password } = this.state;
        const { company_name, address1, address2, postal_code, city, province, country } = e;
        this.props.register(
            first_name,
            last_name,
            email,
            password,
            company_name,
            address1,
            address2,
            city,
            postal_code,
            province,
            country,
            e.token.id
        );
    }

    renderUserSignup() {
        const { classes } = this.props;
        const { error } = this.state;
        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>Register</h2>
                            <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={10}>
                                        <form>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Face className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    placeholder: "First name",
                                                    id: "first_name",
                                                    onChange: (e) => this.handleChange(e),
                                                }}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Face className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    placeholder: "Last name",
                                                    id: "last_name",
                                                    onChange: (e) => this.handleChange(e),
                                                }}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Email className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    placeholder: "E-mail",
                                                    id: "email",
                                                    onChange: (e) => this.handleChange(e),
                                                    required: true,
                                                    onBlur: (e) => this.validateEmail(e),
                                                }}
                                                labelText={error.email ? error.email : null}
                                                error={error.email !== null}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    type: 'password',
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    placeholder: "Password",
                                                    id: "password",
                                                    onChange: (e) => this.handleChange(e),
                                                    onBlur: () => this.validatePasswords(),
                                                }}
                                                error={error.password !== null}
                                                labelText={error.password ? error.password : null}
                                            />
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    placeholder: "Your password again",
                                                    id: "password_confirmation",
                                                    type: 'password',
                                                    onChange: (e) => this.handleChange(e),
                                                    onBlur: () => this.validatePasswords(),
                                                }}
                                            />
                                            <FormControlLabel
                                                classes={{
                                                    root: classes.checkboxLabelControl,
                                                    label: classes.checkboxLabel
                                                }}
                                                control={
                                                    <Checkbox
                                                        id={"terms"}
                                                        onClick={(e) => this.handleChangeCheck(e)}
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <span>I agree to the <a href="https://tradespecifix.com/terms-of-use" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</span>
                                                }
                                            />
                                        </form>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <div className={classes.center}>
                                    <Button round color="success" onClick={() => this.nextStep()} disabled={this.isNextStepButtonDisabled()}>
                                        Get started
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    renderPrePayment() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={5}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>Billing Information</h2>
                            <CardBody>
                                <StripeContainer onTokenGenerated={(e) => this.onTokenGenerated(e)} showFirstChargeWarning={true} onTokenError={msg => this.showError(msg)} />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>);
    }

    render() {
        const { step } = this.state;
        if (this.props.isAuthenticated) {
            return <Redirect to="/dashboard" />
        }
        if (step === 'userInfos') {
            return this.renderUserSignup();
        } else if (step === 'plan') {
            return this.renderPrePayment();
        }
    }
}

RegisterPage.propTypes = {
    classes: PropTypes.object.isRequired
};


const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated
    }
};
const mapDispatchToProps = dispatch => {
    return {
        register: (...args) => {
            return dispatch(auth.register(...args));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(registerPageStyle)(RegisterPage));
