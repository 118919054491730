import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

export default function RenderInWindow(props) {
  const [container, setContainer] = useState(null);
  const newWindow = useRef(null);

  useEffect(() => {
    // Create container element on client-side
    setContainer(document.createElement("div"));
  }, []);

  useEffect(() => {
    // When container is ready
    if (container) {
      // Create window
      newWindow.current = window.open(
        "#",
        "",
        "width=600,height=400,left=200,top=200"
      );

      if (newWindow.current) {
        newWindow.current.document.write(`${props.timesheetData}`);

        // Append container
        newWindow.current.document.body.appendChild(container);

        newWindow.current.print();

        // Save reference to window for cleanup
        const curWindow = newWindow.current;

        // set open to false to allow printing again w/o refreshing
        props.setOpen(false);
        // Return cleanup function
        return () => curWindow.close();
      }
    }
  }, [container]);

  return container && createPortal(props.children, container);
}
