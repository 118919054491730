import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import queryString from "query-string";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons
import NoteIcon from "@material-ui/icons/Note";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import LoadingCircularIcon from "../../components/LoadingCircularIcon";

import BaseComponent from "views/BaseComponent";

import { notes, projects } from "actions";
import NoteFormStyles from "./NoteFormStyles";

import CustomEditor from "../../components/Editor/CustomEditor";

class NoteForm extends BaseComponent {
  state = {
    forceRedirect: null,
    saving: false,
    item: null,
  };
  imageUploadRef = null;

  constructor(props) {
    super(props);
    this.imageUploadRef = React.createRef();
  }

  initState(state_) {
    state_ = this.getInitState({ ...this.state, ...state_ });
    this.setState(state_);
  }
  componentDidMount() {
    const { match, get, getProject } = this.props;

    const project_id = match.params.project_id;
    let params = queryString.parse(this.props.location.search);
    const note_id = match.params.id;
    if (note_id === undefined) {
      getProject(project_id).then((res) =>
        this.initState({ project: res.item, log_time_id: params.time_log_id })
      );
    } else {
      get(note_id).then((res) => {
        this.initState({ item: res.item });
      });
    }
  }
  handleCreate() {
    const { create } = this.props;
    const { description, project, log_time_id } = this.state;

    this.setState({ saving: true }, () =>
      create(project.id, description, log_time_id).then((res) => {
        this.setState({ forceRedirect: "/notes/" });
      })
    );
  }
  handleSave() {
    const { save } = this.props;
    const { item } = this.state;
    let { description } = this.state;
    if (description === undefined) {
      description = item.description;
    }

    this.setState({ saving: true }, () =>
      save(item.id, description).then((res) => {
        this.showSuccessfulAlert();
        this.setState({
          item: res.item,
          description: res.item.description,
          saving: false,
        });
      })
    );
  }
  createPicture(e) {
    const { item } = this.state;
    this.setState({ saving: true }, () => {
      this.props.createImage(item.id, e.file).then((res) => {
        let { item } = this.state;
        item.images.push(res.item);
        this.setState({ item, saving: false });
        this.imageUploadRef.current.reset();
      });
    });
  }
  savePicture(picture_id, e) {
    const { item } = this.state;
    this.setState({ saving: true }, () => {
      this.props.saveImage(picture_id, e.file).then((res) => {
        const id = item.images.findIndex((el) => el.id === picture_id);
        item.images[id] = res.item;
        this.setState({ item, saving: false });
      });
    });
  }
  removePicture(image_id) {
    const { item } = this.state;
    this.setState({ saving: true });
    this.props.deleteImage(image_id).then(() => {
      const images = item.images.filter((i) => i.id !== image_id);
      item.images = images;
      this.setState({ saving: false, item: item });
    });
  }
  rotatePicture(picture_id, degrees) {
    this.props.rotateImage(picture_id, degrees);
  }
  render() {
    const { classes } = this.props;
    const { item, saving, project } = this.state;

    if (this.state.forceRedirect !== null)
      return <Redirect to={this.state.forceRedirect} />;

    if (project === null) return null;

    if (this.props.match.params.id !== undefined && item === null) return null;

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem md={12} lg={12} sm={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <NoteIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {item === null ? "Add Note" : "View Note"}
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Date
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={9} md={9}>
                    {item ? (
                      <Moment format={"dddd HH:mm:ss"}>{item.date}</Moment>
                    ) : (
                      <Moment format={"dddd HH:mm:ss"} />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Site
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={9} md={9}>
                    {project ? project.title : null}
                    {item ? item.project.title : null}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Description
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={9} md={9}>
                    <CustomEditor
                      onChange={(description) => this.setState({ description })}
                      defaultValue={item ? item.description : null}
                    />
                  </GridItem>
                </GridContainer>
                {item ? (
                  <GridContainer>
                    <GridItem xs={12} sm={3} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Picture
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9} md={9}>
                      {item.images.map((picture) => (
                        <ImageUpload
                          key={picture.id}
                          addButtonProps={{
                            color: "rose",
                            round: true,
                            disabled: saving,
                          }}
                          changeButtonProps={{
                            color: "rose",
                            round: true,
                            disabled: saving,
                          }}
                          removeButtonProps={{
                            color: "danger",
                            round: true,
                            disabled: saving,
                          }}
                          initAvatar={picture.image}
                          handleImageChange={(e) =>
                            this.savePicture(picture.id, e)
                          }
                          handleImageDelete={() =>
                            this.removePicture(picture.id)
                          }
                          handleRotateLeft={() =>
                            this.rotatePicture(picture.id, 90)
                          }
                          handleRotateRight={() =>
                            this.rotatePicture(picture.id, -90)
                          }
                        />
                      ))}

                      <ImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                          disabled: saving,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                          disabled: saving,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                          disabled: saving,
                        }}
                        handleImageChange={(e) => this.createPicture(e)}
                        innerRef={this.imageUploadRef}
                        displayPreview={false}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
                <GridContainer justify="flex-end">
                  <GridItem xs={12} sm={9} md={9}>
                    {item === null || item.id === undefined ? (
                      <Button
                        round
                        color="info"
                        onClick={() =>
                          this.setState({ forceRedirect: "/dashboard" })
                        }
                        disabled={saving}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        round
                        color="info"
                        onClick={() => this.props.history.goBack()}
                        disabled={saving}
                      >
                        Cancel
                      </Button>
                    )}
                    {item === null ? (
                      <Button
                        color="success"
                        round
                        onClick={() => this.handleCreate()}
                        disabled={saving}
                      >
                        <LoadingCircularIcon
                          width={38}
                          height={38}
                          strokeColor={"#fff"}
                          loading={saving}
                        />
                        Create
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        round
                        onClick={() => this.handleSave()}
                        disabled={saving}
                      >
                        <LoadingCircularIcon
                          width={38}
                          height={38}
                          strokeColor={"#fff"}
                          loading={saving}
                        />
                        Save
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  let project = state.projects.item;
  if (state.notes.item !== null) project = state.notes.item.project;

  return {
    item: state.notes.item,
    user: state.auth.user,
    project: project,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get: (id) => {
      return dispatch(notes.getNote(id));
    },
    save: (note_id, description) => {
      return dispatch(notes.saveNote(note_id, description));
    },
    create: (project_id, description, time_log_id, image) => {
      return dispatch(
        notes.createNote(project_id, description, time_log_id, image)
      );
    },
    createImage: (note_id, image) => {
      return dispatch(notes.createNoteImage(note_id, image));
    },
    saveImage: (image_id, image) => {
      return dispatch(notes.saveNoteImage(image_id, image));
    },
    deleteImage: (image_id) => {
      return dispatch(notes.deleteNoteImage(image_id));
    },
    getProject: (id) => {
      return dispatch(projects.getProject(id));
    },
    rotateImage: (image_id, degrees) => {
      return dispatch(notes.rotateImage(image_id, degrees));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NoteFormStyles)(NoteForm));
