import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
    },
    "& #date": {
      filter: "invert(1)",
      color: "black",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&:hover fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "var(--trade-font)",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

export default CssTextField;
