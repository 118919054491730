import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import "./NotesTimeline.css";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Gravatar from "react-gravatar";
import Avatar from "@material-ui/core/Avatar";
import Moment from "react-moment";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import Carousel from '../Carousel/Carousel';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    width: "350px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function CustomizedTimeline(props) {
  const classes = useStyles();
  const { item } = props;

  return props.type === "notes" ? (
    <Timeline align="alternate">
      {item.map((data, key) => (
        <TimelineItem key={key}>
          <TimelineContent>
            <Paper elevation={4} className={classes.paper}>
              <Typography
                inline="true"
                variant="body1"
                style={{ fontWeight: 600 }}
                align="left"
              >
                {data.user_first_name + " " + data.user_last_name}
              </Typography>
              <Box m={2} />
              <Typography
                inline="true"
                variant="body1"
                align="left"
              >
                {data.description}
              </Typography>
              <Box m={2} />
              {(data.images.length > 0) ? <Carousel images={data.images}/> : null}
              <div className="notes-footer">
                <Moment format={"LT Do MMMM YYYY"}>{data.date}</Moment>
              </div>
            </Paper>
          </TimelineContent>

          <TimelineSeparator>
            {data.user_avatar ? (
              <Avatar src={data.user_avatar} />
            ) : (
              <Gravatar
                email={data.user_email}
                size={40}
                style={{ borderRadius: "50%" }}
              />
            )}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent />
        </TimelineItem>
      ))}
    </Timeline>
  ) : (
    <div>
      <h3 style={{ textAlign: "center" }}>
        {item ? item[0]["project"]["title"] : null}
      </h3>
      <Timeline align="alternate">
        {item.map((data, key) => (
          <TimelineItem key={key}>
            <TimelineContent>
              <Paper elevation={4} className={classes.paper}>
                <Typography
                  inline="true"
                  variant="body1"
                  style={{ fontWeight: 600 }}
                  align="left"
                >
                  {data.user_first_name + " " + data.user_last_name}
                </Typography>
                <Typography
                  inline="true"
                  variant="body1"
                  align="left"
                >
                  {data.description}
                </Typography>
                {(data.images.length > 0) ? <Carousel images={data.images}/> : null} 
                <div className="notes-footer">
                  <Moment format={"LT Do MMMM YYYY"}>{data.date}</Moment>
                </div>
              </Paper>
            </TimelineContent>

            <TimelineSeparator>
              {data.user_avatar ? (
                <Avatar src={data.user_avatar} />
              ) : (
                <Gravatar
                  email={data.user_email}
                  size={40}
                  style={{ borderRadius: "50%" }}
                />
              )}

              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent />
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedTimeline);
