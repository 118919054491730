import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { auth, workers } from "actions";
import { getUserTimezone } from "utils";
import SnackbarContent from "components/Snackbar/SnackbarContent";

// @material-ui/core components
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import bgImage from "assets/img/background.jpg";
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import { Box, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import BaseComponent from "views/BaseComponent.jsx";

import logo from "assets/img/tradespecifix-logo-full.png";
import {updateUserTimezone} from "../../actions/workers";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class LoginPage extends BaseComponent {
  state = {
    cardAnimaton: "cardHidden",
    email: localStorage.getItem("email"),
    password: null,
    showPassword: false,
    rememberMe: false,
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    this.initState(this.state);
    super.componentDidMount();
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleRememberMe() {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  login = (e) => {
    e.preventDefault();
    if (this.state.rememberMe) localStorage.setItem("email", this.state.email);

    this.props.login(
      this.state.email,
      this.state.password,
      this.state.rememberMe
    ).then(res => {
      if (res !== undefined && res.token) {
        this.props.updateUserTimezone(getUserTimezone());
      }
    })
  };

  render() {
    const { classes, ...rest } = this.props;
    const resetPasswordUrl = `${process.env.API_URL}/password_reset`;

    // if (this.props.isAuthenticated && this.props.agreeSafetyMeeting) {
    //   return <Redirect to="/dashboard" />;
    // } else if (this.props.isAuthenticated && !this.props.agreeSafetyMeeting) {
    //   return <Redirect to="/project-selection" />;
    // }

    if (this.props.isAuthenticated) {
      return <Redirect to="/project-selection" />;
    }

    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            <div className={classes.container}>
              {this.state.alert}
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6}>
                  <form onSubmit={this.login}>
                    {this.props.errors.length > 0 &&
                      this.props.errors.map(function (error, id) {
                        if (error.field === "non_field_errors")
                          return (
                            <SnackbarContent
                              message={error.message}
                              color="danger"
                              key={id}
                            />
                          );
                        return null;
                      })}
                    <Card
                      login
                      className={`${classes[this.state.cardAnimaton]} ${classes.transparentCard
                        }`}
                    >
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                      >
                        <img
                          src={logo}
                          alt="logo of Tradespecifix"
                          className={`${classes.logo} ${classes.textCenter}`}
                        />
                      </CardHeader>
                      <CardBody className={classes.cardBody}>
                        <CustomInput
                          labelText="Email"
                          id="email"
                          value={this.state.email}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) =>
                              this.setState({
                                email: e.target.value,
                              }),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            inputProps: {
                              autoCapitalize: "none",
                            },
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          type="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) =>
                              this.setState({ password: e.target.value }),
                            onKeyUp: (e) => {
                              if (e.keyCode === 13) this.login(e);
                            },
                            type: this.state.showPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.state.showPassword ? (
                                  <Visibility
                                    onClick={() =>
                                      this.setState({ showPassword: false })
                                    }
                                  />
                                ) : (
                                  <VisibilityOff
                                    onClick={() =>
                                      this.setState({ showPassword: true })
                                    }
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardBody>
                      <Grid container>
                        <Grid item md={12}>
                          <GridItem
                            xs={6}
                            md={11}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                onChange={() => this.handleRememberMe()}
                                control={<Checkbox />}
                                label="Remember Me"
                                style={{
                                  margin: 0,
                                  marginLeft: "15px",
                                }}
                              />
                            </FormGroup>
                          </GridItem>
                          <Box
                            m={1}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                          >
                            <WhiteTextTypography>
                              <a
                                href={resetPasswordUrl}
                                style={{
                                  display: "block",
                                  color: "white",
                                  textAlign: "center",
                                }}
                              >
                                Forgot Password?
                              </a>
                            </WhiteTextTypography>{" "}
                            <br />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={12}>
                          <Box
                            m={1}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                          >
                            <WhiteTextTypography>
                              <a
                                href="/register"
                                style={{
                                  display: "block",
                                  color: "white",
                                  textAlign: "center",
                                  textDecoration: "underline",
                                }}
                              >
                                Register
                              </a>
                            </WhiteTextTypography>{" "}
                            <br />
                          </Box>
                        </Grid>
                      </Grid>

                      <CardFooter className={classes.justifyContentCenter}>
                        <Button
                          color="success"
                          size="lg"
                          onClick={this.login}
                          round
                          className={classes.loginButton}
                          disabled={
                            this.state.email === null ||
                            this.state.email === "" ||
                            this.state.password === null ||
                            this.state.password === "" ||
                            navigator.onLine === false
                          }

                        >
                          Login
                        </Button>
                      </CardFooter>
                    </Card>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map((field) => {
      return { field, message: state.auth.errors[field] };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, remember_me) => {
      return dispatch(auth.login(username, password, remember_me));
    },
    updateUserTimezone: (timezone) => {
      return dispatch(workers.updateUserTimezone(timezone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
