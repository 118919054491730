import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import LoadingCircularIcon from "components/LoadingCircularIcon";

// core components
import Button from "components/CustomButtons/Button.jsx";
import RotateLeft from "@material-ui/icons/RotateLeft";
import RotateRight from "@material-ui/icons/RotateRight";
import TrashIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import withStyles from "@material-ui/core/styles/withStyles";
import imageUploadStyle from "./ImageUploadStyle";
import GridContainer from "../Grid/GridContainer";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.initAvatar !== undefined && this.props.initAvatar !== null) {
      this.state = {
        imagePreviewUrl: this.props.initAvatar
      };
    }else{
      this.state = {
        file: null,
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
      };
    }
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file_ = e.target.files[0];
    if(this.props.displayPreview){
      reader.onloadend = () => {
        this.setState({
          file: file_,
          imagePreviewUrl: reader.result
        });
        if(this.props.handleImageChange)
          this.props.handleImageChange(this.state);
      };
    }else{
      reader.onloadend = () => {
        this.setState({
          file: file_,
        });
        if(this.props.handleImageChange)
          this.props.handleImageChange(this.state);
      };
    }
    reader.readAsDataURL(file_);
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
    if(this.props.handleImageDelete)
      this.props.handleImageDelete(this.state);
  }
  reset() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.resetFile){
      const {avatar} = nextProps;
      return ({file: null, imagePreviewUrl: avatar ? defaultAvatar : defaultImage});
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.initAvatar !== this.state.imagePreviewUrl && this.props.initAvatar !== undefined && this.props.initAvatar !== null && this.state.imagePreviewUrl !== defaultAvatar){
      this.setState({imagePreviewUrl: this.props.initAvatar, rotating: false, rotatingRight: false, rotatingLeft: false})
    }
  }

  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      handleRotateLeft,
      handleRotateRight,
      classes
    } = this.props;
    const { rotating, rotatingLeft, rotatingRight } = this.state;
    const editClass = handleRotateLeft ? classes.middleButton: classes.firstButton;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" accept="image/*" />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="..." />
        </div>
        <GridContainer justify={"center"}>
          {this.state.file === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              {this.props.handleRotateLeft ? (
                <Button className={classes.firstButton} {...changeButtonProps} onClick={() => this.setState({rotating: true, rotatingLeft: true}, () => handleRotateLeft())} disabled={rotating} color={"info"} justIcon={true}>
                  {rotatingLeft === true ? (
                    <LoadingCircularIcon width={38} height={38} strokeColor={"#fff"} loading={rotatingLeft} styles={{marginBottom: "0px"}}/>
                  ):
                    <RotateLeft />
                  }
                </Button>
              ):null}
              {this.props.handleRotateRight ? (
                <Button {...changeButtonProps} className={classes.middleButton} onClick={() => this.setState({rotating: true, rotatingRight: true}, () => handleRotateRight())} disabled={rotating} color={"info"} justIcon={true}>
                  {rotatingRight === true ? (
                    <LoadingCircularIcon width={38} height={38} strokeColor={"#fff"} loading={rotatingRight} styles={{marginBottom: "0px"}}/>
                  ):
                    <RotateRight />
                  }
                </Button>
              ):null}
              <Button {...changeButtonProps} className={editClass} onClick={() => this.handleClick()} disabled={rotating} justIcon={true}>
                  <EditIcon />
              </Button>
              <Button
                {...removeButtonProps}
                className={classes.lastButton}
                disabled={rotating}
                onClick={() => this.handleRemove()}
                justIcon={true}
              >
                <TrashIcon />
              </Button>
              {avatar ? <br /> : null}
            </span>
          )}
        </GridContainer>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  initAvatar: PropTypes.string,
  handleImageChange: PropTypes.func,
  handleImageDelete: PropTypes.func,
  handleRotateRight: PropTypes.func,
  handleRotateLeft: PropTypes.func,
  resetFile: PropTypes.bool,
  displayPreview: PropTypes.bool,
};

ImageUpload.defaultProps = {
  displayPreview: true
};

export default withStyles(imageUploadStyle)(ImageUpload);
