import {
    boxShadow,
} from "assets/jss/material-dashboard-pro-react.jsx";

const AvatarStyle = {
    container: {
        float: "left",
        zIndex: "5",
        overflow: "hidden",
        transition: "all 300ms linear",
        borderRadius: "50%",
    },
    containerShadow: {
        ...boxShadow
    },
    avatarImg: {
        width: "100%",
        verticalAlign: "middle",
        border: "0"
    },
    img: {
        width: "100%",
        height: "100%",
        border: "0",
        verticalAlign: "middle",
    }
};

export default AvatarStyle;
