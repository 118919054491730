import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import { safety_meetings } from "actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-background)",
    marginBottom: "1rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  largerText: {
    fontSize: "1.2rem",
  },
  centeredText: {
    margin: "auto",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  safetyMeetingSection: {
    flexGrow: 1,
    margin: "1rem",
  },
  rowIcon: {},
  labels: {
    margin: "auto",
    textAlign: "center",
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const GreyAppBar = withStyles({
  root: {
    backgroundColor: "var(--trade-background)",
    borderRadius: "4px",
  },
})(AppBar);

const GreenTabs = withStyles({
  indicator: {
    color: "var(--trade-success)",
    backgroundColor: "var(--trade-success)",
  },
})(Tabs);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&:hover fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
})(TextField);

const FormLabel = withStyles({
  label: {
    fontSize: "1.3rem",
    color: "var(--trade-font)",
  },
})(FormControlLabel);

const GreenCheckbox = withStyles({
  root: {
    color: "var(--trade-success)",
    "&$checked": {
      color: "var(--trade-success)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const possibleQuestionTypes = [
  { value: "string", name: "Text" },
  { value: "choices", name: "Choice" },
];

function AddSafetyMeetingPage(props) {
  const [meetingId, setMeetingId] = useState(null);
  const [step, setStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [frequency, setFrequency] = useState("");
  const [description, setDescription] = useState("");
  const [fieldForms, setFieldForms] = useState([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionOption, setQuestionOption] = useState("");
  const [questionOptions, setQuestionOptions] = useState("");
  const [questionOptionList, setQuestionOptionList] = useState([]);
  const [company, setCompany] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const item_id = props.match.params.safety_meeting_id;
    console.log(item_id);
    //setQuestionType(possibleQuestionTypes[0].value);

    if (item_id !== undefined) {
      setMeetingId(item_id);
      props.getSafetyMeetingInfo(item_id).then((res) => {
        setCode(res.item.code);
        setTitle(res.item.title);
        setFrequency("");
        setDescription(res.item.description);
        setCompany(res.item.company);
        setClientEmail(res.item.client_email);
        setFieldForms(res.item.fields);
      });
    } else {
      const temp = props.current_company
        ? props.current_company
        : props.user.last_time_log.project.company_id;
      setCompany(temp);
    }
  }, []);

  {
    /* User Input Management */
  }
  const addFieldFormsBackend = async (fieldForms, meetingId) => {
    for (var i = 0; i < fieldForms.length; i++) {
      var response = await props.createFieldForm({
        order: 10,
        title: fieldForms[i].title,
        type: fieldForms[i].type,
        options: fieldForms[i].options,
        safety_meeting_info: meetingId,
      });
      console.log(response);
    }
  };

  const addFieldFormToSafetyMeeting = () => {
    //check if fields are filled
    if (questionTitle !== "" && questionType !== "") {
      if (
        (questionType === "choices" || questionType === "multi") &&
        questionOptionList.length < 2
      ) {
        //show snack with error - at least 2 choices needed
        setSnackOpen(true);
        setErrorMessage(
          "You need to create at least 2 choices for this type of question"
        );
      } else {
        setFieldForms([
          ...fieldForms,
          {
            order: "10",
            title: questionTitle,
            type: questionType,
            options: questionOptions,
            isRequired: "false",
          },
        ]);

        setQuestionTitle("");
        setQuestionOptions("");
        setQuestionOption("");
        setQuestionOptionList([]);
        setQuestionType("");
      }
    } else {
      //show snack error, title and type cannot be empty
      setSnackOpen(true);
      setErrorMessage("Fields Title and Type cannot be empty");
    }
  };

  const addAnswerToFieldForm = () => {
    if (questionOption !== "") {
      const ls = questionOptionList.filter(
        (elem) => elem.toLowerCase() === questionOption.toLowerCase()
      );

      if (ls.length > 0) {
        setSnackOpen(true);
        setErrorMessage("This choice was already added to the list");
      } else {
        var elm =
          questionOptions + questionOption + "|" + questionOption + "\r\n";

        setQuestionOptions(elm);
        setQuestionOptionList([...questionOptionList, questionOption]);
        setQuestionOption("");
      }
    } else {
      setSnackOpen(true);
      setErrorMessage("Choice field cannot be empty");
    }
  };

  const createMeeting = () => {
    if (
      company !== "" &&
      title !== "" &&
      code !== "" &&
      description !== "" &&
      clientEmail !== ""
    ) {
      const data = {
        company: company,
        title: title,
        code: code,
        description: description,
        daily: true,
        toolbox: false,
        library: false,
        clientEmail: clientEmail,
        files: [],
        frequency: frequency,
      };
      props.createSafetyMeetingInfo(data).then(async (e) => {
        if (e.type === "CREATE_SAFETY_MEETING" && e.item !== null) {
          addFieldFormsBackend(fieldForms, e.item.id).then((elem) => {
            setCode("");
            setTitle("");
            setDescription("");
            setFieldForms([]);
            setStep(1);
          });
        } else {
          setSnackOpen(true);
          setErrorMessage(
            "There was an error creating the safety meeting. Please try again later"
          );
        }
      });
    } else {
      setSnackOpen(true);
      setErrorMessage("Please make sure that none of the fields are empty");
    }
  };

  const deleteFieldForm = (ev) => {
    const ff = fieldForms.filter((elem) => elem.title === ev.target.name)[0];
    if (ff !== undefined && ff.id !== undefined) {
      props.deleteFieldForm(ff.id);
    }

    const update = fieldForms.filter((elem) => elem.title !== ev.target.name);
    setFieldForms(update);
  };

  const updateMeeting = () => {
    const data = {
      id: meetingId,
      company: company,
      title: title,
      code: code,
      description: description,
      daily: true,
      toolbox: false,
      library: false,
      files: [],
      client_email: clientEmail,
      frequency: frequency,
    };

    if (
      (questionType === "choices" || questionType === "multi") &&
      questionOptionList.length < 2
    ) {
      //show snack with error - at least 2 choices needed
      setSnackOpen(true);
      setErrorMessage(
        "You need to create at least 2 choices for this type of question"
      );
      return;
    }

    if (questionType === "text" && !questionTitle) {
      setSnackOpen(true);
      setErrorMessage("Question title cannot be empty");
      return;
    }

    props.updateSafetyMeetingInfo(data).then(async (e) => {
      for (var i = 0; i < fieldForms.length; i++) {
        if (!fieldForms[i].id) {
          var response = await props.createFieldForm({
            order: 10,
            title: fieldForms[i].title,
            type: fieldForms[i].type,
            options: fieldForms[i].options,
            safety_meeting_info: meetingId,
          });
        }
      }

      setCode("");
      setTitle("");
      setDescription("");
      setFieldForms([]);
      setStep(1);
    });
  };

  {
    /* View Components */
  }

  const safetyMeetingDetails = () => {
    return (
      <Grid container>
        <Grid container item md={4}>
          {/**Safety Meeting code */}
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={classes.labels}
              >
                Code
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={code}
                name="meetingCode"
                onChange={(event) => setCode(event.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
          {/**Safety Meeting Title */}
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography component={"span"}>
                Title
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={title}
                name="meetingTitle"
                onChange={(event) => setTitle(event.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
          {/**Safety Meeting Frequency */}
          {/* <Grid item md={3} className={classes.labels}>
                        <Box m={1} justifyContent="center" alignItems="center" display="flex" height="auto">
                            <WhiteTextTypography component={'span'} className={classes.labels}>Frequency</WhiteTextTypography>
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Box m={2}>
                            <CssTextField 
                                variant="outlined" 
                                fullWidth={true}
                                value={frequency}
                                name="meetingFrequency"
                                onChange={(event) => setFrequency(event.target.value)}
                            >
                            </CssTextField>
                        </Box>
                    </Grid> */}
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={classes.labels}
              >
                Safety Meeting Recipient
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={clientEmail}
                name="clientEmail"
                onChange={(event) => setClientEmail(event.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
        </Grid>
        {/** Safety Meeting Description Textarea */}
        <Grid container item md={8}>
          <Box m={2} style={{ width: "100%" }}>
            <CssTextField
              variant="outlined"
              multiline
              rows={15}
              fullWidth={true}
              label="Details"
              value={description}
              name="meetingDescription"
              onChange={(ev) => setDescription(ev.target.value)}
            ></CssTextField>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const questionContentRow = (data) => {
    const qtype = possibleQuestionTypes.filter(
      (elem) => elem.value === data.type
    )[0].name;
    const ls = [];
    data.options.split("\r\n").forEach((elem) => {
      var temp = elem.split("|")[0];
      ls.push(temp);
    });

    let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
    prettyOptions = prettyOptions.slice(0, prettyOptions.length - 1);

    return (
      <Grid container item md={12} key={data.title}>
        <Grid item md={4} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {data.title}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">{qtype}</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {prettyOptions}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box mb={1} ml={2}>
            <button
              style={{
                backgroundColor: "var(--trade-tertiary)",
                padding: "0.3rem 0.7rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
                margin: "auto",
              }}
              onClick={deleteFieldForm}
              name={data.title}
            >
              Delete
            </button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const safetyMeetingQuestionInput = () => {
    return (
      <React.Fragment>
        {/**Safety Meeting Q & A input */}
        <Grid container item sm={12} lg={12}>
          <Grid item sm={12} lg={4} md={4}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Question title</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={questionTitle}
                name="currentQuestionTitle"
                onChange={(ev) => setQuestionTitle(ev.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
          <Grid item sm={12} lg={2} md={2}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Question type</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                select
                value={questionType}
                name="currentQuestionType"
                onChange={(ev) => setQuestionType(ev.target.value)}
              >
                {possibleQuestionTypes.map((elem) => (
                  <MenuItem value={elem.value} key={elem.value}>
                    {elem.name}
                  </MenuItem>
                ))}
              </CssTextField>
            </Box>
          </Grid>
          <Grid item sm={12} lg={3} md={3}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Options</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={questionOption}
                name="currentQuestionOption"
                onChange={(ev) => setQuestionOption(ev.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
          <Grid item sm={12} lg={3} md={3}>
            <Box m={2}>
              <WhiteTextTypography>
                {" "}
                <br />{" "}
              </WhiteTextTypography>
            </Box>
            <Box m={2}>
              <button
                style={{
                  backgroundColor: "green",
                  padding: "0.3rem 0.7rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                  margin: "auto",
                }}
                onClick={addAnswerToFieldForm}
              >
                Add Answer
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
        {/**List of questions and answers */}
        <Grid container item sm={12} lg={12}>
          <Grid item sm={12} lg={4} md={4}></Grid>
          <Grid item sm={12} lg={2} md={2}></Grid>
          <Grid item sm={12} lg={3} md={3}>
            {questionOptionList.map((elem) => (
              <Box
                m={2}
                p={1}
                style={{ borderRadius: "4px", backgroundColor: "#555" }}
                key={elem}
              >
                <WhiteTextTypography>{elem}</WhiteTextTypography>
              </Box>
            ))}
          </Grid>
          <Grid item sm={12} lg={3} md={3}></Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const questionsHeaderRow = (titles) => {
    return (
      <Grid container item md={12}>
        <Grid item md={4} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Title
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Type
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">Options</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box m={2}></Box>
        </Grid>
      </Grid>
    );
  };

  const safetyMeetingQuestions = () => {
    return (
      <Grid container>
        <Grid item md={12}>
          <Box
            mr={2}
            justifyContent="flex-end"
            alignItems="flex-end"
            display="flex"
          >
            <button
              onClick={addFieldFormToSafetyMeeting}
              style={{
                backgroundColor: "green",
                padding: "0.7rem 2rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Add Question
            </button>
          </Box>
        </Grid>
        {safetyMeetingQuestionInput()}
        <Grid container item md={12}>
          {
            //list of added questions
            questionsHeaderRow(["Question", "Type", "Options"])
          }
          {fieldForms.map((elem) => questionContentRow(elem))}
        </Grid>
      </Grid>
    );
  };

  const mainContainer = () => {
    return (
      <Grid container>
        <Grid item md={12}>
          <Box m={2} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h4">
              {meetingId === null
                ? "Create new Safety Meeting"
                : "Edit Safety Meeting"}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <div className={classes.safetyMeetingSection}>
          <GreyAppBar position="static">
            <GreenTabs
              value={activeTab}
              onChange={(ev, val) => setActiveTab(val)}
              aria-label="simple tabs example"
            >
              <Tab label="Safety Meeting Details" {...a11yProps(0)} />
              <Tab label="Safety Meeting Questions" {...a11yProps(1)} />
            </GreenTabs>
            <TabPanel value={activeTab} index={0}>
              {
                safetyMeetingDetails()
                //tempContent("tab 1")
              }
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {
                safetyMeetingQuestions()
                //tempContent("tab 2")
              }
            </TabPanel>
          </GreyAppBar>
        </div>
        <Grid item md={12}>
          <Box m={2} justifyContent="center" alignItems="center" display="flex">
            <button
              onClick={meetingId !== null ? updateMeeting : createMeeting}
              style={{
                backgroundColor: "green",
                padding: "0.7rem 2rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
              }}
            >
              {meetingId !== null ? "Update Meeting" : "Create Safety Meeting"}
            </button>
            <Snackbar
              open={snackOpen}
              autoHideDuration={5000}
              onClose={() => setSnackOpen(false)}
            >
              <Alert onClose={() => setSnackOpen(false)} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const stepper = () => {
    switch (step) {
      case 0:
        return mainContainer();
      case 1:
        return <Redirect to="/safety-meetings/list" />;
      default:
        return mainContainer();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "var(--trade-body)",
        minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "2rem",
      }}
    >
      {stepper()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    current_company: state.companies.current_company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSafetyMeetingInfo: (id) => {
      return dispatch(safety_meetings.getSafetyMeeting(id));
    },
    createSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.createSafetyMeetingInfo(
          data.company,
          data.title,
          data.code,
          data.description,
          true,
          false,
          data.library,
          data.files,
          data.task_type_id,
          data.clientEmail,
          data.frequency
        )
      );
    },
    updateSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.saveSafetyMeeting(
          data.id,
          data.company,
          data.title,
          data.code,
          data.description,
          true,
          false,
          data.library,
          data.files,
          data.task_type_id,
          data.client_email,
          data.frequency
        )
      );
    },
    createFieldForm: (fieldFormData) => {
      return dispatch(safety_meetings.createFieldForm(fieldFormData));
    },
    deleteFieldForm: (id) => {
      return dispatch(safety_meetings.deleteFieldForm(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSafetyMeetingPage);
