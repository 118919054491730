import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { CardElement, injectStripe } from "react-stripe-elements";
import StripeStyles from "./StripeStyles";

import DomainIcon from "@material-ui/icons/Domain";
import LoadingIcon from "components/LoadingIcon";

class Stripe extends React.Component {
    state = {
        complete: false,
        loading: false,
        name: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        state: '',
        postal_code: '',
        country: '',
        cardComplete: false,
        error: { card: null },
        countries: ['Canada', 'Other'],
        provinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Québec', 'Saskatchewan'],
    };

    handleChange(event, value) {
        if (value === undefined) {
            value = event.target.value;
            if (value === '')
                value = null;
            if (value === 'true' || value === 'false')
                value = (value === 'true');
        }
        if (event.target.id) {
            this.setState({ [event.target.id]: value });
        } else {
            this.setState({ [event.target.name]: value });
        }
    }

    componentDidMount() {
        const { name, address1, address2, city, province, state, postal_code, country, loading } = this.props;
        if (name)
            this.setState({ company_name: name });
        if (address1)
            this.setState({ address1 });
        if (address2)
            this.setState({ address2 });
        if (city)
            this.setState({ city });
        if (province)
            this.setState({ province });
        if (state)
            this.setState({ state });
        if (postal_code)
            this.setState({ postal_code });
        if (country)
            this.setState({ country });

        if (loading !== undefined)
            this.setState({ loading });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.loading !== undefined && nextProps.loading !== prevState.loading)
            return ({ loading: nextProps.loading });
        return null;
    }

    async submit() {
        this.setState({ loading: true });
        const { company_name, address1, address2, city, province, postal_code, country } = this.state;
        this.props.stripe.createToken({
            name: company_name,
            address_line1: address1,
            address_line2: address2,
            address_city: city,
            address_state: province,
            address_zip: postal_code,
            address_country: country,
            currency: "CAD",
        }).then((payload, response) => {
            if (response !== undefined && response.error !== undefined && response.error.message !== undefined && this.props.onTokenError) {
                this.props.onTokenError(response.error.message);
            } else if (this.props.onTokenGenerated) {
                this.props.onTokenGenerated({ ...this.state, token: payload.token });
            }
        });
    }

    onCardChange(ev) {
        const { cardComplete, error } = this.state;
        if (ev.complete !== cardComplete)
            this.setState({ cardComplete: ev.complete });

        if (ev.error === undefined && error.card !== null)
            this.setState({ error: { ...error, card: null } });

        if (ev.error !== undefined)
            this.setState({ error: { ...error, card: ev.error.message } });

    }

    isEmpty(field) {
        return field === null || field === undefined || !field.length;
    }

    saveButtonDisabled() {
        const { error, cardComplete, loading } = this.state;
        const { company_name, address1, city, province, postal_code, country } = this.state;
        if (
            this.isEmpty(company_name) ||
            this.isEmpty(address1) ||
            this.isEmpty(city) ||
            this.isEmpty(province) ||
            this.isEmpty(postal_code) ||
            this.isEmpty(country)
        )
            return true;
        return loading || cardComplete === false || error.card !== null
    }

    render() {
        const { classes, showFirstChargeWarning, buttonLabel, buttonColor } = this.props;
        const { country, countries, province, provinces, loading } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={10}>
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange: (e) => this.handleChange(e),
                            id: "company_name",
                            defaultValue: this.props.name,
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <DomainIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: "Company name"
                        }}
                    />
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange: (e) => this.handleChange(e),
                            id: "address1",
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <DomainIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: "Address",
                            defaultValue: this.props.address1,
                        }}
                    />
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange: (e) => this.handleChange(e),
                            id: "postal_code",
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <DomainIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: "Postal Code",
                            defaultValue: this.props.postal_code,
                        }}
                    />
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                        }}
                        inputProps={{
                            onChange: (e) => this.handleChange(e),
                            id: "city",
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <DomainIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: "City",
                            defaultValue: this.props.city,
                        }}
                    />
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            htmlFor="province"
                            className={classes.selectLabel}
                        >
                            Province
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: "province"
                            }}
                            onChange={(e) => this.handleChange(e)}
                            inputProps={{
                                name: "province",
                                id: "province",
                            }}
                            value={province}
                        >
                            <MenuItem
                                disabled
                                selected={true}
                                classes={{
                                    root: classes.selectMenuItem
                                }}
                            >
                                Choose a province
                            </MenuItem>
                            {provinces.map((province_, index) => {
                                return <MenuItem
                                    key={index}
                                    selected={(province_ === province)}
                                    value={province_}
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    {province_}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            htmlFor="country"
                            className={classes.selectLabel}
                        >
                            Country
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: "country"
                            }}
                            onChange={(e) => this.handleChange(e)}
                            inputProps={{
                                name: "country",
                                id: "country",
                            }}
                            value={country}
                        >
                            <MenuItem
                                disabled
                                selected={true}
                                classes={{
                                    root: classes.selectMenuItem
                                }}
                            >
                                Choose a country
                            </MenuItem>
                            {countries.map((country_, index) => {
                                return <MenuItem
                                    key={index}
                                    selected={(country_ === country)}
                                    value={country_}
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    {country_}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {showFirstChargeWarning ?
                        <span className={classes.firstMonthCharge}>You will be charged 34.99$ + 4.99$ per user after your trial ends.</span>
                        : null}
                    <CardElement hidePostalCode className={classes.stripeCard} onChange={(e) => this.onCardChange(e)} />
                    <div className={classes.center}>
                        <Button round color={buttonColor} onClick={() => this.submit()} disabled={this.saveButtonDisabled()}>
                            <LoadingIcon width={38} height={38} strokeColor={"#fff"} loading={loading} />
                            {buttonLabel}
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

Stripe.propTypes = {
    classes: PropTypes.object.isRequired,
    onTokenGenerated: PropTypes.func.isRequired,
    onTokenError: PropTypes.func.isRequired,
    fullName: PropTypes.string,
    showFirstChargeWarning: PropTypes.bool,
    buttonLabel: PropTypes.string,
    buttonColor: PropTypes.string,
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    postal_code: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    country: PropTypes.string,
    loading: PropTypes.bool,
};

Stripe.defaultProps = {
    showFirstChargeWarning: false,
    buttonLabel: "Sign-up",
    buttonColor: "success",
    name: "",
    address1: "",
    address2: "",
    postal_code: "",
    city: "",
    province: "",
    country: "",
    loading: false,
};

export default withStyles(StripeStyles)(injectStripe(Stripe));
