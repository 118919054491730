import Raven from "raven-js";
import axios from 'axios';
import store from 'store';
import moment from 'moment';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const logException = (ex, context) => {
  Raven.captureException(ex, {
    extra: context
  });
  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
};

export const WhiteTextTypography = withStyles({
    root: {
        color: "var(--trade-font)",
    },
})(Typography);

export const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const token = store.get('token');
let ax = axios.create({
    baseURL: process.env.API_URL,
    headers: {'Content-Type': 'application/json'}
});

// Need a seperate axios to prevent inifite recursing in the interceptors
const axRefresh = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

if(token){
  ax.defaults.headers['Authorization'] = `Token ${token}`;
  axRefresh.defaults.headers['Authorization'] = `Token ${token}`;
}

ax.interceptors.request.use(function (config) {
    const token = store.get('token');
    if(token){
      if(config.headers['Authorization'] === undefined)
          config.headers['Authorization'] = `Token ${token}`;
      // Do something before request is sent
      const expiry = moment(store.get("expiry"));
      // const expiry = moment("2019-11-12T01:41:18.371473Z");
      const now = moment.utc();
      const hours = expiry.diff(now, 'hours', true);
      if(hours < 12 && hours > 0){
        axRefresh.post('/api/auth/refresh/').then(res => {
          store.set('token', res.data.token);
          store.set('expiry', res.data.expiry);
          ax.defaults.headers['Authorization'] = `Token ${res.data.token}`;
          config.headers['Authorization'] = ax.defaults.headers['Authorization'];
        });
      }else if(hours < 1){
        delete ax.defaults.headers['Authorization'];
        delete config.headers['Authorization'];
      }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

const logEvent = (eventType, eventInfo) => {
    ax.post("/api/events/", {'details': {'type': eventType, ...eventInfo}})
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            } else if (res.status >= 400 && res.status < 500) {
                throw res.data;
            }
        })
        .catch((err) => logException(err));
}

export { logException, logEvent, ax };

