import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons
import DomainIcon from "@material-ui/icons/Domain";
import PaymentIcon from "@material-ui/icons/Payment";
import WorkIcon from "@material-ui/icons/Work";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import StripeContainer from "components/Register/StripeContainer";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { companies } from "actions";
import companyStyle from "assets/jss/tradespecifix/company";
import BaseComponent from "../BaseComponent";

class CompanyForm extends BaseComponent {
  timeout = null;
  state = {
    timezones: CompanyForm.getTimezones(),
    timezone: "",
    paymentPending: false,
    newItem: true,
    task: "",
  };

  static getTimezones() {
    return [
      "America/Edmonton",
      "America/Halifax",
      "America/Montreal",
      "America/St_Johns",
      "America/Toronto",
      "America/Vancouver",
      "America/Winnipeg",
      "America/Yellowknife",
    ];
  }
  componentDidMount() {
    const item_id = this.props.match.params.id;
    let state_ = this.getInitState(this.state);
    if (item_id) {
      this.props.get(item_id).then((res) => {
        console.log(res);
      });
      this.props.get(item_id).then((res) => {
        state_ = this.state;
        state_["newItem"] = false;
        state_["timezone"] = res.item.timezone;
        state_["tasks_types"] = res.item.tasks_types;
        this.initState(state_);
      });
    } else {
      state_["newItem"] = true;
      this.initState(state_);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // When creating a new item, we need to derive the props
    if (
      nextProps.item &&
      nextProps.item.tasks_types &&
      prevState.tasks_types &&
      nextProps.item.tasks_types.length !== prevState.tasks_types.length
    ) {
      return { tasks_types: nextProps.item.tasks_types };
    }
    return null;
  }
  handleSave() {
    if (this.state.newItem === false) {
      this.props.save(this.state, this.props.item).then(() => {
        this.showSuccessfulAlert();
      });
    } else {
      this.props.create(this.state);
    }
  }
  updateBilling(state, item) {
    this.props.updateBilling(state, item).then((e) => {
      this.setState({ paymentPending: false }, () =>
        this.showSuccessfulAlert()
      );
    });
  }
  createTask(task, item) {
    this.props
      .createTask(task, item.id)
      .then(() => {
        this.showSuccessfulAlert();
        this.setState({
          task: "",
        });
      })
      .catch((e) => this.showError(e));
  }

  reorderTasks(e) {
    const { tasks_types } = this.state;
    if (!e.destination) {
      return;
    }

    const result = Array.from(tasks_types);
    const [removed] = result.splice(e.source.index, 1);
    result.splice(e.destination.index, 0, removed);
    result.forEach((task, index) => {
      if (task.order !== index) {
        this.props.updateTaskOrder(task.id, index);
      }
    });
    this.setState({ tasks_types: result });
  }
  render() {
    const { classes, item } = this.props;
    const { newItem, task, tasks_types } = this.state;
    if (newItem === false && (item === undefined || item.id === undefined))
      return null;

    // If the user has been created, let's redirect the browser to the edit page instead
    if (newItem && item !== undefined && item.id !== undefined) {
      const redirectTo = "/companies/" + item.id;
      return <Redirect to={redirectTo} />;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          {this.state.alert}
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <DomainIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {newItem ? "Create a new company" : this.props.item.name}
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Name
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlNoPadding,
                      }}
                      inputProps={{
                        onChange: (e) => this.handleChange(e),
                        id: "name",
                        defaultValue: this.props.item
                          ? this.props.item.name
                          : "",
                        type: "text",
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontalDropdown}>
                      Timezone
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="timezone"
                        className={classes.selectLabel}
                      >
                        Choose a timezone
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.timezone}
                        onChange={(e) => this.handleChange(e)}
                        inputProps={{
                          name: "timezone",
                          id: "timezone",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Choose a timezone
                        </MenuItem>
                        {this.state.timezones.map((timezone, index) => {
                          return (
                            <MenuItem
                              key={index}
                              selected={
                                newItem === false &&
                                timezone === this.props.item.timezone
                              }
                              value={timezone}
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              {timezone}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="flex-end">
                  <GridItem xs={12} sm={12} md={9}>
                    <Button
                      round
                      color="success"
                      onClick={() => this.handleSave()}
                    >
                      {newItem ? "Create" : "Save"}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color={"info"} icon>
              <CardIcon color={"info"}>
                <WorkIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Tasks</h4>
            </CardHeader>
            <CardBody>
              <span>
                If you add tasks here, workers will need to select what kind of
                work they are doing before starting their timer.
              </span>

              <DragDropContext onDragEnd={(e) => this.reorderTasks(e)}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className={classes.taskList}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {item !== undefined &&
                        tasks_types &&
                        tasks_types.map((task, index) => {
                          return (
                            <Draggable
                              draggableId={task.id}
                              index={index}
                              key={task.id}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className={classes.taskItemContainer}
                                  key={task.id}
                                >
                                  <DragHandleIcon />
                                  {task.title}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
            <CardFooter>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "New task",
                  id: "task",
                  onChange: (e) => this.handleChange(e),
                  value: task,
                }}
              />
              <Button
                round
                color="success"
                onClick={() => this.createTask(task, item)}
                disabled={task === undefined || task === ""}
              >
                Add
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        {newItem === false && item.id !== undefined ? (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <PaymentIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Billing information</h4>
              </CardHeader>
              <CardBody>
                <span>
                  Your subscription is currently{" "}
                  {item.subscription_active ? "active" : "not active"}.
                </span>
                <StripeContainer
                  name={item.name}
                  address1={item.address1}
                  address2={item.address2}
                  postal_code={item.postal_code}
                  city={item.city}
                  province={item.province}
                  country={item.country}
                  onTokenGenerated={(e) => this.updateBilling(e, item)}
                  onTokenError={(msg) => this.showError(msg)}
                  buttonLabel={"Save"}
                  loading={this.state.paymentPending}
                />
              </CardBody>
            </Card>
          </GridItem>
        ) : null}
      </GridContainer>
    );
  }
}

CompanyForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    item: state.companies.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get: (id) => {
      return dispatch(companies.getCompany(id));
    },
    save: (state, item) => {
      const name = state.name ? state.name : item.name;
      const timezone = state.timezone ? state.timezone : item.timezone;
      return dispatch(companies.saveCompany(item.id, name, timezone));
    },
    create: (state) => {
      return dispatch(companies.createCompany(state.name, state.timezone));
    },
    updateBilling: (state, item) => {
      const company_id = item.id;
      return dispatch(
        companies.updateBilling(
          company_id,
          state.company_name,
          state.address1,
          state.address2,
          state.postal_code,
          state.city,
          state.province,
          state.country,
          state.token.id
        )
      );
    },
    createTask: (task, item_id) => {
      return dispatch(companies.createTask(item_id, task));
    },
    updateTaskOrder: (...args) => {
      return dispatch(companies.updateTaskOrder(...args));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(companyStyle)(CompanyForm));
