import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const NoteFormStyle = {
  ...regularFormsStyle,
  labelHorizontal: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "0px",
    marginRight: "0",
    "@media (min-width: 992px)": {
      float: "right"
    }
  },
  textarea: {
    backgroundColor: "#eceaea",
    width: "250px",
    marginBottom: "5px",
    minHeight: "100px",
    border: "0",
  }
};

export default NoteFormStyle;
