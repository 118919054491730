import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NotesTimeline from "../NotesTimeline/NotesTimeline";
import Spinner from "react-bootstrap/Spinner";
import { ax } from "utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import "./NotesList.css";

const moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "#000000",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

function NotesList(props) {
  const { item, type, project, noteCreated } = props;
  const [totalItems, setTotalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const classes = useStyles();

  const fetchData = () => {
    const endpoint = "/api/notes/?project_id=" + project.id + "&limit=10";
    ax.get(endpoint)
      .then((res) => {
        setTotalItems(res.data.results);
        setNext(res.data.next);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [noteCreated, props.currentProject]);

  const fetchMoreData = async () => {
    ax.get(next)
      .then((res) => {
        console.log(res.data.results);
        setTotalItems([...totalItems, ...res.data.results]);
        setNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LoadMoreButton = () => {
    return next ? (
      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        style={{ padding: "0.8rem 1.1rem" }}
        onClick={fetchMoreData}
      >
        Load More
      </Button>
    ) : null;
  };

  return (
    <div>
      {loading ? (
        <div className="spinnerContainer">
          {/* <Spinner animation="border" variant="warning" /> */}
          <h2 style={{ color: "white" }}>Loading Notes...</h2>
        </div>
      ) : null}
      <div className="notes-wrapper" id="style-5">
        <div
          style={{
            width: "50vw",
            textAlign: "center",
          }}
        >
          {totalItems.length > 0 ? (
            <NotesTimeline item={totalItems} type="notes" />
          ) : loading ? null : (
            <h1 className="no-files-message">
              <span>No Notes Added</span>
            </h1>
          )}
        </div>
        {next ? <LoadMoreButton /> : null}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    project: state.projects.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesList);
