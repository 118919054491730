import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// theme components
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {Link} from "react-router-dom";
import Fab from "@material-ui/core/Fab";

// icons
import DomainIcon from "@material-ui/icons/Domain";
import Edit from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ScheduleIcon from "@material-ui/icons/Schedule";

// custom code
import {companies} from "actions";
import companyPageStyle from "assets/jss/tradespecifix/company";
import {Tooltip} from "@material-ui/core";

class CompaniesList extends Component {
    state = {
        searchStr: "",
        itemId: null,
        item: null,
    };

    componentDidMount() {
        this.props.fetch();
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <Fab color="primary" aria-label="Add" className={classes.Fab} component={Link} to="/companies/add">
                                <AddIcon />
                            </Fab>
                            <CardIcon color="info">
                                <DomainIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Companies</h4>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHead={[
                                    "Name",
                                    ""
                                ]}
                                tableData={this.props.companies.items.map(item =>
                                    [
                                        item.name,
                                        <React.Fragment>
                                            <Tooltip title="View time reports">
                                                <Button round className={classes.firstButton} color="info" component={Link} to={"/companies/" + item.id + "/timesheet"} justIcon><ScheduleIcon /></Button>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <Button round className={classes.lastButton} color="info" component={Link} to={"/companies/" + item.id} justIcon><Edit /></Button>
                                            </Tooltip>
                                        </React.Fragment>
                                    ])
                                }/>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

CompaniesList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        companies: state.companies,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => {
            dispatch(companies.fetchCompanies());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(companyPageStyle)(CompaniesList));
