import React from "react";
import ExcelSVG from "../svgs/Excel";
import PDFSVG from "../svgs/PDF";
import PowerPointSVG from "../svgs/PowerPoint";
import WordSVG from "../svgs/Word";

const IconSVG = (props) => {
  const { icon, fill } = props;

  switch (icon) {
    case "pdf":
      return (
        <PDFSVG
          fill={fill}
          style={{
            height: "20px",
            width: "20px",
            cursor: "pointer",
            paddingRight: "6px",
          }}
        />
      );
    case "xlsx":
    case "xls":
      return (
        <ExcelSVG
          fill={fill}
          style={{
            height: "20px",
            width: "20px",
            cursor: "pointer",
            paddingRight: "6px",
          }}
        />
      );
    case "ppt":
    case "pptx":
      return (
        <PowerPointSVG
          fill={fill}
          style={{
            height: "20px",
            width: "20px",
            cursor: "pointer",
            paddingRight: "6px",
          }}
        />
      );
    case "docx":
    case "doc":
      return (
        <WordSVG
          fill={fill}
          style={{
            height: "20px",
            width: "20px",
            cursor: "pointer",
            paddingRight: "6px",
          }}
        />
      );
    default:
      return "";
  }
};

export default IconSVG;
