import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { safety_meetings } from "actions";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CssTextField from "components/CssTextField/CssTextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 950,
    minHeight: "100vh",
    backgroundColor: "var(--trade-body)",
  },
  container: {
    height: "auto",
    backgroundColor: "var(--trade-body)",
    width: "100%",
    padding: "2rem",
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-body)",
    marginBottom: "1rem",
  },
  headers: {
    color: "var(--trade-primary)",
  },
  description: {
    color: "var(--trade-font)",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    height: "auto",
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "var(--trade-font)",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

function SafetyMeetingSlider(props) {
  const [safetyMeeting, setSafetyMeeting] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldForms, setFieldForms] = useState([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionOption, setQuestionOption] = useState("");
  const [questionOptions, setQuestionOptions] = useState("");
  const [questionOptionList, setQuestionOptionList] = useState([]);
  const [isSavingMeeting, setIsSavingMeeting] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackType, setSnackType] = useState("error");

  // meeting values
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(props.meeting.title);
  const [code, setCode] = useState(props.meeting.code);
  const [clientEmail, setClientEmail] = useState(props.meeting.client_email);
  const [description, setDescription] = useState(props.meeting.description);
  const [newMeetingData, setNewMeetingData] = useState({
    id: props.meeting.id,
    company: props.current_company,
    title: props.meeting.title,
    code: props.meeting.code,
    description: props.meeting.description,
    daily: props.meeting.daily,
    toolbox: props.meeting.toolbox,
    library: false,
    fields: [],
    client_email: props.meeting.client_email,
    frequency: props.meeting.frequency,
  });

  const classes = useStyles();

  const possibleQuestionTypes = [
    { value: "string", name: "Text" },
    { value: "choices", name: "Choice" },
  ];

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDialogOpen(false);
    setDrawerOpen(false);
    setIsEditing(false);
    setIsSavingMeeting(false);
  };

  useEffect(() => {
    setSafetyMeeting(props.meeting);
    setFieldForms(props.meeting.fields);
  }, [props.meeting]);

  const addFieldFormsBackend = async (fieldForms, meetingId) => {
    for (var i = 0; i < fieldForms.length; i++) {
      var response = await props.createFieldForm({
        order: 10,
        title: fieldForms[i].title,
        type: fieldForms[i].type,
        options: fieldForms[i].options,
        safety_meeting_info: meetingId,
      });
      console.log(response);
    }
  };

  const addFieldFormToSafetyMeeting = () => {
    //check if fields are filled
    if (questionTitle !== "" && questionType !== "") {
      if (
        (questionType === "choices" || questionType === "multi") &&
        questionOptionList.length < 2
      ) {
        //show snack with error - at least 2 choices needed
        setSnackOpen(true);
        setSnackType("error");
        setErrorMessage(
          "You need to create at least 2 choices for this type of question"
        );
      } else {
        setFieldForms([
          ...fieldForms,
          {
            order: "10",
            title: questionTitle,
            type: questionType,
            options: questionOptions,
            isRequired: "false",
          },
        ]);

        setQuestionTitle("");
        setQuestionOptions("");
        setQuestionOption("");
        setQuestionOptionList([]);
        setQuestionType("");
      }
    } else {
      //show snack error, title and type cannot be empty
      setSnackOpen(true);
      setSnackType("error");
      setErrorMessage("Fields Title and Type cannot be empty");
    }
  };

  const addAnswerToFieldForm = () => {
    if (questionOption !== "") {
      const ls = questionOptionList.filter(
        (elem) => elem.toLowerCase() === questionOption.toLowerCase()
      );

      if (ls.length > 0) {
        setSnackOpen(true);
        setSnackType("error");
        setErrorMessage("This choice was already added to the list");
      } else {
        var elm =
          questionOptions + questionOption + "|" + questionOption + "\r\n";

        setQuestionOptions(elm);
        setQuestionOptionList([...questionOptionList, questionOption]);
        setQuestionOption("");
      }
    } else {
      setSnackOpen(true);
      setSnackType("error");
      setErrorMessage("Choice field cannot be empty");
    }
  };

  const deleteFieldForm = (ev) => {
    const ff = fieldForms.filter((elem) => elem.title === ev.target.name)[0];
    if (ff !== undefined && ff.id !== undefined) {
      props.deleteFieldForm(ff.id);
    }

    const update = fieldForms.filter((elem) => elem.title !== ev.target.name);
    setFieldForms(update);
  };

  const createMeeting = () => {
    if (
      props.current_company !== "" &&
      title !== "" &&
      code !== "" &&
      description !== "" &&
      clientEmail !== ""
    ) {
      const data = {
        id: props.meeting.id,
        company: props.current_company,
        title: title,
        code: code,
        description: description,
        daily: props.meeting.daily,
        toolbox: props.meeting.toolbox,
        library: false,
        files: [],
        clientEmail: clientEmail,
        frequency: props.meeting.frequency,
      };

      const duplicateMeetingNumber = Number(
        title
          .split(" ")
          .at(-1)
          .replace(/[^0-9]/g, "")
      );

      props.safety_meeting_infos.map((meeting) => {
        if (meeting.title === title) {
          if (duplicateMeetingNumber > 0) {
            data.title =
              title.substring(0, title.length - 3) +
              ` (${duplicateMeetingNumber + 1})`;
          } else {
            data.title = title + ` (${duplicateMeetingNumber + 1})`;
          }
        }
      });

      props.createSafetyMeetingInfo(data).then(async (e) => {
        if (e.type === "CREATE_SAFETY_MEETING" && e.item !== null) {
          addFieldFormsBackend(fieldForms, e.item.id).then((elem) => {
            // setCode("");
            // setTitle("");
            // setDescription("");
            // setFieldForms([]);
            setSnackOpen(true);
            setSnackType("success");
            setErrorMessage("Safety meeting created");
            setTimeout(() => {
              closeDrawer();
            }, 1000);
          });
        } else {
          setSnackOpen(true);
          setSnackType("error");
          setErrorMessage(
            "There was an error creating the safety meeting. Please try again later"
          );
        }
      });
    } else {
      setSnackOpen(true);
      setSnackType("error");
      setErrorMessage("Please make sure that none of the fields are empty");
    }
  };

  const updateMeeting = () => {
    const data = {
      id: props.meeting.id,
      company: props.current_company,
      title: title,
      code: code,
      description: description,
      daily: props.meeting.daily,
      toolbox: props.meeting.toolbox,
      library: false,
      files: [],
      client_email: clientEmail,
      frequency: props.meeting.frequency,
    };
    if (
      (questionType === "choices" || questionType === "multi") &&
      questionOptionList.length < 2
    ) {
      //show snack with error - at least 2 choices needed
      setSnackOpen(true);
      setSnackType("error");
      setErrorMessage(
        "You need to create at least 2 choices for this type of question"
      );
      return;
    }

    if (questionType === "text" && !questionTitle) {
      setSnackOpen(true);
      setSnackType("error");
      setErrorMessage("Question title cannot be empty");
      return;
    }

    props.updateSafetyMeetingInfo(data).then(async (e) => {
      for (var i = 0; i < fieldForms.length; i++) {
        if (!fieldForms[i].id) {
          var response = await props.createFieldForm({
            order: 10,
            title: fieldForms[i].title,
            type: fieldForms[i].type,
            options: fieldForms[i].options,
            safety_meeting_info: props.meeting.id,
          });
        }
      }

      // setCode("");
      // setTitle("");
      // setDescription("");
      // setFieldForms([]);
      setSnackOpen(true);
      setSnackType("success");
      setErrorMessage("Safety meeting updated");
      setTimeout(() => {
        closeDrawer();
      }, 1000);
    });
  };

  const fieldFormList = () => {
    return (
      <Grid container item md={12} className={classes.container}>
        {props.item
          ? Object.values(props.item.fields).map((elem) => {
              return (
                <Grid
                  container
                  item
                  md={12}
                  className={classes.tableRow}
                  key={elem.title}
                >
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {elem.title}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {elem.type}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {elem.field_value}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })
          : safetyMeeting.fields.map((elem) => {
              const ls = [];
              elem.options.split("\r\n").forEach((e) => {
                var temp = e.split("|")[0];
                ls.push(temp);
              });

              let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
              prettyOptions = prettyOptions.slice(0, prettyOptions.length);
              return (
                <Grid
                  container
                  item
                  md={12}
                  className={classes.tableRow}
                  key={elem.title}
                >
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {elem.title}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {elem.type}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box
                      m={1}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <WhiteTextTypography variant="body1">
                        {prettyOptions}
                      </WhiteTextTypography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
      </Grid>
    );
  };

  const questionsHeaderRow = () => {
    return (
      <Grid container item md={12}>
        <Grid item md={4} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Title
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">
              Question Type
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography variant="h6">Options</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box m={2}></Box>
        </Grid>
      </Grid>
    );
  };

  const questionContentRow = (data) => {
    const qtype = possibleQuestionTypes.filter(
      (elem) => elem.value === data.type
    )[0].name;
    const ls = [];
    data.options.split("\r\n").forEach((elem) => {
      var temp = elem.split("|")[0];
      ls.push(temp);
    });

    let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
    prettyOptions = prettyOptions.slice(0, prettyOptions.length - 1);

    return (
      <Grid container item md={12} key={data.title}>
        <Grid item md={4} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {data.title}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">{qtype}</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box
            mb={1}
            ml={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography variant="body2">
              {prettyOptions}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={3} sm={12}>
          <Box mb={1} ml={2}>
            <button
              style={{
                backgroundColor: "var(--trade-tertiary)",
                padding: "0.3rem 0.7rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
                margin: "auto",
              }}
              onClick={deleteFieldForm}
              name={data.title}
            >
              Delete
            </button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const safetyMeetingQuestionInput = () => {
    return (
      <React.Fragment>
        {/**Safety Meeting Q & A input */}
        <Grid container item sm={12} lg={12}>
          <Grid item sm={12} lg={4} md={4}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Question title</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={questionTitle}
                name="currentQuestionTitle"
                onChange={(ev) => setQuestionTitle(ev.target.value)}
              ></CssTextField>
            </Box>
          </Grid>
          <Grid item sm={12} lg={2} md={2}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Question type</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                select
                value={questionType}
                name="currentQuestionType"
                onChange={(ev) => setQuestionType(ev.target.value)}
              >
                {possibleQuestionTypes.map((elem) => (
                  <MenuItem value={elem.value} key={elem.value}>
                    {elem.name}
                  </MenuItem>
                ))}
              </CssTextField>
            </Box>
          </Grid>
          <Grid item sm={12} lg={3} md={3}>
            <Box ml={2} mt={2}>
              <WhiteTextTypography>Answers</WhiteTextTypography>
            </Box>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={questionOption}
                name="currentQuestionOption"
                onChange={(ev) => setQuestionOption(ev.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AddIcon onClick={() => addAnswerToFieldForm()} />
                    </InputAdornment>
                  ),
                }}
              ></CssTextField>
            </Box>
          </Grid>
          {/* <Box m={2}>
              <WhiteTextTypography>
                {" "}
                <br />{" "}
              </WhiteTextTypography>
            </Box> */}
          <Grid
            item
            sm={12}
            lg={3}
            md={3}
            onClick={() => addFieldFormToSafetyMeeting()}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={addFieldFormToSafetyMeeting}
              style={{
                backgroundColor: "green",
                padding: "0.7rem 2rem",
                fontWeight: "bold",
                color: "var(--trade-font)",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Add Question
            </button>
          </Grid>

          {/* <button
                style={{
                  backgroundColor: "green",
                  padding: "0.3rem 0.7rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                  margin: "auto",
                }}
                onClick={addAnswerToFieldForm}
              >
                Add Answer
              </button> */}
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => setSnackOpen(false)}
          >
            <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
              {errorMessage}
            </Alert>
          </Snackbar>
        </Grid>
        {/**List of questions and answers */}
        <Grid container item sm={12} lg={12}>
          <Grid item sm={12} lg={4} md={4}></Grid>
          <Grid item sm={12} lg={2} md={2}></Grid>
          <Grid item sm={12} lg={3} md={3}>
            {questionOptionList.map((elem) => (
              <Box
                m={2}
                p={1}
                style={{ borderRadius: "4px", backgroundColor: "#555" }}
                key={elem}
              >
                <WhiteTextTypography>{elem}</WhiteTextTypography>
              </Box>
            ))}
          </Grid>
          <Grid item sm={12} lg={3} md={3}></Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const safetyMeetingInfo = () => {
    return (
      <Grid container className={classes.container}>
        {!isEditing ? (
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "var(--trade-success)",
              color: "white",
              margin: "0.8rem 1.1rem",
            }}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        ) : null}
        {props.pdfPath ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: "0.8rem 1.1rem" }}
            onClick={() => window.open(props.pdfPath)}
          >
            View PDF
          </Button>
        ) : null}
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              style={{ placeContent: isEditing ? "flex-start" : "" }}
            >
              <AmberTypography
                variant="h4"
                className={classes.headers}
                style={{ width: isEditing ? "10rem" : "" }}
              >
                Title:{" "}
              </AmberTypography>
              {isEditing ? (
                <Grid item md={6}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <CssTextField
                      variant="outlined"
                      fullWidth={true}
                      value={title}
                      name="meetingTitle"
                      onChange={(event) => {
                        setTitle(event.target.value);
                        setNewMeetingData({
                          ...newMeetingData,
                          title: event.target.value,
                        });
                      }}
                    ></CssTextField>
                  </Box>
                </Grid>
              ) : (
                <WhiteTextTypography
                  variant="h4"
                  style={{ marginLeft: "1rem" }}
                >
                  &#9;&#9; {safetyMeeting.title}
                </WhiteTextTypography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              style={{ placeContent: isEditing ? "flex-start" : "" }}
            >
              <AmberTypography
                variant="h4"
                className={classes.headers}
                style={{ width: isEditing ? "10rem" : "" }}
              >
                Code:{" "}
              </AmberTypography>
              {isEditing ? (
                <Grid item md={6}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <CssTextField
                      variant="outlined"
                      fullWidth={true}
                      value={code}
                      name="meetingCode"
                      onChange={(event) => {
                        setCode(event.target.value);
                        setNewMeetingData({
                          ...newMeetingData,
                          code: event.target.value,
                        });
                      }}
                    ></CssTextField>
                  </Box>
                </Grid>
              ) : (
                <WhiteTextTypography
                  variant="h4"
                  style={{ marginLeft: "1rem" }}
                >
                  &#9;&#9; {safetyMeeting.code}
                </WhiteTextTypography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              style={{ placeContent: isEditing ? "flex-start" : "" }}
            >
              <AmberTypography
                variant="h4"
                className={classes.headers}
                style={{ width: isEditing ? "10rem" : "" }}
              >
                Safety Meeting Recipient:{" "}
              </AmberTypography>
              {isEditing ? (
                <Grid item md={6}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <CssTextField
                      variant="outlined"
                      fullWidth={true}
                      value={clientEmail}
                      name="meetingCode"
                      onChange={(event) => {
                        setClientEmail(event.target.value);
                        setNewMeetingData({
                          ...newMeetingData,
                          client_email: event.target.value,
                        });
                      }}
                    ></CssTextField>
                  </Box>
                </Grid>
              ) : (
                <WhiteTextTypography
                  variant="h4"
                  style={{ marginLeft: "1rem" }}
                >
                  &#9;&#9; {safetyMeeting.client_email}
                </WhiteTextTypography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Box m={2}>
            {isEditing ? (
              <CssTextField
                variant="outlined"
                multiline
                rows={15}
                fullWidth={true}
                label="Details"
                value={description}
                name="meetingDescription"
                onChange={(event) => {
                  setDescription(event.target.value);
                  setNewMeetingData({
                    ...newMeetingData,
                    description: event.target.value,
                  });
                }}
              ></CssTextField>
            ) : (
              <pre
                style={{ color: "var(--trade-font)" }}
                className={classes.description}
              >
                {safetyMeeting.description}
              </pre>
            )}
          </Box>
        </Grid>
        {isEditing ? (
          <Grid container>
            {/* <Grid item md={12}>
              <Box
                mr={2}
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
              >
                <button
                  onClick={addFieldFormToSafetyMeeting}
                  style={{
                    backgroundColor: "green",
                    padding: "0.7rem 2rem",
                    fontWeight: "bold",
                    color: "var(--trade-font)",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  Add Question
                </button>
              </Box>
            </Grid> */}
            {safetyMeetingQuestionInput()}
            <Grid container item md={12}>
              {
                //list of added questions
                fieldForms.length ? questionsHeaderRow() : null
              }
              {fieldForms.map((elem) => questionContentRow(elem))}
            </Grid>
          </Grid>
        ) : null}
        {!isEditing && props.meeting.fields.length ? (
          <Grid container item md={12}>
            <Grid item md={4}>
              <Box
                m={1}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <WhiteTextTypography variant="h5" className={classes.headers}>
                  Question
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box
                m={1}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <WhiteTextTypography variant="h5" className={classes.headers}>
                  Question Type
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                m={1}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <WhiteTextTypography variant="h5" className={classes.headers}>
                  Answers
                </WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        {!isEditing && props.meeting.fields.length ? fieldFormList() : null}
        {isEditing ? (
          <Grid
            container
            item
            md={12}
            justifyContent="center"
            style={{ marginTop: "3rem" }}
          >
            <Grid item md={2}>
              <Box justifyContent="center" alignItems="flex-end" display="flex">
                <button
                  onClick={() => {
                    // props.closeMeetingContainer();
                    // setDrawerOpen(false);
                    setIsEditing(false);
                  }}
                  style={{
                    backgroundColor: "#F44336",
                    padding: "0.7rem 1rem",
                    fontWeight: "bold",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  Cancel
                </button>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box justifyContent="center" alignItems="flex-end" display="flex">
                <button
                  onClick={() => {
                    // updateMeeting(newMeetingData);
                    // setDrawerOpen(false);
                    setDialogOpen(true);
                  }}
                  style={{
                    backgroundColor: "green",
                    padding: "0.7rem 1rem",
                    fontWeight: "bold",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  Save
                </button>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Save As...
          </DialogTitle>
          <DialogActions>
            <Button
              autoFocus
              style={{
                color: "var(--trade-secondary)",
              }}
              onClick={() => createMeeting()}
            >
              New meeting
            </Button>
            <Button
              autoFocus
              style={{
                color: "var(--trade-tertiary)",
              }}
              onClick={() => updateMeeting()}
            >
              Overwrite current meeting
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <div className={classes.mainContainer}>
        {safetyMeeting !== null ? (
          safetyMeetingInfo()
        ) : (
          <div> No Info available </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <React.Fragment>
        {props.whiteIcon ? (
          <WhiteTextTypography onClick={openDrawer}>
            <ChevronRightIcon />
          </WhiteTextTypography>
        ) : (
          <Typography onClick={openDrawer}>
            <ChevronRightIcon />
          </Typography>
        )}

        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    current_company: state.companies.current_company,
    safety_meeting_infos: state.safety_meetings.items_info,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createFieldForm: (fieldFormData) => {
      return dispatch(safety_meetings.createFieldForm(fieldFormData));
    },
    createSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.createSafetyMeetingInfo(
          data.company,
          data.title,
          data.code,
          data.description,
          true,
          false,
          data.library,
          data.files,
          data.task_type_id,
          data.clientEmail,
          data.frequency
        )
      );
    },
    deleteFieldForm: (id) => {
      return dispatch(safety_meetings.deleteFieldForm(id));
    },
    getCompanySafetyMeetingInfos: (company_id) => {
      return dispatch(safety_meetings.getCompanySafetyMeetingInfos(company_id));
    },
    updateSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.saveSafetyMeeting(
          data.id,
          data.company,
          data.title,
          data.code,
          data.description,
          true,
          false,
          data.library,
          data.files,
          data.task_type_id,
          data.client_email,
          data.frequency
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafetyMeetingSlider);
// export default SafetyMeetingSlider;
