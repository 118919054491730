import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyD5m8-MIE1HEFf-mdrqsZBq2tZLXw2kz0U",
    authDomain: "tradespecifix-1553023504647.firebaseapp.com",
    databaseURL: "https://tradespecifix-1553023504647.firebaseio.com",
    projectId: "tradespecifix-1553023504647",
    storageBucket: "tradespecifix-1553023504647.appspot.com",
    messagingSenderId: "590446259612",
    appId: "1:590446259612:web:19869440656de5a3df2c59"
});

let messaging = {};
messaging.isSupported = () => false;
if(firebase.messaging.isSupported()){
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        "BBhtk262Zf_6lGn2BGZEDpqynqYNeXX1RraiIxDCLIDROjCPc3HKiWcwF4Ls2Pe3y2cVdSNUjXIO0luCqtirGgw"
    );
    messaging.isSupported = firebase.messaging.isSupported;
}
export { messaging };
