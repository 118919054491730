import React, { Component, createRef } from "react";
import "./SiteSelection.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { time_logs, workers, safety_meetings, companies, auth } from "actions";
import { connect } from "react-redux";
import NavBar from "../NavBar/Navbar";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import LoadingCircularIcon from "../../../src/components/LoadingCircularIcon";
import Danger from "components/Typography/Danger";
import WarningIcon from "@material-ui/icons/Warning";

import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import MuiAlert from "@material-ui/lab/Alert";
import CssTextField from "components/CssTextField/CssTextField";
import {ax, getUserTimezone} from "utils";

import MobileAlert from "newComponents/MobileAlert/MobileAlert";

const axios = require("axios");
const moment = require("moment"); // require

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class SiteSelection extends Component {
  _isMounted = false;
  safetyMeetingContainer = createRef();

  state = {
    companyID: "",
    projectID: "",
    taskID: "",
    errors: {},
    isLoading: true,
    buttonValue: false,
    displaySelection: false,
    longitude: null,
    latitude: null,
    availableProjects: [],
    availableTasks: [],
    safetyMeetingInfos: [],
    safetyMeetings: [],
    currentSafetyMeetingInfo: null,
    currentSafetyMeeting: null,
    current_index: 0,
    snackOpen: false,
    snackMessage: "",
    snackSeverity: "error",
    displayAdminMeeting: false,
    project: "",
  };

  componentDidMount() {
    this.getCompanies();
    this._isMounted = true;
    this.getCoordinates();

    const timelog = this.props.user.last_time_log;
    if (timelog !== null && timelog.end === null && timelog.task_type_id !== null) {
      if (timelog.type === 'break' || timelog.type === 'delivery') {
        this.setState({ redirectTo: '/on-' + timelog.type })
      } else if (timelog.safety_meeting_read) {
        this.setState({ redirectTo: '/dashboard' })
      } else {
        // The user has a timer running but has not read the safety meetings
        this.setState({ isLoading: true });
        const company_id = timelog.project.company_id;
        const project_id = timelog.project.id;

        this.props.updateCompanyContext(company_id);
        this.getProjects(company_id).then((projects) => {
          const projectObj = projects.filter(
            (elem) => elem.id === project_id
          )[0];

          this.setState({
            availableTasks: projectObj.tasks,
            companyID: company_id,
            projectID: project_id,
            taskID: timelog.task_type_id,
            timeLogID: timelog.id,
            projectSelectorLocked: true,
            isLoading: true,
            displaySelection: true,
          }, () => this.getSafetyMeetings());
        });
      }
    } else {
      // The user does not have any timer running
      this.getProjects(this.props.user.company);
    }
  }

  getCompanies = () => {
    this.props.getCompanies().then(response => {
      // If only one company in the account, let's select it by default
      if (response.count === 1) {
        const company = response.items[0];
        this.props.updateCompanyContext(company.id)
        this.setState({
          companyID: company.id,
          projectID: "",
          currentSafetyMeetingInfo: null,
        })
      }
    });
  }

  getProjects = (company_id) => {
    return ax.get("/api/projects/short/?limit=100&company=" + company_id)
      .then((res) => {
        this.setState({
          availableProjects: res.data.results,
          isLoading: false,
        });
        return res.data.results;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCoordinates = () => {
    let url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        // handle success
        if (this._isMounted) {
          this.setState({
            latitude: Number(response.data.latitude),
            longitude: Number(response.data.longitude),
          });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  startEnabled() {
    const { user, gpsEnabled } = this.props;
    // if (user.company_subscription_active === false) return false;
    return !(user.gps_forced && gpsEnabled === false);
  }

  readSafetyMeetings = () => {
    // start time log right now, put everything else in read only, save the time log id
    this.setState({
      projectSelectorLocked: true
    });
    this.startTimer().then((response) => {
      this.setState({
        timeLogID: response.item.id,
      })
      this.deploySafetyMeeting();
    });
  }


  deploySafetyMeeting = () => {
    if (this.state.projectID && this.state.taskID) {
      this.getSafetyMeetings();
      this.setState({ isLoading: true });
    }
  };

  getSafetyMeetings = () => {
    const { user } = this.props;
    const { projectID, taskID } = this.state;

    this.props.fetchTaskSafetyMeetings(taskID).then((res) => {
      if (res) {
        if (res.items?.length === 0) {
          this.setState({
            ...this.state,
            currentSafetyMeetingInfo: null,
            safetyMeeting: false,
          });
        } else {
          const meetings = [];

          for (var i = 0; i < res.items.length; i++) {
            // create a temp safety meeting for each SafetyMeetingInfo,
            meetings.push({
              safety_meeting_info: res.items[i].id,
              user: user.id,
              created_by: user.id,
              project_id: projectID,
              fields: {},
            });

            // initialize fields of each safety meeting to ""
            var lastMeeting = meetings[meetings.length - 1];
            res.items[i].fields.forEach((elem) => {
              elem.field_value = "";
              lastMeeting.fields[elem.id] = elem;
            });
          }

          this.setState({
            ...this.state,
            currentSafetyMeetingInfo: res.items[0],
            currentSafetyMeeting: meetings[0],
            safetyMeetingInfos: res.items,
            safetyMeetings: meetings,
            safety_meetings_count: res.items.length,
            current_index: 0,
            items_read: 0,
            projectID,
            worker_id: user.id,
            isLoading: false,
          });
        }
      }
    });
  };

  handleFieldFormChange = (ev) => {
    const meeting = this.state.currentSafetyMeeting;
    meeting.fields[ev.target.name].field_value = ev.target.value;

    this.setState({
      currentSafetyMeeting: meeting,
    });
  };

  displayChoicesFieldForm = (fieldForm) => {
    const optionList = fieldForm.options
      .split("\r\n")
      .map((elem) => elem.split("|")[0]);
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1} style={{ color: "white" }}>
            {fieldForm.title}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1}>
            <CssTextField
              value={
                this.state.currentSafetyMeeting.fields[fieldForm.id].field_value
              }
              onChange={this.handleFieldFormChange}
              name={fieldForm.id}
              variant="outlined"
              select
              style={{ minWidth: "15vw" }}
            >
              {optionList.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </CssTextField>
          </Box>
        </Grid>
      </Grid>
    );
  };

  displayTextField = (fieldForm) => {
    return (
      <Grid container item md={12} key={fieldForm.id}>
        <Grid item md={12}>
          <Box m={1} style={{ color: "white" }}>
            {fieldForm.title}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1}>
            <CssTextField
              value={
                this.state.currentSafetyMeeting.fields[fieldForm.id].field_value
              }
              name={fieldForm.id}
              onChange={this.handleFieldFormChange}
              style={{ minWidth: "15vw" }}
              variant="outlined"
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  displayFieldForm = (fieldForm) => {
    switch (fieldForm.type) {
      case "choices":
        return this.displayChoicesFieldForm(fieldForm);
      case "string":
        return this.displayTextField(fieldForm);
      default:
        return null;
    }
  };

  displayFieldForms = (ffs) => {
    return (
      <Grid container>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{ height: "3px", backgroundColor: "white" }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        <Grid item md={2}>
          <Box m={1} justifyContent="center" display="flex" alignItems="center">
            <WhiteTextTypography variant="h5">Q &amp; A</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={5} style={{ margin: "auto" }}>
          <Box
            m={1}
            style={{ height: "3px", backgroundColor: "white" }}
            justifyContent="center"
            display="flex"
            alignItems="center"
          ></Box>
        </Grid>
        {ffs.map((elem) => this.displayFieldForm(elem))}
      </Grid>
    );
  };

  displaySafetyMeeting = () => {
    const { currentSafetyMeetingInfo } = this.state;

    const data = (
      <div>
        <WhiteTextTypography variant="h5" style={{ textAlign: "center" }}>
          {currentSafetyMeetingInfo.title}
        </WhiteTextTypography>
        <WhiteTextTypography variant="h6" style={{ textAlign: "center" }}>
          Safety Meeting Code: {currentSafetyMeetingInfo.code}
        </WhiteTextTypography>
        {currentSafetyMeetingInfo.last_update ? (
          <WhiteTextTypography
            variant="subtitle1"
            style={{ textAlign: "left" }}
          >
            Last update :{" "}
            {moment(currentSafetyMeetingInfo.last_update).format("YYYY-MM-DD")}
          </WhiteTextTypography>
        ) : (
          ""
        )}
        {/* <Typography
          dangerouslySetInnerHTML={{ __html: safety_meeting.description }}
        ></Typography> */}
        <pre
          style={{
            whiteSpace: "pre-wrap",
            height: "auto",
            color: "white",
            maxHeight: "25vh",
          }}
        >
          {currentSafetyMeetingInfo.description}
        </pre>
        {currentSafetyMeetingInfo.images.length ? (
          <div>
            {" "}
            <p style={{ textTransform: "uppercase" }}>
              <strong> Images</strong>
            </p>{" "}
            <div className="safetyImageWrapper">
              <div className="safetyImageConatiner">
                {currentSafetyMeetingInfo.images.map((image, index) => {
                  return (
                    <img
                      src={image.image}
                      alt={currentSafetyMeetingInfo.title}
                      key={index}
                      className="safetyImage"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        {this.displayFieldForms(currentSafetyMeetingInfo.fields)}
      </div>
    );
    return data;
  };

  showNextMeeting = () => {
    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);
    for (var i = 0; i < fieldKeys.length; i++) {
      if (
        this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === ""
      ) {
        //show snackbar
        this.setState({
          snackOpen: true,
          snackMessage: "Please Answer all the questions before proceeding.",
        });
        return;
      }
    }

    // create safety meting
    this.props
      .ackMeeting(
        this.state.currentSafetyMeeting.safety_meeting_info,
        this.state.currentSafetyMeeting.project_id,
        this.state.currentSafetyMeeting.user,
        this.state.currentSafetyMeeting.fields,
        this.state.taskID,
        this.state.timeLogID,
      )
      .then((elem) => {
        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          const next_index = this.state.current_index + 1;
          this.setState({
            currentSafetyMeetingInfo: this.state.safetyMeetingInfos[next_index],
            currentSafetyMeeting: this.state.safetyMeetings[next_index],
            current_index: next_index,
            items_read: this.state.items_read + 1,
          });

          // var myDiv = document.getElementById("style-5"); //Scroll to top
          // myDiv.scrollTop = 0;
        }
      });
  };

  async startTimer() {
    const { user } = this.props;
    const { projectID, taskID } = this.state;
    const { latitude, longitude } = this.state;
    return this.props.startTimer(
      projectID,
      latitude,
      longitude,
      user.id,
      taskID
    );
  }

  goToDashboard = () => {
    const fieldKeys = Object.keys(this.state.currentSafetyMeeting.fields);

    this.setState({ isLoading: true });

    for (var i = 0; i < fieldKeys.length; i++) {
      if (
        this.state.currentSafetyMeeting.fields[fieldKeys[i]].field_value === ""
      ) {
        //show snackbar
        this.setState({
          snackOpen: true,
          snackMessage: "Please Answer all the questions before proceeding.",
        });
        console.log(this.state.currentSafetyMeeting.fields[fieldKeys[i]]);
        return;
      }
    }

    this.props
      .ackMeeting(
        this.state.currentSafetyMeeting.safety_meeting_info,
        this.state.currentSafetyMeeting.project_id,
        this.state.currentSafetyMeeting.user,
        this.state.currentSafetyMeeting.fields,
        this.state.taskID,
        this.state.timeLogID,
      )
      .then((elem) => {
        this.setState({ isLoading: false });
        if (elem && elem.type === "ACK_SAFETY_MEETING") {
          this.setState({
            redirectTo: "/dashboard",
            displaySelection: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  startAdminProject = (project, user, task) => {
    this.setState({ isLoading: true, displayAdminMeeting: true });
    this.handleTaskChange(null, task.id, project);
  };

  handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      this.setState({
        buttonValue: true,
      });
    } else {
      this.setState({
        buttonValue: false,
      });
    }
  };

  handleSMChange = (ev) => {
    if (
      this.safetyMeetingContainer.current.scrollHeight ===
      this.safetyMeetingContainer.current.clientHeight &&
      this.state.currentSafetyMeetingInfo !== null
    ) {
      this.setState({
        buttonValue: true,
      });
    }
  };

  handleProjectChange = (event, newValue) => {
    const projectObj = this.state.availableProjects.filter(
      (elem) => elem.id === newValue.id
    )[0];

    if (projectObj !== undefined) {
      this.setState({
        availableTasks: projectObj.tasks,
        taskID: "",
        currentSafetyMeetingInfo: null,
        projectID: newValue.id,
        project: newValue,
      });
    }
  };

  handleCompanyChange = (event) => {
    const companyObj = this.props.companies.filter(
      (elem) => elem.id === event.target.value
    )[0];

    this.getProjects(companyObj.id);
    this.props.updateCompanyContext(companyObj.id);

    this.setState({ isLoading: true });

    if (companyObj) {
      this.setState({
        companyID: companyObj.id,
        projectID: "",
        currentSafetyMeetingInfo: null,
      });
    }
  };

  handleTaskChange = (event, task, project) => {
    if (event) {
      this.setState(
        {
          taskID: event.target.value,
        },
        () => {
          //console.log(this.state.selectedProject);
          this.readSafetyMeetings();
        }
      );
    } else {
      this.setState(
        {
          taskID: task,
          projectID: project.id,
        },
        () => {
          //console.log(this.state.selectedProject);
          this.readSafetyMeetings();
        }
      );
    }
  };

  companySelect = () => {
    return (
      <React.Fragment>
        {this.props.companies && this.props.companies.length > 0 ? (
          <CssTextField
            value={this.state.companyID}
            onChange={this.handleCompanyChange}
            disabled={this.state.projectSelectorLocked}
            variant="outlined"
            label="Select Company"
            select
            style={{
              minWidth: "15vw",
              marginRight: "2rem",
              marginBottom: "1rem",
            }}
          >
            {this.props.companies.map((company) => (
              <MenuItem
                value={company.id}
                key={company.id}
                style={{ textTransform: "capitalize" }}
              >
                {company.name}
              </MenuItem>
            ))}
          </CssTextField>
        ) : null}
      </React.Fragment>
    );
  };

  projectSelect = () => {
    const projectOptions = this.state.availableProjects
      .filter((elem) => !elem.archived)
      .filter((elem) => elem.title !== "Office Work")
      .sort((a, b) => a.title.localeCompare(b.title));

    return (
      <React.Fragment>
        {this.state.availableProjects &&
          this.state.availableProjects.length > 0 ? (
          <Autocomplete
            id="ProjectSelector"
            value={this.state.project}
            onChange={this.handleProjectChange}
            label="Select Project"
            disabled={this.state.projectSelectorLocked}
            select
            disableClearable
            style={{
              minWidth: "20vw",
              marginRight: "2rem",
              marginBottom: "1rem",
            }}
            getOptionLabel={(option) => (option ? option.title : null)}
            options={projectOptions}
            loading={this.state.isLoading}
            renderInput={(params) => (
              <CssTextField {...params} variant="outlined" label="Project">
                {params.title}
              </CssTextField>
            )}
          />
        ) : null}
      </React.Fragment>
    );
  };

  taskSelect = () => {
    return (
      <React.Fragment>
        {this.state.availableTasks.length > 0 ? (
          <CssTextField
            value={this.state.taskID}
            onChange={this.handleTaskChange}
            variant="outlined"
            label="Select Task"
            disabled={this.state.projectSelectorLocked}
            select
            style={{ minWidth: "15vw", marginBottom: "1rem" }}
          >
            {this.state.availableTasks
              .filter((task) => task.title !== "Site Worker")
              .map((task, index) => (
                <MenuItem
                  value={task.id}
                  key={index}
                  style={{ textTransform: "capitalize" }}
                >
                  {task.title}
                </MenuItem>
              ))}
          </CssTextField>
        ) : null}
      </React.Fragment>
    );
  };

  managerCardOptions = (task) => {
    const imgSrc = task.title
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_");

    const currentProj = this.state.availableProjects.filter(
      (elem) => elem.is_admin_project
    );

    return (
      <Card
        sx={{ maxWidth: 345 }}
        style={{
          backgroundColor: "transparent",
          border: "1px solid var(--trade-neutral)",
          height: "8rem",
          width: "9rem",
          padding: "1rem",
          boxShadow: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          if (task.title !== "Site Worker") {
            this.startAdminProject(currentProj[0], this.props.user, task);
          } else {
            this.setState({ displaySelection: true });
          }
        }}
      >
        <CardMedia
          component="img"
          height="50%"
          src={`${imgSrc}.svg`}
          alt="task icon"
          style={{ objectFit: "contain" }}
        />
        <CardContent>
          <WhiteTextTypography variant="body2" color="text.secondary">
            {task.title}
          </WhiteTextTypography>
        </CardContent>
      </Card>
    );
  };

  createProjectButton = () => {
    return (
      <React.Fragment>
        <Link
          style={{
            backgroundColor: "green",
            padding: "0.7rem 2rem",
            fontWeight: "bold",
            color: "white",
            border: "none",
            borderRadius: "4px",
          }}
          to="/projects/add"
        >
          Create Project
        </Link>
      </React.Fragment>
    );
  };

  renderProjectSelection = () => {
    const { user } = this.props;
    const {
      currentSafetyMeetingInfo,
      items_read,
      safety_meetings_count,
      redirectTo,
    } = this.state;

    const { latitude, longitude } = this.state;

    const gpsEnabled = this.props.isGeolocationEnabled
      ? this.props.isGeolocationEnabled
      : false;

    if (redirectTo !== undefined) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const itemString = 'safety meeting';
    return (
      <div className="mapProjectDivisionContainer">
        {/* <div className="selfLocationMap">
            <LastGPSLocation latitude={latitude} longitude={longitude} />
          </div> */}
        <div className="siteSelectionWrapper" onMouseOver={this.handleSMChange}>
          <div className="siteSelectionContainer">
            <div className="projectSelectionContainer">
              <div className="projectSelectionWrapper">
                {this.companySelect()}
                {this.projectSelect()}
                {this.taskSelect()}
              </div>
            </div>
            <div
              className="safetyMeetingContainer"
              id="style-5"
              onScroll={this.handleScroll}
              ref={this.safetyMeetingContainer}
            >
              {this.state.isLoading && (
                <Spinner
                  animation="border"
                  variant="warning"
                  className="spinner"
                />
              )}
              {currentSafetyMeetingInfo ? (
                this.displaySafetyMeeting()
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    flexDirection: "column-reverse",
                  }}
                >
                  {/* <p>Please Select Project & Task.</p> */}
                  {this.props.current_company && this.props.user.is_manager ? (
                    this.createProjectButton()
                  ) : (
                    <p>Please Select Project & Task.</p>
                  )}
                </div>
              )}
            </div>
            {this.startEnabled() && user.gps_forced && gpsEnabled === false ? (
              <React.Fragment>
                <Danger>
                  <WarningIcon />
                </Danger>
                <span>You need to enable GPS to start logging your time.</span>
              </React.Fragment>
            ) : null}

            {this.startEnabled() &&
              user.gps_forced &&
              (longitude === null || latitude === null) ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingCircularIcon
                  strokeColor={"#fff"}
                  loading={true}
                  styles={{
                    margin: "-10px 5px -5px 0px",
                  }}
                />
                <span>Waiting for GPS lock.</span>
              </div>
            ) : null}

            {longitude === null || latitude === null ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingCircularIcon
                  strokeColor={"#fff"}
                  loading={true}
                  styles={{
                    margin: "-10px 5px -5px 0px",
                  }}
                />
                <span
                  style={{ textTransform: "uppercase", margin: "10px 0px" }}
                >
                  Please Allow GPS to lock and then Proceed.
                </span>
              </div>
            ) : null}
            {currentSafetyMeetingInfo ? (
              items_read === safety_meetings_count - 1 ? (
                <React.Fragment>
                  <Button
                    round
                    color="success"
                    size="lg"
                    className="start_timer"
                    onClick={this.goToDashboard}
                    style={{ fontWeight: "bold" }}
                  >
                    I have read and understood
                    <br /> the {itemString}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    round
                    color="success"
                    size="lg"
                    className="start_timer"
                    onClick={this.showNextMeeting}
                    style={{ fontWeight: "bold" }}
                  >
                    I have read and understood
                    <br /> the {itemString}, read next item
                  </Button>
                </React.Fragment>
              )
            ) : null}
            <Snackbar
              open={this.state.snackOpen}
              autoHideDuration={5000}
              onClose={() => this.setState({ snackOpen: false })}
            >
              <Alert
                onClose={() => this.setState({ snackOpen: false })}
                severity={this.state.snackSeverity}
              >
                {this.state.snackMessage}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.user.last_time_log) {
      if (this.props.user.last_time_log.end === null && this.props.user.last_time_log.safety_meeting_read) {
        return <Redirect to="/dashboard" />;
      }
    }

    if (this.state.redirectTo !== undefined) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <React.Fragment>
        <NavBar />
        <MobileAlert />
        <div>
          {this.state.displaySelection || this.props.user.role === "WORKER" ? (
            this.renderProjectSelection()
          ) : (
            <div className="admin-selection">
              <WhiteTextTypography>
                Hi, {this.props.user.first_name}, what would you like to do
                today?
              </WhiteTextTypography>
              {this.state.displayAdminMeeting &&
                this.state.currentSafetyMeetingInfo ? (
                <div
                  style={{
                    height: "25vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {this.displaySafetyMeeting()}
                  {this.state.currentSafetyMeetingInfo ? (
                    this.state.items_read ===
                      this.state.safety_meetings_count - 1 ? (
                      <React.Fragment>
                        <Button
                          round
                          color="success"
                          size="lg"
                          className="start_timer"
                          onClick={this.goToDashboard}
                          style={{ fontWeight: "bold" }}
                        >
                          I have read and understood
                          <br /> the Safety Meeting
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          round
                          color="success"
                          size="lg"
                          className="start_timer"
                          onClick={this.showNextMeeting}
                          style={{ fontWeight: "bold" }}
                        >
                          I have read and understood
                          <br /> the Safety Meeting, read next item
                        </Button>
                      </React.Fragment>
                    )
                  ) : null}
                </div>
              ) : (
                <div className="admin-projects">
                  {this.state.isLoading && (
                    <Spinner
                      animation="border"
                      variant="warning"
                      className="spinner"
                    />
                  )}
                  {this.state.availableProjects
                    .filter((elem) => elem.is_admin_project)
                    .map((project, index) =>
                      project.tasks
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((task) => this.managerCardOptions(task))
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SiteSelection.propTypes = {
  user: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    projects: state.projects.items,
    current_company: state.companies.current_company,
    companies: state.companies.items,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          false,
          getUserTimezone(),
        )
      );
    },
    updateCompanyContext: (company_id) => {
      return dispatch(auth.getCompanyContext(company_id));
    },
    getCompanies: () => {
      return dispatch(companies.fetchCompanies());
    },
    fetchTaskSafetyMeetings: (task_type_id) => {
      return dispatch(
        safety_meetings.fetchSafetyMeetingsInfoForTask(task_type_id)
      );
    },
    ackMeeting: (meeting_id, project_id, worker_id, fields_values, task_id, timelog_id) => {
      if (fields_values === undefined) fields_values = [];
      return dispatch(
        safety_meetings.acknowledgeMeeting(
          meeting_id,
          project_id,
          worker_id,
          fields_values,
          task_id,
          timelog_id,
        )
      );
    },
    gpsRefused: () => {
      return dispatch(workers.gpsRefused());
    },
    gpsAccepted: () => {
      return dispatch(workers.gpsAccepted());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelection);
