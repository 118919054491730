import React from 'react';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import Moment from 'react-moment';
import TimeReport from "../TimeReport";
import {Link} from "react-router-dom";
// theme components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
// custom code
import {time_logs, companies} from "actions";
import reportStyle from "assets/jss/tradespecifix/report";

class CompanyTimeReport extends TimeReport {
    constructor(props){
        super(props);
        const item_id = this.props.match.params.id;
        this.state = this.getInitState({
            'item_id': item_id,
            'ordered_logs': null
        });
        this.getLogs(this.state.start, this.state.end);
        this.props.getCompany(item_id);
    }
    getLogs(start, end){
        return this.props.getLogs(this.state.item_id, start, end);
    }
    static getSiteTotal(rows){
        return rows.map(worker_row => {
            const start = moment(worker_row.start);
            const end = moment(worker_row.end);
            const duration = moment.duration(end.diff(start));
            return duration.asHours();
        }).reduce(TimeReport.sumRound, 0);
    }
    static getTotal(rows){
        return Object.values(rows).map(worker_rows => {
            return worker_rows.map((worker_row) => {
                const start = moment(worker_row.start);
                const end = moment(worker_row.end);
                const duration = moment.duration(end.diff(start));
                return duration.asHours();
            }).reduce(TimeReport.sumRound, 0);
        }).reduce(TimeReport.sumRound, 0);
    }
    static _renderWeeklySite(project_id, project_name, rows){
        return <TableRow key={project_name}>
            <TableCell component="th" scope="row">
                <Link to={"/projects/" + project_id + "/timesheet/"}>{project_name}</Link>
            </TableCell>
            <TableCell align="right">{CompanyTimeReport.getSiteTotal(rows)}</TableCell>
        </TableRow>;
    }
    getWeeklyReport() {
        const { classes } = this.props;
        if(this.props.logs_ordered === undefined)
            return <div/>;

        return <Table className={classes.fullWidth}>
            <TableHead>
                <TableRow>
                    <TableCell>Site</TableCell>
                    <TableCell align="right">Hours</TableCell>
                </TableRow>
            </TableHead>
            {this.props.logs_ordered !== undefined?Object.keys(this.props.logs_ordered).map(project_id => {
                const rows = this.props.logs_ordered[project_id];
                const project_name = rows[0].project.title;
                return <TableBody key={project_id}>
                    {CompanyTimeReport._renderWeeklySite(project_id, project_name, rows)}
                </TableBody>;
            }):null}
            <TableFooter>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Total
                    </TableCell>
                    <TableCell align="right">{CompanyTimeReport.getTotal(this.props.logs_ordered)}</TableCell>
                </TableRow>
            </TableFooter>
        </Table>;
    }
    getWeeklyDetailedReport() {
        const { classes } = this.props;
        return <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="right">Start</TableCell>
                    <TableCell align="right">End</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.logs.map(log => {
                    return <React.Fragment key={log.id}>
                        <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                                <b>{log.user_first_name} {log.user_last_name}</b>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Moment format={"dddd HH:mm:ss"}>{log.start}</Moment></TableCell>
                            <TableCell><Moment format={"dddd HH:mm:ss"}>{log.end}</Moment></TableCell>
                        </TableRow>
                    </React.Fragment>
                })}
            </TableBody>
        </Table>;
    }
    render() {
        const { classes } = this.props;
        if(this.props.item === undefined || this.props.item.id === undefined)
            return null;
        return <div>
            <GridContainer>
                <GridItem className={classes.fullWidth} container justify="center">
                    <h3>{this.props.item.name}</h3>
                </GridItem>
            </GridContainer>
            {this.renderWeekNavigation()}
            <GridContainer>
                <GridItem className={classes.fullWidth}>
                    <CustomTabs
                        headerColor="success"
                        className={classes.fullWidth}
                        tabs={[
                            {
                                tabName: "Weekly",
                                tabContent: (this.getWeeklyReport())
                            },
                            //{
                            //    tabName: "Detailed",
                            //    tabContent: (this.getWeeklyDetailedReport())
                            //},
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        item: state.companies.item,
        logs: state.time_logs.items,
        logs_ordered: state.time_logs.items_ordered_by_company,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLogs: (company_id, start, end) => {
            return dispatch(time_logs.fetchTimeLogsByCompanyId(company_id, start, end));
        },
        getCompany: (company_id) => {
            return dispatch(companies.getCompany(company_id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(reportStyle)(CompanyTimeReport));
