import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DayTableTimeSheet from "../TableSlider/TableSlider";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import { ax } from "utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import "./SafetyMeetingList.css";

var moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "#000000",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

const SafetyMeetingList = (props) => {
  const [right, setRight] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dayItems, setDayItems] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [startDate, endDate] = dateRange;
  const classes = useStyles();

  const { item, type, project, currentYear } = props;

  const date = new Date()
    .toLocaleString("en-US", {
      timeZone: props.user.company_timezone,
    })
    .split("/")[1];
  const month = new Date()
    .toLocaleString("en-US", {
      timeZone: props.user.company_timezone,
    })
    .split("/")[0];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    fetchData(date, month, currentYear);
  }, [props.currentProject]);

  const fetchData = (day, month, year) => {
    ax.get(
      "/api/safety-meetings/?project_id=" +
        project.id +
        "&day=" +
        day +
        "&month=" +
        month +
        "&year=" +
        year
    )
      .then((res) => {
        setTotalItems([res.data.results]);
        setNext(res.data.next);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchMoreData = async () => {
    ax.get(next)
      .then((res) => {
        console.log(res.data.results);
        setTotalItems([...totalItems, ...res.data.results]);
        setNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDrawer = (open, state, item) => () => {
    switch (state) {
      case "open":
        setRight(open);

        // change is here  --------------------------------
        setDateRange([
          moment(props.item, "MMMM YYYY").startOf("month").toDate(),
          moment(props.item, "MMMM YYYY").endOf("month").toDate(),
        ]);
        // change is here ---------------------------------

        // const [month, year] = props.item.split(" ");
        const month = props.item.split(" ")[0];
        const year = props.item.split(" ")[1];
        const monthIndex = months.indexOf(month);
        const monthParam = monthIndex + 1;
        fetchData(monthParam, year);
        break;

      case "close":
        setRight(open);
        break;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        // width: "60vw",
        backgroundColor: "transparent",
        textAlign: "center",
      }}
      onKeyDown={toggleDrawer(false, "close")}
    >
      {loading ? (
        <div className="spinnerContainer">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : totalItems[0].length > 0 ? (
        totalItems.map((item, key) => {
          return (
            <div key={key}>
              <div className="dayValue">
                {/* <h3> {moment(item[0].read_on).format("Do MMMM YYYY")}</h3> */}
                <DayTableTimeSheet
                  tableData={item}
                  key={key}
                  type={"safety-meetings"}
                />
              </div>
            </div>
          );
        })
      ) : (
        <h2 className="no-meetings-message">
          No Safety Meetings Read for the above period.
        </h2>
      )}
      {/* <LoadMoreButton /> */}
    </div>
  );
};

SafetyMeetingList.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    safety_meetings_project: state.safety_meetings.current_project_items,
    project: state.projects.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetyMeetingList);
