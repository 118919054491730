import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { workers } from "actions";
import TablePagination from "@material-ui/core/TablePagination";
import { Avatar } from "@material-ui/core";
import Gravatar from "react-gravatar";
import CreateIcon from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import ArchiveIcon from "@material-ui/icons/Archive";
import SideDrawerWorker from "../SideDrawerWorker/SideDrawerWorker";
import "./WorkerLibrary.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

var moment = require("moment"); // require

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      backgroundColor: "transparent",
      margin: "auto 0",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "var(--trade-font)",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "var(--trade-neutral)",
    },
    "&:after": {
      borderColor: "var(--trade-neutral)",
    },
  },
  icon: {
    fill: "var(--trade-neutral)",
  },

  root: {
    "& > *": {
      borderColor: "var(--trade-neutral)",
    },
    backgroundColor: "var(--trade-background)",
  },
  noLocation: {
    border: "solid 2px rgba(200, 20, 20, 1)",
    backgroundColor: "#444",
  },
});
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const WarningTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "2rem",
  },
})(Typography);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "var(--trade-background)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: "#FFFFFF",
  },
}))(TableCell);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const LoadingCircle = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(CircularProgress);

function Row(props) {
  const { row, user, updateWorkerManagerStatus } = props;
  const [loading, setLoading] = React.useState(false);
  const [permissionsItem, setPermissionsItem] = React.useState(row.role);

  const handlePermissionSelect = (event) => {
    const target = event.target;
    const value = target.value;

    setPermissionsItem(value);

    if (value === "OWNER" || value === "MANAGER") {
      updateWorkerManagerStatus(row.id, value);
    } else {
      updateWorkerManagerStatus(row.id, value);
    }
  };

  const classes = useStyles();
  const styles = {
    tableCell: {
      color: "var(--trade-primary)",
      fontWeight: "bold",
    },
    buttonColor: {
      color: "white",
    },
    amberColor: {
      color: "var(--trade-primary)",
    },
    tableBorder: {
      borderTop: "solid 2px var(--trade-neutral)",
      borderBottom: "solid 2px var(--trade-neutral)",
    },
    tableAvatar: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tableStyles: { width: "99%", maxHeight: "80vh" },

    tableHead: {
      color: "white",
    },

    tablePagination: { backgroundColor: "var(--trade-background)", color: "white" },
    buttonColorArchive: { color: "var(--trade-tertiary)" },
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "var(--trade-background)",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const archiveWorker = (worker_id) => {
    setLoading(true);
    props
      .archiveWorker(worker_id)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <TableRow
        className={
          row.gps_permission_status === "denied"
            ? classes.noLocation
            : classes.root
        }
        style={
          row.gps_permission_status === "denied"
            ? styles.tableBorderNoLocation
            : styles.tableBorder
        }
      >
        <TableCell style={styles.tableCell} align="left">
          <Grid container item md={8}>
            <Grid item md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                {row.avatar ? (
                  <Avatar src={row.avatar} />
                ) : (
                  <Gravatar
                    email={row.email}
                    avatar_url={row.avatar}
                    size={35}
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item md={10}>
              <Box m={2}>
                <WhiteTextTypography>
                  {row.user.first_name + " " + row.user.last_name}
                </WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center" style={styles.tableCell}>
          <AmberTypography>
            {moment(row.user.date_joined).format("Do MMMM YYYY")}
          </AmberTypography>
        </TableCell>
        <TableCell align="center" style={styles.tableCell}>
          <WhiteTextTypography style={{ textTransform: "lowercase" }}>
            {row.user.email}
          </WhiteTextTypography>
        </TableCell>
        {/* <TableCell align="center" style={styles.tableCell}>
          <AmberTypography>
          {row.tasks_types
            ? row.tasks_types.map(
              (item, key) => (key ? ", " : "") + item.title
              )
              : ""}
              </AmberTypography>
            </TableCell> */}
        {/* Role  dropdown cell */}
        {user.role === "OWNER" || user.role === "MANAGER" ? (
          <TableCell align="center" style={styles.tableCell}>
            <FormControl fullWidth id="permissions-dropdown">
              <CssTextField
                variant="outlined"
                labelId="select-permission-label"
                id="permission-select"
                value={permissionsItem}
                onChange={handlePermissionSelect}
                select
                style={{ minWidth: 120 }}
              >
                {user.role === "OWNER" ? (
                  <MenuItem value="OWNER">Owner</MenuItem>
                ) : null}
                <MenuItem value="MANAGER">Manager</MenuItem>
                <MenuItem value="WORKER">Worker</MenuItem>
              </CssTextField>
            </FormControl>
          </TableCell>
        ) : null}
        {/* Edit Worker */}
        <TableCell align="center" style={styles.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            component={Link}
            to={"/workers/" + row.user.id}
            title="Edit Worker Details"
          >
            <CreateIcon style={styles.buttonColor} />
          </IconButton>
        </TableCell>
        {/* Archive Worker */}
        {user.role !== "OWNER" ? null : (
        <TableCell align="center" style={styles.tableCell}>
          {" "}
          {row.user.id !== user.id && !row.user.is_manager ? (
            loading ? (
              <LoadingCircle />
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => archiveWorker(row.id)}
                title="Archive Worker"
              >
                <ArchiveIcon style={styles.buttonColorArchive} />
              </IconButton>
            )
          ) : row.user.id === user.id ? (
            <WhiteTextTypography> Cannot Archive Yourself</WhiteTextTypography>
          ) : (
            <WhiteTextTypography> Permission Not Allowed</WhiteTextTypography>
          )}
        </TableCell>
        )}
        {/* Details Worker */}
        <TableCell align="center" style={styles.tableCell}>
          <SideDrawerWorker worker_id={row.user.id} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable(props) {
  useEffect(() => {
    fetchWorkers();
  }, [props.fetchWorkers]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackType, setSnackType] = React.useState("error");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const fetchWorkers = () => {
    props.fetchWorkers();
  };

  const { workers, user } = props;

  const styles = {
    tableStyles: { width: "99%", maxHeight: "80vh" },

    tableHead: {
      color: "white",
    },

    tablePagination: { backgroundColor: "var(--trade-background)", color: "white" },
  };
  const titleSection = () => (
    <Grid container style={{ padding: "2% 0 0 3%" }}>
      <Grid item md={12}>
        <WhiteTextTypography variant="h4">Workers</WhiteTextTypography>
      </Grid>
      <Grid container item md={12}>
        <WhiteTextTypography>
          <span
            style={{
              backgroundColor: "var(--trade-tertiary)",
              height: "20px",
              width: "20px",
              borderRadius: "20px",
              margin: "2px",
              display: "inline-block",
              position: "relative",
              top: "6px",
            }}
          ></span>
          <span style={{ margin: "2px", display: "inline-block" }}>
            Location permission for Geofencing Refused
          </span>
        </WhiteTextTypography>
      </Grid>
      <Grid container item md={12}>
        <WhiteTextTypography>
          You currently have {props.count_active} active worker
          {props.count_active > 1 ? "s" : ""}.
        </WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const updateWorkerManagerStatus = (worker_id, role) => {
    return props.updateWorkerManagerStatus(worker_id, role).then((res) => {
      if (res.type === "GET_WORKER") {
        console.log("SUCCESS!");
      } else {
        console.log("ERROR");
      }
    });
  };

  const archiveWorker = (worker_id) => {
    return props.archiveWorker(worker_id).then((res) => {
      if (res.type === "GET_WORKER") {
        showSuccessSnack();
        props.fetchWorkers();
      } else {
        showErrorSnack();
      }
    });
  };

  const showSuccessSnack = () => {
    setSnackType("success");
    setSnackMessage("Worker Archived");
    setSnackOpen(true);
  };

  const showErrorSnack = () => {
    setSnackType("error");
    setSnackMessage("Couldn't archive worker, try again later.");
    setSnackOpen(true);
  };

  const activeWorkers = workers
    .filter((item) => !item.archived)
    .sort((a, b) => {
      if (a.first_name < b.first_name) {
        return -1;
      } else if (a.first_name > b.first_name) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => a.user.first_name.localeCompare(b.user.first_name));
  return (
    <div className="worker-list-container">
      {titleSection()}
      {activeWorkers.length !== 0 ? (
        <div className="worker-list-table-container">
          <TableContainer
            component={Paper}
            style={styles.tableStyles}
            id="style-5"
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>

                  <StyledTableCell align="center">Date Added</StyledTableCell>

                  <StyledTableCell align="center">Email</StyledTableCell>

                  {user.role === "OWNER" || user.role === "MANAGER" ? (
                    <StyledTableCell align="center">
                      Permissions
                    </StyledTableCell>
                  ) : null}

                  <StyledTableCell align="center">Edit</StyledTableCell>

                  {user.role === "OWNER" ? (
                      <StyledTableCell align="center">Archive</StyledTableCell>
                  ) : null }

                  <StyledTableCell align="center">Details</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeWorkers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, key) => (
                    <Row
                      key={key}
                      row={item}
                      user={user}
                      archiveWorker={archiveWorker}
                      updateWorkerManagerStatus={updateWorkerManagerStatus}
                    />
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              style={styles.tablePagination}
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={activeWorkers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <WarningTextTypography>No Active Workers</WarningTextTypography>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workers: state.workers.items,
    count: state.workers.count,
    count_active: state.workers.count_active,
    isStaff: state.auth.user.is_staff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //will use company of current timelog to know which workers to fetch
    fetchWorkers: () => {
      dispatch(workers.fetchWorkers());
    },
    archiveWorker: (worker_id) => {
      return dispatch(workers.archiveWorker(worker_id));
    },
    updateWorkerManagerStatus: (workerId, role) => {
      return dispatch(workers.updateWorkerManagerStatus(workerId, role));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
