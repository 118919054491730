import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { workers } from "actions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import NotificationsPreferences from "newComponents/NotificationsPreferences/NotificationsPreferences";
import GridItem from "../../components/Grid/GridItem";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
  root: {
    color: "var(--trade-font)",
  },
})(Typography);

const GreenCheckbox = withStyles({
  root: {
    color: "var(--trade-success)",
    "&$checked": {
      color: "var(--trade-success)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&:hover fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "var(--trade-font)",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const GreenTypography = withStyles({
  root: {
    color: "green",
  },
})(Typography);

const FormLabel = withStyles({
  label: {
    fontSize: "1.3rem",
    color: "var(--trade-font)",
  },
})(FormControlLabel);

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    maxHeight: "10rem",
    height: "10rem",
    maxWidth: "10rem",
    width: "100%",
    margin: "auto",
  },
  imageIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  avatarImage: {
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
    margin: "auto",
  },
  imageWrapper: {
    position: "relative",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "100%",
    width: "100%",
    margin: "0",
    padding: "0",
  },
  userInfoContainer: {
    backgroundColor: "var(--trade-background)",
    borderRadius: "8px",
    padding: "1rem",
  },
  passwordReqs: {
    textAlign: "center",
  },
}));

function WorkerPage(props) {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [company, setCompany] = useState("");
  const [workerId, setWorkerId] = useState("");
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const avatarInput = useRef();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  useEffect(() => {
    const workerId = props.match.params.id;
    if (workerId !== undefined) {
      if (workerId === props.user.id) {
        setUserId(workerId);
        setFirstName(props.user.first_name);
        setLastName(props.user.last_name);
        setEmail(props.user.email);
        setPhone(props.user.phone_number);
        setAvatar(props.user.avatar);
        setIsManager(props.user.is_manager);
        setCompany(props.user.company);
      } else {
        props.getWorker(workerId).then((res) => {
          setUserId(workerId);
          setFirstName(res.item.first_name);
          setLastName(res.item.last_name);
          setEmail(res.item.email);
          setPhone(res.item.phone_number);
          setAvatar(res.item.avatar);
          setIsManager(res.item.is_manager);
          setCompany(res.item.company);
          setWorkerId(res.item.worker_id);
        });
      }
    } else {
      setUserId(props.user.id);
      setFirstName(props.user.first_name);
      setLastName(props.user.last_name);
      setEmail(props.user.email);
      setPhone(props.user.phone_number);
      setAvatar(props.user.avatar);
      setIsManager(props.user.is_manager);
      setCompany(props.user.company);
    }
  }, [props.match.params.id]);

  const passwordValidator = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]{8,}$/;
    return regex.test(password) && password === passwordConfirm;
  };

  const changePassword = () => {
    // if (passwordValidator()) {
    props.updatePassword(password, userId).then((elem) => {
      if (elem.type === "GET_WORKER") {
        setPasswordDialogOpen(false);
        setSnackType("success");
        setSnackMessage("Password updated");
        setSnackOpen(true);
      }
    });
    // } else {
    //   setSnackType("error");
    //   setSnackMessage("Passwords don't match or don't meet requirements");
    //   setSnackOpen(true);
    // }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const clickAvatarInput = () => {
    avatarInput.current.click();
  };

  const handleAvatarChange = (ev) => {
    if (ev.target.files.length > 0) {
      console.log(ev.target.files[0]);
      setAvatarFile(ev.target.files[0]);
      setAvatar(URL.createObjectURL(ev.target.files[0]));
      props.saveAvatar(userId, ev.target.files[0]).then((elem) => {
        if (elem.type === "GET_WORKER") {
          setSnackType("success");
          setSnackMessage("Avatar updated");
          setSnackOpen(true);
        }
      });
    }
  };

  const saveChanges = () => {
    //validate
    if (userId === props.user.id) {
      if (
        userId === "" ||
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        phone === "" ||
        company === ""
      ) {
        setSnackType("error");
        setSnackMessage("There can be no empty fields");
        setSnackOpen(true);
      } else {
        let data = {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phone,
          company: company,
          isManager: isManager,
        };
        props.saveUser(data).then((elem) => {
          if (elem.type === "GET_WORKER") {
            setSnackType("success");
            setSnackMessage("Your Information was updated");
            setSnackOpen(true);
          }
        });
      }
    } else {
      if (workerId) {
        props.updateWorkerManagerStatus(workerId, isManager).then((elem) => {
          if (elem.type === "GET_WORKER") {
            setSnackType("success");
            setSnackMessage("Worker was updated");
            setSnackOpen(true);
          }
        });
      } else {
        setSnackType("error");
        setSnackMessage("Unable to update worker");
        setSnackOpen(true);
      }
    }
  };

  const editDisabled = () => {
    return userId !== props.user.id;
  };

  //safety meetings, timelogs, projects,
  const userInfo = () => {
    return (
      <Grid container>
        <Grid container item md={12}>
          <Grid item sm={1} md={4}></Grid>
          <Grid container item md={4} className={classes.userInfoContainer}>
            <Grid item sm={12} md={12}>
              <input
                type="file"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
                ref={avatarInput}
              />
              <Box
                m={2}
                justifyContent="center"
                onClick={clickAvatarInput}
                alignItems="center"
                display="flex"
                className={classes.imageContainer}
              >
                {avatar === null ? (
                  <WhiteTextTypography align="center">
                    Add Avatar <br />
                    <AddIcon fontSize="large" />
                  </WhiteTextTypography>
                ) : (
                  <div className={classes.imageWrapper}>
                    <img src={avatar} className={classes.avatarImage} />
                    <GreenTypography>
                      <EditIcon
                        className={classes.imageIcon}
                        fontSize="large"
                      />
                    </GreenTypography>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box mt={3} mb={3} ml={2} mr={2}>
                <CssTextField
                  label="First Name"
                  fullWidth={true}
                  variant="outlined"
                  value={firstName}
                  onChange={(ev) => setFirstName(ev.target.value)}
                  name="firstName"
                  disabled={editDisabled()}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box mb={3} ml={2} mr={2}>
                <CssTextField
                  label="Last Name"
                  fullWidth={true}
                  variant="outlined"
                  value={lastName}
                  onChange={(ev) => setLastName(ev.target.value)}
                  name="lastName"
                  disabled={editDisabled()}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box mb={3} ml={2} mr={2}>
                <CssTextField
                  label="Email"
                  fullWidth={true}
                  variant="outlined"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  name="email"
                  disabled={editDisabled()}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box mb={3} ml={2} mr={2}>
                <CssTextField
                  label="Phone Number"
                  fullWidth={true}
                  variant="outlined"
                  value={phone}
                  onChange={(ev) => setPhone(ev.target.value)}
                  name="phone"
                  disabled={editDisabled()}
                />
              </Box>
            </Grid>
            {props.user.is_manager ? (
              <Grid item md={12}>
                <Box justifyContent="center" alignItems="center" display="flex">
                  <FormLabel
                    control={<GreenCheckbox checked={isManager} />}
                    label={"Is Manager?"}
                    name="Is Manager"
                    onChange={(ev, value) => setIsManager(value)}
                  />
                </Box>
              </Grid>
            ) : null}
            {userId === props.user.id || props.user.role === "OWNER" ? (
              <Grid item md={12}>
                <Box justifyContent="center" alignItems="center" display="flex">
                  <Button
                    style={{ color: "var(--trade-font)" }}
                    onClick={() => setPasswordDialogOpen(true)}
                  >
                    Change password
                  </Button>
                  <Dialog
                    open={passwordDialogOpen}
                    onClose={() => setPasswordDialogOpen(false)}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle
                      id="form-dialog-title"
                      style={{ backgroundColor: "var(--trade-background)" }}
                    >
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <WhiteTextTypography variant="h5">
                          Change Password
                        </WhiteTextTypography>
                      </Box>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: "var(--trade-background)" }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Box m={1}>
                            <CssTextField
                              label="Password"
                              fullWidth={true}
                              variant="outlined"
                              value={password}
                              type={showPassword ? "text" : "password"}
                              onChange={(ev) => setPassword(ev.target.value)}
                              name="firstName"
                            />
                          </Box>
                          <IconButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Box m={1}>
                            <CssTextField
                              label="Confirm Password"
                              fullWidth={true}
                              variant="outlined"
                              value={passwordConfirm}
                              type={showPassword ? "text" : "password"}
                              onChange={(ev) =>
                                setPasswordConfirm(ev.target.value)
                              }
                              name="firstName"
                            />
                          </Box>
                          <IconButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "var(--trade-background)" }}>
                      <Button
                        onClick={() => setPasswordDialogOpen(false)}
                        style={{ color: "var(--trade-font)" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={changePassword}
                        style={{ color: "var(--trade-font)" }}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid item sm={1} md={4}></Grid>
        </Grid>

        <Grid container item md={12}>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Box
              mt={3}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem",
                  fontWeight: "bold",
                  color: "var(--trade-font)",
                  border: "none",
                  borderRadius: "4px",
                  margin: "auto",
                }}
                onClick={saveChanges}
              >
                Save Changes
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
                  {snackMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      style={{
        backgroundColor: "var(--trade-background)",
      }}
      justify={"center"}
    >
      {userInfo()}
      <Grid container md={12} justify={"center"}>
        <GridItem md={4}>
          <NotificationsPreferences user={props.user} />
        </GridItem>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAvatar: (workerId, avatar) => {
      return dispatch(workers.updateAvatar(workerId, avatar));
    },
    updatePassword: (password, workerId) => {
      return dispatch(workers.saveWorkerPassword(workerId, password));
    },
    saveUser: (data) => {
      return dispatch(
        workers.saveWorker(
          data.userId,
          data.firstName,
          data.lastName,
          data.email,
          data.phoneNumber,
          data.company,
          data.isManager,
          false
        )
      );
    },
    updateWorkerManagerStatus: (workerId, is_manager) => {
      return dispatch(workers.updateWorkerManagerStatus(workerId, is_manager));
    },
    getWorker: (workerId) => {
      return dispatch(workers.getWorker(workerId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerPage);
