import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Gravatar from "react-gravatar";
import Moment from "react-moment";
import { GMap, ScrollableTabsButtonAuto } from "../ProjectSlider/ProjectSlider";
import SideDrawerContent from "../SideDrawerWorker/SideDrawerContent";
import SideDrawerWorker from "../SideDrawerWorker/SideDrawerWorker";
import Spinner from "react-bootstrap/Spinner";
import { ax } from "../../utils";
import { connect } from "react-redux";
import { time_logs, workers } from "actions";

const axios = require("axios");

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const meetingStyles = makeStyles((theme) => ({
  mainContainer: {
    width: 950,
    minHeight: "100vh",
    backgroundColor: "#000000",
  },
  container: {
    height: "auto",
    backgroundColor: "#000000",
    width: "100%",
    padding: "2rem",
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "#000000",
    marginBottom: "1rem",
  },
  headers: {
    color: "var(--trade-primary)",
  },
  description: {
    color: "var(--trade-font)",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    height: "auto",
  },
}));

function SafetyMeetingContent(props) {
  const [safetyMeeting, setSafetyMeeting] = useState(null);

  const classes = meetingStyles();

  useEffect(() => {
    setSafetyMeeting(props.meeting);
  }, [props.meeting]);

  const fieldFormList = () => {
    return (
      <Grid container item md={12} className={classes.container}>
        {safetyMeeting.fields.map((elem) => {
          const ls = [];
          elem.options.split("\r\n").forEach((e) => {
            var temp = e.split("|")[0];
            ls.push(temp);
          });

          let prettyOptions = ls.reduce((prev, next) => prev + ", " + next);
          prettyOptions = prettyOptions.slice(0, prettyOptions.length);
          return (
            <Grid
              container
              item
              md={12}
              className={classes.tableRow}
              key={elem.title}
            >
              <Grid item md={4}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="body1">
                    {elem.title}
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="body1">
                    {elem.type}
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="body1">
                    {prettyOptions}
                  </WhiteTextTypography>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const safetyMeetingInfo = () => {
    return (
      <Grid container className={classes.container}>
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <AmberTypography variant="h4" className={classes.headers}>
                Title:{" "}
              </AmberTypography>
              <WhiteTextTypography variant="h4" style={{ marginLeft: "1rem" }}>
                &#9;&#9; {safetyMeeting.title}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={12}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <AmberTypography variant="h4" className={classes.headers}>
                Code:{" "}
              </AmberTypography>
              <WhiteTextTypography variant="h4" style={{ marginLeft: "1rem" }}>
                &#9;&#9; {safetyMeeting.code}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Box m={2}>
            {/* <p 
                            className={classes.description} 
                            dangerouslySetInnerHTML={{ __html: safetyMeeting.description, style: {color: "white"} }}>
                        </p> */}
            <pre style={{ color: "white" }} className={classes.description}>
              {safetyMeeting.description}
            </pre>
          </Box>
        </Grid>
        <Grid container item md={12}>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Question
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Question Type
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography variant="h5" className={classes.headers}>
                Options
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        {fieldFormList()}
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <div className={classes.mainContainer}>
        {safetyMeeting !== null ? (
          safetyMeetingInfo()
        ) : (
          <div> No Info available </div>
        )}
      </div>
    );
  };

  return <div>{drawerContent()}</div>;
}

const projectStyles = makeStyles((theme) => ({
  drawerBody: {
    //maxWidth: "40%",
    backgroundColor: "#000000",
    minHeight: "100vh",
    width: "70vw",
    //padding: "1rem",
    //position: "relative",
  },
  subheader: {
    //marginRight: "0.5rem",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  bodyText: {
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  centeredText: {
    margin: "auto",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  textContainer: {
    textAlign: "center",
    margin: "auto",
  },
  mainContainer: {
    //width: 1050,
    width: "100%",
    backgroundColor: "#000000",
  },
  headers: {
    margin: "auto 2rem",
  },
  tableRow: {
    backgroundColor: "var(--trade-background)",
    height: "3rem",
    alignItems: "center",
    borderTop: "solid 2px black",
  },
  topContainer: {
    margin: "2rem",
  },
}));

function ProjectContent(props) {
  const [project, setProject] = useState(null);
  const [fullProject, setFullProject] = useState(null);
  const [loading, setLoading] = useState(false);

  const classes = projectStyles();

  useEffect(() => {
    if (props.company) {
      ax.get("/api/projects/" + props.project.id)
        .then((res) => {
          setFullProject(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setProject(props.project);
  }, [props.project]);

  const startTimeLog = () => {
    setLoading(true);
    props
      .startTimer(
        props.project.id,
        props.coords.latitude,
        props.coords.longitude,
        props.user.id,
        props.user.last_time_log.task_type_id
      )
      .then(() => setLoading(false));
  };

  const workerRow = (worker) => {
    return (
      <Grid container item md={12} key={worker.id}>
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Avatar src={worker.avatar} className={classes.centeredText} />
          </Box>
        </Grid>
        <Grid item md={10}>
          <Box m={2}>
            <WhiteTextTypography>
              {worker.first_name} {worker.last_name}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1}>
          <Box m={2}>
            <WhiteTextTypography>
              <SideDrawerWorker worker_id={worker.id} />
            </WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const onSiteWorkerRow = (worker) => {
    function msToTime(duration) {
      var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      if (hours > 0) {
        return hours + " hrs" + minutes + " min";
      } else {
        return minutes + " min";
      }
    }

    return (
      <Grid
        container
        item
        md={12}
        className={classes.tableRow}
        key={project.id}
      >
        {/* Worker Name and Avatar*/}
        <Grid container item md={3} style={{ maxHeight: "3rem" }}>
          <Grid item md={5}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="100%"
            >
              <Avatar
                src={worker.avatar}
                className={classes.centeredText}
                style={{ margin: "0" }}
              />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box m={2} margin="0">
              <WhiteTextTypography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {worker.first_name} {worker.last_name}
              </WhiteTextTypography>
              <WhiteTextTypography className={classes.smallerText}>
                {worker.phone_number}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        {/* Task */}
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {worker.last_time_log?.task_type?.title}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              <Moment format={"LT"}>{worker.last_time_log?.start}</Moment>
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              {worker.last_break && worker.last_break?.end
                ? msToTime(
                    Date.parse(worker.last_break.end) -
                      Date.parse(worker.last_break.start)
                  )
                : "N/A"}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            display="flex"
            // justifyContent="center"
            alignContent="center"
            height="100%"
            marginLeft="1rem"
          >
            <WhiteTextTypography>
              <Moment date={worker.last_time_log?.start} durationFromNow />
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1}>
          <Box m={2} style={{ margin: 0 }}>
            <WhiteTextTypography>
              <SideDrawerWorker worker_id={worker.id} />
            </WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const projectInfo = () => {
    return (
      <Grid container item md={12} className={classes.mainContainer}>
        <Grid container item md={12} className={classes.topContainer}>
          <Grid item md={1}>
            <Box display="flex" justifyContent="center" alignContent="center">
              {project.company_logo ? (
                <Avatar
                  variant="square"
                  src={project.company_logo}
                  style={{ width: "80%", height: "80%", margin: "auto" }}
                />
              ) : (
                <Gravatar
                  email={project.client_email}
                  size={40}
                  style={{ borderRadius: "10%" }}
                />
              )}
            </Box>
          </Grid>
          <Grid container item md={9}>
            <Grid item md={12}>
              <Box className={classes.headers}>
                <WhiteTextTypography variant="h6">
                  {project.title}
                </WhiteTextTypography>
                <WhiteTextTypography>{project.address}</WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={2}>
            <Grid
              item
              md={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box>
                {loading ? (
                  <div
                    className="spinnerContainer"
                    style={{
                      margin: "1rem auto",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                    }}
                  >
                    <Spinner animation="border" variant="warning" />
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: "var(--trade-success)",
                      borderColor: "var(--trade-success)",
                      fontSize: "0.65rem",
                    }}
                    onClick={() => startTimeLog()}
                  >
                    Start Timer
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={12} className={classes.topContainer}>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <span className={classes.subheader}>Project Manager: </span>
            <span className={classes.bodyText}>
              {project.project_manager
                ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                : "No Project Manager"}
            </span>
          </Grid>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <span className={classes.subheader}>Company: </span>
            <span className={classes.bodyText}> {project.company_name} </span>
          </Grid>
          <Grid item md={4} sm={12} className={classes.textContainer}>
            <span className={classes.subheader}>Status: </span>
            <span className={classes.bodyText}>
              {project.status ? project.status.status : "In Progress"}
            </span>
          </Grid>
        </Grid>
        {project.title !== "Office Work" ? (
          <Grid item md={12}>
            <Box m={2}>
              <GMap
                latitude={project.latitude}
                longitude={project.longitude}
                googleMapURL={process.env.GOOGLE_MAPS_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                radius={project.radius}
                onSiteWorkers={fullProject && fullProject.users_on_project}
                containerElement={
                  <div
                    style={{
                      height: `250px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  />
                }
              ></GMap>
            </Box>
          </Grid>
        ) : null}
        <Grid item md={12}>
          <div className="worker-tab-pane">
            <ScrollableTabsButtonAuto project_id={project.id} />
          </div>
        </Grid>
        {fullProject && fullProject.users_on_project.length ? (
          <div className="workers-on-site">
            <Grid item md={12}>
              <Box ml={3}>
                <WhiteTextTypography variant="h5">
                  Who's On Site
                </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid
              container
              item
              md={12}
              className={classes.tableHeaders}
              style={{ margin: 0 }}
            >
              {/* Table Headers */}
              <Grid item md={3}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Team Assigned</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={3}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Task</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Time In</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Lunch</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0.2rem 1rem"
                >
                  <WhiteTextTypography>Hours</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={1}>
                <Box
                  m={2}
                  display="flex"
                  // justifyContent="center"
                  alignContent="center"
                  margin="0"
                >
                  <WhiteTextTypography>Details</WhiteTextTypography>
                </Box>
              </Grid>
            </Grid>
            <Grid container item md={12}>
              {fullProject
                ? fullProject.users_on_project.map((elem) =>
                    onSiteWorkerRow(elem)
                  )
                : null}
            </Grid>
          </div>
        ) : null}
        <Grid item md={12}>
          <Box ml={3}>
            <WhiteTextTypography variant="h5">
              Assigned Workers
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container item md={12} className={classes.topContainer}>
          {fullProject
            ? fullProject.workers.map((elem) => workerRow(elem))
            : null}
        </Grid>
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <Grid container className={classes.drawerBody}>
        {project !== null ? projectInfo() : <div> No Info available </div>}
      </Grid>
    );
  };

  return <div>{drawerContent()}</div>;
}

function SearchDetailSlider(props) {
  const {
    drawerOpen,
    closeDrawer,
    item,
    company,
    user,
    startTimer,
    stopTimer,
  } = props;

  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });

  const getCoordinates = () => {
    let url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        // handle success
        setCoords({
          latitude: Number(response.data.latitude),
          longitude: Number(response.data.longitude),
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    getCoordinates();
  }, []);

  const MeetingSlider = () => {
    return <SafetyMeetingContent meeting={item.item} />;
  };

  const ProjectSlider = () => {
    return (
      <div>
        <ProjectContent
          project={item.item}
          company={company}
          user={user}
          startTimer={startTimer}
          stopTimer={stopTimer}
          coords={coords}
        />
      </div>
    );
  };

  const WorkerSlider = () => {
    return (
      <div>
        <SideDrawerContent item={item} />
      </div>
    );
  };

  const DrawerContent = () => {
    if (item && item.type === "worker") {
      return <WorkerSlider />;
    } else if (item && item.type === "project") {
      return <ProjectSlider />;
    } else if (item && item.type === "meeting") {
      return <MeetingSlider />;
    } else {
      return null;
    }
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
      <DrawerContent />
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, latitude, longitude, worker_id, task_id) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          true
        )
      );
    },
    stopTimer: (time_log_id, latitude, longitude) => {
      return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchDetailSlider);

// export default React.memo(SearchDetailSlider);
