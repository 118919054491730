import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography, Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { help_faq } from 'actions';
import withStyles from "@material-ui/core/styles/withStyles";

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    },
})(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        backgroundColor: "var(--trade-background)",
        width: "100%",
        color: "var(--trade-font)",
    },
    details: {
        backgroundColor: "#333",
        height: "auto",
        paddingBottom: "0px",
    },
    answer: {
        marginLeft: theme.typography.pxToRem(10)
    },
    stepDescription: {
        textAlign: "center",
    },
    faqItems: {
        marginTop: theme.typography.pxToRem(10)
    }
}));

function HelpFAQList(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);

    const handleChange = panel => (ev, expanded) => {
        setExpanded(expanded ? panel : false);
    }

    useEffect(() => {
        props.fetch().then((res) => {
        });
    }, []);

    const renderItem = (item) => {
        return (
            <Grid item md={12} key={item.id}>
                <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)} className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                        {item.question}
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <p className={classes.answer} dangerouslySetInnerHTML={{ __html: item.answer_html }}></p>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }
    return (
        <div style={{ backgroundColor: "var(--trade-body)", minHeight: "100%", width: "100%", position: "absolute", padding: "2rem" }}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <WhiteTextTypography variant="h4" component={'span'} className={classes.stepDescription}>Help centre</WhiteTextTypography>
                </Grid>
                <Grid item md={12}>
                    <WhiteTextTypography variant="p" component={'span'} className={classes.stepDescription}>Our most commonly asked questions can be found below.</WhiteTextTypography>
                </Grid>
                <Grid item md={12}>
                    <WhiteTextTypography variant="p" component={'span'} className={classes.stepDescription}>Can't find the answer you're looking for? Please email our support team at <a href="mail:help@tradespecifix.com">help@tradespecifix.com</a></WhiteTextTypography>
                </Grid>
            </Grid>
            <Grid container md={6} className={classes.faqItems}>
                {
                    props.items.map((elem) => renderItem(elem))
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        items: state.help_faq.items,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => {
            return dispatch(help_faq.fetchHelpFAQ());
        },
        get: (id) => {
            return dispatch(help_faq.getHelpFAQ(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpFAQList);