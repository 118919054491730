import { logException, logEvent, ax } from "utils";
import store from "store";

export const loadUser = () => {
  return (dispatch) => {
    dispatch({ type: "USER_LOADING" });
    const token = store.get("token");
    //const token = "";
    if (token) {
      return ax
        .get("/api/auth/user/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: "USER_LOADED", user: res.data });
            return res.data;
          } else if (res.status >= 400 && res.status < 500) {
            dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
            throw res.data;
          }
        })
        .catch((err) => logException(err));
    }
  };
};

export const getCompanyContext = (company_id) => {
  return (dispatch) => {
    //dispatch({ type: "USER_LOADING" });
    return ax
      .get("/api/auth/user/?company=" + company_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "USER_LOADED", user: res.data });
          return res.data;
        } else if (res.status >= 400 && res.status < 500) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        }
      })
      .catch((err) => logException(err));
  };
};

export const register = (
  first_name,
  last_name,
  email,
  password,
  company_name,
  address1,
  address2,
  city,
  postal_code,
  province,
  country,
  stripe_token,
  stripe_plan,
  promo_code,
  role
) => {
  return (dispatch) => {
    return ax
      .post("/api/auth/register/", {
        first_name,
        last_name,
        email,
        password,
        company_name,
        address1,
        address2,
        city,
        postal_code,
        province,
        country,
        stripe_token,
        stripe_plan,
        promo_code,
        role,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        if (res.status >= 500) {
          logException(res);
          return { status: res.status, type: "DATA_ERROR_SAVING" };
        } else if (res.status === 200) {
          store.set("token", res.data.token);
          store.set("expiry", res.data.expiry);
          const token = localStorage.getItem("fcmToken");

          if (token !== null) {
            ax.patch("/api/users/" + res.data.user.id + "/", {
              tokens: [{ token }],
            });
          }
          dispatch({ type: "LOGIN_SUCCESSFUL", data: res.data });
          return { type: "SUCCESS", data: res.data };
        } else {
          return { type: "DATA_ERROR_SAVING", error: res.data };
        }
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const lock = () => {
  return (dispatch) => {
    //dispatch({ type: "USER_LOCKED" });
    return dispatch({ type: "LOGOUT_SUCCESSFUL" });
  };
};

export const login = (username, password, remember_me) => {
  return (dispatch) => {
    let body = { username, password, remember_me };

    return ax
      .post("/api/auth/login/", body)
      .then((res) => {
        if (res.status < 500) {
          return { status: res.status, data: res.data };
        } else {
          console.error("Server Error!");
          logException(res);
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          store.set("token", res.data.token);
          store.set("expiry", res.data.expiry);
          const token = localStorage.getItem("fcmToken");
          if (token !== null) {
            ax.patch("/api/users/" + res.data.user.id + "/", {
              tokens: [{ token }],
            });
          }
          // I'm leaving this here as an example on how to trigger events
          // In this specific case, the event logged in the backend
          // logEvent('user-login-successful');
          dispatch({ type: "LOGIN_SUCCESSFUL", data: res.data });
          return res.data;
        }
      })
      .catch((error) => {
        logException(error, { username });
        if (error.response === undefined) {
          return dispatch({ type: "LOGIN_FAILED_SERVER_DOWN" });
        } else {
          const status = error.response.status;
          if (status === 403 || status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
              data: error.response.data,
            });
          } else {
            dispatch({ type: "LOGIN_FAILED", data: error.response.data });
          }
        }
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    return ax
      .post("/api/auth/logout/")
      .then((res) => {
        if (res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return { status: res.status, data: res.data };
        } else {
          console.error("Server Error!");
          logException(res);
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 204) {
          dispatch({ type: "LOGOUT_SUCCESSFUL" });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          logException(res);
          throw res.data;
        }
      })
      .catch((err) => logException(err));
  };
};
