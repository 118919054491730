import React from 'react';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import Moment from 'react-moment';
import TimeReport from "../TimeReport";
// theme components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
// custom code
import {time_logs, projects} from "actions";
import reportStyle from "assets/jss/tradespecifix/report";

class ProjectTimeReport extends TimeReport {
    constructor(props){
        super(props);
        const item_id = this.props.match.params.id;
        this.state = this.getInitState({
            'item_id': item_id,
            'ordered_logs': null
        });
        this.getLogs(this.state.start, this.state.end);
        this.props.getProject(item_id);
    }
    getLogs(start, end){
        return this.props.getLogs(this.state.item_id, start, end);
    }
    static _renderWeeklyDayHeader(date) {
        return <TableRow key={date}>
            <TableCell colSpan={3} component="th" scope="row" padding={"none"}>
                <b><Moment format={'dddd MMMM D'}>{date}</Moment></b>
            </TableCell>
        </TableRow>;
    }

    static getDayTotal(rows){
        let durations = rows.map(worker_row => {
            if(worker_row.type === 'break') return 0;
            const start = moment(worker_row.start);
            const end = moment(worker_row.end);
            const duration = moment.duration(end.diff(start));
            return duration.asHours();
        });
        return +durations.reduce(TimeReport.sumRound, 0);
    }
    static getDayTotalBreak(rows){
        let durations = rows.map(worker_row => {
            if(worker_row.type !== 'break') return 0;
            const start = moment(worker_row.start);
            const end = moment(worker_row.end);
            const duration = moment.duration(end.diff(start));
            return duration.asHours();
        });
        return +durations.reduce(TimeReport.sumRound, 0);
    }
    static getTotal(rows){
        let durations = Object.values(rows).map(dates => {
            return Object.values(dates).map(worker_rows => {
                return worker_rows.map((worker_row) => {
                    if(worker_row.type === 'break') return 0;
                    const start = moment(worker_row.start);
                    const end = moment(worker_row.end);
                    const duration = moment.duration(end.diff(start));
                    return duration.asHours();
                }).reduce(TimeReport.sumRound, 0);
            }).reduce(TimeReport.sumRound, 0);
        });
        return +durations.reduce(TimeReport.sumRound, 0);
    }
    static getTotalBreak(rows){
        let durations = Object.values(rows).map(dates => {
            return Object.values(dates).map(worker_rows => {
                return worker_rows.map((worker_row) => {
                    if(worker_row.type !== 'break') return 0;
                    const start = moment(worker_row.start);
                    const end = moment(worker_row.end);
                    const duration = moment.duration(end.diff(start));
                    return duration.asHours();
                }).reduce(TimeReport.sumRound, 0);
            }).reduce(TimeReport.sumRound, 0);
        });
        return +durations.reduce(TimeReport.sumRound, 0);
    }
    _renderWeeklyDay(rows){
        const { classes } = this.props;
        return Object.keys(rows).map(company_id => {
            const employee_rows = rows[company_id];
            return <TableRow key={company_id}>
                <TableCell component="th" scope="row" padding={"none"}>
                    <Link to={"/workers/" + employee_rows[0].user + "/timesheet"}>
                        {employee_rows[0].user_first_name}
                        {employee_rows[0].user_last_name}
                    </Link>
                </TableCell>
                <TableCell padding={"none"}>{ProjectTimeReport.getDayTotal(employee_rows)} h</TableCell>
                <TableCell padding={"none"} className={classes.breakColor}>{ProjectTimeReport.getDayTotalBreak(employee_rows)} h</TableCell>
            </TableRow>;
        });
    }
    getWeeklyReport() {
        const { classes } = this.props;
        if(this.props.logs_ordered === undefined)
            return <div/>;

        return <Table className={classes.fullWidth}>
            <TableHead>
                <TableRow>
                    <TableCell padding={"none"}>Worker</TableCell>
                    <TableCell padding={"none"}>Hours</TableCell>
                    <TableCell padding={"none"}>Break</TableCell>
                </TableRow>
            </TableHead>
            {this.props.logs_ordered !== undefined?Object.keys(this.props.logs_ordered).map(date => {
                const rows = this.props.logs_ordered[date];
                return <TableBody key={date}>
                    {ProjectTimeReport._renderWeeklyDayHeader(date)}
                    {this._renderWeeklyDay(rows)}
                </TableBody>;
            }):null}
            <TableFooter>
                <TableRow>
                    <TableCell component="th" scope="row" padding={"none"}>
                        Total
                    </TableCell>
                    <TableCell padding={"none"}>{ProjectTimeReport.getTotal(this.props.logs_ordered)}</TableCell>
                    <TableCell padding={"none"}>{ProjectTimeReport.getTotalBreak(this.props.logs_ordered)}</TableCell>
                </TableRow>
            </TableFooter>
        </Table>;
    }
    getWeeklyDetailedReport() {
        const { classes } = this.props;
        return <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="right">Start</TableCell>
                    <TableCell align="right">End</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.logs.map(log => {
                    return <React.Fragment key={log.id}>
                        <TableRow>
                            <TableCell component="th" scope="row" colSpan={2}>
                                <b>{log.user_first_name} {log.user_last_name}</b>{log.type === 'break'? (<b> - Break</b>): null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Moment format={"dddd MMMM D HH:mm:ss"}>{log.start}</Moment></TableCell>
                            <TableCell><Moment format={"dddd MMMM D HH:mm:ss"}>{log.end}</Moment></TableCell>
                        </TableRow>
                    </React.Fragment>
                })}
            </TableBody>
        </Table>;
    }

    render() {
        const { classes } = this.props;
        if(this.props.item === undefined || this.props.item.id === undefined)
            return null;
        return <div>
            <GridContainer>
                <GridItem className={classes.fullWidth} container justify="center">
                    <h3>{this.props.item.title}</h3>
                </GridItem>
            </GridContainer>
            {this.renderWeekNavigation()}
            <GridContainer>
                <GridItem className={classes.fullWidth}>
                    <CustomTabs
                        headerColor="success"
                        className={classes.fullWidth}
                        tabs={[
                            {
                                tabName: "Weekly",
                                tabContent: (this.getWeeklyReport())
                            },
                            {
                                tabName: "Detailed",
                                tabContent: (this.getWeeklyDetailedReport())
                            },
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        item: state.projects.item,
        logs: state.time_logs.items,
        logs_ordered: state.time_logs.items_ordered_by_worker,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLogs: (project_id, start, end) => {
            return dispatch(time_logs.fetchTimeLogsByProjectId(project_id, start, end));
        },
        getProject: (project_id) => {
            return dispatch(projects.getProject(project_id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(reportStyle)(ProjectTimeReport));
