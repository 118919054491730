import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons
import FolderIcon from "@material-ui/icons/Folder";
import CardIcon from "components/Card/CardIcon.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import BaseComponent from "../BaseComponent";
import FileUpload from "components/CustomUpload/FileUpload.jsx";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

import { projects, files } from "actions";
import projectStyle from "assets/jss/tradespecifix/project";

// Alert dialog
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";

class ProjectFileForm extends BaseComponent {
    timer = null;
    state = {
        redirectTo: null,
        name: '',
        project: '',
        id: undefined,
        error: undefined,
        file: undefined,
        alertOpen: false,
        alertMessage: ''
    };

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.timer !== null)
            clearInterval(this.timer);
    }
    componentDidMount() {
        this.initState(this.state);
        const project_id = this.props.match.params.project_id;
        if (project_id !== undefined)
            this.setState({ project: project_id });

        const item_id = this.props.match.params.id;
        if (item_id !== undefined)
            this.props.getFile(item_id).then(res => {
                this.setState({
                    name: res.item.name,
                    project: res.item.project,
                    id: res.item.id,
                })
            });

        this.props.fetchProjects();
    }

    handleSave() {
        if (this.state.id !== undefined) {
            this.props.save(this.state).then((e) => {
                if (e.error !== undefined) {
                    this.setState({ error: e.error });
                } else {
                    this.setState({ error: undefined });
                    this.showSuccessfulAlert();
                    this.timer = setInterval(
                        () => this.setState({ redirectTo: '/projects/' + this.state.project + "/files" }),
                        1000
                    )
                }
            });
        } else {

            this.props.create(this.state).then(e => {

                // If there's an empty file name or empty file, toggle an alert dialog with a message
                if (e === undefined) {
                    this.handleOpenAlert("Error: You must provide a name and a file to upload")
                    return;
                }

                if (e.error !== undefined) {
                    this.setState({ error: e.error });
                    // TODO: not sure how to hit this error - Stanford
                    this.handleOpenAlert(e.error)
                } else {
                    this.setState({ error: undefined });
                    this.showNotification("File created successfully.");
                    this.timer = setInterval(
                        () => this.setState({ redirectTo: '/projects/' + this.state.project + "/files" }),
                        1000
                    )
                }
            });
        }
    }
    // Closing the snackbar
    handleCloseAlert = () => {
        this.setState({ alertOpen: false });
    };

    handleOpenAlert(message) {
        this.setState({ alertOpen: true, alertMessage: message });
    };

    render() {
        const { classes, projects } = this.props;
        const { redirectTo, alert, name, id, project } = this.state;
        let newItem = false;
        if (id === undefined)
            newItem = true;

        if (redirectTo !== null)
            return <Redirect to={redirectTo} />;


        return <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                {alert}
                <Card>
                    <Snackbar
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.state.alertOpen}
                        onClose={this.handleCloseAlert}
                        message="Error"
                    >
                        <Alert
                            onClose={this.handleCloseAlert}
                            severity="error">
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <FolderIcon />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{newItem ? "Create a new file" : "File : " + name}</h4>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e),
                                            id: "name",
                                            value: name,
                                            type: "text"
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        File
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <FileUpload
                                        addButtonProps={{
                                            color: "rose",
                                            round: true
                                        }}
                                        changeButtonProps={{
                                            color: "rose",
                                            round: true
                                        }}
                                        removeButtonProps={{
                                            color: "danger",
                                            round: true
                                        }}
                                        downloadButtonProps={{
                                            color: "rose",
                                            round: true
                                        }}
                                        fileId={id}
                                        handleFileChange={(e) => this.setState({ file: e.file })}
                                        handleFileDelete={() => this.setState({ file: null })}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Project
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            inputProps={{
                                                name: 'project',
                                                id: 'project',
                                                onChange: (e) => this.handleChange(e),
                                            }}
                                            classes={{
                                                select: classes.select
                                            }}
                                            value={project}
                                            required
                                        >
                                            {projects.map((project_) => {
                                                if (project_.id === project)
                                                    return <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                        value={project_.id}
                                                        key={project_.id}
                                                        selected
                                                    >
                                                        {project_.title}
                                                    </MenuItem>;
                                                else
                                                    return <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                        value={project_.id}
                                                        key={project_.id}
                                                    >
                                                        {project_.title}
                                                    </MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="flex-end">
                                <GridItem xs={12} sm={12} md={9}>
                                    <Button round color="info" onClick={() => this.props.history.goBack()}>Cancel</Button>
                                    <Button round color="success" onClick={() => this.handleSave()}>{newItem ? "Create" : "Save"}</Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>;
    }
}

ProjectFileForm.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        projects: state.projects.items,
        user: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFile: (id) => {
            return dispatch(files.getFile(id));
        },
        fetchProjects: () => {
            return dispatch(projects.fetchProjects());
        },
        save: (state) => {
            const id = state.id;
            const name = state.name;
            const project = state.project;
            const file = state.file;
            return dispatch(files.saveFile(
                id,
                name,
                project,
                file,
            ));
        },
        create: (state) => {
            return dispatch(files.createFile(
                state.project,
                state.name,
                state.file,
            ));
        },
    }
};

// Function to fill Alert Dialog 
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(projectStyle)(ProjectFileForm));
