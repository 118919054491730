import Dashboard from "views/Dashboard/Dashboard.jsx";

import { ReactComponent as DashboardIcon } from "assets/img/menu/dashboard_black_24dp.svg";
import { ReactComponent as ProjectsIcon } from "assets/img/menu/projects_24dp.svg";
import { ReactComponent as HealthSafetyIcon } from "assets/img/menu/health_and_safety_black_24dp.svg";
import { ReactComponent as CompanyIcon } from "assets/img/menu/company_24dp.svg";
//import {ReactComponent as TenderIcon} from "assets/img/menu/tender_24dp.svg"
import { ReactComponent as WorkersIcon } from "assets/img/menu/workers_24dp.svg";
import HelpIcon from "@material-ui/icons/Help";

//import WorkersList from "views/Workers/WorkersList";
import WorkerPage from "views/Workers/WorkerPage";
import WorkerMapReport from "views/Workers/WorkerMapReport";
import CompaniesList from "views/Company/CompaniesList";
import CompanyTimeReport from "views/Company/CompanyTimeReport";
import CompanyForm from "views/Company/CompanyForm";
import CompanyPage from "views/Company/CompanyPage";
import ProjectsList from "views/Project/ProjectsList";
import ProjectFilesList from "views/ProjectFiles/ProjectFilesList";
import ProjectFileForm from "views/ProjectFiles/ProjectFileForm";
import ProjectNotesList from "views/Project/ProjectNotesList";
// import ProjectForm from "views/Project/ProjectForm";
// import SafetyMeetingsList from "views/SafetyMeetings/SafetyMeetingsList";
import SafetyMeetingLibrary from "views/SafetyMeetings/SafetyMeetingLibrary";
import ArchivedMeetingLibrary from "views/SafetyMeetings/ArchivedMeetingLibrary";
//import SafetyMeetingsForm from "views/SafetyMeetings/SafetyMeetingsForm";
import SafetyMeetingsWorkerList from "views/SafetyMeetings/SafetyMeetingsWorkerList";
import ProjectTimeReport from "views/Project/ProjectTimeReport";
import NoteForm from "views/Notes/NoteForm";
import NotesList from "views/Notes/NotesList";
import AddProjectPage from "views/Project/AddProjectPage";
import ProjectLibrary from "views/Project/ProjectLibrary";
import ArchivedProjects from "views/Project/ArchivedProjects";
import AddSafetyMeetingPage from "../views/SafetyMeetings/AddSafetyMeetingPage";
import WorkerLibrary from "../newComponents/WorkerLibrary/WorkerLibrary";
import ArchivedWorkerLibrary from "../newComponents/WorkerLibrary/ArchivedWorkerLibrary";
import WorkerTimeSheet from "../newComponents/WorkerTimeSheetPage/WorkerTimeSheetPage";
import HelpForm from "views/Help/HelpTicketForm";
import HelpFAQList from "views/Help/HelpFAQList";
import HelpVideoList from "views/Help/HelpVideoList";
import BreakPage from "../newComponents/BreakPage/BreakPage";
import SiteSelection from "../newComponents/SiteSelection/SiteSelection";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    path: "/projects/:project_id/notes/add",
    name: "Add note",
    component: NoteForm,
    hidden: true,
  },
  {
    path: "/projects/:project_id/notes/:page",
    component: ProjectNotesList,
    hidden: true,
  },
  {
    path: "/projects/:project_id/notes/",
    component: ProjectNotesList,
    hidden: true,
  },
  {
    path: "/projects/:project_id/files/add",
    component: ProjectFileForm,
    hidden: true,
  },
  {
    path: "/projects/:project_id/files/:id/edit",
    component: ProjectFileForm,
    hidden: true,
  },
  {
    path: "/projects/:project_id/files/",
    component: ProjectFilesList,
    hidden: true,
  },
  {
    path: "/projects/:id/timesheet/",
    component: ProjectTimeReport,
    hidden: true,
  },
  {
    path: "/projects/list/:page",
    name: "Projects",
    icon: ProjectsIcon,
    component: ProjectsList,
    managerOnly: true,
    hidden: true,
  },
  {
    path: null,
    name: "Projects",
    icon: ProjectsIcon,
    managerOnly: true,
    children: [
      {
        path: "/projects/list/",
        name: "View Project Library",
        //component: ProjectsList,
        component: ProjectLibrary,
        managerOnly: true,
      },
      {
        path: "/projects/add",
        name: "New Project",
        //component: ProjectForm,
        component: AddProjectPage,
        managerOnly: true,
      },
      {
        path: "/projects/archived-list/",
        name: "Archived Projects",
        component: ArchivedProjects,
        managerOnly: true,
      },
    ],
  },
  {
    path: "/projects/:id",
    name: "Edit project",
    //component: ProjectForm,
    component: AddProjectPage,
    hidden: true,
    managerOnly: true,
  },
  // {
  //   path: "/workers/list/:page",
  //   name: "Workers",
  //   icon: WorkersIcon,
  //   component: WorkersList,
  //   managerOnly: true,
  //   hidden: true,
  // },
  {
    path: null,
    name: "Workers",
    icon: WorkersIcon,
    managerOnly: true,
    children: [
      {
        path: "/workers/list/",
        name: "View Workers Library",
        component: WorkerLibrary,
        managerOnly: true,
      },
      {
        path: "/workers/add",
        name: "New Worker",
        component: WorkerPage,
        hidden: true,
        managerOnly: true,
      },
      {
        path: "/workers/archived-list/",
        name: "Archived Workers",
        component: ArchivedWorkerLibrary,
        managerOnly: true,
      },
    ],
  },
  {
    path: "/workers/:id/timesheet/",
    name: "Time report",
    component: WorkerTimeSheet,
    hidden: true,
  },
  {
    path: "/workers/:id/gps/",
    name: "Time report",
    component: WorkerMapReport,
    hidden: true,
    managerOnly: true,
  },
  {
    path: "/workers/:id",
    name: "Workers",
    component: WorkerPage,
    hidden: true,
  },
  {
    path: "/companies/:id/timesheet/",
    component: CompanyTimeReport,
    hidden: true,
  },
  {
    path: "/companies/add",
    name: "Companies",
    component: CompanyForm,
    hidden: true,
    adminOnly: true,
  },
  {
    path: "/companies/:id",
    name: "Companies",
    component: CompanyPage,
    hidden: true,
    adminOnly: true,
  },
  {
    path: "/companies/:id",
    name: "My company",
    icon: CompanyIcon,
    redirect: true,
    pathTo: "/companies/:id",
    hidden: true, //for now
    adminOnly: false,
    replaceCompanyId: true,
    managerOnly: true,
  },
  {
    path: "/companies",
    name: "Companies",
    icon: CompanyIcon,
    component: CompaniesList,
    adminOnly: true,
    hidden: true, // for now
  },
  // {
  //   component: SafetyMeetingsForm,
  //   icon: TenderIcon,
  //   path: null,
  //   children: [
  //       {
  //         path: "/tenders/list",
  //         name: "View Tender List",
  //         component: Dashboard,
  //         managerOnly: true,
  //       },
  //       {
  //         path: "/tenders/add",
  //         name: "New Tender",
  //         component: Dashboard,
  //         managerOnly: true,
  //       },
  //       {
  //         path: "/tenders/archived",
  //         name: "Closed Tenders",
  //         component: Dashboard,
  //         managerOnly: true,
  //       },
  //     ]
  // },
  {
    path: "/safety-meetings/:safety_meeting_id",
    name: "Safety Meetings",
    component: AddSafetyMeetingPage,
    adminOnly: true,
    hidden: true,
  },
  {
    path: "/safety-meetings-info/",
    name: "Safety Meetings",
    component: AddSafetyMeetingPage,
    managerOnly: true,
    hidden: true,
  },
  {
    path: null,
    name: "Safety Meetings",
    icon: HealthSafetyIcon,
    managerOnly: true,
    children: [
      {
        path: "/safety-meetings/list",
        name: "View Safety Meeting Library",
        component: SafetyMeetingLibrary,
        managerOnly: true,
      },
      {
        path: "/safety-meetings/add",
        name: "New Safety Meeting",
        //component: SafetyMeetingsForm,
        component: AddSafetyMeetingPage,
        managerOnly: true,
      },
      {
        path: "/safety-meetings/archived",
        name: "Archived Safety Meetings",
        component: ArchivedMeetingLibrary,
        managerOnly: true,
      },
    ],
  },
  {
    path: "/time-logs/:time_log_id/notes",
    name: "Notes",
    component: NotesList,
    hidden: true,
  },
  {
    path: "/time-logs/start/:project_id",
    name: "On-Site",
    component: SafetyMeetingsWorkerList,
    hidden: true,
  },
  { path: "/notes/:id", component: NoteForm, hidden: true },
  {
    path: null,
    name: "Help",
    component: HelpForm,
    icon: HelpIcon,
    children: [
      {
        path: "/help/ticket",
        name: "Ask for help",
        component: HelpForm,
      },
      {
        path: "/help/video",
        name: "Video library tutorials",
        component: HelpVideoList,
      },
      {
        path: "/help/faq",
        name: "Frequently Asked Questions",
        component: HelpFAQList,
      },
    ],
  },
  {
    path: "/on-break",
    name: "BreakPage",
    component: BreakPage,
    public: false,
    hideLayout: true,
  },
  {
    path: "/on-delivery",
    name: "DeliveryPage",
    component: BreakPage,
    public: false,
    hideLayout: true,
  },
  {
    path: "/project-selection",
    name: "SiteSelection",
    component: SiteSelection,
    public: false,
    hideLayout: true,
  },

  //  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];

export default dashRoutes;
