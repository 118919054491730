const initialState = {
    item: null,
    items: [],
    global_items: [], // Will hold less informations on the projects, but will hold them all for the company, used in the Assign Worker and SearchDashboard widget
    count: 0,
    count_active: 0,
    next: null,
    prev: null,
  };
  
  export default function projects(state = initialState, action) {
    switch (action.type) {
      case "COUNT_PROJECTS":
        return { ...state, count: action.count };
  
      case "PROJECTS_LOADED":
        if (state.item) {
          const updatedItem = action.items.find(
            (item) => item.id === state.item.id
          );
          if (updatedItem !== undefined) state.item = updatedItem;
        }
        return {
          ...state,
          items: action.items,
          count: action.count,
          count_active: action.count_active,
          next: action.next,
          prev: action.prev,
        };
      case "APPEND_PROJECTS":
        return {
          ...state,
          items: [...state.items, ...action.items],
          count: action.count,
          next: action.next,
          prev: action.prev,
        };
      case "ARCHIVE_PROJECT":
        const activeProjects = state.items.filter((elem) => elem.id !== action.item.id) 
        return {
          ...state,
          items: activeProjects,
          count: activeProjects.length,
        } 
      case "CREATE_PROJECT":
        //
        return {...state, items: [...state.items, action.item], global_items: [...state.global_items, action.item]}
      case "GET_PROJECT":
        // let tempItems = state.items.map((value) => {
        //   if (value.id === action.item.id) {
        //     return action.item;
        //   }
        //   return value;
        // });
        return { ...state, item: action.item };
      case "UPDATE_PROJECT":
        if (state.item && state.item.id) {
          if (state.item.id === action.item.id) {
            state.item = action.item;
          }
        }
        let temps = state.items.map((elem) => {
          if (elem.id === action.item.id) {
            return action.item;
          }
          return elem;
        })
        let global_temps = state.global_items.map((elem) => {
          if (elem.id === action.item.id) {
            return action.item;
          }
          return elem;
        })
        return {...state, items: temps, global_items: global_temps}
      case "SHORT_PROJECTS_LOADED":
        return {
          ...state,
          global_items: action.items,
        };

      case "WORKER_TIME_LOG_STOPPED":
        let items = state.items.map((project) => {
          project.users_on_project = project.users_on_project
            .map((user) => {
              if (user.last_time_log.id === action.item.id) return null;
              return user;
            })
            .filter((user) => user !== null);
          return project;
        });
        return { ...state, items: items };
      case "TIME_LOG_STOPPED":
        // removes user from users_on_project when timelog is stopped. 
        let temp = state.items.map((project) => {
          project.users_on_project = project.users_on_project
            .map((user) => {
              if (user.last_time_log.id === action.item.id) return null;
              return user;
            })
            .filter((user) => user !== null);
          return project;
        });
        return { ...state, items: temp, item: null};
      case "LOGOUT_SUCCESSFUL":
        return {
          item: {},
          items: [],
          count: 0,
        }
      default:
        return state;
    }
  }
  