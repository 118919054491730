import { combineReducers } from 'redux';
import users from "./users";
import notes from "./notes";
import auth from "./auth";
import workers from "reducers/worker";
import companies from "reducers/companies";
import projects from "reducers/projects";
import safety_meetings from "reducers/safety_meetings";
import time_logs from "reducers/time_log";
import breaks from "reducers/break";
import files from "reducers/files";
import user_positions from "reducers/user_positions";
import help_tickets from "reducers/help_tickets";
import help_faq from "reducers/help_faq";
import help_video from "reducers/help_video";


const tradeApp = combineReducers({
  users,
  notes,
  auth,
  workers,
  companies,
  projects,
  safety_meetings,
  time_logs,
  breaks,
  help_tickets,
  files,
  user_positions,
  help_faq,
  help_video,
});

export default tradeApp;
