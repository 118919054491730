const initialState = {
    items: [],
    item: {},
    count: 0,
    current_company: null,
};
let item = '';

export default function companies(state=initialState, action) {
    switch (action.type) {
        case 'COUNT_COMPANIES':
            return {...state, count: action.count};

        case 'FETCH_COMPANIES':
            return {...state, items: action.items, count: action.count};

        case 'GET_COMPANY':
            return {...state, item: action.item};
        
        case "GET_TIME_LOG":
            return {...state, current_company: action.item.project.company_id};
        
        case "USER_LOADED":
            return {...state, current_company: action.user.current_company};
        case "LOGIN_SUCCESSFUL":
            return {...state, current_company: action.data.user.current_company};
        case 'FETCH_TASKS':
            return {...state, tasks: action.items, task_count: action.count};

        case 'TASK_TYPE_CREATED':
            item = state.item;
            // item.tasks_types.push(action.item);
            // item.tasks_types.sort((a, b) => a.order > b.order);
            return {...state, item};

        case 'TASK_TYPE_ORDER_UPDATED':
            item = state.item;
            const id = item.tasks_types.findIndex(a => a.id === action.item.id);
            item.tasks_types[id].order = action.item.order;
            item.tasks_types.sort((a, b) => a.order > b.order);
            return {...state, item};
        case "LOGOUT_SUCCESSFUL":
            return {
                items: [],
                item: {},
                count: 0,
                current_company: null,
            }
        default:
            return state;
    }
}
