import { logException, ax } from "../utils";

const endpoint = "/api/projects/";

export const count = () => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + "count/")
      .then((res) => {
        return dispatch({
          type: "COUNT_PROJECTS",
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in dashboard
export const fetchProjects = (limit, page, archived) => {
  return (dispatch) => {
    let params = {};
    if (limit !== undefined) params.limit = limit;
    if (page !== undefined) params.offset = page * limit;
    if (archived === undefined) archived = false;
    params.archived = archived;

    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "PROJECTS_LOADED",
          items: res.data.results,
          count: res.data.count,
          count_active: res.data.count_active,
          next: res.data.next,
          prev: res.data.prev,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in project library (assign worker doesn't fetch, just gets items from store)
export const getCompanyProjects = (
  company_id,
  sort_key,
  sort_order,
  archived,
  limit,
  offset
) => {
  const params = new URLSearchParams();
  if (company_id !== undefined) {
    params.append("company", company_id);
  }
  if (sort_key !== undefined) {
    params.append("sort_key", sort_key);
  }
  if (sort_key !== undefined && sort_order !== undefined) {
    params.append("sort_order", sort_order);
  }
  if (archived === undefined) archived = false;
  params.append("archived", archived);

  if (offset === undefined) offset = 0;
  params.append("offset", offset);

  if (limit !== undefined) params.append("limit", limit);

  return (dispatch) => {
    return ax
      .get(endpoint + "?" + params)
      .then((res) => {
        return dispatch({
          type: "PROJECTS_LOADED",
          items: res.data.results,
          count: res.data.count,
          count_active: res.data.count_active,
          next: res.data.next,
          prev: res.data.prev,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used for paginated results to fetch more data.
export const fetchMoreProject = (url) => {
  return (dispatch) => {
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECTS",
          items: res.data.results,
          count: res.data.count,
          count_active: res.data.count_active,
          next: res.data.next,
          prev: res.data.prev,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

//
export const getShortProjects = (company_id) => {
  return (dispatch) => {
    dispatch({ type: "PROJECTS_LOADING" });

    return ax
      .get(endpoint + "short/?company=" + company_id)
      .then((res) => {
        return dispatch({
          type: "SHORT_PROJECTS_LOADED",
          items: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// used in project library
export const archiveProject = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_DELETE" });
    return ax
      .delete(endpoint + project_id + "/")
      .then((res) => {
        return dispatch({
          type: "UPDATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_DELETE",
          error: err,
        });
      });
  };
};

export const reactivateProject = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .patch(endpoint + project_id + "/", { archived: false })
      .then((res) => {
        return dispatch({
          type: "UPDATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// used to fetch current active project for dashboard
// updates state.projects.item
export const getProject = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// used to fetch details of any other project that is not the active one
// doesn't update state.projects.item
export const getProjectDetails = (id) => {
  return (dispatch) => {
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "TEMP_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// only used in update project page
export const updateProject = (
  id,
  title,
  company,
  client_email,
  autoStartGeofence,
  lat,
  lng,
  radius,
  job_number,
  address,
  project_manager,
  start_date,
  expected_completion,
  budget,
  street_address,
  city,
  province,
  country,
  postal_code
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    let completion = new Date(expected_completion);
    let startDate = new Date(start_date);
    const params = new URLSearchParams();
    params.append("title", title);
    params.append("client_email", client_email);
    params.append("auto_start_geofence", autoStartGeofence);
    params.append("radius", radius);
    params.append("job_number", job_number);
    params.append("address", address);
    params.append("project_manager", project_manager);
    params.append("start_date", startDate.toISOString());
    params.append("expected_completion", completion.toISOString());
    params.append("budget", budget);
    params.append("latitude", lat);
    params.append("longitude", lng);
    params.append("company", company);
    params.append("is_active", true);
    params.append("archived", false);
    params.append("street_address", street_address);
    params.append("city", city);
    params.append("province", province);
    params.append("country", country);
    params.append("postal_code", postal_code);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return ax
      .put(endpoint + id + "/", params, config)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const updateProjectStatus = (id, status) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    const params = new URLSearchParams();
    params.append("status", status);
    params.append("id", id);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return ax
      .put(endpoint + id + "/", params, config)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// add project page
export const createProject = (
  title,
  company,
  client_email,
  autoStartGeofence,
  lat,
  lng,
  radius,
  job_number,
  address,
  project_manager,
  expected_completion,
  budget,
  street_address,
  city,
  province,
  country,
  postal_code,
  start_date
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let completion;
    let startDate;

    if (expected_completion) completion = new Date(expected_completion);
    if (start_date) startDate = new Date(start_date);

    // let data = {
    //   title: title,
    //   client_email: client_email,
    //   radius: radius,
    //   address: address,
    //   project_manager: {id: project_manager},
    //   expected_completion: completion.toISOString(),
    //   budget: budget
    // };
    // if (lat) {
    //   data["latitude"] = lat;
    // }
    // if (lng) {
    //   data["longitude"] = lng;
    // }
    // if (company !== null && company !== undefined && company !== "") {
    //   data["company"] = company;
    // }

    const params = new URLSearchParams();
    params.append("title", title);
    params.append("client_email", client_email);
    params.append("auto_start_geofence", autoStartGeofence);
    params.append("radius", radius);
    params.append("job_number", job_number);
    params.append("address", address);
    if (project_manager !== "none-selected") {
      params.append("project_manager", project_manager);
    }
    params.append("latitude", lat);
    params.append("longitude", lng);
    params.append("company", company);
    params.append("is_active", true);
    params.append("archived", false);
    params.append("street_address", street_address);
    params.append("city", city);
    params.append("province", province);
    params.append("country", country);
    params.append("postal_code", postal_code);

    if (startDate) params.append("start_date", startDate.toISOString());
    if (completion)
      params.append("expected_completion", completion.toISOString());
    params.append("budget", budget);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return ax
      .post(endpoint, params, config)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "CREATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// add project page
export const addTaskToProject = (task_type_id, project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    return ax
      .post(`/api/projects/${project_id}/add-task/`, {
        task_id: task_type_id,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "TASK_ADDED",
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// add project apge
export const removeTaskFromProject = (taskId, projectId) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    return ax
      .post(`/api/projects/${projectId}/remove-task/`, {
        task_id: taskId,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "TASK_ADDED",
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// refetch project in question
export const assignWorkerToProject = (worker_id, project_id) => {
  let data = {
    worker_id: worker_id,
  };
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .post(endpoint + project_id + "/add-worker/", data)
      .then((res) => {
        return dispatch({
          type: "ASSIGN_WORKER_TO_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// only used by old components
export const saveProject = (
  id,
  title,
  company_id,
  client_email,
  latitude,
  longitude,
  radius
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    let data = {
      title: title,
      client_email: client_email,
      latitude: latitude,
      longitude: longitude,
      radius: radius,
    };
    if (company_id !== null && company_id !== undefined) {
      data["company"] = company_id;
    }
    return ax
      .put(endpoint + id + "/", data)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "UPDATE_PROJECT",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};
