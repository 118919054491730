const initialState = {
    items: [],
    items_project: [],
    current_project_items: [],
    items_info: [],
    items_by_worker: {},
    worker_safety_meetings: [],
    item: null,
    count: 0,
    count_info: 0,
    next: null,
    prev: null,
    loading: true,
  };
  
  export default function safety_meetings(state = initialState, action) {
    var updatedMeetings;
    switch (action.type) {
      
      case "GET_SAFETY_MEETING":
        // used to get safety meeting we're about to update
        updatedMeetings = state.items_info.map((meeting) => {
          if (meeting.id === action.item.id) {
            return action.item;
          }
          return meeting;
        })
        return { ...state, items_info: updatedMeetings, item: action.item };
      case "UPDATE_SAFETY_MEETING":
        // updates item in list of items_info
        updatedMeetings = updatedMeetings = state.items_info.map((meeting) => {
          if (meeting.id === action.item.id) {
            return action.item;
          }
          return meeting;
        })
        return {
          ...state,
          items_info: updatedMeetings,
          item: action.item,
        }
      case 'CREATE_SAFETY_MEETING':
        return {...state, items_info: [...state.items_info, action.item]}
      case "FETCH_SAFETY_MEETING_INFOS":
        // items_info are not really used at the moment, but will be soon
        return {
          ...state,
          items_info: action.items,
          count_info: action.count,
        };
      case "FETCH_SAFETY_MEETINGS":
        return { ...state, items: action.items, count: action.count };
  
      case "FETCH_SAFETY_MEETINGS_PROJECT":
        return { 
          ...state, 
          current_project_items: action.items, 
          count: action.count,
          next: action.next,
          prev: action.previous,
          loading: false,
        };
      
      case "APPEND_PROJECT_SAFETY_MEETINGS":
        return {
          ...state, 
          current_project_items: [...state.current_project_items, ...action.items], 
          count: action.count,
          next: action.next,
          prev: action.previous,
        }
      case "FETCH_SAFETY_MEETINGS_PER_WORKER":
        const worker_id = action.worker_id;
  
        let new_state = {
          ...state,
          items_by_worker: {
            ...state.items_by_worker,
          },
          worker_safety_meetings: action.items.results,
        };
  
        new_state.items_by_worker[worker_id] = action.items;
  
        return new_state;
      case "LOGOUT_SUCCESSFUL":
        return {
          items: [],
          items_project: [],
          current_project_items: [],
          items_info: [],
          items_by_worker: {},
          worker_safety_meetings: [],
          item: null,
          count: 0,
          count_info: 0,
        }
      default:
        return state;
    }
  }
  