import {
    container,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import fabStyle from "assets/jss/tradespecifix/fab";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

const reportStyle = {
    ...extendedFormsStyle,
    ...buttonGroupStyle,
    fullWidth: {
        width: "100%"
    },
    ...buttonStyle,
    ...customSelectStyle,
    ...fabStyle,
    ...customCheckboxRadioSwitch,
    cardTitle,
    xlogo: {
        width: "24px",
        height: "24px",
    },
    container: {
        ...container,
    },
    hidden: {
        display: 'none'
    },
    cardHeader: {
        marginBottom: "20px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    totalRow: {
        "& th, & td": {
            borderBottom: 'none',
            borderTop: "2px solid var(--trade-neutral)"
        }
    },
    centered: {
        textAlign: "center"
    },
    centerTextAlign: {
        width: "100%",
        textAlign: "center"
    },
    breakColor: {
        color: "#787878"
    }
};

export default reportStyle;
