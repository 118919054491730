import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { files, notes } from "actions";
import store from "store";
import "./AddFiles.css";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Snackbar from "@material-ui/core/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: "16vw",
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  rootTab: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  blueButton: {
    backgroundColor: "var(--trade-success)",
    color: "white",
  },
}));

function MaxWidthDialog(props) {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");
  const snackDelay = 2100;

  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const create = (state) => {
    props.create(state).then((e) => {
      console.log(e);
      if (e && e.type === "FILE_CREATED") {
        setSnackMessage("File successfully uploaded");
        setSnackType("success");
        setSnackOpen(true);
        props.createdFile(true);
        setLoading(false);
        setTimeout(() => {
          setOpen(false);
          props.createdFile(false);
        }, snackDelay);
      } else {
        setLoading(false);
        setSnackMessage("Unable to upload file");
        setSnackType("error");
        setSnackOpen(true);
      }
    });
  };

  const createImage = (state) => {
    props.create_image(state).then((res) => {
      if (res && res.type === "IMAGE_CREATED") {
        setSnackMessage("Image successfully uploaded");
        setSnackType("success");
        setSnackOpen(true);
        props.createdPhoto(true);
        setLoading(false);
        setTimeout(() => {
          setOpen(false);
          props.createdPhoto(true);
        }, snackDelay);
      } else {
        setSnackMessage("Unable to upload image");
        setSnackType("error");
        setSnackOpen(true);
        setLoading(false);
      }
    });
  };

  const createNote = async (state) => {
    try {
      const e = await props.createNote(state);

      console.log(e);
      if (e && e.type === "CREATE_NOTE") {
        setSnackMessage("Note successfully created");
        setSnackType("success");
        setSnackOpen(true);
        props.createdNote(true);
        setLoading(false);
        if (state.image) {
          await props.createNoteImage(e.item.id, state.image);
        }

        setTimeout(() => {
          setOpen(false);
          props.createdNote(false);
        }, snackDelay);
      } else {
        throw new Error("Error");
      }
    } catch (ex) {
      setSnackMessage("Unable to upload file");
      setSnackType("error");
      setSnackOpen(true);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        // color="primary"
        onClick={handleClickOpen}
        className={classes.blueButton}
        style={{ padding: "0.8rem 1.1rem" }}
      >
        <ControlPointIcon /> {/*&nbsp; Add*/}
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div className="addFileWrapper">
          <div className="addFileContainer">
            <FullWidthTabs
              projectName={props.projectName}
              create={create}
              createNote={createNote}
              createImage={createImage}
              loading={loading}
              setLoading={setLoading}
              // redirect={props.redirect}
              projectID={props.projectID}
              user={props.user}
            />
          </div>
        </div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={snackDelay}
          onClose={() => setSnackOpen(false)}
        >
          <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
            {snackMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    create: (state) => {
      return dispatch(
        files.createFile(
          state.project,
          state.name,
          state.file,
          state.restricted
        )
      );
    },
    create_image: (state) => {
      return dispatch(files.createImage(state.project, state.name, state.file));
    },
    createNote: (state) => {
      return dispatch(
        notes.createNote(
          state.project,
          state.desc,
          state.time_log_id
          // state.image
        )
      );
    },
    createNoteImage: (noteId, image) => {
      return dispatch(notes.createNoteImage(noteId, image));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaxWidthDialog);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();

  const useStickyState = (key = "tabValue", initialState = null) => {
    const [state, setState] = React.useState(() => {
      const storedState = store.get(key);

      return [0, 1, 2].includes(storedState) ? storedState : 0;
    });

    React.useEffect(() => {
      store.set(key, state);
    }, [state]);

    return [state, setState];
  };

  const [value, setValue] = useStickyState("tabValue", 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setselectedPhoto(null);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [selectedFileName, setselectedFileName] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const [selectedPhotoName, setselectedPhotoName] = React.useState(null);
  const [selectedPhoto, setselectedPhoto] = React.useState(null);

  const [FileName, setFileName] = React.useState("");
  const [noName, setNoName] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [fileType, setfileType] = React.useState(null);

  const [desc, setDesc] = React.useState("");
  const [noteError, setNoteError] = React.useState(false);

  const [enableButton, setenableButton] = React.useState(true);

  const [restrictedFile, setRestrictedFile] = React.useState(true);

  const [dragActive, setDragActive] = useState(false);

  const user = props.user;

  const handleFileName = (event) => {
    setFileName(event.target.value);
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const image_extensions = ["jpeg", "png", "jpg"];
  const file_extensions = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];

  const handleFileChange = (event, type, uploadType) => {
    let fileUploaded, file_extension, image_extension, items;
    if (uploadType === "dragAndDrop") {
      if (event.dataTransfer.files[0] !== undefined) {
        switch (type) {
          case "file":
            fileUploaded = event.dataTransfer.files[0];
            items = fileUploaded.name.split(".");
            file_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setSelectedFile(fileUploaded);
            setselectedFileName(fileUploaded.name);

            if (
              file_extensions.indexOf(file_extension) < 0 &&
              image_extensions.indexOf(file_extension) < 0
            ) {
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(false);
            }

            break;

          case "photo":
            fileUploaded = event.dataTransfer.files[0];
            items = fileUploaded.name.split(".");
            image_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setselectedPhoto(fileUploaded);
            setselectedPhotoName(fileUploaded.name);

            if (image_extensions.indexOf(image_extension) < 0) {
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(false);
            }
            break;

          case "note_image":
            fileUploaded = event.dataTransfer.files[0];
            items = fileUploaded.name.split(".");
            image_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setselectedPhoto(fileUploaded);
            setselectedPhotoName(fileUploaded.name);

            if (image_extensions.indexOf(image_extension) < 0) {
              setenableButton(false);
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(true);
            }
            break;
        }
      }
    } else {
      if (event.target.files[0] !== undefined) {
        switch (type) {
          case "file":
            fileUploaded = event.target.files[0];
            items = fileUploaded.name.split(".");
            file_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setSelectedFile(fileUploaded);
            setselectedFileName(fileUploaded.name);

            if (
              file_extensions.indexOf(file_extension) < 0 &&
              image_extensions.indexOf(file_extension) < 0
            ) {
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(false);
            }

            break;

          case "photo":
            fileUploaded = event.target.files[0];
            items = fileUploaded.name.split(".");
            image_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setselectedPhoto(fileUploaded);
            setselectedPhotoName(fileUploaded.name);
            if (image_extensions.indexOf(image_extension) < 0) {
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(false);
            }
            break;

          case "note_image":
            fileUploaded = event.target.files[0];
            items = fileUploaded.name.split(".");
            image_extension = fileUploaded
              ? items[items.length - 1].toLowerCase()
              : null;
            setselectedPhoto(fileUploaded);
            setselectedPhotoName(fileUploaded.name);
            if (image_extensions.indexOf(image_extension) < 0) {
              setenableButton(false);
              setfileType("File Not Supported");
            } else {
              setfileType(null);
              setenableButton(true);
            }
            break;
        }
      }
    }
  };

  const handleFileUpload = (type) => {
    if (FileName === "") {
      setNoName(true);
    } else {
      props.setLoading(true);
      if (type === "file") {
        const state = {
          project: props.projectID,
          name: FileName,
          file: selectedFile,
          restricted: restrictedFile,
        };
        props.create(state);
      } else if (type === "photo") {
        const state = {
          project: props.projectID,
          name: FileName,
          file: selectedPhoto,
        };
        props.createImage(state);
      }
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    // if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    handleFileChange(e, type, "dragAndDrop");
    // }
  };

  const handleDesc = (event) => {
    setDesc(event.target.value);
  };

  const submitNote = () => {
    // need to check selectedPhoto is not null
    if (desc === "") {
      setNoteError(true);
    } else {
      props.setLoading(true);
      // submit note
      const state = {
        project: props.projectID,
        desc: desc,
        time_log_id: props.user.last_time_log.id,
        image: selectedPhoto,
      };
      props.createNote(state);
    }
  };

  var today = new Date(),
    time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  return (
    <div className={classes.rootTab}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Files" {...a11yProps(0)} />
          <Tab label="Notes" {...a11yProps(1)} />
          <Tab label="Photos" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "73vh" }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className="fileUploadContainer">
            <div className="fileUpload">
              <TextField
                error={noName}
                helperText="Enter File Name"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={FileName}
                onChange={handleFileName}
                style={{
                  width: "60%",
                }}
              />
              <Box mt={2} />

              <TextField
                id="outlined-basic"
                label="Project"
                variant="outlined"
                value={props.projectName}
                style={{
                  width: "60%",
                }}
                disabled
              />

              <Box mt={2} />
              {selectedFile ? (
                <h5
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "var(--trade-secondary)",
                  }}
                >
                  {selectedFileName}
                </h5>
              ) : null}

              {/* <Button onClick={handleClick}>Upload a File</Button> */}
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <Button onClick={handleClick}>Upload a File</Button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) => handleDrop(e, "file")}
                  ></div>
                )}
              </form>
              {user.role !== "WORKER" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="restricted-checkbox"
                          onChange={() => setRestrictedFile(!restrictedFile)}
                          defaultChecked
                        />
                      }
                      label="Restricted Access"
                    />
                  </FormGroup>
                  <p className="restrited-subtext">
                    (Limit worker access to sensitive files)
                  </p>
                </div>
              ) : null}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => handleFileChange(e, "file")}
                style={{ display: "none" }}
              />
              <Box mt={2} />
              {selectedFile === null ? <h6>{alert}</h6> : null}
              <h6
                style={{ color: "var(--trade-tertiary)", textAlign: "center" }}
              >
                {fileType && selectedFile ? fileType : null}
              </h6>
              <h6>Supported Formats: PDF, DOC/DOCX, XLS, PPT</h6>
              <Box mt={2} />
              {props.loading ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem auto",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <Button
                  onClick={() => handleFileUpload("file")}
                  color="primary"
                  disabled={enableButton}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="fileUploadContainer">
            <div className="fileUpload">
              <TextField
                id="outlined-basic"
                label="time"
                variant="outlined"
                value={time}
                style={{
                  width: "60%",
                }}
                disabled
              />
              <Box mt={2} />

              <TextField
                id="outlined-basic"
                label="Project"
                variant="outlined"
                value={props.projectName}
                style={{
                  width: "60%",
                }}
                disabled
              />

              <Box mt={2} />

              <TextField
                id="outlined-multiline-static"
                label="Enter Note"
                multiline
                rows={5}
                style={{
                  width: "80%",
                }}
                // defaultValue="Default Text"
                value={desc}
                onChange={handleDesc}
                variant="outlined"
                error={noteError}
              />

              <Box mt={2} />
              {selectedPhoto ? (
                <h5
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "var(--trade-secondary)",
                  }}
                >
                  {selectedPhotoName}
                </h5>
              ) : null}

              {/* <Button onClick={handleClick}>Upload a file</Button> */}
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <Button onClick={handleClick}>Upload a File</Button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) => handleDrop(e, "note_image")}
                  ></div>
                )}
              </form>
              <input
                ref={hiddenFileInput}
                onChange={(e) => handleFileChange(e, "note_image")}
                style={{ display: "none" }}
                type="file"
              />
              <Box mt={2} />
              {selectedPhoto === null ? <h6>{alert}</h6> : null}
              <h6
                style={{ color: "var(--trade-tertiary)", textAlign: "center" }}
              >
                {fileType && selectedPhoto ? fileType : null}
              </h6>
              <h6>Supported Formats: JPEG/JPG, PNG</h6>
              <Box mt={2} />
              {props.loading ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem auto",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <Button
                  color="primary"
                  disabled={!enableButton}
                  onClick={submitNote}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="fileUploadContainer">
            <div className="fileUpload">
              <TextField
                error={noName}
                helperText="Enter File Name"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={FileName}
                onChange={handleFileName}
                style={{
                  width: "60%",
                }}
              />
              <Box mt={2} />

              <TextField
                id="outlined-basic"
                label="Project"
                variant="outlined"
                value={props.projectName}
                style={{
                  width: "60%",
                }}
                disabled
              />

              <Box mt={2} />
              {selectedPhoto ? (
                <h5
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "var(--trade-secondary)",
                  }}
                >
                  {selectedPhotoName}
                </h5>
              ) : null}
              {/* <Button onClick={handleClick}>Upload a File</Button> */}
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div>
                    <p>Drag and drop your file here or</p>
                    <Button onClick={handleClick}>Upload a File</Button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={(e) => handleDrop(e, "photo")}
                  ></div>
                )}
              </form>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => handleFileChange(e, "photo")}
                style={{ display: "none" }}
              />
              <Box mt={2} />
              {selectedPhoto === null ? <h6>{alert}</h6> : null}
              <h6
                style={{ color: "var(--trade-tertiary)", textAlign: "center" }}
              >
                {fileType && selectedPhoto ? fileType : null}
              </h6>
              <h6>Supported Formats: JPEG/JPG, PNG</h6>
              <Box mt={2} />
              {props.loading ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem auto",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <Button
                  onClick={() => handleFileUpload("photo")}
                  color="primary"
                  disabled={enableButton}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
