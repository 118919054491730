import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { help_video } from 'actions';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

const useStyles = makeStyles((theme) => ({
    videoCard: {
        backgroundColor: "#333",
        color: "var(--trade-font)",
    },

}));

function HelpVideoList(props) {
    const classes = useStyles();

    useEffect(() => {
        props.fetchVideos();
    }, []);

    const videoCard = (item) => {
        return (
            <Grid item sm={12} md={4} key={item.id}>
                <Box m={2}>
                    <Card className={classes.videoCard}>
                        <CardActionArea>
                            <CardMedia
                                component="video"
                                height="290"
                                src={item.video}
                                //src="https://youtu.be/X8CD2QEYo6Q"
                                title={item.title}
                                controls
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {item.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
            </Grid>
        );
    }

    return (
        <div style={{ backgroundColor: "var(--trade-body)", minHeight: "100%", width: "100%", position: "absolute", padding: "2rem" }}>
            <Grid container>
                <Grid item md={12}>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <WhiteTextTypography variant="h4" component={'span'} className={classes.stepDescription}>
                            Help Video Library
                        </WhiteTextTypography>
                    </Box>
                </Grid>
                {
                    props.videos.map((elem) => videoCard(elem))
                }
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        videos: state.help_video.items,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideos: () => {
            return dispatch(help_video.fetchHelpVideo());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpVideoList);