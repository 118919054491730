const initialState = {
    item: {
      first_name: "",
      last_name: "",
      email: "",
      notification_preference: {},
    },
    items: [],
    worker_assigned_projects_items: [],
    count: 0,
    count_active: 0,
};
  
export default function workers(state = initialState, action) {
    let items = [];
    switch (action.type) {
      case "ADD_WORKER":
        return [...state, { text: action.text }];
  
      case "COUNT_WORKERS":
        return { ...state, count: action.count, count_active: action.count_active };
  
      case "FETCH_WORKERS":
        if (state.item !== undefined && state.item.id !== undefined) {
          const updatedWorker = action.items.find(
            (worker) => worker.id === state.item.id
          );
          if (updatedWorker !== undefined) state.item = updatedWorker;
        }
        return { items: action.items, count: action.count, count_active: action.count_active };
  
      case "BREAK_STOPPED":
        items = state.items.map((worker) => {
          if (
            worker.last_break !== null &&
            worker.last_break.id === action.item.id
          ) {
            worker.last_break = action.item;
            worker.on_break = false;
          }
          return worker;
        });
        return { ...state, items: items };
  
      case "TIME_LOG_STOPPED":
        items = state.items.map((worker) => {
          if (
              worker.last_time_log !== undefined &&
            worker.last_time_log !== null &&
            worker.last_time_log.id === action.item.id
          ) {
            worker.last_time_log = action.item;
          }
          return worker;
        });
        return { ...state, items: items };
      case "GET_WORKER":
        state.items = state.items.map((value) => {
          if (value.id === action.item.id) {
            return action.item;
          }
          return value;
        });
        return { ...state, item: action.item };
      case "CREATE_WORKER":
        const updated_items = [...state.items, action.item]
        return {...state, items: updated_items}
  
      case "GET_WORKER_ASSIGNED_PROJECTS":
        return { ...state, worker_assigned_projects_items: action.item };
      case "LOGOUT_SUCCESSFUL":
        return {
          item: {
            first_name: "",
            last_name: "",
            email: "",
            notification_preference: {},
          },
          items: [],
          worker_assigned_projects_items: [],
          count: 0,
          count_active: 0,
        }
      default:
        return state;
    }
  }
  