
import { logException, ax } from "utils";

const endpoint = "/api/help/video/";

export const fetchHelpVideo = () => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(endpoint)
          .then(res => {
              return dispatch({
                  type: 'FETCH_HELP_VIDEO',
                  items: res.data.results,
                  count: res.data.count,
              })
          })
          .catch(err => {
              dispatch({
                  type: 'DATA_ERROR_LOADING',
                  error: err
              });
              logException(err);
          })
    };
};

export const getHelpVideo = id => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(endpoint + id + '/')
            .then(res => {
                return dispatch({
                    type: 'GET_HELP_VIDEO',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};