import React, { useEffect, useState } from "react";
import { ax } from "utils";
import { connect } from "react-redux";
import { notes } from "actions";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@material-ui/core/TextField";

import "./Lightbox.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: "var(--trade-success)",
    color: "white",
  },
}));

const Lightbox = (props) => {
  // image is a string. the image src to be displayed
  // open is a boolean. controlling if the modal is open or closed
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const [noteError, setNoteError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");
  const snackDelay = 1500;

  const { image, open, setOpen } = props;

  const classes = useStyles();

  const handleCommentText = (event) => {
    setCommentText(event.target.value);
  };

  const handleClose = () => setOpen(false);

  const createNote = async (state) => {
    try {
      const e = await props.createNote(state);

      if (e && e.type === "CREATE_NOTE") {
        props.createdNote(true);
        if (state.image) {
          let formData = new FormData();
          formData.append("note_id", e.item.id);
          formData.append("image", image.file?.split("media")[1]);
          formData.append("is_copy", "True");
          await ax
            .post("/api/notes-images/", formData)
            .then((res) => {
              setSnackMessage("Note successfully created");
              setSnackType("success");
              setSnackOpen(true);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        setTimeout(() => {
          setOpen(false);
          props.createdNote(false);
        }, snackDelay);
      } else {
        throw new Error("Error");
      }
    } catch (ex) {
      setSnackMessage("Unable to create note");
      setSnackType("error");
      setSnackOpen(true);
      setLoading(false);
    }
  };

  const submitNote = () => {
    // need to check selectedPhoto is not null
    if (commentText === "") {
      setNoteError(true);
    } else {
      setLoading(true);
      // submit note
      const state = {
        project: props.project.id,
        desc: commentText,
        time_log_id: props.user.last_time_log.id,
        image: image.file,
      };
      createNote(state);
    }
  };

  return (
    // the modal has built-in logic for open and close functionality
    // open controls if the modal is visible or not
    // onclose handles changing the open variable to false on click
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className="lightbox-container">
          <img className="lightbox-img" src={image.file}></img>
          <div className="comment-area">
            <TextField
              id="outlined-basic"
              label="Enter Comment"
              variant="outlined"
              fullWidth
              // multiline
              // rows={2}
              onChange={handleCommentText}
            />
            <div className="submit-comment-button">
              {loading ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem",
                    placeContent: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <Button
                  variant="contained"
                  className={classes.submitButton}
                  onClick={submitNote}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={snackDelay}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createNote: (state) => {
      return dispatch(
        notes.createNote(
          state.project,
          state.desc,
          state.time_log_id
          // state.image
        )
      );
    },
    createNoteImage: (noteId, image) => {
      return dispatch(notes.createNoteImage(noteId, image));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox);
