import Moment from "react-moment";
let store = require("store");

const initialState = {
  token: store.get("token"),
  expiry: store.get("expiry"),
  isAuthenticated: null,
  isLoading: true,
  isAdmin: false,
  isLocked: false,
  user: null,
  errors: {},
  gpsUpdate: null,
  currentProject: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case "USER_LOCKED":
      store.remove("token");
      store.remove("expiry");
      return {
        ...state,
        isLocked: true,
        isAuthenticated: false,
      };
    case "GET_WORKER":
      if (action.item.id === state.user.id)
        return {
          ...state,
          isAuthenticated: true,
          isLoading: false,
          user: action.item,
        };
      return state;

    case "GET_TIME_LOG":
      if (action.item.user === state.user.id)
        return {
          ...state,
          user: { ...state.user, last_time_log: action.item },
        };
      return state;
    case "BREAK_STOPPED":
      if (action.item.user === state.user.id)
        return {
          ...state,
          user: { ...state.user, last_break: action.item, on_break: false },
        };
      return state;

    case "GET_BREAK":
      if (action.item.user === state.user.id) {
        const on_break = action.item.end == null;
        return {
          ...state,
          user: { ...state.user, last_break: action.item, on_break: on_break },
        };
      }
      return state;

    case "USER_LOADING":
      return { ...state, isLoading: true };

    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.user,
      };

    case "COMPANY_UPDATED":
      if (action.item.id === state.user.company) {
        state.user.company_name = action.item.name;
        state.user.company_timezone = action.item.timezone;
      }
      return state;

    case "USER_NOT_LOADED":
      return { ...state, isAuthenticated: false, isLoading: false };

    case "LOGIN_SUCCESSFUL":
      store.set("token", action.data.token);
      store.set("expiry", action.data.expiry);
      return {
        ...state,
        user: action.data.user,
        isAuthenticated: true,
        isLoading: false,
        isLocked: false,
        errors: null,
        token: action.data.token,
      };

    case "SET_AGREE_SAFETY_MEETING":
      return {
        ...state,
        ...action.data,

        isLoading: false,
        isLocked: false,
      };

    case "NOTE_CREATED":
      if (
        action.item.time_log &&
        action.item.time_log.id === state.user.last_time_log.id
      )
        state.user.last_time_log.notes.push(action.item);
      return { ...state };

    case "TASK_TYPE_CREATED":
      if (action.item.company_id === state.user.company) {
        state.user.tasks_types.push(action.item);
      }
      return { ...state };

    case "TASK_TYPE_ORDER_UPDATED":
      if (action.item.company_id === state.user.company) {
        const id = state.user.tasks_types.findIndex(
          (a) => a.id === action.item.id
        );
        state.user.tasks_types[id].order = action.item.order;
        state.user.tasks_types.sort((a, b) => a.order > b.order);
      }
      return { ...state };

    case "LOGIN_FAILED_SERVER_DOWN":
      store.remove("token");
      return {
        ...state,
        errors: {
          non_field_errors: [
            "Server is having issues, please try again later.",
          ],
        },
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };

    case "LOGIN_FAILED":
      return {
        ...state,
        errors: action.data,
        isAuthenticated: false,
        isLoading: false,
      };

    case "AUTHENTICATION_ERROR":
    case "LOGOUT_SUCCESSFUL":
      store.remove("token");
      store.remove("expiry");
      return {
        token: null,
        expiry: null,
        isAuthenticated: false,
        isLoading: false,
        isAdmin: false,
        isLocked: false,
        user: null,
        errors: {},
        gpsUpdate: null,
        currentProject: null,
      };

    case "OWN_POSITION_UPDATED":
      const gpsUpdate = {
        longitude: action.longitude,
        latitude: action.latitude,
        time: action.time,
      };
      return { ...state, gpsUpdate };
      
    case "ACK_SAFETY_MEETING":
      return state;

    case 'SCHEDULE_UPDATED':
      let newState = state;
      newState['user']['notification_preference'] = action.item;
      return newState;

    default:
      return state;
  }
}
