import React from "react";
import {SvgIcon} from "@material-ui/core";

/**
 * @return {null}
 */
function LoadingCircularIcon(props) {
    if (props.loading !== true)
        return null;
    let styles = {};
    if(props.styles !== undefined){
        styles = props.styles;
    }
    if(styles.width === undefined && styles.height === undefined){
      styles.width = "30px";
      styles.height = "30px";
    }
    let strokeColor = props.strokeColor;
    if(strokeColor === undefined)
        strokeColor = "#fff";
    let innerStrokeColor = props.innerStrokeColor;
    if(innerStrokeColor === undefined)
        innerStrokeColor = "#c3c3c3";

    return (
      <SvgIcon viewBox="0 0 100 100" style={styles}>
          <circle cx="50" cy="50" fill="none" strokeLinecap="round" r="40" strokeWidth="12" stroke={strokeColor} strokeDasharray="62.83185307179586 62.83185307179586">
              <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
          </circle>
          <circle cx="50" cy="50" fill="none" strokeLinecap="round" r="27" strokeWidth="12" stroke={innerStrokeColor} strokeDasharray="42.411500823462205 42.411500823462205" strokeDashoffset="42.411500823462205">
              <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;-360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
          </circle>
      </SvgIcon>
    );
}

export default LoadingCircularIcon;
