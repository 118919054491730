import { logException, ax } from "utils";

const companiesEndpoint = "/api/companies/";
const taskEndpoint = "/api/tasks-types/";

export const countCompanies = () => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(companiesEndpoint + "count/")
            .then(res => {
                return dispatch({
                    type: 'COUNT_COMPANIES',
                    count: res.data.count
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    }
};

export const fetchCompanies= () => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(companiesEndpoint)
            .then(res => {
                return dispatch({
                    type: 'FETCH_COMPANIES',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const getCompany = id => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(companiesEndpoint + id + "/")
            .then(res => {
                return dispatch({
                    type: 'GET_COMPANY',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const updateTaskOrder = (task_id, order) => {
  return (dispatch) => {

      dispatch({type: "DATA_SAVING"});
      return ax.patch(
          taskEndpoint + task_id + "/",
          {order}
      ).then(res => {
          dispatch({type: "DATA_SAVING"})
          return dispatch({
              type: "TASK_TYPE_ORDER_UPDATED",
              item: res.data,
          })
      })
      .catch(err => {
          dispatch({
              type: 'DATA_ERROR_SAVING',
              error: err
          });
          logException(err);
      });
  };
};

export const createCompany = (name, timezone) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.post(
            companiesEndpoint,
            {name, timezone}
            )
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                return dispatch({
                    type: 'GET_COMPANY',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
            });
    }
};

export const saveCompanyLogo = (companyId, logo) => {
    return (dispatch) => {
        dispatch({ type: "DATA_SAVING" });

        let formData = new FormData();
        formData.append("logo", logo, logo.name);
        return ax
        .post(companiesEndpoint + companyId + "/add-logo/", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            dispatch({ type: "DATA_SAVED" });
            return dispatch({
                type: "GET_COMPANY",
                item: res.data,
            });
        })
        .catch((err) => {
            logException(err);
            dispatch({
                type: "DATA_ERROR_SAVING",
                error: err,
            });
        });
    }
}

export const createTask = (company, title) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.post(
            taskEndpoint,
            {company, title}
        ).then(res => {
            dispatch({type: "DATA_SAVED"});
            return dispatch({
                type: 'TASK_TYPE_CREATED',
                item: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: 'DATA_ERROR_SAVING',
                error: err
            });
            logException(err);
        });
    }
};

export const addSafetyMeetingToTask = (taskTypeId, safetyMeetingId) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});

        return ax.post(
            `/api/tasks-types/${taskTypeId}/add-safety-meeting-info/`,
            {info_id: safetyMeetingId}
        ).then(res => {
            dispatch({type: "DATA_SAVED"});
            return dispatch({
                type: 'MEETING_ADDED_TO_TASK',
                item: res.data
            })
        }).catch(err => {
            dispatch({
                type: 'DATA_ERROR_SAVING',
                error: err
            });
            logException(err);
        })
    }
};

export const removeSafetyMeetingFromTask = (taskTypeId, safetyMeetingId) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});

        return ax.post(
            `/api/tasks-types/${taskTypeId}/remove-safety-meeting-info/`,
            {info_id: safetyMeetingId}
        ).then(res => {
            dispatch({type: "DATA_SAVED"});
            return dispatch({
                type: 'MEETING_REMOVED_FROM_TASK',
                item: res.data
            })
        }).catch(err => {
            dispatch({
                type: 'DATA_ERROR_SAVING',
                error: err
            });
            logException(err);
        })
    }
};

export const saveCompany = (id, name, timezone) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.put(
            companiesEndpoint + id + "/",
            {'name': name, 'timezone': timezone}
            )
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                dispatch({type: "COMPANY_UPDATED", item: res.data});
                return dispatch({
                    type: 'GET_COMPANY',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
            })

    }
};

export const updateBilling = (id, name, address1, address2, postal_code, city, province, country, stripe_token) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.put(
            companiesEndpoint + id + "/payment/",
            {
                name,
                address1,
                address2,
                postal_code,
                city,
                province,
                country,
                stripe_token,
            })
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                dispatch({type: "COMPANY_UPDATED", item: res.data});
                return dispatch({
                    type: 'GET_COMPANY',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
            })
    }
};

