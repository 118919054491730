import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from 'react-router-dom';

// theme components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardFooter from "../../components/Card/CardFooter";
import Muted from "components/Typography/Muted.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// icons
import AlarmIcon from "@material-ui/icons/Alarm";

import { safety_meetings } from "actions";
import safetyMeetingsPageStyle from "assets/jss/tradespecifix/safetyMeetings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import { FormHelperText } from "@material-ui/core";

class SafetyMeetingsWorkerList extends Component {
    state = { errors: {}, };
    componentDidMount() {
        const project_id = this.props.match.params.project_id;
        const urlParams = new URLSearchParams(window.location.search);
        const taskTypeId = urlParams.get('task_type_id');
        const worker_id = urlParams.get('worker_id');
        this.props.fetchProjectSafetyMeetings(project_id, taskTypeId).then((res) => {
            if (res.items.length === 0) {
                this.setState({ 'redirectTo': '/dashboard' });
            } else {
                // We need to init fields_values for Option field. <Select /> can't be a uncontrolled component
                const ppe = res.items[0];
                const select_fields = ppe.fields.filter(field => field.type === 'choices');
                let fields_values = {};
                select_fields.forEach(f => fields_values[f.id] = { field_value: '' });
                this.setState({
                    ...this.state,
                    'safety_meeting': res.items[0],
                    'safety_meetings': res.items,
                    'safety_meetings_count': res.items.length,
                    'current_index': 0,
                    'items_read': 0,
                    project_id,
                    worker_id,
                    fields_values
                });
            }
        });
    }

    static getRequiredFields(safety_meeting) {
        return safety_meeting.fields.filter(field => field.required);
    }

    acknowledgeSafetyMeeting(safety_id, project_id, worker_id, fields_values) {
        return new Promise((resolve, reject) => {
            const required_fields = SafetyMeetingsWorkerList.getRequiredFields(this.state.safety_meeting).map(field => field.id);
            const missing_fields = required_fields.filter(field => {
                // If no answer has been provided to this required fields, let's return it right now
                if (fields_values[field] === undefined || fields_values[field].field_value === '')
                    return true;

                // Make sure a checkbox that is required has not been checked, then unchecked
                if (fields_values[field].type === 'bool')
                    return fields_values[field].field_value === false || fields_values[field].field_value === undefined;

                return fields_values[field].field_value === undefined;
            });

            let errors = {};
            missing_fields.forEach(field => errors[field] = 'Required field.');
            if (Object.keys(errors).length > 0) {
                reject(errors);
            } else {
                resolve(this.props.ackMeeting(safety_id, project_id, worker_id, fields_values));
            }
        });
    }

    showNextMeeting() {
        this.acknowledgeSafetyMeeting(this.state.safety_meeting.id, this.state.project_id, this.state.worker_id, this.state.fields_values).then((e) => {
            if (e.error !== undefined)
                this.setState({ error: e.error });
            else {
                const next_index = this.state.current_index + 1;
                const ppe = this.state.safety_meetings[next_index];
                const select_fields = ppe.fields.filter(field => field.type === 'choices');
                let fields_values = {};
                select_fields.forEach(f => fields_values[f.id] = { field_value: '' });
                this.setState({
                    'safety_meeting': this.state.safety_meetings[next_index],
                    'current_index': next_index,
                    'items_read': this.state.items_read + 1,
                    fields_values,
                });
            }
        }).catch(errors => {
            this.setState({ errors })
        });
    }

    goToDashboard() {
        this.acknowledgeSafetyMeeting(this.state.safety_meeting.id, this.state.project_id, this.state.worker_id, this.state.fields_values).then(() => {
            this.setState({ 'redirectTo': '/dashboard' });
        }).catch(errors => this.setState({ errors }));
        //}).catch(errors => console.log(errors));
    }

    setFieldValue(field, event) {
        let value = event.target.value;
        if (value === '')
            value = undefined;
        if (value === 'true' || value === 'false')
            value = (value === 'true');

        const field_value = { ...field, field_value: value };
        const errors = { ...this.state.errors, [field.id]: undefined };

        console.log(field.id, field_value, value)
        this.setState({
            fields_values: { ...this.state.fields_values, [field.id]: { ...field_value } },
            errors
        });
    }

    setFieldRawValue(field, raw_value) {
        const field_value = { ...field, field_value: raw_value };
        const errors = { ...this.state.errors, [field.id]: undefined };

        this.setState({
            fields_values: { ...this.state.fields_values, [field.id]: { ...field_value } },
            errors
        });
    }

    setFieldIntegerValue(field, event) {
        this.setFieldValue(field, event);
    }

    setFieldCheckboxValue(field, event) {
        const field_value = { ...field, field_value: event.target.checked };
        const errors = { ...this.state.errors, [field.id]: undefined };
        this.setState({
            fields_values: { ...this.state.fields_values, [field.id]: { ...field_value } },
            errors
        });
    }

    fieldHasError(field_id) {
        const { errors } = this.state;
        return errors[field_id] !== undefined;
    }
    getFieldError(field_id) {
        const { errors } = this.state;
        if (this.fieldHasError(field_id))
            return errors[field_id];

        return null;
    }

    getOptions(field) {
        const options = field.options.split('\n').map(option => option.split('|'));
        const { classes } = this.props;
        const field_value = this.state.fields_values[field.id];
        if (options.length < 4) {
            return <ButtonGroup key={field.id} className={classes.buttonGroup}>
                {options.map(option => {
                    let className = classes.button;
                    if (field_value.field_value === option[0]) {
                        className = " " + classes.buttonSelected
                    }
                    return <Button round className={className} onClick={(e) => this.setFieldRawValue(field, option[0])} value={option[0]} key={option[0]}>{option[1]}</Button>
                })}
            </ButtonGroup>
        } else {
            return <Select
                value={field_value.field_value}
                inputProps={{
                    id: field.id,
                    onChange: (e) => this.setFieldValue(field, e),
                }}
            >
                {options.map((option, idx) => <MenuItem key={idx} value={option[0]} classes={{ root: classes.selectMenuItem }}>{option[1]}</MenuItem>)}
            </Select>
        }
    }

    isEmpty(field_value) {
        return !field_value.length;
    }

    render() {
        const { classes } = this.props;
        const { safety_meeting, items_read, safety_meetings_count, redirectTo, errors } = this.state;
        if (redirectTo !== undefined)
            return <Redirect to={this.state.redirectTo} />;
        if (safety_meeting === null || safety_meeting === undefined)
            return null;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} key={safety_meeting.code}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <AlarmIcon />
                            </CardIcon>
                            <h3 className={classes.cardTitle}>{safety_meeting.title}</h3>
                            <Muted><h6 className={classes.cardSubtitle}>{safety_meeting.code}</h6></Muted>
                        </CardHeader>
                        <CardBody className={classes.cardBody}>
                            <div dangerouslySetInnerHTML={{ __html: safety_meeting.description }} />
                            {safety_meeting.images.map((image, index) => {
                                return <img src={image.image} alt={safety_meeting.title} key={index} className={classes.safetyImage} />;
                            })}
                            {safety_meeting.fields.map((field, index) => {
                                if (field.type === 'string') {
                                    return <CustomInput
                                        labelText={field.title}
                                        id={field.id}
                                        key={field.id}
                                        error={this.fieldHasError(field.id)}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            onChange: (e) => this.setFieldValue(field, e),
                                        }}
                                        required={field.required}
                                    />

                                } else if (field.type === 'choices') {
                                    return <FormControl key={field.id} className={classes.formControl} fullWidth error={this.fieldHasError(field.id)}>
                                        <InputLabel htmlFor={field.id} FormLabelClasses={{ root: classes.selectLabel, asterisk: classes.requiredAsterisk }} required={field.required}>{field.title}</InputLabel>
                                        {this.getOptions(field)}
                                        {errors[field.id] ? <FormHelperText>{errors[field.id]}</FormHelperText> : null}
                                    </FormControl>

                                } else if (field.type === 'int') {
                                    return <CustomInput
                                        key={field.id}
                                        labelText={field.title}
                                        id={field.id}
                                        error={this.fieldHasError(field.id)}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            inputProps: {
                                                min: field.min_value,
                                                max: field.max_value,
                                            },
                                            onKeyUp: (e) => this.setFieldIntegerValue(field, e),
                                            onMouseUp: (e) => this.setFieldIntegerValue(field, e),
                                        }}
                                        required={field.required}
                                    />

                                } else if (field.type === 'bool') {
                                    return <div className={classes.checkboxAndRadio} key={field.id}>
                                        <FormControl required={field.required} error={this.fieldHasError(field.id)}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            onClick={(e) => this.setFieldCheckboxValue(field, e)}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label + " " + (field.required ? classes.required : '')
                                                    }}
                                                    label={field.title}
                                                />
                                            </FormGroup>
                                            {this.fieldHasError(field.id) ? <FormLabel component="span" className={classes.checkboxError}>This field is required</FormLabel> : null}
                                        </FormControl>
                                    </div>
                                }
                                return <span />;
                            })}
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                            {items_read === safety_meetings_count - 1 ?
                                <Button onClick={() => this.goToDashboard()} color="success" round>I have read and understood<br /> the safety meeting</Button>
                                : <Button onClick={() => this.showNextMeeting()} color="success" round>I have read and understood<br /> the safety meeting, read next item</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

SafetyMeetingsWorkerList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProjectSafetyMeetings: (project_id, task_type_id) => {
            return dispatch(safety_meetings.fetchSafetyMeetingsInfoPerProject(project_id, task_type_id));
        },
        ackMeeting: (meeting_id, project_id, worker_id, fields_values) => {
            if (fields_values === undefined)
                fields_values = [];
            return dispatch(safety_meetings.acknowledgeMeeting(meeting_id, project_id, worker_id, fields_values));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(safetyMeetingsPageStyle)(SafetyMeetingsWorkerList));
