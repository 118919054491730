//component used for assigning worker to site from the dashboard's "Assign Worker" button
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { projects, workers } from "actions";
import CssTextField from "components/CssTextField/CssTextField";
import { ax } from "utils";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogBody: {
    backgroundColor: "var(--trade-background)",
    color: "var(--trade-font)",
    textAlign: "center",
  },
}));

function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [workersLoading, setWorkersLoading] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const [project, setProject] = useState(null);
  const [worker, setWorker] = useState("");

  const getData = () => {
    const projects_url = "/api/projects/reduced/?limit=1000";
    const workers_url = "/api/users/reduced/";

    ax.get(workers_url)
      .then((res) => {
        setWorkers([...res.data.results]);
        setWorkersLoading(false);
      })
      .catch((err) => {
        setWorkersLoading(false);
        console.log(err);
      });

    // getting project objects
    ax.get(projects_url)
      .then((res) => {
        setProjects([...res.data.results]);
        setProjectsLoading(false);
      })
      .catch((err) => {
        setProjectsLoading(false);
        console.log(err);
      });
  };

  const updateNewWorkerCreated = () => {
    props.handleNewWorkerCreated();
  };

  useEffect(() => {
    getData();
  }, [props.projects, props.workers, props.newWorkerCreated]);

  useEffect(() => {
    if (props.type === "worker" && props.worker_id) {
      setWorker(props.worker_id);
    }
  }, [workers]);

  useEffect(() => {
    if (props.openAssignDialog) {
      setOpen(true);
    }
  }, [props.openAssignDialog]);

  const handleChangeProject = (event, newValue) => {
    setProject(newValue);
  };

  const handleChangeWorker = (event, newValue) => {
    setWorker(newValue);
    setIsProjectSelected(true);
    handleFilterProjects(newValue);
  };

  const handleClickOpen = (worker_id) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProject(null);
    setWorker("");
    if (props.setOpenAssignDialog) props.setOpenAssignDialog(false);
  };

  const handleSubmit = () => {
    if (project === "") {
      setError("Select Project");
      return;
    }
    if (worker === "") {
      setError("Select Worker");
      return;
    }
    if (project !== null && worker !== "") {
      props.assignWorker(worker.id, project.id).then((response) => {
        const assignedWorker = workers.find((elem) => elem.id === worker.id);
        if (response !== undefined) {
          setProject(null);
          setWorker("");
          handleClose();
          setSnackType("success");
          setSnackMessage(`Assigned ${assignedWorker.first_name} to site`);
          setSnackOpen(true);
          // There is no good reason to reload the page if we are handling the redux stores correctly
          // window.location.reload();
          // Which is not the case in this component
        } else {
          setSnackType("error");
          setSnackMessage(
            `Couldn't assign ${assignedWorker.first_name} to site`
          );
          setSnackOpen(true);
        }
      });
    }
  };

  const handleFilterProjects = (selectedWorker) => {
    props.getWorkerAssignedProjects(selectedWorker.id).then((res) => {
      const availableProjects = projects.filter(
        (project) =>
          !res?.item.some(
            (assignedProject) => project.id === assignedProject.id
          )
      );
      setFilteredProjects(availableProjects);
    });
  };

  const WorkerSelect = () => {
    const unassignedWorkers = workers.filter((worker) => {
      return !filteredWorkers.some((filteredOutWorker) => {
        return worker.id === filteredOutWorker.id;
      });
    });
    const workerOptions = unassignedWorkers.sort((a, b) =>
      a.first_name.localeCompare(b.first_name)
    );
    return (
      <Autocomplete
        id="AssignWorkerProjectSelector"
        value={worker}
        onChange={handleChangeWorker}
        label="Worker"
        disableClearable
        style={{ minWidth: "16vw", margin: "0.5rem auto" }}
        getOptionLabel={(option) =>
          option ? option.first_name + " " + option.last_name : null
        }
        options={workerOptions}
        loading={workersLoading}
        renderInput={(params) => (
          <CssTextField {...params} variant="outlined" label="Worker">
            {params.title}
          </CssTextField>
        )}
      />
    );
  };

  const ProjectSelectAutocomplete = () => {
    const options = filteredProjects.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    return (
      <Autocomplete
        id="AssignWorkerProjectSelector"
        value={project}
        onChange={handleChangeProject}
        label="Project"
        disableClearable
        style={{ minWidth: "16vw", margin: "0.5rem auto" }}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={projectsLoading}
        renderInput={(params) => (
          <CssTextField {...params} variant="outlined" label="Project">
            {params.title}
          </CssTextField>
        )}
      />
    );
  };

  return (
    <div>
      {!props.showButton && props.type !== "assign-dialog" ? (
        props.type === "dashboard" ? (
          <Button
            // color="secondary"
            size="small"
            style={{ fontSize: "0.8rem", color: "var(--trade-success)" }}
            onClick={() => handleClickOpen(null)}
          >
            <AddCircleIcon style={{ fill: "var(--trade-success)" }} />
            &nbsp; assign worker
          </Button>
        ) : (
          <Button
            // color="secondary"
            style={{ fontSize: "0.8rem", color: "var(--trade-success)" }}
            onClick={() => handleClickOpen(props.worker_id)}
          >
            <AddCircleIcon />
            &nbsp; assign {props.worker_name} to site
          </Button>
        )
      ) : null}
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogBody}>
          Assign Worker to Project
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.dialogBody}
        >
          <WorkerSelect />
          <Box m={2} />
          {isProjectSelected ? <ProjectSelectAutocomplete /> : ""}
          {error}
        </DialogContent>
        <DialogActions className={classes.dialogBody}>
          <Button
            onClick={handleSubmit}
            style={{ color: "var(--trade-success)" }}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    projects: state.projects.items,
    workers: state.workers.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignWorker: (worker_id, project_id) => {
      return dispatch(projects.assignWorkerToProject(worker_id, project_id));
    },
    getWorkerAssignedProjects: (worker_id) => {
      return dispatch(workers.getWorkerAssignedProjects(worker_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormDialog);
