import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Button } from "@material-ui/core";
import { PropTypes } from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const GuidePopover = (props) => {
  const { id, show, comp, title, description, anchorOrigin, transformOrigin, arrow } = props
  const dispatch = useDispatch();
  const guideState = useSelector(state => state.help_faq);
  const [visible, setVisible] = useState(true);

  const setGuideAction = (step, show) => dispatch => {
    dispatch({
      type: "EXPLAIN_STEP",
      step: step,
      show: show
    })
  }

  useEffect(() => {
    const showed = localStorage.getItem("guide-show")
    if (showed === 'yes') {
      setVisible(false)
    }
  }, [])

  return visible ? (
    <Popover
      className="ex-popover"
      id={id}
      open={show}
      anchorEl={comp.current}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className={`ex-container ${arrow || 'top'}`}>
        <h4>{title}</h4>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="ex-action">
        {
          guideState.exStep > 0 ? (
            <Button
              size="small"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => { dispatch(setGuideAction(guideState.exStep - 1, true)) }}
            >
              Back
            </Button>
          ) : (
            <div />
          )
        }
        {
          guideState.exStep < 5 ? (
            <Button
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => { dispatch(setGuideAction(guideState.exStep + 1, true)) }}
            >
              Next
            </Button>
          ) : (
            <Button
              size="small"
              onClick={() => { dispatch(setGuideAction(0, false)); localStorage.setItem('guide-show', 'yes') }}
            >
              Finish
            </Button>
          )
        }
      </div>
    </Popover>
  ) : (<div />)
}

GuidePopover.propTypes = {
  id: PropTypes.string.isRequired,
  comp: PropTypes.instanceOf(Element),
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};


export default GuidePopover