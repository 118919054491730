import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "components/CustomButtons/Button.jsx";
//import {Link} from "react-router-dom";
import { safety_meetings } from "actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HistoryIcon from '@material-ui/icons/History';

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

const AmberTypography = withStyles({
    root: {
        color: "var(--trade-primary)"
    },
})(Typography);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const RedTypography = withStyles({
    root: {
        color: "var(--trade-tertiary)"
    },
})(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaper-root": {
            width: '100%',
            backgroundColor: 'transparent',
        }
    },
    tableRow: {
        borderRadius: "4px",
        backgroundColor: "var(--trade-background)",
        marginBottom: "1rem",
    },
    smallerText: {
        fontSize: "0.8rem",
    },
    largerText: {
        fontSize: "1.2rem"
    },
    centeredText: {
        margin: "auto",
    },
    iconButton: {
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none"
    },
    rowIcon: {},
}));

function ArchivedMeetingLibrary(props) {
    const classes = useStyles();

    useEffect(() => {
        props.getSafetyMeetings();
    }, []);

    const titleSection = () => (
        <Grid container>
            <Grid item md={12}>
                <WhiteTextTypography variant="h4"> Archived Safety Meetings</WhiteTextTypography>
            </Grid>
            <Grid item md={12}>
                <WhiteTextTypography> They can be activated by clicking on the activate button </WhiteTextTypography>
            </Grid>
        </Grid>
    );

    const activateSafetyMeeting = (meeting_id) => {
        //console.log(event);
        console.log(meeting_id);
        props.activateMeeting(meeting_id);
    }

    const tableRow = (safety_meeting) => {
        return (
            <Grid container item md={12} className={classes.tableRow} key={safety_meeting.id}>
                <Grid item md={2}>
                    <Box display="flex" justifyContent="center" alignContent="center" height="100%" >
                        <AmberTypography className={classes.centeredText}>
                            {safety_meeting.title}
                        </AmberTypography>
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box display="flex" justifyContent="center" alignContent="center" height="100%">
                        <WhiteTextTypography className={classes.centeredText} >
                            {safety_meeting.code}
                        </WhiteTextTypography>
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box display="flex" justifyContent="center" alignContent="center" height="100%">
                        <AmberTypography className={classes.centeredText}>{safety_meeting.company_name}</AmberTypography>
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box display="flex" justifyContent="center" alignContent="center" height="100%">
                        {
                            safety_meeting.daily ? <WhiteTextTypography className={classes.centeredText}> <CheckCircleIcon /> </WhiteTextTypography> : null
                        }
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box display="flex" justifyContent="center" alignContent="center" height="100%">
                        <Box component="span" className={classes.centeredText}>
                            <HtmlTooltip title={
                                <React.Fragment>
                                    <Typography color="inherit" variant="subtitle1"> Reactivate Safety Meeting </Typography>
                                </React.Fragment>
                            }>
                                <WhiteTextTypography>
                                    <Button className={classes.iconButton} justIcon id={safety_meeting.id} onClick={() => activateSafetyMeeting(safety_meeting.id)}>
                                        <HistoryIcon id={safety_meeting.id} />
                                    </Button>
                                </WhiteTextTypography>
                            </HtmlTooltip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    };

    const tableSection = () => (
        <Grid container>
            <Grid container item md={12} className="myclass">

                <Grid item md={2}>
                    <Box m={2} display="flex" justifyContent="center" alignContent="center">
                        <WhiteTextTypography>Title</WhiteTextTypography>
                    </Box>
                </Grid>
                <Grid item md={2} >
                    <Box m={2} display="flex" justifyContent="center" alignContent="center">
                        <WhiteTextTypography>Meeting Code</WhiteTextTypography>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box m={2} display="flex" justifyContent="center" alignContent="center">
                        <WhiteTextTypography>Company</WhiteTextTypography>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box m={2} display="flex" justifyContent="center" alignContent="center">
                        <WhiteTextTypography> Daily </WhiteTextTypography>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box m={2} display="flex" justifyContent="center" alignContent="center">
                        <WhiteTextTypography>Activate</WhiteTextTypography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container item md={12}>
                {
                    props.safety_meetings.filter((elem) => !elem.is_active).map((elem) => tableRow(elem))
                }
            </Grid>
        </Grid>
    );

    return (
        <div style={{ backgroundColor: "var(--trade-body)", minHeight: "100%", width: "100%", position: "absolute", padding: "2rem" }}>
            {titleSection()}
            {tableSection()}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        safety_meetings: state.safety_meetings.items_info,
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSafetyMeetings: () => {
            return dispatch(safety_meetings.fetchSafetyMeetingInfos())
        },
        activateMeeting: (meetingId) => {
            return dispatch(safety_meetings.activateMeeting(meetingId));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedMeetingLibrary);