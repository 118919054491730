/* global google */
import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";

import withStyles from "@material-ui/core/styles/withStyles";
import mapStyle from "./MapStyle";

import { compose } from 'recompose';

const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Circle,
} = require("react-google-maps");
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");


export function getGravatarUrl(email) {
    let formattedEmail = ('' + email).trim().toLowerCase();
    const img = "//0.gravatar.com/avatar/" + md5(formattedEmail) + "?d=retro&r=g&s=34";
    return img;
}

const markerStyling= {
    display: "none"
};

export const DashboardGoogleMap = compose(
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        disableDefaultUI={true}
        defaultZoom={12}
        center={{ lat: props.latitude, lng: props.longitude }}
        defaultOptions={{
            scrollwheel: false,
            disableDefaultUI: false,
            draggable: true,
        }}
    >
        <Circle center={{ lat: props.latitude, lng: props.longitude }} radius={props.radius} visible={true} draggable={false}/>
        {props.workers.map((worker, index) => ( worker.last_position  !== null && worker.last_position.latitude !== null &&
            <MarkerWithLabel
                key={index + "--" + worker.id}
                position={{ lat: parseFloat(worker.last_position.latitude), lng: parseFloat(worker.last_position.longitude) }}
                labelAnchor={new google.maps.Point(0, 0)}
                labelStyle={markerStyling}
                icon={{ url: worker.avatar || getGravatarUrl(worker.email), scaledSize: new google.maps.Size(32, 32)}}
                visible={true}
            >
                <span></span>
            </MarkerWithLabel>
        ))}
    </GoogleMap>
);


class Map extends React.Component {
    render() {
        const { project, workers } = this.props;
        if(project.longitude === null || project.latitude === null)
            return null;

        return <DashboardGoogleMap
            latitude={parseFloat(project.latitude)}
            longitude={parseFloat(project.longitude)}
            radius={parseFloat(project.radius)}
            workers={workers}
            googleMapURL={process.env.GOOGLE_MAPS_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
                <div
                    style={{
                        height: `280px`,
                        borderRadius: "6px",
                        overflow: "hidden"
                    }}
                />
            }
            mapElement={<div style={{ height: `100%` }} />}
        />;
    }
}

Map.propTypes = {
    workers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        avatar: PropTypes.string,
        email: PropTypes.string.isRequired, // Used to display the avatar is no avatar is set
        last_position: PropTypes.shape({
            latitude: PropTypes.string,
            longitude: PropTypes.string,
            date: PropTypes.string
        })
    })),
    project: PropTypes.shape({
        longitude: PropTypes.string,
        latitude: PropTypes.string,
        radius: PropTypes.number,
    })
};

Map.defaultProps = {
    longitude: null,
    latitude: null,
};

export default withStyles(mapStyle)(Map);
