import React, { useEffect, useState } from "react";

import TablePagination from "@material-ui/core/TablePagination";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Gravatar from "react-gravatar";

import { connect } from "react-redux";
import "./TableSlider.css";
import { Avatar } from "@material-ui/core";
import DailyWorkerLogs from "newComponents/DailyWorkerLogs/DailyWorkerLogs";
import SafetyMeetingSlider from "newComponents/SafetyMeetingSlider/SafetyMeetingSlider";

const GreyTable = withStyles({
  root: {
    backgroundColor: "var(--trade-background)",
    color: "var(--trade-font)",
  },
})(Table);

const CustomRow = withStyles({
  root: {
    backgroundColor: "var(--trade-background)",
    color: "var(--trade-font)",
    borderRadius: "8px",
  },
})(TableRow);

const CustomCell = withStyles({
  root: {
    backgroundColor: "var(--trade-background)",
    color: "var(--trade-font)",
    borderRadius: "8px",
  },
})(TableCell);

const CustomTableHead = withStyles({
  root: {
    backgroundColor: "var(--trade-background)",
    color: "var(--trade-font)",
    borderRadius: "8px",
  },
})(TableHead);

var moment = require("moment");

function BasicTable(props) {
  const { tableData, type, user, isExportingLogs, setIsExportingLogs } = props;
  const [logSummaries, setLogSummaries] = useState({});

  const tableHeaderTimeSheet = [
    "worker",
    "task",
    "on site",
    "break",
    "delivery",
    "details",
  ];

  useEffect(() => {
    if (type === "logs" || type === "worker-logs") {
      createLogSummaries();
    }
  }, [tableData]);

  const createLogSummaries = () => {
    const summaries = {};
    for (var i = 0; i < tableData.length; i++) {
      var diff = moment
        .duration(
          moment(tableData[i].end, "DD/MM/YYYY HH:mm:ss").diff(
            moment(tableData[i].start, "DD/MM/YYYY HH:mm:ss")
          )
        )
        .asSeconds();

      if (!summaries[tableData[i].user_id]) {
        //create dict entry and init time depending on type
        summaries[tableData[i].user_id] = {
          worker: `${tableData[i].user_first_name} ${tableData[i].user_last_name}`,
          avatar: tableData[i].user_avatar,
          task:
            tableData[i].task_type === null
              ? tableData[i].type
              : tableData[i].task_type?.title,
          email: tableData[i].user_email,
          timelog: 0,
          break: 0,
          delivery: 0,
          details: tableData[i].user_id,
        };
      }

      if (diff) {
        summaries[tableData[i].user_id] = {
          ...summaries[tableData[i].user_id],
          [tableData[i].type]:
            summaries[tableData[i].user_id][tableData[i].type] + diff,
        };
      }
    }

    Object.keys(summaries).forEach((elem) => {
      summaries[elem]["worked_hours"] = Math.floor(
        summaries[elem].timelog / 3600
      );
      summaries[elem]["worked_minutes"] = Math.floor(
        (summaries[elem].timelog % 3600) / 60
      );
      summaries[elem]["break_hours"] = Math.floor(summaries[elem].break / 3600);
      summaries[elem]["break_minutes"] = Math.floor(
        (summaries[elem].break % 3600) / 60
      );
      summaries[elem]["delivery_hours"] = Math.floor(
        summaries[elem].delivery / 3600
      );
      summaries[elem]["delivery_minutes"] = Math.floor(
        (summaries[elem].delivery % 3600) / 60
      );
    });

    setLogSummaries(summaries);
  };

  const tableHeaderWorkerTimeSheet = [
    "worker",
    "task",
    "on site",
    "break",
    "delivery",
    "details",
  ];

  const tableHeaderSafetyMeeting = [
    "worker",
    "read on",
    "title",
    "code",
    "Safety Meeting",
  ];

  const tableHeaderGPSEntries = ["Event", "Time", "Geofence", "worker"];

  const calculateDuration = (start, end) => {
    const x = moment
      .duration(
        moment(end, "DD/MM/YYYY HH:mm:ss").diff(
          moment(start, "DD/MM/YYYY HH:mm:ss")
        )
      )
      .format("h [hrs] m [min] s [seconds]");
    return x;
  };

  const selectTableHeader = (type) => {
    switch (type) {
      case "logs":
        return tableHeaderTimeSheet.map((item, key) => (
          <CustomCell align="center" key={key}>
            <span style={{ textTransform: "uppercase" }}>{item}</span>
          </CustomCell>
        ));

      case "worker-logs":
        return tableHeaderWorkerTimeSheet.map((item, key) => (
          <CustomCell align="center" key={key}>
            <span style={{ textTransform: "uppercase" }}>{item}</span>
          </CustomCell>
        ));

      case "safety-meetings":
        return tableHeaderSafetyMeeting.map((item, key) => (
          <CustomCell align="center" key={key}>
            <span style={{ textTransform: "uppercase" }}>{item}</span>
          </CustomCell>
        ));

      case "gps-entries":
        return tableHeaderGPSEntries.map((item, key) => (
          <CustomCell align="center" key={key}>
            <span style={{ textTransform: "uppercase" }}>{item}</span>
          </CustomCell>
        ));

      default:
        break;
    }
  };

  const projectTimeLogs = () => {
    return (
      <React.Fragment>
        {Object.keys(logSummaries)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((key, index) => (
            <CustomRow key={index}>
              <CustomCell component="th" scope="row">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {logSummaries[key].avatar ? (
                    <Avatar
                      style={{ height: "30px", width: "30px" }}
                      src={logSummaries[key].avatar}
                    />
                  ) : (
                    <Gravatar
                      size={25}
                      style={{ borderRadius: "50%" }}
                      email={logSummaries[key].email}
                      avatar_url={logSummaries[key].avatar}
                    />
                  )}
                  &nbsp;
                  {logSummaries[key].worker}
                </div>
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {logSummaries[key].task}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {logSummaries[key].worked_hours +
                  " hrs " +
                  logSummaries[key].worked_minutes +
                  " mins"}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {logSummaries[key].break_hours +
                  " hrs " +
                  logSummaries[key].break_minutes +
                  " mins"}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {logSummaries[key].delivery_hours +
                  " hrs " +
                  logSummaries[key].delivery_minutes +
                  " mins"}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {/* <SideDrawer item={props.item} type={"worker-logs"} worker_id={key}/> */}
                <DailyWorkerLogs
                  user_name={logSummaries[key].worker}
                  user_avatar={logSummaries[key].avatar}
                  user_email={logSummaries[key].email}
                  logs={tableData.filter((elem) => elem.user_id === key)}
                />
              </CustomCell>
            </CustomRow>
          ))}
      </React.Fragment>
    );
  };

  const workerTimeLogs = () => {
    return (
      <React.Fragment>
        {Object.keys(logSummaries).map((key, index) => (
          <CustomRow key={index}>
            <CustomCell component="th" scope="row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {logSummaries[key].avatar ? (
                  <Avatar
                    style={{ height: "30px", width: "30px" }}
                    src={logSummaries[key].avatar}
                  />
                ) : (
                  <Gravatar
                    size={25}
                    style={{ borderRadius: "50%" }}
                    email={logSummaries[key].email}
                    avatar_url={logSummaries[key].avatar}
                  />
                )}
                &nbsp;
                {logSummaries[key].worker}
              </div>
            </CustomCell>
            <CustomCell align="center" component="th" scope="row">
              {logSummaries[key].task}
            </CustomCell>
            <CustomCell align="center" component="th" scope="row">
              {logSummaries[key].worked_hours +
                " hrs " +
                logSummaries[key].worked_minutes +
                " mins"}
            </CustomCell>
            <CustomCell align="center" component="th" scope="row">
              {logSummaries[key].break_hours +
                " hrs " +
                logSummaries[key].break_minutes +
                " mins"}
            </CustomCell>
            <CustomCell align="center" component="th" scope="row">
              {logSummaries[key].delivery_hours +
                " hrs " +
                logSummaries[key].delivery_minutes +
                " mins"}
            </CustomCell>
            <CustomCell align="center" component="th" scope="row">
              {/* <SideDrawer item={props.item} type={"worker-logs"} worker_id={key}/> */}
              <DailyWorkerLogs
                user_name={logSummaries[key].worker}
                user_avatar={logSummaries[key].avatar}
                user_email={logSummaries[key].email}
                logs={tableData.filter((elem) => elem.user_id === key)}
              />
            </CustomCell>
          </CustomRow>
        ))}
      </React.Fragment>
    );
  };

  const selectTableData = (type, page, rowsPerPage) => {
    switch (type) {
      case "logs": {
        return projectTimeLogs();
      }
      case "worker-logs": {
        return workerTimeLogs();
      }

      case "safety-meetings": {
        return tableData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, key) => (
            <CustomRow
              key={key}
              style={{ backgroundColor: "#000000", color: "white" }}
            >
              <CustomCell align="center" component="th" scope="row">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {item.user_avatar ? (
                    <Avatar
                      src={item.user_avatar}
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    <Gravatar
                      email={item.user_email}
                      avatar_url={item.user_avatar}
                      size={25}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                  &nbsp;
                  {item.user_name}
                </div>
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {moment(item.read_on).utc().local().format("LT Do MMMM YYYY")}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {item.safety_meeting_info.title}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {item.safety_meeting_info.code}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {" "}
                {/* <SafetyMeetingGPS
                  type={"safety-meeting-view"}
                  info={item.safety_meeting_info}
                /> */}
                <SafetyMeetingSlider
                  meeting={item.safety_meeting_info}
                  whiteIcon={false}
                  pdfPath={item.full_pdf_path}
                  item={item}
                />
              </CustomCell>
            </CustomRow>
          ));
      }

      case "gps-entries": {
        return tableData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, key) => (
            <CustomRow key={key}>
              <CustomCell align="center" component="th" scope="row">
                {item.type}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {moment(item.date).utc().local().format("LT Do MMMM YYYY")}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                {item.project_name}
              </CustomCell>
              <CustomCell align="center" component="th" scope="row">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {item.user_avatar ? (
                    <Avatar
                      src={item.user_avatar}
                      style={{ height: "30px", width: "30px" }}
                    />
                  ) : (
                    <Gravatar
                      email={item.user_email}
                      avatar_url={item.user_avatar}
                      size={25}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                  &nbsp;
                  {item.user_first_name + " " + item.user_last_name}
                </div>
              </CustomCell>
            </CustomRow>
          ));
      }

      default:
        break;
    }
  };

  const setWidth = (type) => {
    if (type.indexOf("logs") === 0) {
      return "55vw";
    }
    if (type.indexOf("safety-meetings") === 0) {
      return "100%";
    }
    if (type.indexOf("gps-entries") === 0) {
      return "55vw";
    }
    if (type.indexOf("worker-logs") === 0) {
      return "55vw";
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <GreyTable style={{ width: setWidth(type) }} aria-label="simple table">
        <CustomTableHead>
          <CustomRow>{selectTableHeader(type)}</CustomRow>
        </CustomTableHead>
        <TableBody>{selectTableData(type, page, rowsPerPage)}</TableBody>
      </GreyTable>

      {type !== "worker-logs" ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ color: "white" }}
        />
      ) : (
        <Box m={2}></Box>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
