import {
    container,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import fabStyle from "assets/jss/tradespecifix/fab";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle";

const companyStyle = {
    ...buttonGroupStyle,
    ...customSelectStyle,
    ...buttonStyle,
    ...fabStyle,
    ...customCheckboxRadioSwitch,
    cardTitle,
    container: {
        ...container,
    },
    taskList: {
        borderRadius: "3px",
    },
    taskItemContainer: {
        padding: "8px 20px",
        border: "1px solid #c3c3c3",
        "& svg": {
            backgroundColor: "#dbdbdb",
            marginRight: "10px",
            cursor: "pointer",
        }
    },
    cardHeader: {
        marginBottom: "20px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    formControlNoPadding: {
        margin: "0px",
        paddingTop: "0px",
    },
    labelHorizontalDropdown: {
        color: "rgba(0, 0, 0, 0.26)",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingTop: "27px",
        marginRight: "0",
        "@media (min-width: 992px)": {
            float: "right"
        },
    }
};

export default companyStyle;
