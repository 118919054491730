import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import { ax } from "utils";
import { connect } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.jsx";
import Chip from "@material-ui/core/Chip";
import CssTextField from "components/CssTextField/CssTextField";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import TasksAndMeetingsRow from "../TasksAndMeetingsRow/TasksAndMeetingsRow";
import Visibility from "@material-ui/icons/Visibility";

const WhiteTextTypography = withStyles({
  root: {
    color: "var(--trade-font)",
  },
})(Typography);

function TaskAndMeetingTable(props) {
  // const [tasks, setTasks] = useState([]);

  useEffect(() => {}, [props.infoDeleted]);

  return (
    <Grid
      container
      // style={{ border: "1px solid var(--trade-neutral)" }}
    >
      <Grid container item md={12}>
        {/* Table Headers */}
        <Grid item md={4}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            // margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Tasks</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={5}>
          <Box
            m={2}
            display="flex"
            // justifyContent="center"
            alignContent="center"
            // margin="0.2rem 1rem"
          >
            <WhiteTextTypography>Safety Meetings</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
      {props.adminTasks.map((task, index) => (
        <TasksAndMeetingsRow
          key={index}
          allSafetyMeetings={props.allSafetyMeetings}
          currentInfo={props.currentInfo}
          filterInfos={props.filterInfos}
          handleRemoveTask={props.handleRemoveTask}
          handleNewTaskTitle={props.handleNewTaskTitle}
          handleSafetyMeetingDelete={props.handleSafetyMeetingDelete}
          infoDeleted={props.infoDeleted}
          isEditingTask={props.isEditingTask}
          newTaskTitle={props.newTaskTitle}
          safety_meeting_infos={props.safety_meeting_infos}
          selectedTasks={props.selectedTasks}
          setIsEditingTask={props.setIsEditingTask}
          setNewTaskTitle={props.setNewTaskTitle}
          setSelectedTasks={props.setSelectedTasks}
          task={task}
        />
      ))}
      {props.selectedTasks.map((task, index) => (
        <TasksAndMeetingsRow
          key={index}
          allSafetyMeetings={props.allSafetyMeetings}
          currentInfo={props.currentInfo}
          filterInfos={props.filterInfos}
          handleRemoveTask={props.handleRemoveTask}
          handleNewTaskTitle={props.handleNewTaskTitle}
          handleSafetyMeetingDelete={props.handleSafetyMeetingDelete}
          infoDeleted={props.infoDeleted}
          isEditingTask={props.isEditingTask}
          newTaskTitle={props.newTaskTitle}
          selectedTasks={props.selectedTasks}
          setIsEditingTask={props.setIsEditingTask}
          setNewTaskTitle={props.setNewTaskTitle}
          setSelectedTasks={props.setSelectedTasks}
          task={task}
        />
      ))}
      {props.AddMeetingRow(props.dragActive)}

      {/* {props.showSafetyMeetingLists && ( */}
      <Grid
        item
        md={12}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          border: "1px solid var(--trade-neutral)",
          borderTop: "none",
        }}
        onClick={props.createTask}
      >
        <Button
          round
          justIcon
          style={{
            width: "1rem",
            height: "1rem",
            minWidth: "1rem",
            marginLeft: "8px",
            backgroundColor: "var(--trade-success)",
          }}
        >
          <AddIcon />
        </Button>
        <WhiteTextTypography style={{ marginLeft: "10px" }}>
          Add Task To Project
        </WhiteTextTypography>
      </Grid>
      {/* )} */}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return;
};

const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskAndMeetingTable);
