import React, { useEffect } from "react";
import { ax } from "utils";
import AssignWorkerFromDashboard from "../AssignWorkerFromDashboard/AssignWorkerFromDashboard";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { workers } from "actions";
import Spinner from "react-bootstrap/Spinner";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import "./CreateWorker.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    width: "44ch",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  helperText: {
    textTransform: "lowercase",
  },
}));

function AlertDialog(props) {
  const classes = useStyles();
  const { user } = props;

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    gps_forced: false,
    is_manager: false,
    role: "",
  });

  const [error, setError] = React.useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    password: false,
    role: false,
  });

  const [workerId, setWorkerId] = React.useState("");
  const [workerRole, setWorkerRole] = React.useState("");
  const [openArchivedDialog, setOpenArchivedDialog] = React.useState(false);
  const [userArchived, setUserArchived] = React.useState(false);
  const [emailExists, setEmailExists] = React.useState(false);
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(true);
  const [snackValue, setSnackValue] = React.useState(false);
  const [snackText, setSnackText] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success");
  const [requestError, setRequestError] = React.useState(null);
  const [creatingUser, setCreatingUser] = React.useState(false);
  const [newWorkerRole, setNewWorkerRole] = React.useState("");

  const closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackValue(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        ["password"]: e.target.value,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
    e.target.value === ""
      ? setError({
          ...error,
          [e.target.name]: true,
        })
      : setError({
          ...error,
          [e.target.name]: false,
        });
  };

  useEffect(() => {
    if (newWorkerRole === "OWNER" || newWorkerRole === "MANAGER") {
      setState({
        ...state,
        is_manager: true,
        role: newWorkerRole,
      });
    } else {
      setState({
        ...state,
        role: newWorkerRole,
      });
    }
  }, [newWorkerRole]);

  const handlePermissionSelect = (event) => {
    const target = event.target;
    const value = target.value;

    setNewWorkerRole(value);
  };

  useEffect(() => {
    if (props.workerUpdated === "new_worker") {
      setSnackText("User Already Exists, Worker Added To Your Company");
    } else if (props.workerUpdated === "new_worker_user") {
      setSnackText("New Worker added to your company");
    } else if (props.workerUpdated === "update_worker") {
      setSnackText("Worker Already Exists, Worker Role Updated");
      setSnackSeverity("warning");
    }
  }, [props.workerUpdated]);

  const emailValidator = (event) => {
    // need to validate if worker is archived
    const email = event.target.value;
    const company = user.company;
    ax.post(process.env.API_URL + "/api/workers/exists/", {
      email,
      company,
    }).then((res) => {
      if (res.data.archived) {
        setSnackValue(true);
        setSnackText("This worker is archived.");
        setSnackSeverity("warning");
        setUserArchived(true);
        setWorkerId(res.data.worker_id);
        setWorkerRole(res.data.worker_role);
        setTimeout(() => {
          setOpenArchivedDialog(true);
        }, 1000);
      } else {
        setUserArchived(false);
        fetch(process.env.API_URL + "/api/register/exists", {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({ email: email }),
        })
          .then((res) => res.json())
          .then((e) => {
            if (e.exists) {
              setSnackValue(true);
              setSnackText("An account already exists with this email.");
              setSnackSeverity("warning");
              setEmailExists(true);
              setTimeout(() => {
                setOpenAssignDialog(true);
              }, 1000);
            } else {
              setEmailExists(false);
            }
          });
      }
    });
  };

  const handleSubmit = () => {
    var pattern = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

    if (state.first_name.length === 0) {
      return setError({ ...error, ["first_name"]: true });
    }
    if (state.last_name.length === 0) {
      return setError({ ...error, ["last_name"]: true });
    }
    if (state.email.length === 0 || !pattern.test(state.email)) {
      return setError({ ...error, ["email"]: true });
    }
    if (isNaN(state.phone.length) || state.phone.length <= 9) {
      return setError({ ...error, ["phone"]: true });
    }

    // validating the password with only numbers and 10 digits which is the length of a phone number
    const passwordRegex = /^(?=.*[0-9])(?=.{10,})/;
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/;

    if (state.password.length === 0 || !passwordRegex.test(state.password)) {
      return setError({ ...error, ["password"]: true });
    }

    if (!state.role) {
      return setError({ ...error, ["role"]: true });
    }

    //second push just to check

    setCreatingUser(true);

    /**
     * worker.firstName,
          worker.lastName,
          worker.email,
          worker.phoneNumber,
          worker.company,
          worker.password,
          worker.isManager,
     */

    const worker = {
      firstName: state.first_name,
      lastName: state.last_name,
      email: state.email,
      phoneNumber: state.phone,
      company: props.current_company,
      password: state.password,
      isManager: state.is_manager,
      role: state.role,
    };

    props
      .createWorker(worker)
      .then((res) => {
        setCreatingUser(false);
        setSnackValue(true);
        setTimeout(handleClose, 3000);
        props.handleNewWorkerCreated();
      })
      .catch((err) => {
        setCreatingUser(false);
        //setTimeout(handleClose, 1000);
        console.log(err.response);
        if (err.response) {
          if (
            err.response.data["email"] &&
            err.response.data["email"].length > 0
          ) {
            setRequestError(err.response.data["email"][0]);
          }
        }
      });
  };

  const handleClose = () => {
    setRequestError("");
    closeSnackBar();
    setState({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      gps_forced: false,
      is_manager: false,
      role: "",
    });
    setError({
      first_name: false,
      last_name: false,
      email: false,
      phone: false,
      password: false,
    });
    setNewWorkerRole("");
    setOpen(false);
  };
  const styles = {
    errorStyle: {
      textAlign: "center",
      color: "red",
      textTransform: "uppercase",
    },
    spanStyle: {
      cursor: "pointer",
    },
    checkBoxContainer: {
      textAlign: "center",
    },
    checkBoxLabel: { color: "black" },
  };

  const handleCloseArchiveDialog = () => {
    setOpenArchivedDialog(false);
  };

  const handleReactivateWorker = () => {
    props
      .reactivateWorker(workerId, workerRole)
      .then(() => {
        setSnackValue(true);
        setSnackText("Worker unarchived");
        setSnackSeverity("success");
        setTimeout(() => {
          setOpenArchivedDialog(false);
        }, 1000);
      })
      .catch((err) => {
        setSnackValue(true);
        setSnackText("Couldn't unarchive worker. Please, try again");
        setSnackSeverity("error");
      });
  };

  const ArchiveDialog = () => {
    return (
      <Dialog
        open={openArchivedDialog}
        onClose={handleCloseArchiveDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogBody}>
          Unarchive worker?
        </DialogTitle>
        <DialogActions className={classes.dialogBody}>
          <Button
            onClick={() => setOpenArchivedDialog(false)}
            style={{ color: "var(--trade-primary)" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleReactivateWorker()}
            style={{ color: "var(--trade-primary)" }}
          >
            Unarchive
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <span onClick={handleClickOpen} style={styles.spanStyle}>
        <strong>New Worker</strong>
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <ArchiveDialog />
        <AssignWorkerFromDashboard
          openAssignDialog={openAssignDialog}
          setOpenAssignDialog={setOpenAssignDialog}
          showButton={false}
          type={"assign-dialog"}
        />
        <DialogTitle id="form-dialog-title">Add Worker</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            onBlur={(e) => emailValidator(e)}
            value={state.email}
            error={error.email}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            helperText={"example: john.smith@email.com"}
          />
          <Box my={2} />
          <TextField
            id="outlined-basic"
            label="First Name"
            name="first_name"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            value={state.first_name}
            error={error.first_name}
          />
          <Box my={2} />
          <TextField
            id="outlined-basic"
            label="Last Name"
            name="last_name"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            value={state.last_name}
            error={error.last_name}
          />
          <Box my={2} />
          <TextField
            id="outlined-basic"
            label="Phone Number"
            name="phone"
            variant="outlined"
            fullWidth
            onChange={(e) => handleChange(e)}
            value={state.phone}
            error={error.phone}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            helperText="example: 1234567890"
          />
          <Box my={2} />
          <FormControl className={clsx(classes.textField)} variant="outlined">
            {" "}
            <InputLabel htmlFor="outlined-adornment-password">
              Temporary Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={state.password}
              error={error.password}
              onChange={(e) => handleChange(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
            />
          </FormControl>

          <Box my={2} />
          <div style={styles.errorStyle}>{requestError}</div>
          <div style={{ textAlign: "center", margin: "auto" }}>
            {creatingUser ? (
              <Spinner animation="border" variant="warning" />
            ) : (
              ""
            )}
          </div>
          <div className="new-worker-role-select">
            {user.role === "OWNER" || user.role === "MANAGER" ? (
              <FormControl id="role-select" error={error.role}>
                <InputLabel id="role-select-label">Role:</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  value={newWorkerRole}
                  onChange={handlePermissionSelect}
                >
                  {user.role === "OWNER" ? (
                    <MenuItem value="OWNER">Owner</MenuItem>
                  ) : null}
                  <MenuItem value="MANAGER">Manager</MenuItem>
                  <MenuItem value="WORKER">Worker</MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Create
          </Button>
        </DialogActions>
        <div className={classes.root}>
          <Snackbar
            open={snackValue}
            autoHideDuration={6000}
            onClose={closeSnackBar}
          >
            <Alert onClose={closeSnackBar} severity={snackSeverity}>
              {snackText}
            </Alert>
          </Snackbar>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    item: state.workers.item,
    user: state.auth.user,
    safety_meetings: state.safety_meetings.items_by_worker,
    current_company: state.companies.current_company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createWorker: (worker) => {
      return dispatch(
        workers.createWorker(
          worker.firstName,
          worker.lastName,
          worker.email,
          worker.phoneNumber,
          worker.company,
          worker.password,
          worker.isManager,
          worker.role
        )
      );
    },
    reactivateWorker: (worker_id, worker_role) => {
      return dispatch(workers.reactivateWorker(worker_id, worker_role));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
