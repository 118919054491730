const initialState = [
];


export default function users(state=initialState, action) {
    let userList = state.slice();

    switch (action.type) {
        case 'ADD_USER':
            return [...state, {text: action.text}];

        case 'UPDATE_USER':
            let userToUpdate = userList[action.id];
            userToUpdate.text = action.text;
            userList.splice(action.id, 1, userToUpdate);
            return userList;

        case 'DELETE_USER':
            userList.splice(action.id, 1);
            return userList;
        case "LOGOUT_SUCCESSFUL":
            return [];
        default:
            return state;
    }
}
