import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Gravatar from "react-gravatar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import WorkerEditTimeLog from "../WorkerEditTimeLog/WorkerEditTimeLog";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { time_logs } from "actions";

var moment = require("moment");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  drawerBody: {
    width: 950,
    backgroundColor: "#000000",
    minHeight: "100vh",
  },
  subheader: {
    //marginRight: "0.5rem",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  bodyText: {
    fontSize: "1rem",
    color: "var(--trade-font)",
  },
  centeredText: {
    margin: "auto",
  },
  drawerheader: {
    textaAlign: "left",
  },
  avatarContainer: {
    webkitBoxShadow: "0px 0px 17px 3px #000000",
    boxShadow: "0px 0px 17px 3px #000000",
    //margin: "1rem",
  },
  title: {
    fontSize: "1.5rem",
    // fontFamily: "Noto Sans JP, sans-serif",
  },
  warningMessage: {
    color: "#ffff",
    fontSize: "1.5rem",
    //fontFamily: "Noto Sans JP, sans-serif",
    margin: "0px 25px 15px 25px",
  },
  fileList: {
    margin: "0px 10px",
    maxHeight: "200px",
    overflowY: "auto",
  },
  listItem: {
    //border: "1px solid #000000",
    borderRadius: "4px",
    backgroundColor: "#000000",
    marginBottom: "1rem",
    padding: "0.5rem",
    height: "fit-conetent",
  },
  textContainer: {
    textAlign: "center",
    margin: "auto",
  },
  mainContainer: {
    backgroundColor: "#000000",
    padding: "2rem",
    width: "100%",
  },
  headers: {
    margin: "auto 2rem",
  },
  topContainer: {
    marginBottom: "2rem",
  },
}));

function DailyWorkerLogs(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const openDrawer = () => {
    console.log(props.logs);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const approveTimeSheet = (timelog_id) => {
    props.approveTimeLog(timelog_id).then((response) => {
      if (response !== undefined) {
        setSnackType("success");
        setSnackMessage("Approved!");
        setSnackOpen(true);
      } else {
        setSnackType("error");
        setSnackMessage("Couldn't approve");
        setSnackOpen(true);
      }
    });
  };

  const calculateDuration = (start, end) => {
    const x = moment
      .duration(
        moment(end, "DD/MM/YYYY HH:mm:ss").diff(
          moment(start, "DD/MM/YYYY HH:mm:ss")
        )
      )
      .format("h [hrs] m [min]");
    return x;
  };

  const TimelogRow = (timelog) => {
    const [approved, setApproved] = useState(false);
    return (
      <Grid
        container
        item
        md={12}
        key={timelog.id}
        className={classes.listItem}
      >
        <Grid item md={2}>
          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
            className={classes.itemBox}
          >
            <WhiteTextTypography>{timelog.project.title}</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
            className={classes.itemBox}
          >
            <WhiteTextTypography>
              {timelog.task_type ? timelog.task_type.title : timelog.type}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
            className={classes.itemBox}
          >
            <WhiteTextTypography>
              {moment(timelog.start).utc().local().format("LT")}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
            className={classes.itemBox}
          >
            <WhiteTextTypography>
              {moment(timelog.end)._isValid
                ? moment(timelog.end).utc().local().format("LT")
                : "On Site"}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignContent="center"
            className={classes.itemBox}
          >
            <WhiteTextTypography>
              {moment(timelog.end)._isValid
                ? calculateDuration(timelog.start, timelog.end)
                : "On Site"}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          {timelog.locked || approved ? (
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              className={classes.itemBox}
            >
              <WhiteTextTypography>Approved</WhiteTextTypography>
            </Box>
          ) : moment(timelog.end)._isValid ? (
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              className={classes.itemBox}
            >
              <Button
                variant="contained"
                style={{
                  color: " white",
                  backgroundColor: "green",
                  height: "fit-content",
                }}
                size="small"
                onClick={() => {
                  approveTimeSheet(timelog.id);
                  setApproved(true);
                }}
              >
                Yes
              </Button>
              &nbsp;
              <WorkerEditTimeLog worker_info={timelog} />
            </Box>
          ) : (
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
              className={classes.itemBox}
            >
              <WhiteTextTypography>On Site</WhiteTextTypography>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };

  const mainContent = () => {
    return (
      <div className={classes.drawerBody}>
        <Grid container item md={12} className={classes.mainContainer}>
          {/*Title Section*/}
          <Grid container item md={12} className={classes.topContainer}>
            <Grid item md={3}></Grid>
            <Grid container item md={6}>
              <Grid item md={4}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  {props.user_avatar ? (
                    <Avatar
                      //variant="square"
                      src={props.user_avatar}
                      style={{ width: "60%", height: "60%", margin: "auto" }}
                    />
                  ) : (
                    <Gravatar
                      email={props.user_email}
                      size={40}
                      style={{ borderRadius: "10%" }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid container item md={8}>
                <Grid item md={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography variant="h5">
                      {props.user_name}'s Time Logs
                    </WhiteTextTypography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <WhiteTextTypography>
                      {props.logs && props.logs.length > 0
                        ? moment(props.logs[0].start).format("Do MMMM YYYY")
                        : null}
                    </WhiteTextTypography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
          <Grid container item md={12}>
            {/*
                        project 
                        task
                        time in
                        time out
                        duration
                        gps
                        approval
                        */}
            <Grid container item md={12}>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">
                    Project
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">Task</WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">
                    Time In
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">
                    Time Out
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">
                    Duration
                  </WhiteTextTypography>
                </Box>
              </Grid>
              <Grid item md={2}>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <WhiteTextTypography variant="h6">
                    Approve
                  </WhiteTextTypography>
                </Box>
              </Grid>
            </Grid>
            {props.logs.map((elem) => TimelogRow(elem))}
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <React.Fragment>
        <WhiteTextTypography onClick={openDrawer}>
          <ChevronRightIcon />
        </WhiteTextTypography>
        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          {mainContent()}
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => setSnackOpen(false)}
          >
            <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
              {snackMessage}
            </Alert>
          </Snackbar>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveTimeLog: (timelog_id) => {
      return dispatch(time_logs.approveTimeLog(timelog_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyWorkerLogs);
