/* global google */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import onSiteStyle from "../../components/Dashboard/OnSiteStyle";
import Moment from "react-moment";
import { getGravatarUrl } from "components/Dashboard/Map";
import { compose } from "recompose";
import Spinner from "react-bootstrap/Spinner";
const { withScriptjs, withGoogleMap, GoogleMap } = require("react-google-maps");

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const markerStyling = {
  display: "none",
};

export const OwnGoogleMap = compose(
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    disableDefaultUI={true}
    defaultZoom={12}
    center={{
      lat: parseFloat(props.latitude),
      lng: parseFloat(props.longitude),
    }}
    defaultOptions={{
      scrollwheel: false,
      disableDefaultUI: false,
      draggable: true,
    }}
  >
    <MarkerWithLabel
      position={{
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude),
      }}
      labelAnchor={new google.maps.Point(0, 0)}
      labelStyle={markerStyling}
      icon={{
        url: props.avatar || getGravatarUrl(props.email),
        scaledSize: new google.maps.Size(32, 32),
      }}
      visible={true}
    >
      <span></span>
    </MarkerWithLabel>
  </GoogleMap>
));

class LastGPSLocation extends React.Component {
  state = {
    currentDateTime: Date().toLocaleString(),
  };

  render() {
    const { user, latitude, longitude, worker, worker_gps, project_gps } =
      this.props;
    const { currentDateTime } = this.state;

    return (
      <div>
        {latitude !== null || longitude !== null ? (
          worker === undefined ? (
            worker_gps !== undefined ? (
              <React.Fragment>
                <OwnGoogleMap
                  latitude={latitude}
                  longitude={longitude}
                  email={worker_gps.user_email}
                  avatar={worker_gps.user_avatar}
                  googleMapURL={process.env.GOOGLE_MAPS_URL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `200px`,
                        width: `40vw`,
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    />
                  }
                />
              </React.Fragment>
            ) : project_gps !== undefined ? (
              <React.Fragment>
                <OwnGoogleMap
                  latitude={project_gps.latitude}
                  longitude={project_gps.longitude}
                  // email={user.email}
                  avatar={project_gps.company_logo}
                  googleMapURL={process.env.GOOGLE_MAPS_URL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `200px`,
                        width: `51vw`,
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    />
                  }
                />

                <span style={{ color: "white" }}>
                  {/* Last update sent : <Moment fromNow>{gpsUpdate["time"]}</Moment> */}
                  Last update sent : <Moment fromNow>{currentDateTime}</Moment>
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <OwnGoogleMap
                  latitude={latitude}
                  longitude={longitude}
                  email={user.email}
                  avatar={user.avatar}
                  googleMapURL={process.env.GOOGLE_MAPS_URL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: `15vh`,
                        width: `60vw`,
                        borderRadius: "6px",
                        overflow: "hidden",
                      }}
                    />
                  }
                />

                {/* <span style={{ color: "white" }}>
                  Last update sent : <Moment fromNow>{currentDateTime}</Moment>
                </span> */}
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <OwnGoogleMap
                latitude={latitude}
                longitude={longitude}
                email={worker.email}
                avatar={worker.avatar}
                googleMapURL={process.env.GOOGLE_MAPS_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `200px`,
                      width: `1000px`,
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  />
                }
              />
            </React.Fragment>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
              height: "15vh",
            }}
          >
            <Spinner
              animation="border"
              variant="warning"
              style={{ height: "4rem", width: "4rem" }}
            />
            <span style={{ color: "white" }}>
              Please Wait while Current Position Loads
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gpsUpdate: state.auth.gpsUpdate,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(onSiteStyle)(LastGPSLocation));
