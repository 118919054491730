// ##############################
// // // LockScreenPage view styles
// #############################

import {
    cardTitle,
    container
} from "assets/jss/material-dashboard-pro-react.jsx";

const lockScreenPageStyle = theme => ({
    cardTitle,
    hidden: {
        display: "none"
    },
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    customSnackbar: {
        borderRadius: "0"
    },
    customCardClass: {
        boxShadow: "none",
        width: "350px",
        margin: "60px auto 0",
        color: "#FFFFFF",
        display: "block",
        transform: "translate3d(0, 0, 0)",
        transition: "all 300ms linear",
        paddingTop: "30px"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardAvatar: {
        maxWidth: "90px",
        maxHeight: "90px",
        marginTop: "-75px",
    },
    transparentCard: {
        background: "none",
        "& input, & svg, & h4": {
            color: "white"
        }
    },
    customCardFooterClass: {
        border: "none",
        paddingTop: "0"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    justifyContentLeft: {
        width: "50%",
        justifyContent: "left !important"
    },
    justifyContentRight: {
        width: "50%",
        justifyContent: "right !important"
    }
});

export default lockScreenPageStyle;
