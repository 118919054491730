import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import { safety_meetings } from "actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArchiveIcon from "@material-ui/icons/Archive";
import SafetyMeetingSlider from "newComponents/SafetyMeetingSlider/SafetyMeetingSlider";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-background)",
    marginBottom: "1rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  largerText: {
    fontSize: "1.2rem",
  },
  centeredText: {
    margin: "auto",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  buttonColorArchive: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    color: "var(--trade-tertiary)",
  },

}));

function SafetyMeetingLibrary(props) {
  const classes = useStyles();
  //const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    props.getSafetyMeetings();
  }, []);

  const titleSection = () => (
    <Grid container>
      <Grid item md={12}>
        <WhiteTextTypography variant="h4"> Safety Meetings</WhiteTextTypography>
      </Grid>
      <Grid item md={12}>
        <WhiteTextTypography>
          All Safety Meetings are listed below and can be filtered
        </WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const archiveMeeting = (meeting_id) => {
    props.archiveMeeting(meeting_id);
  };

  const tableRow = (safety_meeting) => {
    return (
      <Grid
        container
        item
        md={12}
        className={classes.tableRow}
        key={safety_meeting.id}
      >
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <AmberTypography className={classes.centeredText}>
              {safety_meeting.title} {safety_meeting.is_active}
            </AmberTypography>
          </Box>
        </Grid>

        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={classes.centeredText}>
              {safety_meeting.code}
            </WhiteTextTypography>
          </Box>
        </Grid>

        <Grid item md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <AmberTypography className={classes.centeredText}>
              {safety_meeting.task_title}
            </AmberTypography>
          </Box>
        </Grid>

        {props.user.role !== "WORKER" ? (
          <Grid container item md={2}>
            <Grid item md={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                height="100%"
              >
                <Box component="span" className={classes.centeredText}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="subtitle1">
                          {" "}
                          Edit Safety Meeting{" "}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <WhiteTextTypography>
                      <Button
                        className={classes.iconButton}
                        component={Link}
                        to={"/safety-meetings/" + safety_meeting.id}
                        justIcon
                      >
                        <EditIcon />
                      </Button>
                    </WhiteTextTypography>
                  </HtmlTooltip>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                height="100%"
              >
                <Box component="span" className={classes.centeredText}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="subtitle1">
                          {" "}
                          Archive Safety Meeting{" "}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <WhiteTextTypography id={safety_meeting.id}>
                      <Button
                        className={classes.buttonColorArchive}
                        justIcon
                        id={safety_meeting.id}
                        onClick={() => archiveMeeting(safety_meeting.id)}
                      >
                        <ArchiveIcon id={safety_meeting.id} />
                      </Button>
                    </WhiteTextTypography>
                  </HtmlTooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Box component="span" className={classes.centeredText}>
              <SafetyMeetingSlider meeting={safety_meeting} whiteIcon={true} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const tableSection = () => (
    <Grid container>
      <Grid container item md={12} className="myclass">
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Title</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Meeting Code</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Task</WhiteTextTypography>
          </Box>
        </Grid>
        {props.user.role !== "WORKER" ? (
          <Grid container item md={2}>
            <Grid item md={6}>
              <Box
                m={2}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <WhiteTextTypography> Edit </WhiteTextTypography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                m={2}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <WhiteTextTypography>Archive</WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Details</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item md={12}>
        {props.safety_meetings
          .filter((elem) => elem.is_active)
          .map((elem) => tableRow(elem))}
      </Grid>
    </Grid>
  );

  return (
    <div
      style={{
        backgroundColor: "var(--trade-background)",
        minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "2rem",
      }}
    >
      {titleSection()}
      {tableSection()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    safety_meetings: state.safety_meetings.items_info,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSafetyMeetings: () => {
      return dispatch(safety_meetings.fetchSafetyMeetingInfos());
    },
    archiveMeeting: (meetingId) => {
      return dispatch(safety_meetings.archiveMeeting(meetingId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafetyMeetingLibrary);
