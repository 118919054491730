import moment from "moment";
const initialState = {
  items: [],
  current_project_items: [],
  item: {},
  items_ordered_by_company: {},
  items_ordered_by_worker: {},
  worker_time_sheet: {},
  count: 0,
  next: null,
  prev: null,
  loading: true,
};

function orderByDateProject(logs) {
  let ordered_logs = {};

  logs.forEach((log) => {
    const start = moment(log.start);
    const start_formatted = start.format("YYYY-MM-DD");
    if (ordered_logs[start_formatted] === undefined) {
      ordered_logs[start_formatted] = {};
    }
    if (ordered_logs[start_formatted][log.project.id] === undefined) {
      ordered_logs[start_formatted][log.project.id] = [];
    }

    ordered_logs[start_formatted][log.project.id].push(log);
  });
  return ordered_logs;
}

function orderByWorker(logs) {
  let ordered_logs = {};
  logs.forEach((log) => {
    const start = moment(log.start);
    const start_formatted = start.format("YYYY-MM-DD");
    if (ordered_logs[start_formatted] === undefined) {
      ordered_logs[start_formatted] = {};
    }
    if (ordered_logs[start_formatted][log.user] === undefined) {
      ordered_logs[start_formatted][log.user] = [];
    }

    ordered_logs[start_formatted][log.user].push(log);
  });
  return ordered_logs;
}

function orderByCompany(logs) {
  /*
    Returns the log in this format:
    logs{site_id} = [
        {start_date} = [ log1, log2}
    }
     */
  let ordered_logs = {};
  logs.forEach((log) => {
    if (ordered_logs[log.project.id] === undefined) {
      ordered_logs[log.project.id] = [];
    }
    ordered_logs[log.project.id].push(log);
  });
  return ordered_logs;
}

let items = [];
export default function time_logs(state = initialState, action) {
  switch (action.type) {
    case "FETCH_TIME_LOGS":
      // used by old components
      items = action.items.map((log) => {
        log.start = moment(log.start);
        log.end = moment(log.end);
        return log;
      });
      return {
        items: items,
        items_ordered: orderByDateProject(action.items),
        count: action.count,
        items_ordered_by_worker: orderByWorker(action.items),
        items_ordered_by_company: orderByCompany(action.items),
      };
    
    case "FETCH_WORKER_TIME_LOGS": 
      // not sure if it's useful (used by search dashboard and workertimesheetpage)
      items = action.items.map((log) => {
        log.start = moment(log.start);
        log.end = moment(log.end);
        return log;
      });

      return {
        ...state,
        worker_time_sheet: orderByWorker(action.items),
      };
    
    case "FETCH_PROJECT_TIME_LOGS":
      // used by tabpane in dashboard
      items = action.items.map((log) => {
        log.start = moment(log.start);
        log.end = moment(log.end);
        return log;
      });

      return {
        ...state, 
        current_project_items: items,
        count: action.count,
        next: action.next,
        prev: action.prev,
        loading: false,
    }

    case "APPEND_PROJECT_TIME_LOGS":
      items = action.items.map((log) => {
        log.start = moment(log.start);
        log.end = moment(log.end);
        return log;
      });

      return {
        ...state,
        current_project_items: [...state.current_project_items, ...items],
        count: action.count,
        next: action.next,
        prev: action.prev,
      }
    case "GET_TIME_LOG":
      // used by start own timelog, stop own timelog, start break and start delivery
      items = state.current_project_items.map((log) => {
        if (action.item.id === log.id) {
          log.start = moment(action.item.start);
          log.end = moment(action.item.end);
          log.locked = action.item.locked;
        }
        return log;
      });
      return { ...state, item: action.item, current_project_items: items };
    case "GET_WORKER_TIME_LOG":
      items = state.current_project_items.map((log) => {
        if (action.item.id === log.id) {
          log.start = moment(action.item.start);
          log.end = moment(action.item.end);
          log.locked = action.item.locked;
        }
        return log;
      });
      return { ...state, current_project_items: items };
    case "LOGOUT_SUCCESSFUL":
      return {
        items: [],
        current_project_items: [],
        item: {},
        items_ordered_by_company: {},
        items_ordered_by_worker: {},
        worker_time_sheet: {},
        count: 0,
      }
    default:
      return state;
  }
}
