import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Spinner from "react-bootstrap/Spinner";
import NotesTimeline from "../NotesTimeline/NotesTimeline";
import { makeStyles } from "@material-ui/core/styles";

import "./WorkerTabPane.css";

import { ax } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "var(--trade-body)",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

const SimpleListWorker = (props) => {
  const { worker_id } = props;
  const [totalItems, setTotalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const classes = useStyles();

  const fetchData = () => {
    const endpoint = "/api/notes/?worker_id=" + worker_id + "&limit=10";
    ax.get(endpoint)
      .then((res) => {
        setTotalItems(res.data.results);
        setNext(res.data.next);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchMoreData = async () => {
    ax.get(next)
      .then((res) => {
        console.log(res.data.results);
        setTotalItems([...totalItems, ...res.data.results]);
        setNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const LoadMoreButton = () => {
    return next ? (
      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        style={{ padding: "0.8rem 1.1rem" }}
        onClick={fetchMoreData}
      >
        Load More
      </Button>
    ) : null;
  };

  return (
    <div className="projectTimeSheetWrapper" id="style-5">
      <div
        style={{
          width: "50vw",
          backgroundColor: "var(--trade-body)",
          textAlign: "center",
        }}
      >
        {loading ? (
          <div className="spinnerContainer">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : totalItems.length > 0 ? (
          <NotesTimeline item={totalItems} type="notes" />
        ) : (
          <div className="warningMessage">
            <span>No Notes for the above period.</span>
          </div>
        )}
      </div>
      {next ? <LoadMoreButton /> : null}
    </div>
  );
};

export default SimpleListWorker;
