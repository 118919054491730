import React, { useState, useEffect } from "react";
// import "./FilePane.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//import Spinner from "react-bootstrap/Spinner";
import SideDrawer from "../../newComponents/SideDrawer/SideDrawer";

var moment = require("moment"); // require
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SimpleList = (props) => {
  const [monthList, setMonthList] = useState([]);
  const { currentYear } = props;

  useEffect(() => {
    var temps = [];
    const today = new Date();
    if (currentYear === today.getFullYear()) {
      const currentMonth = today.getMonth();
      for (let i = currentMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    } else {
      const lastMonth = 11;
      for (let i = lastMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    }

    // props.gpsEntries.forEach((item) => {
    //   const month = moment(item.date).format("MMMM YYYY");
    //   if (!monthsList.includes(month)) {
    //     monthsList.push(month);
    //   }
    // })
    setMonthList(temps);
  }, [currentYear, props.currentProject]);

  return (
    <div className="projectTimeSheetWrapper" id="style-5">
      {monthList.length !== 0 ? (
        monthList.map((item, key) => (
          <SideDrawer
            key={key}
            item={item}
            type={"gps-entries"}
            lastMonth={monthList.length === key + 1}
          />
        ))
      ) : (
        <span className="files-message">
          No GPS Records Available for this Project.
        </span>
      )}
    </div>
  );
};

SimpleList.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    gpsEntries: state.user_positions.current_project_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleList);
