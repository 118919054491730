import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Moment from "react-moment";

// theme components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Timeline from "components/Timeline/Timeline";
import Pagination from "components/Pagination/Pagination";

import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";

// custom code
import { notes } from "actions";
import projectPageStyle from "assets/jss/tradespecifix/project";
import Tooltip from "@material-ui/core/Tooltip";


class ProjectNotesList extends Component {
    state = {
        projectId: null,
        page: 0,
        limit: 10
    };

    constructor(props) {
        super(props);
        const itemId = this.props.match.params.project_id;
        const page = this.props.match.params.page;
        if (page !== undefined) {
            this.state.page = page;
        }
        if (itemId !== undefined) {
            this.props.fetchNotes(itemId, this.state.limit, this.state.page);
            this.state.projectId = itemId;
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.page !== this.props.match.params.page) {
            this.setState({ page: nextProps.match.params.page });
            const itemId = this.props.match.params.project_id;
            this.props.fetchNotes(itemId, this.state.limit, this.state.page);
        }

    }

    getNoteBody(note) {
        return (
            <React.Fragment>
                <p dangerouslySetInnerHTML={{ __html: note.description }} />
                {/* {note.images.map((file, index) => <img src={file.thumbnail ? file.thumbnail : file.file} className={this.props.classes.images} alt={"Note"} key={index} style={{ width: "100%" }} />)} */}
                {note.images.map((file, index) => <img src={file.file} className={this.props.classes.images} alt={"Note"} key={index} style={{ width: "100%" }} />)}
            </React.Fragment>
        )
    }

    getFooterTitle(note) {
        const { is_manager, history, classes, fetchNotes } = this.props;
        const { page, limit, projectId } = this.state;

        if (is_manager)
            return (
                <GridContainer>
                    <GridItem md={6}>
                        <Moment format={"dddd MMM D HH:mm:ss"}>{note.date}</Moment>
                    </GridItem>
                    <GridItem md={6}>
                        <GridContainer className={classes.justifyEnd}>
                            <Tooltip title="Edit">
                                <Button round color="info" onClick={() => history.push('/notes/' + note.id)} className={classes.firstButton} justIcon><EditIcon /></Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button round color="danger" onClick={() => this.props.deleteNote(note.id).then(() => fetchNotes(projectId, limit, page))} className={classes.lastButton} justIcon><TrashIcon /></Button>
                            </Tooltip>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            );
        else
            return (
                <Moment format={"dddd MMM D HH:mm:ss"}>{note.date}</Moment>
            );
    }

    changePage(newPage) {
        this.setState({ page: newPage - 1 }, () => {
            if (this.state.page === 0)
                this.props.history.push('/projects/' + this.state.projectId + '/notes/');
            else
                this.props.history.push('/projects/' + this.state.projectId + '/notes/' + this.state.page);
        })
    }

    renderPagination() {
        const { page, limit } = this.state;
        const { count } = this.props;
        const maxPages = Math.ceil(count / limit);
        let pages = [];
        for (let i = 1; i <= maxPages; i++) {
            let item = {
                text: i,
                onClick: () => this.changePage(i)
            };
            if (i === parseInt(page) + 1) {
                item['active'] = true
            }
            pages.push(item);
        }
        return (
            <Pagination
                pages={pages}
                color="info"
            />
        );
    }

    render() {
        const { notes, classes } = this.props;
        const { projectId } = this.state;
        if (projectId === undefined || projectId === null)
            return null;


        const time_line_data = notes.map((note, index) => {
            return {
                body: this.getNoteBody(note),
                footerTitle: this.getFooterTitle(note),
                inverted: index % 2,
                avatar_url: note.user_avatar,
                email: note.user_email,
            };
        });

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Timeline stories={time_line_data} />
                    <GridContainer className={classes.alignItemsCenter}>
                        <GridItem md={3} sm={12}>
                            <Button round color="info" onClick={() => this.props.history.goBack()}>Back</Button>
                        </GridItem>
                        <GridItem md={9} sm={12}>
                            <GridContainer className={classes.justifyEnd}>
                                {this.renderPagination()}
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        )
    }
}

ProjectNotesList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    let is_manager = state.auth.user.is_manager;
    if (is_manager === false && state.auth.user.is_staff)
        is_manager = true;

    return {
        notes: state.notes.items,
        is_manager: is_manager,
        count: state.notes.count,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchNotes: (project_id, limit, page) => {
            return dispatch(notes.fetchNotesByProjectId(project_id, limit, page));
        },
        deleteNote: (note_id) => {
            return dispatch(notes.deleteNote(note_id))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(projectPageStyle)(ProjectNotesList));
