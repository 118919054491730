import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { workers } from "actions";
import TablePagination from "@material-ui/core/TablePagination";
import { Avatar } from "@material-ui/core";
import Gravatar from "react-gravatar";
import SideDrawerWorker from "../SideDrawerWorker/SideDrawerWorker";
import RestoreIcon from "@material-ui/icons/Restore";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./WorkerLibrary.css";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

var moment = require("moment"); // require

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderColor: "rgba(80, 80, 80, 1)",
    },
    backgroundColor: "var(--trade-background)",
  },
});
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const WarningTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "2rem",
  },
})(Typography);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "var(--trade-background)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

function Row(props) {
  const { row } = props;
  const classes = useStyles();

  const styles = {
    tableCell: {
      color: "var(--trade-primary)",
      fontWeight: "bold",
    },
    buttonColor: {
      color: "white",
    },
    amberColor: {
      color: "var(--trade-primary)",
    },
    tableBorder: {
      borderTop: "solid 2px var(--trade-neutral)",
      borderBottom: "solid 2px var(--trade-neutral)",
    },
    tableAvatar: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={styles.tableBorder}
        // onClick={() => setOpen(!open)}
      >
        <TableCell style={styles.tableCell} align="left">
          <Grid container item md={8}>
            <Grid item md={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                {row.user.avatar ? (
                  <Avatar src={row.user.avatar} />
                ) : (
                  <Gravatar
                    email={row.user.email}
                    avatar_url={row.user.avatar}
                    size={35}
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item md={10}>
              <Box m={2}>
                <WhiteTextTypography>
                  {row.user.first_name + " " + row.user.last_name}
                </WhiteTextTypography>
              </Box>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center" style={styles.tableCell}>
          <AmberTypography>
            {moment(row.user.date_joined).format("Do MMMM YYYY")}
          </AmberTypography>
        </TableCell>
        <TableCell align="center" style={styles.tableCell}>
          <WhiteTextTypography style={{ textTransform: "lowercase" }}>
            {row.user.email}
          </WhiteTextTypography>
        </TableCell>
        {/* <TableCell align="center" style={styles.tableCell}>
          <AmberTypography>
             {row.tasks_types.map((item, key) => (key ? ", " : "") + item.title)} 
          </AmberTypography>
        </TableCell> */}
        <TableCell align="center" style={styles.tableCell}>
          <AmberTypography style={{ color: "red" }}>ARCHIVED</AmberTypography>
        </TableCell>
        {/* <TableCell align="center" style={styles.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            component={Link}
            to={"/workers/" + row.id}
            title="Edit Worker Details"
          >
            <CreateIcon style={styles.amberColor} />
          </IconButton>
        </TableCell> */}
        <TableCell align="center" style={styles.tableCell}>
          {" "}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => props.reactivateWorker(row.id, row.role)}
            title="Re-Activate Worker"
          >
            <RestoreIcon style={styles.buttonColor} />
          </IconButton>{" "}
        </TableCell>
        <TableCell align="center" style={styles.tableCell}>
          {" "}
          <SideDrawerWorker worker_id={row.id} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable(props) {
  useEffect(() => {
    props.fetchWorkers().then((res) => {
      //console.log(res);
    });
  }, [props.fetchWorkers]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const { workers } = props;
  const reactivateWorker = (id, role) => {
    props.reactivateWorker(id, role).then((res) => {
      if (res.type === "GET_WORKER") {
        showSuccessSnack();
        props.fetchWorkers();
      } else {
        showErrorSnack();
      }
    });
  };
  const styles = {
    tableStyles: { width: "99%", maxHeight: "80vh" },

    tableHead: {
      color: "white",
    },

    tablePagination: {
      backgroundColor: "var(--trade-background)",
      color: "white",
    },
  };
  const titleSection = () => (
    <Grid container style={{ padding: "2% 0 0 3%" }}>
      <Grid item md={12}>
        <WhiteTextTypography variant="h4">Archived Workers</WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const showSuccessSnack = () => {
    setSnackType("success");
    setSnackMessage("Worker Reactivated");
    setSnackOpen(true);
  };

  const showErrorSnack = () => {
    setSnackType("error");
    setSnackMessage("Worker couldn't be reactivated, try again later.");
    setSnackOpen(true);
  };

  const archivedWorkers = workers
    .filter((item) => item.archived)
    .sort((a, b) => {
      if (a.user.first_name < b.user.first_name) {
        return -1;
      }
      if (a.user.first_name > b.user.first_name) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="worker-list-container">
      {titleSection()}
      {archivedWorkers.length ? (
        <div className="worker-list-table-container">
          <TableContainer
            component={Paper}
            style={styles.tableStyles}
            id="style-5"
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="center">Date Added</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  {/* <StyledTableCell align="center">TRADE</StyledTableCell> */}
                  <StyledTableCell align="center">Status</StyledTableCell>
                  {/* <StyledTableCell align="center">EDIT</StyledTableCell> */}
                  <StyledTableCell align="center">Re-Activate</StyledTableCell>
                  <StyledTableCell align="center">Details</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {archivedWorkers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, key) => (
                    <Row
                      key={key}
                      row={item}
                      reactivateWorker={reactivateWorker}
                      showSuccessSnack={showSuccessSnack}
                      showErrorSnack={showErrorSnack}
                    />
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              style={styles.tablePagination}
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={archivedWorkers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <WarningTextTypography>No Archived Workers</WarningTextTypography>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    workers: state.workers.items,
    count: state.workers.count,
    isStaff: state.auth.user.is_staff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorkers: () => {
      return dispatch(workers.fetchWorkers());
    },

    reactivateWorker: (worker_id, worker_role) => {
      return dispatch(workers.reactivateWorker(worker_id, worker_role));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
