import React from "react";
import {Elements, StripeProvider} from 'react-stripe-elements';
import Stripe from "./Stripe";

class StripeContainer extends React.Component {
    render() {
        return (
            <StripeProvider apiKey={process.env.STRIPE_API_KEY}>
                <React.Fragment>
                    <Elements>
                        <Stripe {...this.props} />
                    </Elements>
                </React.Fragment>
            </StripeProvider>
        );
    }
}

StripeContainer.propTypes = {};

StripeContainer.defaultProps = {
  showFirstChargeWarning: false,
};

export default StripeContainer;
