import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import WorkerFilePane from "./WorkerFilePane";
import WorkerNotesPane from "./WorkerNotesPane";
import WorkerGPSEntriesTab from "./WorkerGPSEntries";
import WorkerSafetyMeeting from "./WorkerSafetyMeeting";
import WorkerTimeSheet from "./WorkerTimeSheet";
import moment from "moment";
import { ax } from "utils";

import Spinner from "react-bootstrap/Spinner";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    width: "100%",
    padding: "10px",
    backgroundColor: "var(--trade-body)",
    borderRadius: "10px",
    border: "0.08em solid var(--trade-primary)",
  },


}));

function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // objects
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [noteImages, setNoteImages] = useState([]);
  const [timelogs, setTimelogs] = useState([]);
  const [safetyMeetings, setSafetyMeetings] = useState([]);
  const [gpsEntries, setGpsEntries] = useState([]);

  // next page URL
  const [filesNext, setFilesNext] = useState(null);
  const [notesNext, setNotesNext] = useState(null);
  const [imagesNext, setImagesNext] = useState(null);
  const [noteImagesNext, setNoteImagesNext] = useState(null);
  const [timelogsNext, setTimelogsNext] = useState(null);
  const [safetyMeetingsNext, setSafetyMeetingsNext] = useState(null);
  const [entriesNext, setEntriesNext] = useState(null);

  const [filesLoading, setFilesLoading] = useState(true);
  const [notesLoading, setNotesLoading] = useState(true);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [timelogsLoading, setTimelogsLoading] = useState(true);
  const [safetyMeetingsLoading, setSafetyMeetingsLoading] = useState(true);
  const [entriesLoading, setEntriesLoading] = useState(true);

  const { worker_id } = props;

  useEffect(() => {
    //worker notes
    // ax.get("/api/notes/?worker_id="+worker_id).then((res) => {
    //   setNotes(res.data.results);
    //   setNotesNext(res.data.next);
    //   setNotesLoading(false);
    // }).catch((err) => {
    //   setNotesLoading(false);
    //   console.log(err);
    // });

    // stays the same

    // worker files
    ax.get(
      "/api/project-files/?worker_id=" +
      worker_id +
      "&file_type=document&limit=10"
    )
      .then((res) => {
        setFiles(res.data.results);
        setFilesNext(res.data.next);
        setFilesLoading(false);
      })
      .catch((err) => {
        setFilesLoading(false);
        console.log(err);
      });

    // worker images
    ax.get("/api/project-files/?worker_id=" + worker_id + "&file_type=image")
      .then((res) => {
        setImages(res.data.results);
        setImagesNext(res.data.next);
        setImagesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setImagesLoading(false);
      });

    // note images added by worker
    ax.get("/api/notes-images/?worker_id=" + worker_id)
      .then((res) => {
        setNoteImages(res.data.results);
        setNoteImagesNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });

    // up to here

    // worker time logs
    ax.get("/api/time-logs/?worker_id=" + worker_id)
      .then((res) => {
        let temps = res.data.results.map((log) => {
          log.start = moment(log.start);
          log.end = moment(log.end);
          return log;
        });

        setTimelogs(temps);
        setTimelogsNext(res.data.next);
        setTimelogsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTimelogsLoading(false);
      });

    // worker safety meetings
    ax.get("/api/safety-meetings/?worker_id=" + worker_id)
      .then((res) => {
        setSafetyMeetings(res.data.results);
        setSafetyMeetingsNext(res.data.next);
        setSafetyMeetingsLoading(false);
      })
      .catch((err) => {
        setSafetyMeetingsLoading(false);
        console.log(err);
      });

    // worker gps entries
    ax.get("/api/gps-entries/?worker_id=" + worker_id)
      .then((res) => {
        setGpsEntries(res.data.results);
        setEntriesNext(res.data.next);
        setEntriesLoading(false);
      })
      .catch((err) => {
        setEntriesLoading(false);
        console.log(err);
      });
  }, [worker_id]);

  const getMoreFiles = () => {
    ax.get(filesNext)
      .then((res) => {
        setFiles([...files, ...res.data.results]);
        setFilesLoading(false);
        setFilesNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
        setFilesLoading(false);
      });
  };

  const getMoreNotes = () => {
    ax.get(notesNext)
      .then((res) => {
        setNotes([...notes, ...res.data.results]);
        setNotesLoading(false);
        setNotesNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
        setNotesLoading(false);
      });
  };

  const getMoreImages = () => {
    if (imagesNext) {
      ax.get(imagesNext)
        .then((res) => {
          setImages([...images, ...res.data.results]);
          setImagesLoading(false);
          setImagesNext(res.data.next);
        })
        .catch((err) => {
          setImagesLoading(false);
          console.log(err);
        });
    }

    if (noteImagesNext) {
      ax.get(noteImagesNext)
        .then((res) => {
          const formatedImages = res.data.results.map((elem, index) => ({
            id: elem.id,
            date_created: elem.date_created,
            file: elem.image,
            name: "note image " + index,
            file_type: "image",
          }));
          setNoteImagesNext(res.data.next);
          setNoteImages([...noteImages, ...formatedImages]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getMoreTimelogs = () => {
    ax.get(timelogsNext)
      .then((res) => {
        let temps = res.data.results.map((log) => {
          log.start = moment(log.start);
          log.end = moment(log.end);
          return log;
        });
        setTimelogs([...timelogs, ...temps]);
        setTimelogsNext(res.data.next);
        setTimelogsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimelogsLoading(false);
      });
  };

  const getMoreSafetyMeetings = () => {
    ax.get(safetyMeetingsNext)
      .then((res) => {
        setSafetyMeetings([...safetyMeetings, ...res.data.results]);
        setSafetyMeetingsLoading(false);
        setSafetyMeetingsNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
        setSafetyMeetingsLoading(false);
      });
  };

  const getMoreEntries = () => {
    ax.get(entriesNext)
      .then((res) => {
        setGpsEntries([...gpsEntries, ...res.data.results]);
        setEntriesNext(res.data.next);
        setEntriesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setEntriesLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabs = [
    "files",
    "notes",
    "photos",
    "gps records",
    "worker time sheet",
    "worker safety meetings",
  ];
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "var(--trade-success)",
      height: "80%",
      zIndex: "1",
      bottom: "10%",
      borderRadius: "10px",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "var(--trade-success)",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      color: "#000000",

      "&.MuiTab-root": {
        minWidth: "120px",
      },
      "&.Mui-selected": {
        color: "var(--trade-font)",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "var(--trade-body)",
      },
    })
  );


  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: "var(--trade-body)",
          boxShadow: "none",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={
            {
              style: {
                backgroundColor: "var(--trade-success)",
              },
            }
          }
          style={{
            background: "#FFFF",
            color: "white",
            minHeight: "30px",
            marginTop: "5px",
            borderRadius: "5px",
            padding: "0 5px",
          }}
        >
          {tabs.map((index, key) => {
            return (
              <StyledTab
                key={key}
                label={index}
                {...a11yProps(key)}
                style={{ textTransform: 'capitalize', fontSize: "0.8rem", minHeight: "30px", zIndex: "2" }}
              />
            );
          })}
        </StyledTabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        {filesLoading ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        ) : (
          <WorkerFilePane
            worker_id={worker_id}
            type="files"
            items={files}
            fetchMore={getMoreFiles}
            nextUrl={filesNext}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WorkerNotesPane
          worker_id={worker_id}
          currentYear={props.currentYear}
          // items={notes}
          nextUrl={notesNext}
          fetchMore={getMoreNotes}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {imagesLoading ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="warning" className="spinner" />
          </div>
        ) : (
          <WorkerFilePane
            worker_id={worker_id}
            type="photos"
            items={images}
            noteImages={noteImages}
            fetchMore={getMoreImages}
            nextUrl={imagesNext}
            noteImagesNext={noteImagesNext}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <WorkerGPSEntriesTab
          worker_id={worker_id}
          currentYear={props.currentYear}
        // items={gpsEntries}
        // nextUrl={entriesNext}
        // fetchMore={getMoreEntries}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <WorkerTimeSheet
          worker_id={worker_id}
          currentYear={props.currentYear}
        // items={timelogs}
        // nextUrl={timelogsNext}
        // fetchMore={getMoreTimelogs}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <WorkerSafetyMeeting
          worker_id={worker_id}
          currentYear={props.currentYear}
        // items={safetyMeetings}
        // nextUrl={safetyMeetingsNext}
        // fetchMore={getMoreSafetyMeetings}
        />
      </TabPanel>
    </div>
  );
}

export default ScrollableTabsButtonAuto;
