//// This is no longer used. Previously Notes were tied to a timelog and you could see all notes attached to a timelog
/// This is no longer the case, it is now only tied to a project

import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Moment from "react-moment";


// theme components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Timeline from "components/Timeline/Timeline.jsx";

import {time_logs} from "actions";
import NotesListStyle from "./NotesListStyles";
import {Link} from "react-router-dom";

import Edit from "@material-ui/icons/Edit";


class NotesList extends Component {
  state = {};
  componentDidMount() {
    const time_log_id = this.props.match.params.time_log_id;
    if(time_log_id !== undefined){
      this.props.getTimeLog(time_log_id);
    }
  }

  getNoteBody(note){
    return (
      <React.Fragment>
        <p dangerouslySetInnerHTML={{__html: note.description}} />
        {note.images.map((file, index) => <img src={file.image} className={this.props.classes.images} alt={"Note"} key={index} style={{width:"100%"}} />)}
        <Button round color="info" component={Link} to={"/notes/" + note.id} justIcon><Edit /></Button>
      </React.Fragment>
    )
  }

  render() {
    const { time_log } = this.props;
    if(this.state === null || time_log === undefined || time_log.notes === undefined)
      return null;

    const time_line_data = time_log.notes.map((note, index) => {
      return {
        body: this.getNoteBody(note),
        footerTitle: (<Moment format={"dddd MMM D HH:mm:ss"}>{note.date}</Moment>),
        inverted: index % 2,
        avatar_url: note.user_avatar,
        email: note.user_email,
      };
    });

    return (
      <GridContainer>
        <GridItem xs={12}>
        <Timeline stories={time_line_data} />
        <Button round color="info" onClick={() => this.props.history.goBack()}>Back</Button>
        </GridItem>
      </GridContainer>
    );
  }
}

NotesList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    time_log: state.time_logs.item,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTimeLog: (time_log_id) => {
      return dispatch(time_logs.getTimeLog(time_log_id));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(NotesListStyle)(NotesList));
