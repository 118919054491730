import React, { useState, useEffect, useRef } from "react";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import StripeCheckout from 'react-stripe-checkout';
import { ax } from "utils";
import MenuItem from "@material-ui/core/MenuItem";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(Typography);

const GreenCheckbox = withStyles({
    root: {
        color: "var(--trade-success)",
        '&$checked': {
            color: "var(--trade-success)",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        "& .MuiInputBase-root": {
            color: "white",
            backgroundColor: "transparent",
        },
        "& .MuiFormLabel-root": {
            color: "white",
        },
        "& .MuiInputBase-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #555 inset !important",
            WebkitTextFillColor: "var(--trade-font) !important",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--trade-success)',
            },
            "&.Mui-disabled fieldset": {
                color: "white",
                backgroundColor: "transparent",
                border: "1px solid var(--trade-neutral)"
            },
        },
    },
})(TextField);

const GreenTypography = withStyles({
    root: {
        color: "green"
    }
})(Typography);

const FormLabel = withStyles({
    label: {
        fontSize: "1.3rem",
        color: "white"
    }
})(FormControlLabel);

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        maxHeight: "10rem",
        height: "10rem",
        maxWidth: "10rem",
        width: "100%",
        margin: "auto",
    },
    imageIcon: {
        position: "absolute",
        bottom: 0,
        right: 0,
    },
    avatarImage: {
        height: "100%",
        width: "100%",
        display: "block",
        objectFit: "contain",
        margin: "auto",
    },
    imageWrapper: {
        position: "relative",
        maxHeight: "100%",
        height: "100%",
        maxWidth: "100%",
        width: "100%",
        margin: "0",
        padding: "0",
    },
    companyInfoContainer: {
        backgroundColor: "var(--trade-background)",
        borderRadius: "8px",
        padding: "1rem",
        margin: "1rem auto"
    },
    passwordReqs: {
        textAlign: "center",
    }
}));

const timezones = [
    "America/Edmonton",
    "America/Halifax",
    "America/Montreal",
    "America/St_Johns",
    "America/Toronto",
    "America/Vancouver",
    "America/Winnipeg",
    "America/Yellowknife",
];

function CompanyPage(props) {
    const classes = useStyles();
    const [stripeToken, setStripeToken] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [timezone, setTimezone] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");

    const [avatar, setAvatar] = useState(null);
    const [avatarFile, setAvatarFile] = useState(null);
    const avatarInput = useRef();
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    useEffect(() => {
        const companyId = props.match.params.id;
        if (companyId !== undefined) {
            ax
                .get(`/api/companies/${companyId}/`)
                .then((res) => {
                    console.log(res);
                    setCompanyName(res.data.name);
                    setTimezone(res.data.timezone);
                    setStreetAddress(res.data.address1);
                    setCity(res.data.city);
                    setProvince(res.data.province);
                    setCountry(res.data.country);
                    setPostalCode(res.data.postal_code);
                    setAvatar(res.data.logo);
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            setSnackMessage("Company Does Not Exist");
            setSnackType("error");
            setSnackOpen(true);
        }

    }, [props.match.params.id]);

    const clickAvatarInput = () => {
        avatarInput.current.click();
    }

    const handleAvatarChange = (ev) => {
        if (ev.target.files.length > 0) {
            const companyId = props.match.params.id;
            setAvatarFile(ev.target.files[0]);
            setAvatar(URL.createObjectURL(ev.target.files[0]));

            let formData = new FormData();
            formData.append("logo", ev.target.files[0], ev.target.files[0].name);

            ax.post("/api/companies/" + companyId + "/add-logo/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                setSnackMessage("Logo Updated");
                setSnackType("success");
                setSnackOpen(true);
            }).catch((err) => {
                setSnackMessage("Couldn't update logo");
                setSnackType("error");
                setSnackOpen(true);
            })
        }
    }

    const saveChanges = (token) => {
        const companyId = props.match.params.id;
        ax.put(`/api/companies/${companyId}/`, {
            'name': companyName,
            'timezone': timezone,
            'address1': streetAddress,
            'city': city,
            'province': province,
            'country': country,
            'postal_code': postalCode,
            'stripe_token': token
        }).then((res) => {
            setSnackOpen(true);
            setSnackMessage("Company Updated");
            setSnackType("success");
        }).catch((err) => {
            console.log(err);
        })
    }

    const onToken = (e) => {
        console.log(e);
        saveChanges(e.id);
        setStripeToken(e.id);
    }

    const TimezoneSelect = () => {
        return (
            <CssTextField
                label="Time Zone"
                fullWidth={true}
                variant="outlined"
                select
                value={timezone}
                onChange={(ev) => setTimezone(ev.target.value)}
                name="timezone"
            >
                {
                    timezones.sort((a, b) => a.localeCompare(b)).map((item, index) =>
                        <MenuItem
                            value={item}
                            key={index}
                            style={{ textTransform: "capitalize" }}
                        >
                            {item}
                        </MenuItem>
                    )
                }
            </CssTextField>
        );
    }

    //safety meetings, timelogs, projects, 
    const userInfo = () => {
        return (
            <Grid container >
                <Grid container item md={12}>
                    <Grid item sm={1} md={1}>
                    </Grid>
                    <Grid container item md={4} className={classes.companyInfoContainer}>
                        <Grid item sm={12} md={12}>
                            <Box m={2}>
                                <WhiteTextTypography variant="h4" align="center">
                                    Company Details
                                </WhiteTextTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <input type="file" onChange={handleAvatarChange} style={{ display: "none" }} ref={avatarInput} />
                            <Box m={2} justifyContent="center" onClick={clickAvatarInput}
                                alignItems="center" display="flex" className={classes.imageContainer}>
                                {
                                    (avatar === null)
                                        ? <WhiteTextTypography align="center">
                                            Add Avatar <br />
                                            <AddIcon fontSize="large" />
                                        </WhiteTextTypography>
                                        : <div className={classes.imageWrapper}>
                                            <img src={avatar} className={classes.avatarImage} />
                                            <GreenTypography>
                                                <EditIcon className={classes.imageIcon} fontSize="large" />
                                            </GreenTypography>
                                        </div>
                                }
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            <Box mt={3} mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="Company Name"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={companyName}
                                    onChange={(ev) => setCompanyName(ev.target.value)}
                                    name="companyName"
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <TimezoneSelect />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item sm={1} md={1}>
                    </Grid>
                    {/* Split Between two columns */}
                    <Grid item sm={1} md={1}>
                    </Grid>
                    <Grid container item md={4} className={classes.companyInfoContainer}>
                        <Grid item sm={12} md={12}>
                            <Box m={2}>
                                <WhiteTextTypography variant="h4" align="center">
                                    Your Subscription
                                </WhiteTextTypography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="Street Address"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={streetAddress}
                                    onChange={(ev) => setStreetAddress(ev.target.value)}
                                    name="streetAddress"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="City"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={city}
                                    onChange={(ev) => setCity(ev.target.value)}
                                    name="city"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="Province"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={province}
                                    onChange={(ev) => setProvince(ev.target.value)}
                                    name="province"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="Country"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={country}
                                    onChange={(ev) => setCountry(ev.target.value)}
                                    name="country"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box mb={3} ml={2} mr={2}>
                                <CssTextField
                                    label="Postal Code"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={postalCode}
                                    onChange={(ev) => setPostalCode(ev.target.value)}
                                    name="postalCode"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Box mb={3} ml={2} mr={2} justifyContent="center" alignItems="center" display="flex">
                                <StripeCheckout
                                    token={onToken}
                                    stripeKey={process.env.STRIPE_API_KEY}
                                >
                                    <button style={{
                                        backgroundColor: "green", padding: "1rem",
                                        fontWeight: "bold", color: "white", border: "none", borderRadius: "4px"
                                    }}>
                                        Update Credit Card
                                    </button>
                                </StripeCheckout>
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid item sm={1} md={1}>
                    </Grid>
                </Grid>

                <Grid container item md={12}>
                    <Grid item md={4}></Grid>
                    <Grid item md={4}>
                        <Box mt={3} justifyContent="center" alignItems="center" display="flex">
                            <button
                                style={{
                                    backgroundColor: "green", padding: "0.7rem",
                                    fontWeight: "bold", color: "white", border: "none", borderRadius: "4px",
                                    margin: "auto"
                                }}
                                onClick={saveChanges}
                            >
                                Save Changes
                            </button>
                            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
                                <Alert onClose={() => setSnackOpen(false)} severity={snackType}>
                                    {snackMessage}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Grid>
                    <Grid item md={4}></Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <div style={{ backgroundColor: "var(--trade-body)", minHeight: "100%", width: "100%", position: "absolute", padding: "1rem" }}>
            {userInfo()}
        </div>
    );
}

export default CompanyPage;