import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// theme components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import Pagination from "components/Pagination/Pagination";

// Table
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// icons
import ImageIcon from "@material-ui/icons/Image";
import Edit from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ScheduleIcon from "@material-ui/icons/Schedule";
import TrashIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import NoteIcon from "@material-ui/icons/Note";

// custom code
import {projects} from "actions";
import projectPageStyle from "assets/jss/tradespecifix/project";

class ProjectsList extends Component {
    state = {
        searchStr: "",
        itemId: null,
        item: null,
        page: 0,
        limit: 10
    };

    constructor(props){
        super(props);
        const page = this.props.match.params.page;
        if(page !== undefined){
            this.state.page = page;
        }
        this.refreshPage();
    }

    changePage(newPage) {
        this.setState({page: newPage - 1}, () => {
            if(this.state.page === 0)
                this.props.history.push('/projects/list' );
            else
                this.props.history.push('/projects/list/' + this.state.page);
        })
    }

    refreshPage(){
        this.props.fetchProjects(this.state.limit, this.state.page);
    }

    componentWillReceiveProps(nextProps){
        console.debug("construct projects list")
        if(nextProps.match.params.page !== this.props.match.params.page){
            this.setState({page: nextProps.match.params.page}, () => this.refreshPage());
        }
    }

    renderPagination(){
        const { page, limit } = this.state;
        const { count } = this.props;
        const maxPages = Math.ceil(count/limit);
        let pages = [];
        for(let i = 1; i <= maxPages; i++){
            let item = {
                text: i,
                onClick: () => this.changePage(i)
            };
            if(i === parseInt(page) + 1){
                item['active'] = true
            }
            pages.push(item);
        }
        return (
            <Pagination
                pages={pages}
                color="info"
            />
        );
    }

    render() {
        const { classes, projects, isStaff } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" icon>
                            <Fab color="primary" aria-label="Add" className={classes.Fab} component={Link} to="/projects/add">
                                <AddIcon />
                            </Fab>
                            <CardIcon color="info">
                                <ImageIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Projects</h4>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="none" >Title</TableCell>
                                        {isStaff?<Hidden smDown><TableCell>Company</TableCell></Hidden>:null}
                                        <TableCell padding="none" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projects.map(item => (
                                        <TableRow key={"projects-list-" + item.id} className={item.is_active === false ? classes.archived : null}>
                                            <TableCell padding="none" className={item.is_active === false ? classes.archived : null}>{item.title}</TableCell>
                                            {isStaff?<Hidden smDown><TableCell className={item.is_active === false ? classes.archived : null}>{item.company_name}</TableCell></Hidden>:null}
                                            <TableCell>
                                                <Tooltip title="View time reports">
                                                    <Button round className={classes.firstButton} color="info" component={Link} to={"/projects/" + item.id + "/timesheet"} justIcon><ScheduleIcon /></Button>
                                                </Tooltip>
                                                <Tooltip title="View project files">
                                                    <Button round className={classes.middleButton} color="info" component={Link} to={"/projects/" + item.id + "/files"} justIcon><FolderIcon /></Button>
                                                </Tooltip>
                                                <Tooltip title="View notes">
                                                    <Button round className={classes.lastButton} color="info" component={Link} to={"/projects/" + item.id + "/notes"} justIcon><NoteIcon /></Button>
                                                </Tooltip>
                                                <br className={classes.buttonMargin}/>
                                                <Tooltip title="Edit">
                                                    <Button round className={classes.firstButton} color="info" component={Link} to={"/projects/" + item.id} justIcon><Edit /></Button>
                                                </Tooltip>
                                                {item.is_active?
                                                <Tooltip title="Archive (workers won't be able to log any more time)">
                                                    <Button round color="danger" className={classes.lastButton} onClick={() => this.props.archiveProject(item.id)} justIcon><TrashIcon /></Button>
                                                </Tooltip>:
                                                  <Tooltip title="Re-activate">
                                                      <Button round color="success" className={classes.lastButton} onClick={() => this.props.reactivateProject(item.id)} justIcon><DoneIcon /></Button>
                                                  </Tooltip>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer className={classes.justifyEnd}>
                        {this.renderPagination()}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        )
    }
}

ProjectsList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        projects: state.projects.items,
        isStaff: state.auth.user.is_staff,
        count: state.projects.count,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProjects: (limit, page) => {
            return dispatch(projects.fetchProjects(limit, page));
        },
        archiveProject: (project_id) => {
            return dispatch(projects.archiveProject(project_id));
        },
        reactivateProject: (project_id) => {
            return dispatch(projects.reactivateProject(project_id));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(projectPageStyle)(ProjectsList));
