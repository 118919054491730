import React, { useState } from "react";
import { connect } from "react-redux";
import { SvgIcon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { time_logs } from "actions";

import "./BreakPage.css";
import {getUserTimezone} from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-background)",
    marginBottom: "1rem",
  },
  button: {
    backgroundColor: "var(--trade-success)",
    borderRadius: "30px",
    border: "none",
    padding: "1rem",
    color: "white",
  },
  redButton: {
    backgroundColor: "transparent",
    borderRadius: "30px",
    border: "2px solid #f44336",
    padding: "1rem",
    color: "#f44336",
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

function CoffeeOutlined(props) {
  return (
    <SvgIcon {...props} className="svg-icon break-icon">
      <path d="M18.5 3H6c-1.1 0-2 .9-2 2v5.71c0 3.83 2.95 7.18 6.78 7.29 3.96.12 7.22-3.06 7.22-7v-1h.5c1.93 0 3.5-1.57 3.5-3.5S20.43 3 18.5 3zM16 5v3H6V5h10zm0 5v1c0 2.76-2.24 5-5 5s-5-2.24-5-5v-1m12.5-2H18V5h.5c.83 0 1.5.67 1.5 1.5S19.33 8 18.5 8zM4 19h16v2H4v-2z" />
    </SvgIcon>
  );
}

function DeliveryDiningOutlined(props) {
  return (
    <SvgIcon {...props} className="svg-icon delivery-icon">
      <path d="M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7zM4 14v-1c0-1.1.9-2 2-2h2v3H4zm3 3c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1z" />
      <path d="M5 6h5v2H5zm14 7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
    </SvgIcon>
  );
}

function BreakPage(props) {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState(null);

  const backToWork = () => {
    // @TODO clean this up. We should not be getting the old GPS data to stop the timer
    const { latitude, longitude, id, project, task_type_id, user_id } =
      props.user.last_time_log;
    props
      .startTimer(
        project.id,
        user_id,
        task_type_id,
        parseFloat(latitude),
        parseFloat(longitude)
      )
      .then(() => setRedirectTo("/dashboard"));
  };

  const stopTimer = () => {
    const { latitude, longitude, id } = props.user.last_time_log;

    props
      .stopTimer(id, parseFloat(latitude), parseFloat(longitude))
      .then(() => {
        setRedirectTo("/project-selection");
      });
  };

  const mainContainer = () => {
    if (redirectTo !== null) {
      return <Redirect to={redirectTo} />;
    }
    return (
      <div
        style={{
          backgroundColor: "var(--trade-background)",
          minHeight: "100%",
          width: "100%",
          position: "absolute",
          padding: "2rem",
        }}
      >
        <Grid container>
          <Grid item md={12}>
            <Box
              m={2}
              p={4}
              display="flex"
              justifyContent="center"
              alignContent="center"
              className={classes.container}
              style={{ padding: 0 }}
            >
              {props.user.last_time_log.type === "break" ? (
                <CoffeeOutlined />
              ) : (
                <DeliveryDiningOutlined />
              )}
            </Box>
            <Box
              m={2}
              p={4}
              display="flex"
              justifyContent="center"
              alignContent="center"
              className={classes.container}
            >
              <WhiteTextTypography
                variant="h5"
                style={{
                  color: `${props.user.last_time_log.type === "break"
                    ? "#2196f3"
                    : "#ff9100"
                    }`,
                }}
              >
                You are on{" "}
                {props.user.last_time_log.type === "break"
                  ? "break"
                  : "delivery"}{" "}
                mode
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography>
                <button onClick={backToWork} className={classes.button}>
                  Back To Work At Current Project
                </button>
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography style={{ textAlign: "center" }}>
                <b>Have the site conditions changed?</b>
                <br />
                Please notify your manager if the site conditions have changed
                or are unsafe to proceeed.
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography style={{ textAlign: "center" }}>
                Go to a New Project
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography>
                <button onClick={stopTimer} className={classes.redButton}>
                  Stop Timer And Go To A New Project
                </button>
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  return mainContainer();
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTimer: (project_id, worker_id, task_id, latitude, longitude) => {
      return dispatch(
        time_logs.startTimeLog(
          project_id,
          latitude,
          longitude,
          worker_id,
          task_id,
          true,
          getUserTimezone(),
        )
      );
    },
    stopTimer: (time_log_id, latitude, longitude) => {
      return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakPage);
