import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Spinner from "react-bootstrap/Spinner";
import { ax } from "utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Lightbox from "../Lightbox/Lightbox";

import pdfLogo from "assets/img/pdf-icon.png";

import "./PhotosList.css";

const moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "rgba(80, 80, 80, 0.6)",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "rgba(80, 80, 80, 0.6)",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

function PhotosList(props) {
  const { item, type, project, photoCreated } = props;
  const [totalItems, setTotalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [noteImagesNext, setNoteImagesNext] = useState(null);
  const [imageToShow, setImageToShow] = useState("");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const fetchImages = async () => {
    const imagesEndpoint =
      "/api/project-files/?project_id=" +
      project.id +
      "&file_type=image&limit=14";
    const noteImagesEndpoint =
      "/api/notes-images/?project_id=" + project.id + "&limit=10&is_copy=false";

    const imagesResp = await ax.get(imagesEndpoint);
    const noteImagesresp = await ax.get(noteImagesEndpoint);

    const noteImagesTemp = noteImagesresp.data.results.map((elem, index) => ({
      id: elem.id,
      date_created: elem.date_created,
      file: elem.image,
      name: "note image " + index,
    }));

    let items = [...imagesResp.data.results, ...noteImagesTemp];
    items.sort(function (a, b) {
      return b.date_created.localeCompare(a.date_created);
    });

    const response = {
      items: items,
      imagesNext: imagesResp.data.next,
      noteImagesNext: noteImagesresp.data.next,
    };

    return response;
  };

  const fetchData = () => {
    fetchImages().then((res) => {
      setTotalItems(res.items);
      setNext(res.imagesNext);
      setNoteImagesNext(res.noteImagesNext);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, [photoCreated, props.currentProject]);

  const fetchMoreData = async () => {
    let imagesResp;
    let noteImagesresp;
    let noteImagestemp = [];
    let imagesTemp = [];
    if (next) {
      imagesResp = await ax.get(next);
      imagesTemp = imagesResp.data.results;

      setNext(imagesResp.data.next);
    }
    if (noteImagesNext) {
      noteImagesresp = await ax.get(noteImagesNext);

      noteImagestemp = noteImagesresp.data.results.map((elem, index) => ({
        id: elem.id,
        date_created: elem.date_created,
        file: elem.image,
        name: "note image " + index,
      }));

      setNoteImagesNext(noteImagesresp.data.next);
    }

    let items = [...imagesTemp, ...noteImagestemp];
    items.sort(function (a, b) {
      return b.date_created.localeCompare(a.date_created);
    });
    setTotalItems([...totalItems, ...items]);
  };

  const LoadMoreButton = () => {
    return next ? (
      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        style={{ padding: "0.8rem 1.1rem" }}
        onClick={fetchMoreData}
      >
        Load More
      </Button>
    ) : null;
  };

  const showImage = (item) => {
    setOpen(true);
    setImageToShow(item);
  };

  const open_file = (file) => {
    window.open(file.file, "_blank");
  };

  return (
    <div className="projectTimeSheetWrapper" id="style-5">
      <Lightbox
        image={imageToShow}
        setOpen={setOpen}
        open={open}
        project={project}
        createdNote={props.createdNote}
      />
      <div>
        {loading ? (
          <div className="spinnerContainer">
            {/* <Spinner animation="border" variant="warning" /> */}
            <h2 style={{ color: "white" }}>Loading Photos...</h2>
          </div>
        ) : totalItems.length > 0 ? (
          <div className="photo-grid">
            {totalItems.map((item, key) => (
              <Grid key={item.id} container spacing={2} className="file-grid">
                <Grid item xs={10}>
                  <ListItem style={{ padding: 0, color: "#ff6600" }}>
                    {item.file.substring(
                      item.file.lastIndexOf(".") + 1,
                      item.file.length
                    ) === "pdf" ? (
                      <img
                        onClick={() => open_file(item)}
                        src={pdfLogo}
                        alt="PDF"
                        style={{
                          width: "110px",
                          height: "110px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <img
                        onClick={() => showImage(item)}
                        src={item.thumbnail ? item.thumbnail : item.file}
                        alt="photo"
                        style={{
                          width: "110px",
                          height: "110px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </ListItem>
                </Grid>
              </Grid>
            ))}
          </div>
        ) : (
          <div className="no-files-message">
            <h1>No Photos Uploaded</h1>
          </div>
        )}
      </div>
      {next ? <LoadMoreButton /> : null}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    project: state.projects.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotosList);
