import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Typography, Box } from "@material-ui/core";
import TasksAndMeetingsSlider from "../../newComponents/TasksAndMeetingsSlider/TasksAndMeetingsSlider";
import Visibility from "@material-ui/icons/Visibility";
import { withStyles } from "@material-ui/core/styles";
import "./TasksAndMeetingsRow.css";

const WhiteTextTypography = withStyles({
  root: {
    color: "var(--trade-font)",
  },
})(Typography);

function TasksAndMeetingsRow(props) {
  const taskMeetings = props.allSafetyMeetings.filter((safetyMeeting) =>
    props.task.safety_meeting_infos.includes(safetyMeeting.id)
  );

  return (
    <Grid
      container
      className="tasks-meeting-row"
      style={{ border: "1px solid var(--trade-neutral)" }}
    >
      <Grid container item md={12}>
        {/* Task Title */}
        <Grid item md={4} style={{ display: "flex", alignItems: "center" }}>
          <Box m={2} display="flex" alignContent="center">
            <WhiteTextTypography>{props.task.title}</WhiteTextTypography>
          </Box>
        </Grid>
        {/* Safety Meetings */}
        <Grid item md={5}>
          <Box
            m={2}
            // display="flex"
            // justifyContent="center"
            // alignContent="center"
            // margin="0.2rem 1rem"
          >
            {props.task.is_admin_task
              ? props.safety_meeting_infos.map((meeting) =>
                  props.task.safety_meeting_infos.map((taskMeeting) => {
                    if (meeting.id === taskMeeting) {
                      return (
                        <Chip
                          label={meeting.title}
                          variant="outlined"
                          style={{
                            borderColor: "var(--trade-neutral)",
                            color: "var(--trade-font)",
                          }}
                          onDelete={() =>
                            props.handleSafetyMeetingDelete(
                              props.task.id,
                              meeting.id,
                              props.task.is_admin_task
                            )
                          }
                        >
                          {meeting.title}
                        </Chip>
                      );
                    }
                  })
                )
              : props.task.safety_meeting_infos.length > 0
              ? props.task.safety_meeting_infos.map((taskMeeting) => (
                  <Chip
                    label={taskMeeting.title}
                    variant="outlined"
                    style={{
                      borderColor: "var(--trade-neutral)",
                      color: "var(--trade-font)",
                    }}
                    // onClick={props.handleSafetyMeetingChecked}
                    onDelete={() =>
                      props.handleSafetyMeetingDelete(
                        props.task.id,
                        taskMeeting.id
                      )
                    }
                    // onDelete={() => props.filterInfos(null, taskMeeting)}
                  >
                    {taskMeeting.title}
                  </Chip>
                ))
              : props.currentInfo.map((taskMeeting) => (
                  <Chip
                    label={taskMeeting.title}
                    variant="outlined"
                    style={{
                      borderColor: "var(--trade-neutral)",
                      color: "var(--trade-font)",
                    }}
                    // onClick={props.handleSafetyMeetingChecked}
                    // onDelete={() =>
                    //   props.handleSafetyMeetingDelete(
                    //     props.task.id,
                    //     taskMeeting.id
                    //   )
                    // }
                    onDelete={() => props.filterInfos(null, taskMeeting)}
                  >
                    {taskMeeting.title}
                  </Chip>
                ))}
          </Box>
        </Grid>
        {/* Dummy Column */}
        <Grid
          item
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box m={2}></Box>
        </Grid>
        {/* Delete Icon */}
        <Grid
          item
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box m={2}>
            {/* {!props.task.is_admin_task ? ( */}
            <HighlightOffIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.handleRemoveTask(props.task.id, props.task.is_admin_task)
              }
            />
            {/* ) : null} */}
          </Box>
        </Grid>
        {/* Details Icon */}
        <Grid
          item
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box m={2}>
            <TasksAndMeetingsSlider
              handleNewTaskTitle={props.handleNewTaskTitle}
              isEditingTask={props.isEditingTask}
              newTaskTitle={props.newTaskTitle}
              selectedTasks={props.selectedTasks}
              setIsEditingTask={props.setIsEditingTask}
              setNewTaskTitle={props.setNewTaskTitle}
              setSelectedTasks={props.setSelectedTasks}
              task={props.task}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return;
};

const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksAndMeetingsRow);
