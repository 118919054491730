import moment from "moment";
const initialState = {
    items: [],
    item: {},
    items_ordered_by_company: {},
    items_ordered_by_worker: {},
    count: 0,
};

function orderByDateProject(breaks) {
    let ordered_breaks = {};

    breaks.forEach((item) => {
        const start = moment(item.start);
        const start_formatted = start.format('YYYY-MM-DD');
        if(ordered_breaks[start_formatted] === undefined){
            ordered_breaks[start_formatted] = {};
        }
        if(ordered_breaks[start_formatted][item.project.id] === undefined) {
            ordered_breaks[start_formatted][item.project.id] = [];
        }

        ordered_breaks[start_formatted][item.project.id].push(item);
    });
    return ordered_breaks;
}

function orderByWorker(items){
    let ordered_items = {};
    items.forEach((item) => {
        const start = moment(item.start);
        const start_formatted = start.format('YYYY-MM-DD');
        if(ordered_items[start_formatted] === undefined){
            ordered_items[start_formatted] = {};
        }
        if(ordered_items[start_formatted][item.user] === undefined) {
            ordered_items[start_formatted][item.user] = [];
        }

        ordered_items[start_formatted][item.user].push(item);
    });
    return ordered_items;
}

function orderByCompany(items){
    /*
    Returns the log in this format:
    logs{site_id} = [
        {start_date} = [ log1, log2}
    }
     */
    let ordered_items = {};
    items.forEach((item) => {
        if(ordered_items[item.project.id] === undefined){
            ordered_items[item.project.id] = [];
        }
        ordered_items[item.project.id].push(item);
    });
    return ordered_items;
}

let items = [];
export default function breaks(state=initialState, action) {
    switch (action.type) {
        case 'FETCH_BREAKS':
            items = action.items.map(log => {
                log.start = moment(log.start);
                log.end = moment(log.end);
                return log;
            });
            return {
                items: items,
                items_ordered: orderByDateProject(action.items),
                count: action.count,
                items_ordered_by_worker: orderByWorker(action.items),
                items_ordered_by_company: orderByCompany(action.items)
            };

        case 'GET_BREAK':
            items = state.items.map(log => {
                if(action.item.id === log.id){
                    log.start = moment(action.item.start);
                    log.end = moment(action.item.end);
                    log.locked = action.item.locked;
                }
                return log;
            });
            return {...state, item: action.item, items: items};
        case "LOGOUT_SUCCESSFUL":
            return {
                items: [],
                item: {},
                items_ordered_by_company: {},
                items_ordered_by_worker: {},
                count: 0,
            }
        default:
            return state;
    }
}
