import React, { Component } from "react";
import "./SideNav.css";
import { SvgIcon } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import dashRoutes from "../../routes/dashboard";
import { auth } from "../../actions";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import CreateWorker from "../CreatWorkerDialog/CreateWorker";
import { ReactComponent as ResetIcon } from "assets/img/menu/reset_24dp.svg";


class SideNav extends Component {
  isActiveRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  isActiveParent(parent) {
    const currentLocation = this.props.location.pathname;
    const activeChildren = parent.children.filter(
      (child) => currentLocation.indexOf(child.path) > -1
    );
    return activeChildren.length > 0;
  }
  render() {
    return (
      <Nav
        defaultActiveKey="/home"
        className="flex-column"
        ref={this.props.setRef}
      >
        <div className="navbar-container">
          {dashRoutes.map((route) => {
            if (route.hidden || route.hideLayout) {
              return null;
            }
            if (route.replaceCompanyId) {
              route.path = route.path.replace(/:id/, this.props.user.company);
            }
            if (route.name === "Workers" && this.props.user.role === "WORKER") {
              return null;
            }
            if (route.path === null) {
              return (
                <div
                  className={
                    this.isActiveParent(route)
                      ? "active parent sidenav-icons"
                      : "parent sidenav-icons"
                  }
                  key={route.name}
                >
                  <SvgIcon component={route.icon} />
                  <div className={"nav-children"}>
                    <ul>
                      {route.children.map((child) => {
                        if (
                          child.name.split(" ")[0] === "New" &&
                          this.props.user.role === "WORKER"
                        ) {
                          return null;
                        } else
                          return (
                            <li
                              className={
                                this.isActiveRoute(child.path)
                                  ? "active children-link"
                                  : "children-link"
                              }
                              key={child.name}
                            >
                              {child.name !== "New Worker" ? (
                                <NavLink to={child.path} key={child.path}>
                                  {child.name}
                                </NavLink>
                              ) : (
                                <CreateWorker
                                  handleNewWorkerCreated={
                                    this.props.handleNewWorkerCreated
                                  }
                                  workerUpdated={this.props.workerUpdated}
                                />
                              )}
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
              );
            } else {
              return (
                <NavLink to={route.path} key={route.path}>
                  <div
                    className={
                      this.isActiveRoute(route.path)
                        ? "active sidenav-icons"
                        : "sidenav-icons"
                    }
                  >
                    <SvgIcon component={route.icon} />
                  </div>
                </NavLink>
              );
            }
          })}
          <div className="sidenav-icons" style={{ cursor: "pointer" }}>
            <a href="/dashboard?product_tour_id=407540">
              <SvgIcon component={ResetIcon} />
            </a>
          </div>
        </div>
      </Nav >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => {
      dispatch(auth.loadUser());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sidebarStyle)(withRouter(SideNav)));
