import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import './react-draft-wysiwyg.css';
import PropTypes from "prop-types";

class CustomEditor extends Component {
  constructor(props) {
    super(props);
    const { defaultValue } = props;

    if(defaultValue !== null){
      const blocksFromHtml = convertFromHTML(defaultValue);
      this.state = {
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap)),
        editorRef: React.createRef()
      };
    }else{
      this.state = {
        editorState: EditorState.createEmpty(),
        editorRef: React.createRef()
      };
    }

  }

  onChange() {
    const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
    const text = draftToHtml(rawContentState);
    if(text.trim() !== '<p></p>')
        this.props.onChange(text);
    else
        this.props.onChange(null);
  }

  render() {
    const { editorState, editorRef } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="custom-editor-wrapper"
        onEditorStateChange={(editorState) => this.setState({editorState}, () => this.onChange())}
        toolbar={{
          options: [
            'inline'
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          }
        }}
        ref={editorRef}
      />
    )
  }
}

CustomEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
};
CustomEditor.defaultProps = {
   defaultValue: null,
};

export default CustomEditor;
