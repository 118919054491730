const initialState = {
    item: {},
    items: [],
    count: 0,
};

export default function help_tickets(state=initialState, action) {
    switch (action.type) {
        case 'FETCH_HELP_TICKETS':
            if(state.item.id !== undefined){
                const updatedItem = action.items.find(item => item.id === state.item.id);
                if(updatedItem !== undefined)
                    state.item = updatedItem;
            }
            return {...state, items: action.items, count: action.count};

        case 'GET_HELP_TICKET':
            state.items = state.items.map(value => {
                if(value.id === action.item.id){
                    return action.item;
                }
                return value;
            });
            return {...state, item: action.item};
        case "LOGOUT_SUCCESSFUL":
            return {
                item: {},
                items: [],
                count: 0,
            }
        default:
            return state;
    }
}
