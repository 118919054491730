import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, MenuItem, Select } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import ProjectSlider from "newComponents/ProjectSlider/ProjectSlider";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import { projects } from "actions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArchiveIcon from "@material-ui/icons/Archive";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@material-ui/core/TextField";

import AddIcon from "@material-ui/icons/Add";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RedTypography = withStyles({
  root: {
    color: "var(--trade-tertiary)",
  },
})(Typography);
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-root": {
      color: "var(--trade-font)",
      backgroundColor: "transparent",
      margin: "auto 0",
    },
    "& .MuiFormLabel-root": {
      color: "var(--trade-font)",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&:hover fieldset": {
        borderColor: "var(--trade-neutral)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "var(--trade-font)",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "var(--trade-background)",
    marginBottom: "1rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },
  largerText: {
    fontSize: "1.2rem",
  },
  centeredText: {
    margin: "auto",
  },
  iconButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
}));

function ProjectLibrary(props) {
  const classes = useStyles();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(null);
  const { company_id, getProjects, user } = props;
  const [sortKey, setSortKey] = useState("title");
  const [ascending, setAscending] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [tempProjectStatus, setTempProjectStatus] = useState("");

  const handleChange = (event, project) => {
    let newProject = { ...project };
    newProject.status = event.target.value;
    props.updateProjectStatus(newProject).then(() => setStatusLoading(false));
  };

  useEffect(() => {
    if (company_id) {
      getProjects(company_id, sortKey, ascending).then(() => setLoading(false));
    }
  }, [company_id, getProjects, sortKey, ascending]);

  const titleSection = () => (
    <Grid container>
      <Grid item md={12}>
        <WhiteTextTypography variant="h4">All Projects</WhiteTextTypography>
      </Grid>
      <Grid item md={12}>
        <WhiteTextTypography>
          Projects are listed below and can ordered by Title, Start Date and
          Number{" "}
        </WhiteTextTypography>
      </Grid>
      <Grid item md={12}>
        <WhiteTextTypography>
          You currently have {props.projects_count} active project
          {props.projects_count > 1 ? "s" : ""}.
        </WhiteTextTypography>
      </Grid>
    </Grid>
  );

  const handleArchive = (project) => {
    setActiveProject(project);
    setDialogOpen(true);
  };

  const handleLoadMore = () => {
    setLoading(true);
    props.fetchMore(props.next).then(() => setLoading(false));
  };

  const archiveProject = () => {
    if (activeProject && activeProject.id) {
      props.archiveProject(activeProject.id).then(() => setDialogOpen(false));
    }
  };

  const confirmationDialog = () => {
    return (
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Are you sure you want to archive this project?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={(id) => archiveProject()} color="primary" autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const tableRow = (project) => {
    const startDate = new Date(project.date_created);
    const endDate = new Date(project.expected_completion);
    const formattedStartDate = `${months[startDate.getMonth()]
      } ${startDate.getDate()}, ${startDate.getFullYear()}`;
    const formattedEndDate = `${months[endDate.getMonth()]
      } ${endDate.getDate()}, ${endDate.getFullYear()}`;

    // After clicking on Archive project, a project will be archived but will still be in our props.projects list
    // Its status is updated though, so let's return nothing if the project is still in the list, but archived.
    if (project.archived) return null;

    return (
      <Grid
        container
        item
        md={12}
        className={classes.tableRow}
        key={project.id}
      >
        {/* Project Title */}
        <Grid container item md={3}>
          <Grid item md={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="100%"
            >
              <Avatar
                src={project.company_logo}
                className={classes.centeredText}
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            <Box m={2}>
              <AmberTypography>{project.title}</AmberTypography>
              <WhiteTextTypography className={classes.smallerText}>
                {project.address}
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        {/* Project Schedule */}
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={classes.centeredText}>
              {formattedStartDate}{" "}
              {project.expected_completion ? `- ${formattedEndDate}` : null}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Manager */}
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit" variant="h5">
                    {project.project_manager
                      ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                      : "No Project Manager"}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1"> Contact </Typography>
                  <Typography
                    variant="body2"
                    style={{ textTransform: "lowercase" }}
                  >
                    {project.project_manager
                      ? project.project_manager.email
                      : "No email"}
                  </Typography>
                  <Typography variant="body2">
                    Phone Number:{" "}
                    {project.project_manager
                      ? `${project.project_manager.phone_number}`
                      : "No Number"}
                  </Typography>
                </React.Fragment>
              }
            >
              <AmberTypography className={classes.centeredText}>
                {project.project_manager
                  ? `${project.project_manager.first_name} ${project.project_manager.last_name}`
                  : "No Project Manager"}
              </AmberTypography>
            </HtmlTooltip>
          </Box>
        </Grid>
        {/* Project Number */}
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <WhiteTextTypography className={classes.centeredText}>
              {" "}
              #{project.job_number}{" "}
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* Project Status*/}
        <Grid item md={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            {user.role !== "WORKER" ? (
              statusLoading && selectedProject.id === project.id ? (
                <div
                  className="spinnerContainer"
                  style={{
                    margin: "1rem auto",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="border" variant="warning" />
                </div>
              ) : (
                <CssTextField
                  variant="outlined"
                  label=""
                  value={
                    tempProjectStatus && selectedProject.id === project.id
                      ? tempProjectStatus
                      : project.status?.status
                  }
                  onChange={(e) => {
                    setTempProjectStatus(e.target.value);
                    // setStatusLoading(true);
                    setSelectedProject(project);
                    handleChange(e, project);
                  }}
                  select
                  style={{ minWidth: 170, justifyContent: "center" }}
                >
                  <MenuItem value={"Not Started"}>Not Started</MenuItem>
                  <MenuItem value={"In Progress"}>In Progress</MenuItem>
                  <MenuItem value={"Closed"}>Closed</MenuItem>
                  <MenuItem value={"Lost"}>Lost</MenuItem>
                  <MenuItem value={"Awarded"}>Awarded</MenuItem>
                  <MenuItem value={"Submitted"}>Submitted</MenuItem>
                  <MenuItem value={"Revision"}>Revision</MenuItem>
                  <MenuItem value={"Submitted review"}>
                    Submitted review
                  </MenuItem>
                  <MenuItem value={"Hold"}>Hold</MenuItem>
                  <MenuItem value={"Quotation"}>Quotation</MenuItem>
                  <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
                </CssTextField>
              )
            ) : (
              <WhiteTextTypography className={`${classes.centeredText}`}>
                {project.status ? project.status.status : ""}
              </WhiteTextTypography>
            )}
          </Box>
        </Grid>

        {/*Icon*/}
        {user.role !== "WORKER" ? (
          <Grid item md={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="100%"
            >
              {/* Edit Project Icon*/}
              <Box component="span" className={classes.centeredText}>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit" variant="subtitle1">
                        {" "}
                        Edit Project{" "}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  {
                    //<Button round className={classes.firstButton} color="info" component={Link} to={"/projects/" + item.id} justIcon><Edit /></Button>
                  }
                  <WhiteTextTypography>
                    <Button
                      className={classes.iconButton}
                      component={Link}
                      to={"/projects/" + project.id}
                      justIcon
                    >
                      <EditIcon />
                    </Button>
                  </WhiteTextTypography>
                </HtmlTooltip>
              </Box>
              {/* Archive Project Icon*/}
              <Box component="span" className={classes.centeredText}>
                {project.id === props.user.last_time_log.project.id || user.role !== 'OWNER' ? null : (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="subtitle1">
                          Archive Project
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <div>
                      <Button
                        className={classes.iconButton}
                        justIcon
                        onClick={() => handleArchive(project)}
                      >
                        <RedTypography>
                          <ArchiveIcon />
                        </RedTypography>
                      </Button>
                      {confirmationDialog()}
                    </div>
                  </HtmlTooltip>
                )}
              </Box>
            </Box>
          </Grid>
        ) : null}
        {/* Details Icon that shows the slider */}
        <Grid item md={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            height="100%"
          >
            <Box component="span" className={classes.centeredText}>
              <ProjectSlider
                projectId={project.id}
                handleChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const changeOrder = (key) => {
    if (sortKey === key) {
      setAscending(!ascending);
    }
    setSortKey(key);
  };

  const tableSection = () => (
    <Grid
      container
      style={{
        padding: 0,
        backgroundColor: "var(--trade-background)",
        fontWeight: "bold",
        width: "100%",
      }}
    >
      <Grid container item md={12}>
        {/* Table Headers */}
        <Grid item md={3} onClick={() => changeOrder("title")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Project</WhiteTextTypography>
            {sortKey === "title" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2} onClick={() => changeOrder("date_created")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Start Date - End Date</WhiteTextTypography>
            {sortKey === "date_created" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Project Manager</WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={1} onClick={() => changeOrder("job_number")}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Number</WhiteTextTypography>
            {sortKey === "job_number" ? (
              <WhiteTextTypography>
                {ascending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </WhiteTextTypography>
            ) : null}
          </Box>
        </Grid>

        <Grid item md={2}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Status</WhiteTextTypography>
          </Box>
        </Grid>
        {user.role !== "WORKER" ? (
          <Grid item md={1}>
            <Box
              m={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <WhiteTextTypography>Edit/Archive</WhiteTextTypography>
            </Box>
          </Grid>
        ) : null}

        <Grid item md={1}>
          <Box
            m={2}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <WhiteTextTypography>Details</WhiteTextTypography>
          </Box>
        </Grid>
      </Grid>
      {/* Content Section table rows */}

      <Grid
        container
        item
        md={12}
        style={{
          borderTop: "solid 2px var(--trade-neutral)",
        }}
      >
        {
          //props.projects.filter((elem) => !(elem.archived)).sort((a, b) => a.title.localeCompare(b.title)).map((elem) => tableRow(elem))
          props.projects
            .filter((project) => project.title !== "Office Work")
            .map((elem) => tableRow(elem))
        }
      </Grid>

      {loading ? (
        <div
          className="spinnerContainer"
          style={{
            margin: "1rem auto",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Grid
          container
          item
          md={12}
          style={{
            textAlign: "center",
          }}
        >
          <Box
            justifyContent="center"
            alignContent="center"
            display="flex"
            style={{ margin: "1rem auto" }}
          >
            {props.next ? (
              <Button round onClick={() => handleLoadMore()}>
                Load more
              </Button>
            ) : null}
          </Box>
        </Grid>
      )}
    </Grid>
  );

  return (
    <div
      style={{
        backgroundColor: "var(--trade-background)",
        minHeight: "100%",
        width: "100%",
        position: "absolute",
        padding: "0.5rem",
      }}
    >
      {titleSection()}
      {tableSection()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    projects: state.projects.items,
    projects_count: state.projects.count_active,
    company_id: state.companies.current_company,
    companies: state.companies.items,
    next: state.projects.next,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    archiveProject: (id) => {
      return dispatch(projects.archiveProject(id));
    },
    updateProjectStatus: (project) => {
      return dispatch(projects.updateProjectStatus(project.id, project.status));
    },

    getProjects: (company_id, sort_key, asc_order) => {
      let sort_order = "asc";
      if (asc_order === false) {
        sort_order = "desc";
      }
      return dispatch(
        projects.getCompanyProjects(company_id, sort_key, sort_order, false)
      );
    },
    fetchMore: (url) => {
      return dispatch(projects.fetchMoreProject(url));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLibrary);
