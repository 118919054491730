import React from 'react';
import BaseComponent from "views/BaseComponent";
import Moment from 'react-moment';
import Hidden from "@material-ui/core/es/Hidden/Hidden";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import moment from 'moment';

class TimeReport extends BaseComponent {
    getInitState(state_) {
        const today = moment();
        const start = today.startOf('week').format('YYYY-MM-DD');
        const currentDate = today;
        const end = today.endOf('week').format('YYYY-MM-DD');
        return super.getInitState({
            ...state_,
            'start': start,
            'end': end,
            'currentDate': currentDate,
        });
    }

    static sumRound(acc, currentValue) {
        return (parseFloat(acc) + parseFloat(currentValue)).toFixed(2);
    }

    navigateToPreviousWeek() {
        const newDate = this.state.currentDate.subtract('1', 'week');
        const start = newDate.startOf('week').format('YYYY-MM-DD');
        const end = newDate.endOf('week').format('YYYY-MM-DD');
        this.getLogs(start, end).then(
            () => this.setState({'start': start, 'end': end, 'currentDate': newDate})
        );
    }
    navigateToNextWeek() {
        const newDate = this.state.currentDate.add('1', 'week');
        const start = newDate.startOf('week').format('YYYY-MM-DD');
        const end = newDate.endOf('week').format('YYYY-MM-DD');
        this.getLogs(start, end).then(
            () => this.setState({'start': start, 'end': end, 'currentDate': newDate})
        );
    }
    renderWeekNavigation() {
        return <GridContainer>
                <GridItem xs={2}>
                    <GridContainer alignItems="center">
                        <Button color="info" round onClick={() => this.navigateToPreviousWeek()}>&lt;<Hidden mdDown> Previous week</Hidden></Button>
                    </GridContainer>
                </GridItem>
                <GridItem xs={8}>
                    <GridContainer justify="center" alignItems="center">
                        <GridItem><h4><Moment format={"YYYY-MM-DD"}>{this.state.start}</Moment></h4></GridItem>
                        <GridItem>-</GridItem>
                        <GridItem><h4><Moment format={"YYYY-MM-DD"}>{this.state.end}</Moment></h4></GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={2}>
                    <GridContainer justify="flex-end">
                        <Button color="info" round onClick={() => this.navigateToNextWeek()}><Hidden mdDown>Next week </Hidden>&gt;</Button>
                    </GridContainer>
                </GridItem>
            </GridContainer>;
    }
}

export default TimeReport;
