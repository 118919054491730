import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { auth, workers, companies } from "actions";
import Snackbar from "@material-ui/core/Snackbar";
import { Redirect } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Spinner from "react-bootstrap/Spinner";

// import "./OnboardingPage.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& #date": {
      filter: "invert(1)",
      color: "black",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "#323231",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      borderBottom: "1px solid #858585",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "#fff !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid white",
      },
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "1px solid #F09637",
    },
  },
})(TextField);

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  select: {
    "& label.Mui-focused": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      borderColor: "white",
      backgroundColor: "#323231",
    },
    "& .MuiFormLabel-root": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInputBase-input": {
      borderBottom: "1px solid #858585",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid green",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "1px solid #F09637",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "white",
      fontWeight: "bold",
    },
  },
  stepIcon: {
    "& .MuiStepIcon-root": {
      color: "#ffff",
      fill: "grey",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#ffff",

      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
  },

  input: {
    "& .MuiInputBase-root": {
      color: "white",
      border: "1px solid white",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
  },
  stepSubtitle: {
    margin: "0px auto",
  },
  stepDescription: {
    textAlign: "center",
  },
  passwordReqs: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
  planCard: {
    padding: "0.5rem",
  },
  reviewCard: {
    padding: "1rem",
  },
  inputError: {
    textAlign: "center",
    color: "var(--trade-tertiary)",
    fontSize: "0.8rem",
  },
  planCardTopBox: {
    backgroundColor: "green",
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardChosen: {
    border: "5px solid green",
  },
  planOneChosen: {
    border: "3px solid #4994EC",
    borderRadius: "5px",
  },
  planTwoChosen: {
    border: "3px solid #F09637",
    borderRadius: "5px",
  },
  cardSubtitle: {
    fontWeight: "bold",
    marginBottom: "3px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    maxHeight: "10rem",
    height: "10rem",
    maxWidth: "10rem",
    width: "100%",
    margin: "auto",
  },
  imageWrapper: {
    position: "relative",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "100%",
    width: "100%",
    margin: "0",
    padding: "0",
  },
  imageIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  avatarImage: {
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
    margin: "auto",
  },
  reviewContainer: {
    backgroundColor: "var(--trade-background)",
    height: "auto",
    borderRadius: "4px",
    padding: "1rem",
    marginBottom: "2rem",
  },
  progressBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  linearProgressBar: {
    background: "var(--trade-success)",
    "& colorPrimary": {
      color: "green",
    },
  },
}));

function OnboardingDemoPage(props) {
  // general state
  const classes = useStyles();

  // user info
  const [snackOpen, setSnackOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberConfirm, setPhoneNumberConfirm] = useState("");
  const [emailExists, setEmailExists] = useState(false);

  // ------------------------------------- user info validators ------------------------------------- //
  const userInfoValidator = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      phoneNumber === ""
    ) {
      setSnackOpen(true);
      setErrorMessage("Please make sure that all of the fields are filled");
      return false;
    }
    if (!emailValidator()) {
      setSnackOpen(true);
      setErrorMessage("Invalid email");
      return false;
    }
    if (!phoneValidator()) {
      setSnackOpen(true);
      setErrorMessage("Invalid phone number");
      return false;
    }
    if (phoneNumber !== phoneNumberConfirm) {
      setSnackOpen(true);
      setErrorMessage("Please validate your phone number.");
      return false;
    }
    if (emailExists === true) {
      setSnackOpen(true);
      setErrorMessage("An account already exists with this email.");
      return false;
    }

    return true;
  };

  const emailValidatorExists = (event) => {
    // need to validate if the email already exists
    const email = event.target.value;
    fetch(process.env.API_URL + "/api/register/exists", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((e) => {
        if (e.exists) {
          setSnackOpen(true);
          setErrorMessage("An account already exists with this email.");
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      });
  };

  const emailValidator = () => {
    const regex =
      /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/;
    return regex.test(email);
  };

  const phoneValidator = () => {
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    return regex.test(phoneNumber);
  };

  const getDemoCompany = () => {
    return fetch(process.env.API_URL + "/api/companies/demo/", {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((e) => {
        return e.id;
      });
  };

  const createUser = async () => {
    const isValid = userInfoValidator();
    if (isValid === false) {
      return;
    }

    setIsLoading(true);
    getDemoCompany().then((company) => {
      props
        .register(firstName, lastName, email, phoneNumber, company)
        .then(async (res) => {
          if (res.type === "DATA_ERROR_SAVING") {
            setIsLoading(false);
            setSnackOpen(true);
            setErrorMessage("Unable to create user");
            //loading false
            //show snackbar with error, stay in review page
          } else if (res.type === "DATA_SAVED" && res.data !== null) {
            loginUser();
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    });
  };

  const loginUser = () => {
    props.login(email, phoneNumber).then(() => setRedirect("/dashboard"));
  };
  const UserInfo = () => {
    return (
      <Grid
        justify="center"
        alignItems="center"
        alignContent="center"
        className="user-info"
      >
        {/* Dummy container */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}></Grid>
        </Grid>
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Tell us a little bit about yourself
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              style={{ color: "#858585", textTransform: "none" }}
            >
              Don't worry, you can change everything later.
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* First Name */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="First name"
                fullWidth={true}
                variant="filled"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Last Name */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Last name"
                fullWidth={true}
                variant="filled"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Email */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="E-mail"
                fullWidth={true}
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={emailValidatorExists}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Phone number */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Your phone number"
                fullWidth={true}
                variant="filled"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        {/* password confirmation */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Confirm your phone number"
                fullWidth={true}
                variant="filled"
                type="text"
                value={phoneNumberConfirm}
                onChange={(e) => setPhoneNumberConfirm(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Move next button */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={() => createUser()}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Register
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
        {/* Dummy container */}
        <Grid container item md={12} justify="center">
          <Grid item sm={10} md={4} style={{ width: "100%" }}></Grid>
        </Grid>
      </Grid>
    );
  };

  const render = () => {
    if (props.isAuthenticated) {
      return <Redirect to="/project-selection" />;
    }
    if (isLoading) {
      return (
        <Spinner animation="border" variant="warning" className="spinner" />
      );
    }
    if (redirect === null && isLoading === false) {
      return UserInfo();
    }
    return <Redirect to={redirect} />;
  };

  return render();
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (firstName, lastName, email, phoneNumber, company) => {
      return dispatch(
        workers.createWorker(
          firstName,
          lastName,
          email,
          phoneNumber,
          company,
          phoneNumber,
          true,
          "MANAGER"
        )
      );
    },
    login: (username, password) => {
      return dispatch(auth.login(username, password, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDemoPage);
