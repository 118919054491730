import React, {useEffect, useState} from "react";
import { connect } from "react-redux";

import "./NotificationsPreferences.css";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem";
import Checkbox from "@material-ui/core/Checkbox";
import {WhiteTextTypography} from "utils";
import { workers } from "actions";
import CssTextField from "components/CssTextField/CssTextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import withStyles from "@material-ui/core/styles/withStyles";

const styleGridItem = {
    grid: {
        padding: "10px 15px !important"
    }
}
const GridItemSpacer = withStyles(styleGridItem)(GridItem);

function NotificationsPreferences(props) {
  const [pushEnabled, setPushEnabled] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);

  const [sundayStart, setSundayStart] = useState(false);
  const [sundayEnd, setSundayEnd] = useState(false);

  const [mondayStart, setMondayStart] = useState(false);
  const [mondayEnd, setMondayEnd] = useState(false);

  const [tuesdayStart, setTuesdayStart] = useState(false);
  const [tuesdayEnd, setTuesdayEnd] = useState(false);

  const [wednesdayStart, setWednesdayStart] = useState(false);
  const [wednesdayEnd, setWednesdayEnd] = useState(false);

  const [thursdayStart, setThursdayStart] = useState(false);
  const [thursdayEnd, setThursdayEnd] = useState(false);

  const [fridayStart, setFridayStart] = useState(false);
  const [fridayEnd, setFridayEnd] = useState(false);

  const [saturdayStart, setSaturdayStart] = useState(false);
  const [saturdayEnd, setSaturdayEnd] = useState(false);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackType, setSnackType] = useState("error");

  useEffect(() => {
    const pref = props.user.notification_preference;
    if(pref !== null) {
      setPushEnabled(pref.webpush_enabled);
      setSmsEnabled(pref.sms_enabled);

      setSundayStart(pref.sunday_start);
      setSundayEnd(pref.sunday_end);

      setMondayStart(pref.monday_start);
      setMondayEnd(pref.monday_end);

      setTuesdayStart(pref.tuesday_start);
      setTuesdayEnd(pref.tuesday_end);

      setWednesdayStart(pref.wednesday_start);
      setWednesdayEnd(pref.wednesday_end);

      setThursdayStart(pref.thursday_start);
      setThursdayEnd(pref.thursday_end);

      setFridayStart(pref.friday_start);
      setFridayEnd(pref.friday_end);

      setSaturdayStart(pref.saturday_start);
      setSaturdayEnd(pref.saturday_end);
    } else {
      // If the user doesnt have a notification preference created, let's make one
      props.create();
    }
  }, [props.user.notification_preference]);


  const saveChanges = () => {
    props.save(
        props.user.id,
        {
          'sms_enabled': smsEnabled,
          'webpush_enabled': pushEnabled,
          'sunday_start': sundayStart,
          'sunday_end': sundayEnd,
          'monday_start': mondayStart,
          'monday_end': mondayEnd,
          'tuesday_start': tuesdayStart,
          'tuesday_end': tuesdayEnd,
          'wednesday_start': wednesdayStart,
          'wednesday_end': wednesdayEnd,
          'thursday_start': thursdayStart,
          'thursday_end': thursdayEnd,
          'friday_start': fridayStart,
          'friday_end': fridayEnd,
          'saturday_start': saturdayStart,
          'saturday_end': saturdayEnd,
        }
    ).then(() => {
      setSnackType("success");
      setSnackMessage("Schedule updated.");
      setSnackOpen(true);
    })
  }

  return <Grid container justify={"center"} wrap>
      <GridItemSpacer xs={12} sm={12} md={12}>
          <WhiteTextTypography>
              <h2 style={{paddingTop:"30px"}}>Notifications preference</h2>
          </WhiteTextTypography>
      </GridItemSpacer>
      <GridItemSpacer xs={12} sm={12} md={12}>
          <WhiteTextTypography>
              <Checkbox
                  checked={pushEnabled}
                  onChange={() => setPushEnabled(!pushEnabled)}
              />
              <span>Push notifications enabled</span>
          </WhiteTextTypography>
      </GridItemSpacer>
      <GridItemSpacer xs={12} sm={12} md={12}>
          <WhiteTextTypography>
            <Checkbox
                checked={smsEnabled}
                onChange={() => setSmsEnabled(!smsEnabled)}
            />
            <span>SMS notifications enabled</span>
          </WhiteTextTypography>
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Sunday start"
            fullWidth={true}
            variant="outlined"
            value={sundayStart}
            type="time"
            onChange={(ev) => setSundayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Sunday end"
            fullWidth={true}
            variant="outlined"
            value={sundayEnd}
            type="time"
            onChange={(ev) => setSundayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Monday start"
            fullWidth={true}
            variant="outlined"
            value={mondayStart}
            type="time"
            onChange={(ev) => setMondayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Monday End"
            fullWidth={true}
            variant="outlined"
            value={mondayEnd}
            type="time"
            onChange={(ev) => setMondayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Tuesday start"
            fullWidth={true}
            variant="outlined"
            value={tuesdayStart}
            type="time"
            onChange={(ev) => setTuesdayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Tuesday End"
            fullWidth={true}
            variant="outlined"
            value={tuesdayEnd}
            type="time"
            onChange={(ev) => setTuesdayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Wednesday start"
            fullWidth={true}
            variant="outlined"
            value={wednesdayStart}
            type="time"
            onChange={(ev) => setWednesdayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Wednesday End"
            fullWidth={true}
            variant="outlined"
            value={wednesdayEnd}
            type="time"
            onChange={(ev) => setWednesdayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Thursday start"
            fullWidth={true}
            variant="outlined"
            value={thursdayStart}
            type="time"
            onChange={(ev) => setThursdayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Thursday End"
            fullWidth={true}
            variant="outlined"
            value={thursdayEnd}
            type="time"
            onChange={(ev) => setThursdayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Friday start"
            fullWidth={true}
            variant="outlined"
            value={fridayStart}
            type="time"
            onChange={(ev) => setFridayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Friday End"
            fullWidth={true}
            variant="outlined"
            value={fridayEnd}
            type="time"
            onChange={(ev) => setFridayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Saturday start"
            fullWidth={true}
            variant="outlined"
            value={saturdayStart}
            type="time"
            onChange={(ev) => setSaturdayStart(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={6}>
        <CssTextField
            label="Saturday End"
            fullWidth={true}
            variant="outlined"
            value={saturdayEnd}
            type="time"
            onChange={(ev) => setSaturdayEnd(ev.target.value)}
        />
      </GridItemSpacer>
      <GridItemSpacer md={12}>
        <Button
            onClick={saveChanges}
            style={{
              backgroundColor: "green",
              padding: "0.7rem",
              fontWeight: "bold",
              color: "var(--trade-font)",
              border: "none",
              borderRadius: "4px",
              margin: "auto",
            }}
        >
          Save
        </Button>
        <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => setSnackOpen(false)}
        >
          <MuiAlert elevation={6} variant={"filled"} onClose={() => setSnackOpen(false)} severity={snackType}>
            {snackMessage}
          </MuiAlert>
        </Snackbar>
      </GridItemSpacer>
  </Grid>;
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    save: (id, options) => dispatch(workers.saveSchedule(id, options)),
    create: (id, options) => dispatch(workers.createNotificationPreference()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPreferences);
