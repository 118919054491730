import { logException, ax } from "utils";

const endpoint = "/api/help/tickets/";

export const fetchHelpTickets = () => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(endpoint)
          .then(res => {
              return dispatch({
                  type: 'FETCH_HELP_TICKETS',
                  items: res.data.results,
                  count: res.data.count,
              })
          })
          .catch(err => {
              dispatch({
                  type: 'DATA_ERROR_LOADING',
                  error: err
              });
              logException(err);
          })
    };
};

export const fetchHelpTicketsByWorkerId = (worker_id) => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        let params = {};
        if(worker_id !== undefined)
            params.created_by_id = worker_id;
            return ax.get(endpoint + "?" + new URLSearchParams(params))
            .then(res => {
                return dispatch({
                    type: 'FETCH_HELP_TICKETS',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const fetchHelpTicketsByCompanyId = (company_id) => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        let params = {};
        if(company_id !== undefined)
            params.company_id = company_id;
            return ax.get(endpoint + "?" + new URLSearchParams(params))
            .then(res => {
                return dispatch({
                    type: 'FETCH_HELP_TICKETS',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const getHelpTicket = id => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get(endpoint + id + '/')
            .then(res => {
                return dispatch({
                    type: 'GET_HELP_TICKET',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const createHelpTicket = (question, details, created_by, company) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.post(endpoint, {question, details, created_by, company})
          .then(res => {
              dispatch({type: "DATA_SAVED"});
              return dispatch({
                  type: 'HELP_TICKET_CREATED',
                  item: res.data
              });
          })
          .catch(err => {
              logException(err);
              return dispatch({
                  type: 'DATA_ERROR_SAVING',
                  error: err
              });
          });
    }
};

