import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// theme components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

// Table
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

// icons
import ImageIcon from "@material-ui/icons/Image";
import Edit from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import TrashIcon from "@material-ui/icons/Delete";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// custom code
import { files } from "actions";
import projectPageStyle from "assets/jss/tradespecifix/project";

class ProjectFilesList extends Component {
  state = {
    projectId: null,
  };

  componentDidMount() {
    const itemId = this.props.match.params.project_id;
    // console.log(itemId);
    if (itemId !== undefined) {
      this.props.fetchFiles(itemId);
      this.setState({ projectId: itemId });
    }
  }

  render() {
    const { classes, files } = this.props;
    const { projectId } = this.state;
    if (projectId === undefined || projectId === null) return null;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <Fab
                color="primary"
                aria-label="Add"
                className={classes.Fab}
                component={Link}
                to={"/projects/" + projectId + "/files/add"}
              >
                <AddIcon />
              </Fab>
              <CardIcon color="info">
                <ImageIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Files</h4>
            </CardHeader>
            <CardBody>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none">Name</TableCell>
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((item) => (
                    <TableRow
                      key={item.id}
                      className={
                        item.is_active === false ? classes.archived : null
                      }
                    >
                      <TableCell padding="none">{item.name}</TableCell>
                      <TableCell padding="none">
                        <Tooltip title="Download">
                          <Button
                            round
                            className={classes.firstButton}
                            color="info"
                            onClick={() =>
                              window.open(
                                process.env.API_URL +
                                  "/api/project-files/" +
                                  item.id +
                                  "/download",
                                "_blank"
                              )
                            }
                            justIcon
                          >
                            <CloudDownloadIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Button
                            round
                            className={classes.middleButton}
                            color="info"
                            component={Link}
                            to={
                              "/projects/" +
                              projectId +
                              "/files/" +
                              item.id +
                              "/edit"
                            }
                            justIcon
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Button
                            round
                            color="danger"
                            className={classes.lastButton}
                            onClick={() => this.props.deleteFile(item.id)}
                            justIcon
                          >
                            <TrashIcon />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ProjectFilesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    files: state.files.project_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: (project_id) => {
      return dispatch(files.fetchFilesByProjectId(project_id));
    },
    deleteFile: (file_id) => {
      return dispatch(files.deleteFile(file_id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(projectPageStyle)(ProjectFilesList));
