import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import AvatarStyle from "./AvatarStyle";
import Gravatar from "react-gravatar";

class Avatar extends React.Component {
    render() {
        const { classes, email, avatar_url, size, shadow } = this.props;

        const containerClasses = (shadow? classes.container + " " + classes.containerShadow: classes.container);
        if(this.props.avatar_url){
            const maxSizes = {
                width: size + "px",
                height: size + "px",
            };
            return <div className={containerClasses} style={maxSizes}>
                <img src={avatar_url} alt={"user avatar"} className={classes.img}/>
            </div>;
        }else{
            return <div className={containerClasses}>
                <Gravatar className={classes.avatarImg} email={email} size={size}/>
            </div>
        }
    }
}

Avatar.propTypes = {
    avatar_url: PropTypes.string,
    email: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
    shadow: PropTypes.bool,
};

Avatar.defaultProps = {
    shadow: false
};

export default withStyles(AvatarStyle)(Avatar);
