import 'App.css';
import 'react-dates/initialize';
import 'whatwg-fetch';
import React, { Component } from 'react';
import { Provider} from "react-redux";
import { createStore, applyMiddleware } from 'redux';

import tradeApp from "reducers";
import RootContainer from "components/RootContainer";

import thunk from "redux-thunk";
import createSentryMiddleware from "redux-sentry-middleware";
import * as Sentry from "@sentry/browser";
import 'moment-timezone';
import { messaging } from "fcm";

let store = null;
if(process.env.SENTRY_DSN !== 'null'){
  Sentry.init({dsn: process.env.SENTRY_DSN, release: process.env.RELEASE});
  store = createStore(tradeApp, applyMiddleware(thunk, createSentryMiddleware(Sentry)));
}else{
  store = createStore(tradeApp, applyMiddleware(thunk));
}

class App extends Component {
    componentDidMount() {
        if(messaging.isSupported()){
            Notification.requestPermission().then( permission => {
              if(permission === 'granted'){
                messaging.getToken().then(token => localStorage.setItem("fcmToken", token));
              }
            });
        }
    }
    render() {
        return (
            <Provider store={store}>
                <RootContainer/>
            </Provider>
        );
    }
}


export default App;
