const initialState = {
    items: [],
    worker_items: [],
    current_project_items: [],
    item: null,
    count: 0,
    next: null,
    prev: null,
    loading: true,
  };
  
  export default function notes(state = initialState, action) {
    switch (action.type) {
      case "CREATE_NOTE":
        return {
          ...state,
          item: action.item,
          current_project_items: [...state.current_project_items, action.item]
        }
      case "FETCH_NOTES":
        return {
          ...state,
          items: action.items,
          count: action.count,
        };
      case "FETCH_PROJECT_NOTES":
        return {
          ...state,
          current_project_items: action.items,
          count: action.count,
          next: action.next,
          prev: action.prev,
          loading: false,
        };
      case "APPEND_PROJECT_NOTES":
        return {
          ...state,
          current_project_items:[...state.current_project_items, ...action.items],
          count: action.count,
          next: action.next,
          prev: action.prev,
        }
      case "FETCH_WORKER_NOTES":
        return {
          ...state,
          worker_items: action.items,
          count: action.count,
        };
  
      case "GET_NOTE":
        return { ...state, item: action.item };
  
      case "ROTATED_NOTE_IMAGE":
        let item = state.item;
        const id = item.images.findIndex((el) => el.id === action.item_id);
        if (id !== -1) {
          item.images[id].image = action.image;
        }
  
        return { ...state, item };
      case "NOTE_IMAGE_CREATED":
        let temps = state.items.map((item) => {
          if (item.id === action.item.note_id) {
            item.images.append(action.item);
          }
          return item;
        })

        return {...state, items: temps}
      case "DELETED_NOTE":
        const items = state.items.filter((el) => el.id !== action.item_id);
        return { ...state, items: items };
      case "LOGOUT_SUCCESSFUL":
        return {
          items: [],
          worker_items: [],
          current_project_items: [],
          item: null,
          count: 0,
          next: null,
          prev: null,
        }
      default:
        return state;
    }
  }
  