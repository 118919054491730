// ##############################
// // // Stripe view styles
// #############################
import {
    primaryColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const StripeComponentStyle = {
    center: {
        textAlign: "center",
    },
    stripeCard: {
        margin: "20px 0",
    },
    selectMenuItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "2",
        whiteSpace: "nowrap",
        color: "#333",
        paddingRight: "30px",
    },
    selectLabel: {
        fontSize: "14px",
        color: "rgba(0,0,0, 0.45)",
    },
    selectFormControl: {
        margin: "5px 1px 4px 0px !important",
        "& > div": {
            "&:before": {
                borderBottomWidth: "1px !important",
                borderBottomColor: "#D2D2D2 !important"
            },
            "&:after": {
                borderBottomColor: primaryColor + "!important"
            }
        }
    },
    selectMenu: {
        marginBottom: "10px",
        "& > div > ul": {
            border: "0",
            padding: "5px 0",
            margin: "0",
            boxShadow: "none",
            minWidth: "100%",
            borderRadius: "4px",
            boxSizing: "border-box",
            display: "block",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box"
        },
        "& > div + div": {
            maxHeight: "266px !important"
        }
    },
    firstMonthCharge: {
        display: "block",
        marginTop: "10px"
    }
};

export default StripeComponentStyle;
