import { logException, ax } from "utils";
const endpoint = "/api/user-positions/";
const gpsEndpoint = "/api/gps-entries/";

export const fetchUserPositions = (start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (start !== undefined) {
      params.start = start + " 00:00";
    }
    if (end !== undefined) {
      params.end = end + " 23:59";
    }
    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_USER_POSITIONS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchUserPositionsByWorkerId = (worker_id, start, end) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    let params = {};
    if (start !== undefined) {
      params.start = start + " 00:00";
    }
    if (end !== undefined) {
      params.end = end + " 23:59";
    }
    if (worker_id !== undefined) params.worker_id = worker_id;

    return ax
      .get(endpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_USER_POSITIONS",
          items: res.data.results,
          count: res.data.count,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const getUserPosition = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(endpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_USER_POSITION",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

// main method used for gps entries in dashboard
export const getGPSEntries = (project_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(gpsEndpoint+"?project_id=" + project_id)
      .then((res) => {
        return dispatch({
          type: "GET_PROJECT_GPS_ENTRIES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};

export const fetchMoreEntriesByProjectId = (url) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(url)
      .then((res) => {
        return dispatch({
          type: "APPEND_PROJECT_GPS_ENTRIES",
          items: res.data.results,
          count: res.data.count,
          next: res.data.next,
          prev: res.data.previous,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
}


export const getGPSEntriesByWorkerId = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(gpsEndpoint+"?worker_id=" + worker_id)
      .then((res) => {
        return dispatch({
          type: "GET_WORKER_GPS_ENTRIES",
          item: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
        logException(err);
      });
  };
};
