import {
    container,
    cardTitle,
} from "assets/jss/material-dashboard-pro-react.jsx";

import {successColor} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import fabStyle from "assets/jss/tradespecifix/fab";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const safetyMeetingsPageStyle = {
    ...dashboardStyle,
    ...buttonStyle,
    ...fabStyle,
    ...customCheckboxRadioSwitch,
    ...customSelectStyle,
    ...customInputStyle,
    cardTitle,
    container: {
        ...container,
    },
    checkboxError: {
        fontSize: "14px",
    },
    selectLabel: {
        fontSize: "14px",
        top: "20px",
    },
    requiredAsterisk: {
        color: 'red',
    },
    editor: {
        border: "1px solid #aeaeae",
    },
    editorContent: {
        margin: "0 10px",
    },
    required: {
        '&:after': {
            content: "'\\00a0*'",
            color: 'red',
        },
    },
    checkboxAndRadioHorizontal: {
        position: "relative",
        display: "block",
        float: "right",
        color: "rgba(0, 0, 0, 0.26)",
        fontSize: "14px",
        "&:first-child": {
            marginTop: "15px"
        },
        marginBottom: "0"
    },
    safetyImage: {
        maxWidth: "300px",
        margin: "0 10px"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardBody: {
        '& div > h2:first-child': {
            marginTop: "0px"
        }
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    buttonGroup: {
        paddingTop: "1em",
        "button:not(:first-child)": {
        }
    },
    button: {
        marginRight: "0px",
    },
    buttonSelected: {
        marginRight: "0px",
        backgroundColor: successColor,
        "&:focus, &:active": {
            backgroundColor: successColor,
        }
    }
};

export default safetyMeetingsPageStyle;
