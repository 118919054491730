import React, { Component } from "react";
import "./Navbar.css";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Dropdown, Nav } from "react-bootstrap";
import { Avatar, Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Moment from "react-moment";
import PropTypes from "prop-types";
import AssignWorkerFromDashboard from "../AssignWorkerFromDashboard/AssignWorkerFromDashboard";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import SearchDashboard from "../SearchDashboard/SearchDashboard";
import Snackbar from "@material-ui/core/Snackbar";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import LoginWorker from "../LoginWorker/LoginWorker";

import { time_logs, workers } from "actions";
import { getUserTimezone } from "utils";

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
  },
})(TextField);

class NavBar extends Component {
  _isMounted = false;
  state = {
    saving: false,
    longitude: null,
    latitude: null,
    loading: false,
    passwordDialogOpen: false,
    password: "",
    passwordConfirm: "",
    snackOpen: false,
    snackMessage: "",
    snackType: "error",
  };

  componentDidMount() {
    this._isMounted = true;
    this.getCoordinates();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  shouldComponentUpdate(nextProps, stat, smt, loading) {
    if (
      nextProps.user !== this.props.user ||
      stat.latitude !== this.state.latitude ||
      stat.longitude !== this.state.longitude ||
      loading !== this.state.loading ||
      stat.passwordDialogOpen !== this.state.passwordDialogOpen ||
      this.state.passwordDialogOpen
    ) {
      return true;
    }

    return false;
  }

  getCoordinates = () => {
    let url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            latitude: parseFloat(response.data.latitude),
            longitude: parseFloat(response.data.longitude),
          });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  startDelivery = () => {
    const { latitude, longitude } = this.state;
    const project_id = this.props.user.last_time_log.project.id;

    const data = {
      project_id: project_id,
      latitude: latitude,
      longitude: longitude,
      loading: true,
    };

    this.props.startDelivery(data);
  };

  startBreak = () => {
    const { latitude, longitude } = this.state;
    const project_id = this.props.user.last_time_log.project.id;

    const data = {
      project_id: project_id,
      latitude: latitude,
      longitude: longitude,
      loading: true,
      timezone: getUserTimezone(),
    };

    this.props.startBreak(data);
  };

  stopTimer = () => {
    const { user, stopTimer } = this.props;
    const { latitude, longitude } = this.state;
    this.setState(
      {
        projectSelected: user.last_time_log.project.id,
        saving: true,
        savingStop: true,
        savingBreak: false,
        loading: true,
      },
      () => {
        stopTimer(user.last_time_log.id, latitude, longitude).then((res) => { });
      }
    );
  };

  stopButtonState() {
    const { latitude, longitude } = this.state;
    if (latitude !== null && longitude !== null) {
      return false;
    }
    return true;
  }

  handleOpen = () => {
    this.setState({
      passwordDialogOpen: true,
    });
  };

  passwordValidator = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]{8,}$/;

    return (
      regex.test(this.state.password) &&
      this.state.password === this.state.passwordConfirm
    );
  };

  changePassword = () => {
    // if (this.passwordValidator()) {
    this.props
      .updatePassword(this.state.password, this.props.user.id)
      .then((elem) => {
        if (elem.type === "GET_WORKER") {
          this.setState({
            passwordDialogOpen: false,
            snackType: "success",
            snackMessage: "Password updated",
            snackOpen: true,
            password: "",
          });
        }
      });
    // } else {
    //   this.setState({
    //     snackType: "error",
    //     snackMessage: "Passwords don't match or don't meet requirements",
    //     snackOpen: true,
    //   });
    // }
  };

  render() {
    const classes = {
      toggle: {
        border: "white",
        width: "250px",
        height: "3.125rem",
        background: "rgba(0,0,0,0)",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        color: "white",
      },
      userAvatar: {
        height: "2.5rem",
        width: "2.5rem",
        border: "solid 2px gray",
      },
      menuStyle: {
        width: "250px",
        textAlign: "center",
      },
      detailStyle: { fontSize: "0.8rem", fontFamily: "Montserrat, sans-serif" },
      dropdownStyle: {
        // fontFamily: "Montserrat, sans-serif",
        marginBottom: "0",
      },
      btnContainer: {
        height: "50px",
        width: "300px",
        backgroundColor: "var(--trade-background)",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        borderRadius: "5px",
        marginLeft: "65px",
      },
    };
    const { user, setRef } = this.props;

    const { latitude, longitude } = this.state;

    return (
      <div style={{ backgroundColor: "var(--trade-body)" }}>
        <Navbar bg="transparent" ref={setRef}>
          {user.last_time_log !== null &&
            user.last_time_log.end === null &&
            user.last_time_log.safety_meeting_read ? (
            user.is_staff || user.is_manager ? (
              <Nav style={classes.btnContainer}>
                {user.role !== "WORKER" ? (
                  <AssignWorkerFromDashboard
                    type="dashboard"
                    handleNewWorkerCreated={this.props.handleNewWorkerCreated}
                    newWorkerCreated={this.props.newWorkerCreated}
                  />
                ) : null}
                <LoginWorker
                  latitude={latitude}
                  longitude={longitude}
                  redirect={this.props.redirect}
                />
              </Nav>
            ) : null
          ) : null}
          {user.last_time_log !== null &&
            user.last_time_log.end === null &&
            user.last_time_log.safety_meeting_read ? (
            <Nav className="ml-auto">
              <div className="workerTimeInfoContainer">
                <div className="worker-time-info">
                  <div className="time-in">
                    <p>Time In</p>
                    <p>
                      <Moment format={"LT"}>{user.last_time_log.start}</Moment>
                    </p>
                  </div>
                  <div className="lunch">
                    <p>Lunch</p>
                    <p>-</p>
                  </div>
                  <div className="hours">
                    <p>Hours</p>
                    <p>
                      <Moment date={user.last_time_log.start} durationFromNow />
                    </p>
                  </div>
                </div>
                {this.state.loading ? (
                  <div className="spinner-container">
                    <Spinner
                      animation="border"
                      variant="warning"
                      className="spinner"
                      style={{ position: "inherit" }}
                    />
                  </div>
                ) : (
                  <div className="btn-navbar-container">
                    <div className="break">
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          color: "var(--trade-secondary)",
                          borderColor: "var(--trade-secondary)",
                          fontSize: "0.65rem",
                        }}
                        onClick={this.startBreak}
                      >
                        Break
                      </Button>
                    </div>

                    <div className="delivery">
                      <Button
                        variant="outlined"
                        size="small"
                        style={{
                          color: "var(--trade-primary)",
                          borderColor: "var(--trade-primary)",
                          fontSize: "0.65rem",
                        }}
                        onClick={this.startDelivery}
                      >
                        Delivery
                      </Button>
                    </div>

                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        color: "var(--trade-tertiary)",
                        borderColor: "var(--trade-tertiary)",
                        fontSize: "0.65rem",
                      }}
                      onClick={this.stopTimer}
                    // disabled={this.stopButtonState()}
                    >
                      Stop
                    </Button>

                    <SearchDashboard />
                  </div>
                )}
              </div>
            </Nav>
          ) : null}
          <Nav className="ml-auto">
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="dropdown-toggle"
                style={classes.toggle}
              >
                <div style={classes.detailStyle}>
                  <div>
                    {this.props.user.first_name} {this.props.user.last_name}
                  </div>
                  <div>
                    {this.props.user.is_manager ? "Project Manager" : ""}
                  </div>
                </div>

                <Avatar
                  alt={this.props.user.username}
                  src={this.props.user.avatar}
                  style={classes.userAvatar}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu style={classes.menuStyle} container="body">
                {user.last_time_log !== null &&
                  user.last_time_log.end === null ? (
                  <Dropdown.Item
                    as={Link}
                    to={"/workers/" + this.props.user.id}
                  >
                    <p style={classes.dropdownStyle}>Edit Profile</p>
                  </Dropdown.Item>
                ) : null}
                {user.last_time_log !== null &&
                  user.last_time_log.end === null ? (
                  <Dropdown.Item
                    as={Link}
                    to={"/workers/" + this.props.user.id + "/timesheet"}
                  >
                    <p style={classes.dropdownStyle}>My Timesheet</p>
                  </Dropdown.Item>
                ) : null}
                {user.last_time_log !== null &&
                  user.last_time_log.end === null ? (
                  <Dropdown.Item
                    // as={Button}
                    onClick={this.handleOpen}
                  >
                    <p style={classes.dropdownStyle}>Reset Password</p>
                  </Dropdown.Item>
                ) : null}
                {user.last_time_log !== null &&
                  user.last_time_log.end === null &&
                  user.last_time_log.project.company_owner === user.id ? (
                  <Dropdown.Item
                    as={Link}
                    to={
                      "/companies/" +
                      user.last_time_log.project.company_id +
                      "/"
                    }
                  >
                    <p style={classes.dropdownStyle}>Company Page</p>
                  </Dropdown.Item>
                ) : null}

                <Dropdown.Item as={Link} to={"/lock"}>
                  <p style={classes.dropdownStyle}>Log Out</p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar>
        <Dialog
          open={this.state.passwordDialogOpen}
          onClose={() =>
            this.setState({
              passwordDialogOpen: false,
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: "#000000" }}
          >
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <WhiteTextTypography variant="h5">
                Change Password
              </WhiteTextTypography>
            </Box>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#000000" }}>
            <Grid container>
              {/* <Grid item md={12}>
                <Box
                  m={1}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <WhiteTextTypography className={classes.passwordReqs}>
                    Minimum eight characters, at least one uppercase letter, one
                    lowercase letter and one number
                  </WhiteTextTypography>
                </Box>
              </Grid> */}
              <Grid item md={12}>
                <Box m={1}>
                  <CssTextField
                    label="Password"
                    fullWidth={true}
                    variant="outlined"
                    value={this.state.password}
                    type="password"
                    onChange={(ev) => {
                      this.setState({
                        password: ev.target.value,
                      });
                    }}
                    name="firstName"
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box m={1}>
                  <CssTextField
                    label="Confirm Password"
                    fullWidth={true}
                    variant="outlined"
                    value={this.state.passwordConfirm}
                    type="password"
                    onChange={(ev) =>
                      this.setState({
                        passwordConfirm: ev.target.value,
                      })
                    }
                    name="firstName"
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#000000" }}>
            <Button
              onClick={() =>
                this.setState({
                  passwordDialogOpen: false,
                })
              }
              style={{ color: "white" }}
            >
              Cancel
            </Button>
            <Button onClick={this.changePassword} style={{ color: "white" }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackOpen: false })}
        >
          <Alert
            onClose={() => this.setState({ snackOpen: false })}
            severity={this.state.snackType}
          >
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

Navbar.propTypes = {
  projects: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stopTimer: (time_log_id, latitude, longitude) => {
      return dispatch(time_logs.stopTimeLog(time_log_id, latitude, longitude));
    },
    startBreak: (data) => {
      return dispatch(
        time_logs.startBreak(
          data.project_id,
          data.latitude,
          data.longitude,
          data.worker_id,
          data.timezone,
        )
      );
    },
    startDelivery: (data) => {
      return dispatch(
        time_logs.startDelivery(
          data.project_id,
          data.latitude,
          data.longitude,
          data.worker_id
        )
      );
    },
    updatePassword: (password, workerId) => {
      return dispatch(workers.saveWorkerPassword(workerId, password));
    },
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
