import React, { useState, useEffect } from "react";
import "./WorkerTabPane.css";
import { connect } from "react-redux";

import SideDrawer from "../../newComponents/SideDrawer/SideDrawer";
import Spinner from "react-bootstrap/Spinner";
; // require
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


const SimpleList = (props) => {
  const { currentYear } = props;
  const [monthList, setMonthList] = useState([]);

  useEffect(() => {
    var temps = [];

    const today = new Date();
    if (props.currentYear === today.getFullYear()) {
      const currentMonth = today.getMonth();
      for (let i = currentMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    } else {
      const lastMonth = 11;
      for (let i = lastMonth; i >= 0; i--) {
        temps.push(`${months[i]} ${currentYear}`);
      }
    }

    setMonthList(temps);

  }, [currentYear]);

  return (
    <div className="projectTimeSheetWrapper" id="style-5">
      {monthList.length > 0 ? (
        (
          monthList.map((item, key) => (
            <SideDrawer
              key={key}
              item={item}
              type={"safety-meetings-worker"}
              worker_id={props.worker_id}
            />
          ))
        )
      ) : (
        <span className="no-files-message">
          No Timesheet Available for this Project.
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleList);
