import React, { Component } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import CardMap from "../CardMap/CardMap";
import "./CardCarousel.css";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SwitchSite from "../SwitchSite/SwitchSite";
import Spinner from "react-bootstrap/Spinner";

import { connect } from "react-redux";
import { ax } from "../../utils";

const breakPoints = [{ width: 1, itemsToShow: 5 }];

class CardCarousel extends Component {
  state = {
    switchSiteValue: false,
    currentProject: null,
    currentProjectID: null,
    tasks: [],
    projects: [],
    loading: true,
  };

  myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <ArrowBackIosIcon style={{ color: "white" }} />
      ) : (
        <ArrowForwardIosIcon style={{ color: "white" }} />
      );
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  }

  openSwitchSite = (project) => {
    this.setState({
      switchSiteValue: true,
      currentProjectTitle: project.title,
      currentProjectID: project.id,
      currentProject: project,
      tasks: project.tasks,
    });
  };
  componentDidMount() {
    ax.get("/api/projects/?archived=false").then((res) => {
      this.setState({ projects: res.data.results, loading: false });
    });
  }

  closeSwitchSite = () => {
    this.setState({
      switchSiteValue: false,
    });
  };

  render() {
    const {
      switchSiteValue,
      currentProjectTitle,
      currentProjectID,
      currentProject,
      tasks,
      projects,
      loading,
    } = this.state;
    const { projectID } = this.props;

    const filterProjects = projects
      .filter((project) => project.id !== projectID)
      .sort((a, b) => a.title > b.title);

    return filterProjects.length !== 0 ? (
      <div className="carouselContainer" ref={this.props.setRef}>
        <h1 style={{ color: "white" }}>Active Projects</h1>
        <SwitchSite
          currentProjectTitle={currentProjectTitle}
          currentProjectID={currentProjectID}
          project={currentProject}
          tasks={tasks}
          switchSiteValue={switchSiteValue}
          closeSwitchSite={this.closeSwitchSite}
          redirect={this.props.redirect}
        />
        <Carousel
          breakPoints={breakPoints}
          pagination={false}
          style={{ width: "100%", marginTop: "30px" }}
          renderArrow={this.myArrow}
        >
          {filterProjects.map((project, key) => {
            return (
              <CardMap
                key={key}
                project={project}
                openSwitchSite={() => this.openSwitchSite(project)}
              />
            );
          })}
        </Carousel>
      </div>
    ) : loading ? (
      <Spinner
        animation="border"
        variant="warning"
        className="spinner"
        style={{ margin: "auto" }}
      />
    ) : (
      <div className="cardCarouselErrorMessage" ref={this.props.setRef}>
        <h1>No Active Projects</h1>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCarousel);
