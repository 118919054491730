import { logException, ax } from "../utils";
let moment = require("moment");

const usersEndpoint = "/api/users/";
const notificationPreferenceEndpoint = "/api/notifications/";
const workersEndpoint = "/api/workers/";

//will use company of current timelog to know which workers to fetch
export const fetchWorkers = (limit, page) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });

    let params = {};
    if (limit !== undefined) params.limit = limit;
    if (page !== undefined) params.offset = page * limit;

    return ax
      .get(workersEndpoint + "?" + new URLSearchParams(params))
      .then((res) => {
        return dispatch({
          type: "FETCH_WORKERS",
          items: res.data.results,
          count: res.data.count,
          count_active: res.data.count_active,
          origin: "fetch",
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// pretty much the same as method above, but here we explicitly pass company
// in fetchworkers company is implied by the backend based on last time log
export const getCompanyWorkers = (company_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });

    return ax
      .get(workersEndpoint + "?company=" + company_id)
      .then((res) => {
        return dispatch({
          type: "FETCH_WORKERS",
          items: res.data.results,
          count: res.data.count,
          count_active: res.data.count_active,
          origin: "getcompany",
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

export const reactivateWorker = (worker_id, worker_role) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .patch(workersEndpoint + worker_id + "/", {
        archived: false,
        role: worker_role,
      })
      .then((res) => {
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

export const updateWorkerManagerStatus = (worker_id, role) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .patch(workersEndpoint + worker_id + "/", { role: role })
      .then((res) => {
        return dispatch({
          type: "GET_WORKER",
          item: { ...res.data, worker_id },
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

export const archiveWorker = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_DELETE" });
    return ax
      .delete(workersEndpoint + worker_id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_DELETE",
          error: err,
        });
      });
  };
};

// don't know exactly the purpose of it, some components use it
export const gpsRefused = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      return ax
        .put(usersEndpoint, {
          gps_permission_status: "denied",
        })
        .then((res) => {
          return res.data;
        })
        .catch((e) => logException(e));
    }
  };
};

export const updateUserTimezone = (timezone) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      return ax
          .put(usersEndpoint, { timezone })
          .catch((e) => logException(e));
    }
  };
}

// don't know exactly the purpose of it, some components use it
export const gpsAccepted = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      return ax
        .put(usersEndpoint, { gps_permission_status: "accepted" })
        .then((res) => {
          return res.data;
        })
        .catch((e) => logException(e));
    }
  };
};

// don't know exactly the purpose of it, used in dashboard
export const savePushSub = (sub) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      return ax
        .put(usersEndpoint, {
          pubsub: sub,
        })
        .then((res) => {
          return res.data;
        })
        .catch((e) => logException(e));
    }
  };
};

// used everywhere
export const getWorker = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    return ax
      .get(usersEndpoint + id + "/")
      .then((res) => {
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

export const createWorker = (
  first_name,
  last_name,
  email,
  phone_number,
  company,
  password,
  is_manager,
  role
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .post(workersEndpoint, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone_number: phone_number,
        company: company,
        password: password,
        is_manager: is_manager,
        role: role,
      })
      .then((res) => {
        if (res.error === undefined) {
          return dispatch({ type: "DATA_SAVED", item: res.data });
        } else {
          return dispatch({
            type: "DATA_ERROR_SAVING",
            error: res.error,
          });
        }
      })
      .catch((err) => {
        logException(err);
        return dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const saveWorker = (
  id,
  first_name,
  last_name,
  email,
  phone_number,
  company,
  is_manager,
  gps_forced,
  role
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .put(usersEndpoint + id + "/", {
        first_name,
        last_name,
        email,
        phone_number,
        company,
        is_manager,
        gps_forced,
        role,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const updateAvatar = (id, avatar) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });

    let formData = new FormData();
    formData.append("avatar", avatar, avatar.name);

    return ax
      .post(usersEndpoint + id + "/add-avatar/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

// probably not used anymore
export const updatePosition = (latitude, longitude) => {
  return (dispatch) => {
    dispatch({ type: "DATA_LOADING" });
    if (latitude !== null) latitude = latitude.toFixed(7);
    if (longitude !== null) longitude = longitude.toFixed(7);
    const data = {
      latitude,
      longitude,
    };
    return ax
      .post("/api/user-positions/", data)
      .then((res) => {
        return dispatch({
          type: "OWN_POSITION_UPDATED",
          latitude,
          longitude,
          time: moment(),
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_LOADING",
          error: err,
        });
      });
  };
};

// probably not used anymore
export const removeAvatar = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(usersEndpoint + id + "/", {
        avatar: null,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const saveWorkerPassword = (id, password) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(usersEndpoint + id + "/", {
        password,
      })
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const saveSchedule = (id, options) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .patch(notificationPreferenceEndpoint + id + "/", options)
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "SCHEDULE_UPDATED",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};

export const createNotificationPreference = () => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
        .post(notificationPreferenceEndpoint)
        .then((res) => {
          dispatch({ type: "DATA_SAVED" });
          return dispatch({
            type: "SCHEDULE_UPDATED",
            item: res.data,
          });
        })
        .catch((err) => {
          logException(err);
          dispatch({
            type: "DATA_ERROR_SAVING",
            error: err,
          });
        });
  };
}

//
export const getWorkerAssignedProjects = (worker_id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SAVING" });
    return ax
      .get(usersEndpoint + worker_id + "/projects/")
      .then((res) => {
        dispatch({ type: "DATA_SAVED" });
        return dispatch({
          type: "GET_WORKER_ASSIGNED_PROJECTS",
          item: res.data,
        });
      })
      .catch((err) => {
        logException(err);
        dispatch({
          type: "DATA_ERROR_SAVING",
          error: err,
        });
      });
  };
};
