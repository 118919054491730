import React, { Component } from "react";
import { Avatar, Box } from "@material-ui/core";
import mapImage from "assets/img/dummy-map.png";
import { AvatarGroup } from "@material-ui/lab";
import "./CardMap.css";
import Card from "react-bootstrap/Card";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import Gravatar from "components/Avatar/Avatar";

const useStyles = (theme) => ({
  root: {
    marginLeft: "10px",
  },
});

class CardMap extends Component {
  state = {
    progress: 60,
  };

  render() {
    const { progress } = this.state;
    const styles = {
      companyLogo: { position: "absolute", borderRadius: "5px" },
      cardStyle: {
        width: "17rem",
        backgroundColor: "rgb(53, 51, 51)",
        borderRadius: "10px",
        justifyContent: "space-between"
      },
      avatarStyle: { height: "2rem", width: "2rem" },
    };

    const { classes, project } = this.props;
    const handleClick = () => {
      this.props.openSwitchSite();
    };

    return (
      <Card style={styles.cardStyle} onClick={handleClick}>
        <Avatar
          src="/static/images/avatar/5.jpg"
          variant="square"
          style={styles.companyLogo}
        />
        <Card.Img variant="top" src={mapImage} />
        <p className="projectTitle">{project.title}</p>

        <p className="cardTitle">Team Members</p>
        {project.users_on_project.length !== 0 ? (
          <AvatarGroup max={5} className={classes.root}>
            {project.users_on_project.map((index, key) => {
              return (
                // <Avatar alt={index.first_name} src={index.avatar} key={key} />
                <Gravatar
                  email={index.email}
                  avatar_url={index.avatar}
                  size={40}
                  key={key}
                />
              );
            })}
          </AvatarGroup>
        ) : (
          <p className="cardTitleMessageMemberSite">No Member on Site</p>
        )}
        <p className="cardTitle">On Site</p>
        <div className="workerListContainer">
          {project.users_on_project.length !== 0 ? (
            project.users_on_project.map((index, key) => {
              return <ListItem key={key} info={index} />;
            })
          ) : (
            <p className="cardTitleMessageMemberSite">No Member on Site</p>
          )}
        </div>
        <div className="cardStatus">
          {project.status ? project.status.status : "Not Started"}
        </div>

        {/*  to be implemented into the next phase (MM,June,9,2022)
        <div className="progressBarContainer" >
          <p className="progressTitle">Program Completion</p>
          <p className="progressValue">{progress}%</p>
        </div>
        <div className="progressBar">
          <LinearProgressWithLabel value={progress} />
        </div> */}


      </Card>
    );
  }
}

class ListItem extends Component {
  render() {
    const { info } = this.props;
    return (
      <div className="cardGridContainer">
        <div className="item1 item">
          {/* <Avatar style={styles.userAvatarStyle} /> */}
          <Gravatar email={info.email} avatar_url={info.avatar} size={25} />
        </div>
        <div className="item2 item"></div>

        <div className="item3 item"></div>
        <div className="item4 item">
          <p className="cardInfo cardUserName">
            {info.first_name} {info.last_name}
          </p>
        </div>

        <div className="item5 item">
          <p className="cardInfo"> Time</p>
        </div>
        <div className="item6 item">
          <p className="cardInfo">
            {" "}
            <Moment date={info.last_time_log.start} durationFromNow />
          </p>
        </div>

        <div className="item7 item">
          <p className="cardInfo">Start</p>
        </div>
        <div className="item8 item">
          <p className="cardInfo">
            <strong>
              <Moment format={"LT"}>{info.last_time_log.start}</Moment>
            </strong>
          </p>
        </div>

        <div className="item9 item">
          <p className="cardInfo">End</p>
        </div>
        <div className="item10 item">
          <p className="cardInfo">-</p>
        </div>

        <div className="item11 item">
          <span className="dotGreen"></span>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(CardMap);
