import React from "react";

import Snackbar from "components/Snackbar/Snackbar.jsx";

import AddAlert from "@material-ui/icons/AddAlert";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

class BaseComponent extends React.Component {
  initState(state_) {
    state_ = this.getInitState(state_);
    this.setState(state_);
  }
  getInitState(state_) {
    if (state_ === undefined) {
      state_ = {};
    }
    state_["alert_open"] = true;
    return state_;
  }
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    window.removeEventListener("online", this.connectionListener);
    window.removeEventListener("offline", this.connectionListener);
  }
  componentDidMount() {
    this.connectionListener = () => this.handleConnection();
    window.addEventListener("online", this.connectionListener);
    window.addEventListener("offline", this.connectionListener);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
  handleConnection() {
    if (navigator.onLine === false) {
      this.showNotification(
        "You are not currently connected to the internet.",
        "danger",
        0
      );
    } else if (this.state.alert !== null) {
      this.setState({ alert: null });
    }
  }
  showNotification(msg, color, timeout) {
    this.setState({
      alert: (
        <Snackbar
          place="tc"
          color={color}
          icon={AddAlert}
          message={msg}
          open={this.state.alert_open}
          closeNotification={() => this.setState({ alert: null })}
          close
        />
      ),
    });
    if (timeout === undefined) timeout = 6000;
    if (timeout > 0)
      setTimeout(
        function () {
          this.setState({ alert: null });
        }.bind(this),
        timeout
      );
  }
  handleChange(event, value) {
    if (value === undefined) {
      value = event.target.value;
      // if(value === '')
      //     value = null;
      if (value === "true" || value === "false") value = value === "true";
    }
    if (event.target.id) {
      this.setState({ [event.target.id]: value });
    } else {
      this.setState({ [event.target.name]: value });
    }
  }
  handleChangeCheck(e) {
    this.handleChange(e, e.target.checked);
  }
  showSuccessfulAlert() {
    this.showNotification("Changes saved successfully", "success");
  }
  showError(e) {
    this.showNotification("An error occurred: " + e.message, "danger");
  }
}

BaseComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BaseComponent;
