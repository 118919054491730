import { logException, ax } from "utils";

export const fetchBreaks = (worker_id, start, end) => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        let params = {};
        if(worker_id !== undefined)
            params.worker_id = worker_id;

        if(start !== undefined){
            params.start = start + " 00:00";
        }
        if(end !== undefined){
            params.end = end + " 23:59";
        }
        return ax.get("/api/time-logs/?" + new URLSearchParams(params))
            .then(res => {
                return dispatch({
                    type: 'FETCH_BREAKS',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const fetchBreaksByProjectId = (project_id, start, end) => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});

        let params = {};
        if(project_id !== undefined)
            params.project_id = project_id;

        if(start !== undefined){
            params.start = start + " 00:00";
        }
        if(end !== undefined){
            params.end = end + " 23:59";
        }
        return ax.get("/api/time-logs/?" + new URLSearchParams(params))
            .then(res => {
                return dispatch({
                    type: 'FETCH_BREAKS',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                logException(err);
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
            })
    };
};

export const fetchBreaksByCompanyId = (company_id, start, end) => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});

        let params = {};
        if(company_id !== undefined)
            params.company_id = company_id;

        if(start !== undefined){
            params.start = start + " 00:00";
        }
        if(end !== undefined){
            params.end = end + " 23:59";
        }
        return ax.get("/api/time-logs/?" + new URLSearchParams(params))
            .then(res => {
                return dispatch({
                    type: 'FETCH_BREAKS',
                    items: res.data.results,
                    count: res.data.count,
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};


export const getBreak = id => {
    return (dispatch) => {
        dispatch({type: "DATA_LOADING"});
        return ax.get("/api/time-logs/" + id + '/')
            .then(res => {
                return dispatch({
                    type: 'GET_BREAK',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_LOADING',
                    error: err
                });
                logException(err);
            })
    };
};

export const updateBreak = (id, start, end) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.patch(
            "/api/time-logs/" + id + "/",
            {start, end}
            )
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                return dispatch({
                    type: 'GET_BREAK',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
            })

    }
};

export const stopBreak = (id, latitude, longitude) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});
        return ax.patch(
            "/api/time-logs/" + id + "/",
            {latitude, longitude}
            )
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                dispatch({
                    type: 'BREAK_STOPPED',
                    item: res.data
                });
                return dispatch({
                    type: 'GET_BREAK',
                    item: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
            })

    }
};

export const startBreak = (project_id, latitude, longitude, worker_id, timezone) => {
    return (dispatch) => {
        dispatch({type: "DATA_SAVING"});

        if(latitude !== null)
          latitude = latitude.toFixed(7);
        if(longitude !== null)
          longitude = longitude.toFixed(7);

        let data = {
            'project': {'id': project_id},
            'latitude': latitude,
            'longitude': longitude,
            'type': 'break',
            timezone,
        };

        if(worker_id !== undefined && worker_id !== '')
            data['worker_id'] = worker_id;

        return ax.post(
            "/api/time-logs/",
                data
            )
            .then((res) => {
                if(res.status !== 200 && res.status !== 201){
                    if(res.status === 409){
                        dispatch(getBreak(res.data.item_id));
                        throw res;
                    }else{
                        logException(res);
                        throw res;
                    }
                }
                return res.data;
            })
            .then(res => {
                dispatch({type: "DATA_SAVED"});
                return dispatch({
                    type: 'GET_BREAK',
                    item: res
                })
            })
            .catch(err => {
                dispatch({
                    type: 'DATA_ERROR_SAVING',
                    error: err
                });
                logException(err);
                throw err;
            });
    }
};

