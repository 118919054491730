import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import TextField from "@material-ui/core/TextField";
import { time_logs } from "actions";
import { connect } from "react-redux";

var moment = require("moment"); // require

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    backgroundColor: "var(--trade-body)",
  },
  subTableRoot: {
    "& > *": {
      borderBottom: "unset",
    },
    backgroundColor: "#FFF",
  },
});

const WhiteTextTypography = withStyles({
  root: {
    color: "var(--trade-font)",
    margin: "10px",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "var(--trade-font) !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
})(TextField);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "var(--trade-background)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const AmberTypography = withStyles({
  root: {
    color: "var(--trade-primary)",
  },
})(Typography);

function Rows(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { item } = props;

  const getDayTotalBreak = (rows) => {
    var duration = 0;
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].type === "break") {
        const start = rows[i].start;
        const end = moment(rows[i].end);
        const duration_time = calculateDuration(start, end)["_milliseconds"];
        duration = duration + duration_time;
      }
    }

    var convTime = moment
      .duration(duration, "milliseconds")
      .format("h [hrs] m [min] s [seconds]");

    return convTime;
  };

  const getDayTotal = (rows) => {
    var duration = 0;
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].type === "timelog") {
        const start = rows[i].start;
        const end = moment(rows[i].end);
        const duration_time = calculateDuration(start, end)["_milliseconds"];
        duration = duration + duration_time;
      }
    }

    var convTime = moment
      .duration(duration, "milliseconds")
      .format("h [hrs] m [min] s [seconds]");

    return convTime;
  };

  const calculateDuration = (start, end) => {
    const x = moment.duration(
      moment(end, "DD/MM/YYYY HH:mm:ss").diff(
        moment(start, "DD/MM/YYYY HH:mm:ss")
      )
    );
    return x;
  };

  const rows = () => {
    const styles = {
      tableBorder: {
        borderTop: "solid 2px var(--trade-neutral)",
        borderBottom: "solid 2px var(--trade-neutral)",
        backgroundColor: "var(--trade-background)",
        marginBottom: "2px",
      },
    };

    return (
      <React.Fragment>
        <TableRow
          className={classes.root}
          onClick={() => setOpen(!open)}
          style={styles.tableBorder}
        >
          <TableCell align="center">
            <AmberTypography>{item[0].project.title}</AmberTypography>
          </TableCell>
          <TableCell align="center">
            <AmberTypography>{getDayTotal(item)}</AmberTypography>
          </TableCell>
          <TableCell align="center">
            <AmberTypography>{getDayTotalBreak(item)}</AmberTypography>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root} style={styles.tableBorder}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">TIME INN</TableCell>
                      <TableCell align="center">TIME OUT</TableCell>
                      <TableCell align="center">DURATION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item
                      .slice()
                      .reverse()
                      .map((item, key) => (
                        <TableRow key={key} className={classes.subTableRoot}>
                          <TableCell align="center">
                            {moment(item.start)
                              .utc()
                              .local()
                              .format("Do MMMM YYYY, LT")}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {moment(item.end)._isValid
                              ? moment(item.end)
                                .utc()
                                .local()
                                .format("Do MMMM YYYY, LT")
                              : "ON SITE"}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.end)._isValid
                              ? calculateDuration(item.start, item.end).format(
                                "h [hrs] m [min] s [seconds]"
                              )
                              : "ON SITE"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return rows();
}

function CollapsibleTable(props) {
  const classes = useRowStyles();

  useEffect(() => {
    getWorkerLogs();
  }, [1]);

  const [startDate, setStartDate] = React.useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );

  const styles = {
    tableContainerStyle: {
      backgroundColor: "var(--trade-background)",
    },
    tableStyles: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      scoll: "auto",
    },
    datePickerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const getWorkerLogs = () => {
    props.fetchWorkerTimeSheet(worker_id);
  };

  const { logs } = props;
  const worker_id = props.match.params.id;

  const titleSection = () => (
    <WhiteTextTypography variant="h4"> TIMESHEET</WhiteTextTypography>
  );

  const filterDate = () => {
    const filtered =
      logs !== undefined
        ? Object.keys(logs).size !== 0
          ? Object.keys(logs)
            .filter((key) => moment(key).isBetween(startDate, endDate))
            .reduce((obj, key) => {
              obj[key] = logs[key][worker_id];
              return obj;
            }, {})
          : {}
        : {};

    return filtered;
  };

  const filterProject = (item) => {
    var dictOfProjects = {};
    for (var i = 0; i < item.length; i++) {
      if (item[i].project.title in dictOfProjects) {
        dictOfProjects[item[i].project.title].push(item[i]);
      } else {
        dictOfProjects[item[i].project.title] = [item[i]];
      }
    }

    return Object.keys(dictOfProjects).map((item, key) => (
      <Rows item={dictOfProjects[item]} key={key} />
    ));
  };

  const tableContainer = () => {
    const filtered = filterDate();
    const keys = Object.keys(filtered).reverse();

    return keys.length !== 0 ? (
      keys.map((item, key) => (
        <React.Fragment key={key}>
          <AmberTypography variant="h4">
            {moment(item).format("Do MMMM YYYY")}
          </AmberTypography>
          <TableContainer
            component={Paper}
            key={key}
            style={{ width: "70vw", margin: "20px", borderRadius: 0 }}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">PROJECT</StyledTableCell>
                  <StyledTableCell align="center">HOURS</StyledTableCell>
                  <StyledTableCell align="center">BREAK</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{filterProject(filtered[item])}</TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ))
    ) : (
      <WhiteTextTypography>Timesheet Empty</WhiteTextTypography>
    );
  };

  const datePickerStart = () => {
    return (
      <div style={{ margin: "20px" }}>
        <CssTextField
          id="date"
          label="Start Date"
          type="date"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          name="startDate"
          fullWidth
        />
      </div>
    );
  };

  const datePickerEnd = () => {
    return (
      <div style={{ margin: "20px" }}>
        <CssTextField
          id="date"
          label="End Date"
          type="date"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          name="endDate"
          fullWidth
        />
      </div>
    );
  };

  return (
    <div style={styles.tableContainerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {titleSection()}
      </div>
      <div style={styles.datePickerContainer}>
        {datePickerStart()}
        {datePickerEnd()}
      </div>

      <div style={styles.tableStyles}> {tableContainer()}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    logs: state.time_logs.worker_time_sheet,
    user: state.auth.user,
    worker: state.workers.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorkerTimeSheet: (worker_id) => {
      return dispatch(time_logs.fetchTimeLogsByWorkerId(worker_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
