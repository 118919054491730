import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
//import CssTextField from "components/CssTextField/CssTextField.jsx";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Spinner from "react-bootstrap/Spinner";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#000000",
    },
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    "& .MuiFormLabel-root": {
      color: "#000000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000000",
      },
      "&:hover fieldset": {
        borderColor: "#000000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
})(TextField);

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000",
  },
})(Typography);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  //const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [creatingUser, setCreatingUser] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  /**
      'first_name': user.firstName,
        'last_name': user.lastName,
        'email': user.email,
        'phone_number': user.phoneNumber,
        'password': password,
        'company': user.company,
        'is_active': true.toString(),
        'active': true.toString(), 
     */
  const handleCreateWorker = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      phoneNumber === ""
    ) {
      setError("Please fill out all the boxes");
    } else {
      setCreatingUser(true);
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        phoneNumber: phoneNumber,
      };
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setPhoneNumber("");
      setError("");
      await props.handleWorkerCreation(data);
      setCreatingUser(false);
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open dialog
        </Button> */}
      <Dialog onClose={props.handleClose} open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Add New Worker
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <CssTextField
                    label="First Name"
                    fullWidth={true}
                    variant="outlined"
                    value={firstName}
                    onChange={(ev) => setFirstName(ev.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <CssTextField
                    label="Last Name"
                    fullWidth={true}
                    variant="outlined"
                    value={lastName}
                    onChange={(ev) => setLastName(ev.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <CssTextField
                    label="Email"
                    fullWidth={true}
                    variant="outlined"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <CssTextField
                    label="Phone Number"
                    fullWidth={true}
                    variant="outlined"
                    value={phoneNumber}
                    onChange={(ev) => setPhoneNumber(ev.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <CssTextField
                    label="Temporary Password"
                    fullWidth={true}
                    variant="outlined"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
            <Grid container item md={12}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Box m={1}>
                  <RedTextTypography>{error}</RedTextTypography>
                  <div style={{ textAlign: "center", margin: "auto" }}>
                    {creatingUser ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      ""
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCreateWorker}>
            Create Worker
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
