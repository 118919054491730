import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { safety_meetings } from "actions";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssTextField from "components/CssTextField/CssTextField";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Typography, Box } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
//import Spinner from "react-bootstrap/Spinner";

const AmberTypography = withStyles({
  root: {
    color: "#FF9100",
  },
})(Typography);

const FormLabel = withStyles({
  label: {
    fontSize: "1.3rem",
    color: "white",
  },
})(FormControlLabel);

const GreenTabs = withStyles({
  root: {
    width: "35%",
  },
  indicator: {
    color: "#4CAF50",
    backgroundColor: "#4CAF50",
  },
})(Tabs);

const GreyAppBar = withStyles({
  root: {
    backgroundColor: "#000000",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
  },
})(AppBar);

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    overflow: "scroll",
    backgroundColor: "#000000",
  },
  mainContainer: {
    width: 950,
    minHeight: "100vh",
    backgroundColor: "#000000",
  },
  container: {
    height: "auto",
    backgroundColor: "#000000",
    width: "100%",
    padding: "2rem",
  },
  tableRow: {
    borderRadius: "4px",
    backgroundColor: "#000000",
    marginBottom: "1rem",
  },
  headers: {
    color: "#FF9100",
  },
  description: {
    color: "#fff",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    height: "auto",
  },
  labels: {
    margin: "auto",
    textAlign: "center",
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
  safetyMeetingSection: {
    flexGrow: 1,
    margin: "1rem",
  },
}));

const possibleQuestionTypes = [
  { value: "string", name: "Text" },
  { value: "choices", name: "Choice" },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TasksAndMeetingsSlider(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [questionsRequired, setQuestionsRequired] = useState("no");
  const [safetyMeeting, setSafetyMeeting] = useState(null);
  const [safetyMeetingActiveTab, setSafetyMeetingActiveTab] = useState(0);
  // const [meetingId, setMeetingId] = useState(null);
  const [step, setStep] = useState(0);
  // const [code, setCode] = useState("");
  // const [title, setTitle] = useState("");
  const [frequency, setFrequency] = useState("");
  // const [description, setDescription] = useState("");
  const [daily, setDaily] = useState(false);
  const [toolbox, setToolbox] = useState(false);
  const [fieldForms, setFieldForms] = useState([]);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionOption, setQuestionOption] = useState("");
  const [questionOptions, setQuestionOptions] = useState("");
  const [questionOptionList, setQuestionOptionList] = useState([]);
  const [company, setCompany] = useState(props.task.company);
  const [clientEmail, setClientEmail] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    // setSafetyMeeting(props.meeting);
  }, [props.meeting]);

  const updateMeeting = (data) => {
    // const data = {
    //   id: meetingId,
    //   company: company,
    //   title: title,
    //   code: code,
    //   description: description,
    //   daily: daily,
    //   toolbox: toolbox,
    //   library: false,
    //   files: [],
    //   client_email: clientEmail,
    //   frequency: frequency,
    // };

    props.updateSafetyMeetingInfo(data).then(async (e) => {
      for (var i = 0; i < fieldForms.length; i++) {
        //add the ones that dont have id
        if (fieldForms[i].id === undefined) {
          var response = await props.createFieldForm({
            order: 10,
            title: fieldForms[i].title,
            type: fieldForms[i].type,
            options: fieldForms[i].options,
            safety_meeting_info: data.meetingId,
          });
          console.log(response);
        }
      }

      if (e.type !== "DATA_ERROR_SAVING") {
        const selectedTasksCopy = [...props.selectedTasks];

        const task = selectedTasksCopy.filter(
          (task = {}) => task.id === props.task.id
        )?.[0];

        const taskIndex = selectedTasksCopy.findIndex(
          (task = {}) => task.id === props.task.id
        );

        const meetingIndex = task.safety_meeting_infos.findIndex(
          (meeting = {}) => meeting.id === data.id
        );

        const updatedSafetyMeetingInfo = e.item;

        selectedTasksCopy[taskIndex].safety_meeting_infos[meetingIndex] =
          updatedSafetyMeetingInfo;

        props.setSelectedTasks(selectedTasksCopy);
        props.setIsEditingTask(false);
      }

      // setCode("");
      // setTitle("");
      // setDescription("");
      setDaily(false);
      setToolbox(false);
      setFieldForms([]);
      setStep(1);
    });
  };

  function SafetyMeetingDetails(props) {
    const [meetingId, setMeetingId] = useState(props.meeting.id);
    const [code, setCode] = useState(props.meeting.code);
    const [title, setTitle] = useState(props.meeting.title);
    const [description, setDescription] = useState(props.meeting.description);
    const [newMeetingData, setNewMeetingData] = useState({
      id: props.meeting.id,
      company: company,
      title: props.meeting.title,
      code: code,
      description: props.meeting.description,
      daily,
      toolbox,
      library: false,
      files: [],
      client_email: clientEmail,
      frequency: frequency,
    });

    return (
      <Grid container>
        <Grid container item md={4}>
          {/**Safety Meeting code */}
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={classes.labels}
              >
                Code
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={code}
                name="meetingCode"
                onChange={(event) => {
                  setCode(event.target.value);
                  setNewMeetingData({
                    ...newMeetingData,
                    code: event.target.value,
                  });
                }}
              ></CssTextField>
            </Box>
          </Grid>
          {/**Safety Meeting Title */}
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography component={"span"}>
                Title
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={title}
                name="meetingTitle"
                onChange={(event) => {
                  setTitle(event.target.value);
                  setNewMeetingData({
                    ...newMeetingData,
                    title: event.target.value,
                  });
                }}
              ></CssTextField>
            </Box>
          </Grid>
          <Grid item md={3} className={classes.labels}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                component={"span"}
                className={classes.labels}
              >
                Client Email
              </WhiteTextTypography>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box m={2}>
              <CssTextField
                variant="outlined"
                fullWidth={true}
                value={clientEmail}
                name="clientEmail"
                onChange={(event) => {
                  setClientEmail(event.target.value);
                  setNewMeetingData({
                    ...newMeetingData,
                    clientEmail: event.target.value,
                  });
                }}
              ></CssTextField>
            </Box>
          </Grid>
        </Grid>
        {/** Safety Meeting Description Textarea */}
        <Grid container item md={8}>
          <Box m={2} style={{ width: "100%" }}>
            <CssTextField
              variant="outlined"
              multiline
              rows={15}
              fullWidth={true}
              label="Details"
              value={description}
              name="meetingDescription"
              onChange={(event) => {
                setDescription(event.target.value);
                setNewMeetingData({
                  ...newMeetingData,
                  description: event.target.value,
                });
              }}
            ></CssTextField>
          </Box>
        </Grid>
        {/** create meeting button */}
        <Grid
          container
          item
          md={12}
          justifyContent="center"
          style={{ gap: "3rem" }}
        >
          <Grid item md={2}>
            <Box justifyContent="center" alignItems="flex-end" display="flex">
              <button
                onClick={() => {
                  // props.closeMeetingContainer();
                  setDrawerOpen(false);
                }}
                style={{
                  backgroundColor: "#F44336",
                  padding: "0.7rem 1rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Cancel
              </button>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box justifyContent="center" alignItems="flex-end" display="flex">
              <button
                onClick={() => {
                  updateMeeting(newMeetingData);
                  setDrawerOpen(false);
                }}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 1rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Apply to This Project Only
              </button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const taskInfo = () => {
    return (
      <Grid container>
        <Grid item md={12}>
          {props.isEditingTask ? (
            <CssTextField
              label={"Task Title"}
              value={props.newTaskTitle ? props.newTaskTitle : props.task.title}
              variant="standard"
              name="newTaskTitle"
              onChange={(event) => props.setNewTaskTitle(event.target.value)}
              style={{ margin: "2rem 0 0 2rem" }}
            />
          ) : (
            <WhiteTextTypography
              variant="h4"
              style={{ padding: "2rem 0 0 2rem" }}
            >
              {props.task.title}
            </WhiteTextTypography>
          )}
          {/* <Button
            onClick={
              props.isEditingTask
                ? () => props.handleNewTaskTitle(props.task.id)
                : () => props.setIsEditingTask(true)
            }
            style={{
              marginLeft: "2rem",
              paddingLeft: "0",
              marginTop: props.isEditingTask ? "2rem" : "0",
            }}
          >
            <WhiteTextTypography>
              {props.isEditingTask ? "Save" : "Edit Title "}
            </WhiteTextTypography>
          </Button> */}
        </Grid>
        <Grid item md={12}>
          <div className={classes.safetyMeetingSection}>
            <GreyAppBar position="static">
              <GreenTabs
                value={activeTab}
                onChange={(ev, val) => setActiveTab(val)}
                aria-label="simple tabs example"
                orientation="vertical"
                variant="scrollable"
              >
                {props.task?.safety_meeting_infos.map((meeting, index) => (
                  <Tab label={meeting.title} {...a11yProps(index)} />
                ))}
              </GreenTabs>
              {props.task?.safety_meeting_infos.map((meeting, index) => (
                <TabPanel value={activeTab} index={index}>
                  <SafetyMeetingDetails meeting={meeting} />
                </TabPanel>
              ))}
            </GreyAppBar>
          </div>
        </Grid>
      </Grid>
    );
  };

  const drawerContent = () => {
    return (
      <div className={classes.mainContainer}>
        {props.task !== null ? taskInfo() : <div> No Info available </div>}
      </div>
    );
  };

  return (
    <div>
      <React.Fragment>
        <Typography onClick={openDrawer} style={{ cursor: "pointer" }}>
          <Visibility />
        </Typography>

        <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
          {drawerContent()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

// const mapStateToProps = () => {}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSafetyMeetingInfo: (data) => {
      return dispatch(
        safety_meetings.saveSafetyMeeting(
          data.id,
          data.company,
          data.title,
          data.code,
          data.description,
          data.daily,
          data.toolbox,
          data.library,
          data.files,
          data.task_type_id,
          data.client_email,
          data.frequency
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksAndMeetingsSlider);
