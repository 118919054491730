import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { auth, workers, companies } from "actions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.jsx";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import StripeCheckout from "react-stripe-checkout";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
// import CssTextField from "components/CssTextField/CssTextField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import "./OnboardingPage.css";

const axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& #date": {
      filter: "invert(1)",
      color: "black",
    },
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "#323231",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      borderBottom: "1px solid #858585",
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #555 inset !important",
      WebkitTextFillColor: "#fff !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-disabled fieldset": {
        color: "white",
        backgroundColor: "transparent",
        border: "1px solid var(--trade-neutral)",
      },
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "1px solid #F09637",
    },
  },
})(TextField);

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const GreenTypography = withStyles({
  root: {
    color: "green",
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      width: "100%",
      backgroundColor: "transparent",
    },
  },
  select: {
    "& label.Mui-focused": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
      borderColor: "white",
      backgroundColor: "#323231",
    },
    "& .MuiFormLabel-root": {
      color: "white",
      borderColor: "white",
    },
    "& .MuiInputBase-input": {
      borderBottom: "1px solid #858585",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid var(--trade-neutral)",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid green",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "1px solid #F09637",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--trade-success)",
      },
    },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "white",
      fontWeight: "bold",
    },
  },
  stepIcon: {
    "& .MuiStepIcon-root": {
      color: "#ffff",
      fill: "grey",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#ffff",

      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
    "& .MuiStepIcon-root.MuiStepIcon": {
      color: "#ffff",
      fill: "var(--trade-success)",
    },
  },

  input: {
    "& .MuiInputBase-root": {
      color: "white",
      border: "1px solid var(--trade-neutral)",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
  },
  stepSubtitle: {
    margin: "0px auto",
  },
  stepDescription: {
    textAlign: "center",
  },
  passwordReqs: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
  planCard: {
    padding: "0.5rem",
  },
  reviewCard: {
    padding: "1rem",
  },
  inputError: {
    textAlign: "center",
    color: "var(--trade-tertiary)",
    fontSize: "0.8rem",
  },
  planCardTopBox: {
    backgroundColor: "green",
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cardChosen: {
    border: "5px solid green",
  },
  planOneChosen: {
    border: "3px solid #4994EC",
    borderRadius: "5px",
  },
  planTwoChosen: {
    border: "3px solid #F09637",
    borderRadius: "5px",
  },
  cardSubtitle: {
    fontWeight: "bold",
    marginBottom: "3px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    maxHeight: "10rem",
    height: "10rem",
    maxWidth: "10rem",
    width: "100%",
    margin: "auto",
  },
  imageWrapper: {
    position: "relative",
    maxHeight: "100%",
    height: "100%",
    maxWidth: "100%",
    width: "100%",
    margin: "0",
    padding: "0",
  },
  imageIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  avatarImage: {
    height: "100%",
    width: "100%",
    display: "block",
    objectFit: "contain",
    margin: "auto",
  },
  reviewContainer: {
    backgroundColor: "var(--trade-background)",
    height: "auto",
    borderRadius: "4px",
    padding: "1rem",
    marginBottom: "2rem",
  },
  progressBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  linearProgressBar: {
    background: "var(--trade-success)",
    "& colorPrimary": {
      color: "green",
    },
  },
}));

const ProgressBar = withStyles({
  root: {
    background: "var(--trade-success)",
  },
  colorPrimary: {
    background: "var(--trade-success)",
  },
  barColorPrimary: {
    background: "green",
  },
})(LinearProgress);

const availablePlans = {
  tradespecifix: "price_1Jk2lqIWU06rMBsVO8XePFry",
  tradespecifixPro: "price_1JZk1QIWU06rMBsVelbu7Rco",
  tradespecifixUS: "price_1MCvnJIWU06rMBsVqDvDlVgF",
  tradespecifixProUS: "price_1MCvoPIWU06rMBsVbqaxFajr",
};

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px transparent inset" };

function OnboardingPage(props) {
  // general state
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [snackOpen, setSnackOpen] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [chosenPlan, setChosenPlan] = useState({ id: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [stripeToken, setStripeToken] = useState("");
  const [userCountry, setUserCountry] = useState("");

  // user info
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const avatarInput = useRef();

  // company info
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("Canada");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoFile, setCompanyLogoFile] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const logoInput = useRef();

  const availableCountries = ["Canada", "United States"];
  const availableProvinces = {
    Canada: [
      "British Columbia",
      "Alberta",
      "Manitoba",
      "Saskatchewan",
      "Prince Edward Island",
      "Ontario",
      "Québec",
      "Nova Scotia",
      "Newfoundland & Labrador",
      "New Brunswick",
      "Northwest Territories",
      "Nunavut",
      "Yukon",
    ],
    "United States": [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  };

  const sections = {
    USER_INFO: 0,
    USER_AVATAR: 1,
    COMPANY_INFO: 2,
    COMPANY_LOGO: 3,
    BILLING: 4,
    REVIEW: 5,
    REDIRECT: 6,
  };

  const getCoordinates = () => {
    let url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=c26b0b35a9924c7997145b48d0e748dd";
    axios
      .get(url)
      .then((response) => {
        // handle success
        setUserCountry(response.data.country_code2);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    getCoordinates();
  }, []);

  // ------------------------------------- user info validators ------------------------------------- //
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const clickAvatarInput = () => {
    avatarInput.current.click();
  };

  const handleAvatarChange = (ev) => {
    if (ev.target.files.length > 0) {
      setAvatarFile(ev.target.files[0]);
      setAvatar(URL.createObjectURL(ev.target.files[0]));
    }
  };

  // ------------------------------------- Company Info handlers------------------------------------- //
  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleCompanyAddressChange = (event) => {
    setCompanyAddress(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const clickLogoInput = (ev) => {
    logoInput.current.click();
  };

  const handleLogoChange = (ev) => {
    if (ev.target.files.length > 0) {
      setCompanyLogoFile(ev.target.files[0]);
      setCompanyLogo(URL.createObjectURL(ev.target.files[0]));
    }
  };

  const handlePromoCodeChange = (ev) => {
    setPromoCode(ev.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Validators

  // ------------------------------------- user info validators ------------------------------------- //
  const userInfoValidator = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      passwordConfirm === ""
    ) {
      setSnackOpen(true);
      setErrorMessage("Please make sure that all of the fields are filled");
      return false;
    }

    if (!passwordValidator()) {
      setSnackOpen(true);
      setErrorMessage("Passwords don't match.");
      return false;
    }

    if (!emailValidator()) {
      setSnackOpen(true);
      setErrorMessage("Invalid email");
      return false;
    }

    if (emailExists === true) {
      setSnackOpen(true);
      setErrorMessage("An account already exists with this email.");
      return false;
    }

    //wrapper in case we add more validators other than password
    //check all the fields are filled
    //check passwords
    //check email doesn't exist
    //check email is valid

    return true;
  };

  const emailValidatorExists = (event) => {
    // need to validate if the email already exists
    const email = event.target.value;
    fetch(process.env.API_URL + "/api/register/exists", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((e) => {
        if (e.exists) {
          setSnackOpen(true);
          setErrorMessage("An account already exists with this email.");
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      });
  };

  const emailValidator = () => {
    const regex =
      /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/;
    return regex.test(email);
  };

  const passwordValidator = () => {
    return password.trim() === passwordConfirm.trim();
  };

  // ------------------------------------- company info validators ------------------------------------- //
  const companyInfoValidator = () => {
    const allFieldsFilled =
      companyName !== "" &&
      companyAddress !== "" &&
      city !== "" &&
      province !== "";
    return allFieldsFilled;
  };

  const postalValidator = () => {
    const postal = /^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/;
    const zip = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (country === "Canada") {
      return postal.test(postalCode);
    }
    if (country === "United States") {
      return zip.test(postalCode);
    }
    return false;
  };

  const updateStep = () => {
    //add validators
    //step 1 and 3 are for adding profile picture and company logo respectively
    if (step === sections.USER_INFO) {
      if (userInfoValidator()) {
        setErrorMessage("");
        setStep(step + 1);
      }
    } else if (step === sections.COMPANY_INFO) {
      if (companyInfoValidator()) {
        if (postalValidator()) {
          setErrorMessage("");
          setStep(step + 1);
        } else {
          setSnackOpen(true);
          if (country === "United States") {
            setErrorMessage("Please enter a valid zip code");
          } else {
            setErrorMessage("Please enter a valid postal code");
          }
        }
      } else {
        setSnackOpen(true);
        setErrorMessage("Please make sure to fill out all the fields");
      }
    } else {
      setStep(step + 1);
    }
  };

  // ------------------------------------- backend communication ------------------------------------- //

  const onToken = (e) => {
    console.log(e.id);
    setStripeToken(e.id);

    setStep(step + 1);
  };

  const createUser = async () => {
    const userRole = "OWNER";

    setIsLoading(true);
    let _chosenPlan = chosenPlan;

    if (process.env.STRIPE_API_KEY.indexOf("pk_test") === 0) {
      _chosenPlan.id = 1;
    }

    // props.register(
    //     "Lebron",
    //     "James",
    //     "diego+r2we@tradespecifix.com",
    //     "Tradepros123",
    //     "LA Lakers",
    //     "2843 east 20th Av",
    //     "",
    //     "Vancouver",
    //     "v5m1v2",
    //     "BC",
    //     "Canada",
    //     stripeToken,
    //     //chosenPlan,
    //     1,
    //     promoCode
    // ).then(async (elem) => {
    //     if (elem.type === "DATA_ERROR_SAVING"){
    //         setIsLoading(false);
    //         setSnackOpen(true);
    //         console.log(elem.error);
    //         setErrorMessage("unable to create user");
    //         //loading false
    //         //show snackbar with error, stay in review page
    //     } else if (elem.type === "SUCCESS" && elem.data !== null){
    //         await saveAvatar(elem.data.user.id);
    //         await saveLogo(elem.data.user.company);

    //         setIsLoading(false);
    //         setStep(step + 1);
    //     }
    // }).catch((err) => {
    //     setIsLoading(false);
    //     console.log("error");
    //     console.log(err);
    // });
    props
      .register(
        firstName,
        lastName,
        email,
        password,
        companyName,
        companyAddress,
        "",
        city,
        postalCode,
        province,
        country,
        stripeToken,
        _chosenPlan.id,
        promoCode,
        userRole
      )
      .then(async (elem) => {
        if (elem.type === "DATA_ERROR_SAVING") {
          setIsLoading(false);
          setSnackOpen(true);
          setErrorMessage("unable to create user");
          //loading false
          //show snackbar with error, stay in review page
        } else if (elem.type === "SUCCESS" && elem.data !== null) {
          await saveAvatar(elem.data.user.id);
          await saveLogo(elem.data.user.company);

          setIsLoading(false);
          setStep(step + 1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error");
        console.log(err);
      });
  };

  const saveAvatar = async (workerId) => {
    if (avatarFile !== null) {
      props.saveAvatar(workerId, avatarFile);
    }
  };

  const saveLogo = async (companyId) => {
    if (companyLogoFile !== null) {
      props.saveCompanyLogo(companyId, companyLogoFile);
    }
  };

  // ------------------------------------- view components ------------------------------------- //
  const progressIndicator = () => {
    const steps = [
      "About You",
      "Profile Picture",
      "Company Profile",
      "Company Logo",
      "Setup Billing",
    ];
    return (
      <div className={classes.root}>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index} className={classes.stepIcon}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  const showStep = () => {
    switch (step) {
      case sections.USER_INFO:
        return UserInfo();
      case sections.USER_AVATAR:
        return uploadAvatar();
      case sections.COMPANY_INFO:
        return CompanyInfo();
      case sections.COMPANY_LOGO:
        return uploadCompanyLogo();
      case sections.BILLING:
        return BillingInfo();
      case sections.REVIEW:
        return reviewPage();
      case sections.REDIRECT:
        return <Redirect to="/projects/add" />;
      default:
        return UserInfo();
    }
  };

  const UserInfo = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Tell us a little bit about yourself
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography style={{ color: "#858585" }}>
              Don't worry, you can change everything later.
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* First Name */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="First Name"
                fullWidth={true}
                variant="filled"
                value={firstName}
                autoComplete="off"
                onChange={handleFirstNameChange}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Last Name */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Last Name"
                fullWidth={true}
                variant="filled"
                value={lastName}
                onChange={handleLastNameChange}
                autoComplete="off"
                backgroundColor="#323231"
              />
            </Box>
          </Grid>
        </Grid>
        {/* Email */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Email"
                fullWidth={true}
                variant="filled"
                autoComplete="off"
                value={email}
                onChange={handleEmailChange}
                onBlur={emailValidatorExists}
              />
            </Box>
          </Grid>
        </Grid>
        {/* password */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Password"
                fullWidth={true}
                variant="filled"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  autocomplete: "password",
                  form: {
                    autocomplete: "off",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* password confirmation */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Confirm your password"
                fullWidth={true}
                variant="filled"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                value={passwordConfirm}
                onChange={handlePasswordConfirmChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Move next button */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={updateStep}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Next
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const uploadAvatar = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Upload a profile picture
            </WhiteTextTypography>
          </Box>
        </Grid>
        {/* picture explanation */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                className={classes.passwordReqs}
                style={{ color: "#858585" }}
              >
                This image will be used to display data related to you and will
                be visible to those in your company
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4}>
            <input
              type="file"
              onChange={handleAvatarChange}
              style={{ display: "none" }}
              ref={avatarInput}
            />
            <Box
              m={2}
              justifyContent="center"
              onClick={clickAvatarInput}
              alignItems="center"
              display="flex"
              className={classes.imageContainer}
            >
              {avatar === null ? (
                <div className={classes.imageWrapper}>
                  <Box
                    style={{
                      background: "#858585",
                      padding: "4rem",
                      borderRadius: "8rem",
                    }}
                  >
                    <PersonOutlineOutlinedIcon fontSize="large" />
                  </Box>
                  <GreenTypography>
                    <CameraAltOutlinedIcon
                      className={classes.imageIcon}
                      fontSize="large"
                      style={{
                        backgroundColor: "#f09637",
                        borderRadius: "7rem",
                        width: "3rem",
                        height: "3rem",
                        padding: "0.5rem",
                      }}
                    />
                  </GreenTypography>
                </div>
              ) : (
                <div className={classes.imageWrapper}>
                  <img
                    src={avatar}
                    className={classes.avatarImage}
                    style={{ borderRadius: "7rem" }}
                  />
                  <GreenTypography>
                    <EditIcon
                      className={classes.imageIcon}
                      fontSize="large"
                      style={{
                        backgroundColor: "#f09637",
                        borderRadius: "7rem",
                        width: "3rem",
                        height: "3rem",
                        padding: "0.5rem",
                      }}
                    />
                  </GreenTypography>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* Move next button */}
        <Grid container item md={12}>
          <Grid item sm={1} md={4}>
            <Box m={2}></Box>
          </Grid>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={updateStep}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Next
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
        {/* Skip next button */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
              margin="0"
            >
              <button
                onClick={() => {
                  updateStep();
                  setAvatar(null);
                }}
                style={{
                  backgroundColor: "transparent",

                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                }}
              >
                Skip
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const CompanyInfo = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Tell us about your company, {firstName}
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography style={{ color: "#858585" }}>
              Fill out your company details below.
            </WhiteTextTypography>
          </Box>
        </Grid>

        {/*Company Name*/}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Company Name"
                fullWidth={true}
                variant="filled"
                value={companyName}
                onChange={handleCompanyNameChange}
              />
            </Box>
          </Grid>
        </Grid>
        {/*Company Address*/}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="Company Address"
                fullWidth={true}
                variant="filled"
                value={companyAddress}
                onChange={handleCompanyAddressChange}
              />
            </Box>
          </Grid>
        </Grid>
        {/*Company City*/}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                label="City"
                fullWidth={true}
                variant="filled"
                value={city}
                onChange={handleCityChange}
              />
            </Box>
          </Grid>
        </Grid>
        {/*Company Country*/}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <FormControl className={classes.select} fullWidth={true}>
                <InputLabel id="country-label" variant={"outlined"}>
                  Country
                </InputLabel>
                <Select
                  labelId="country-label"
                  value={country}
                  onChange={handleCountryChange}
                  variant={"filled"}
                >
                  {availableCountries.map((elem, index) => (
                    <MenuItem value={elem} key={index}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {/*Company Province*/}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <FormControl className={classes.select} fullWidth={true}>
                <InputLabel id="province-label" variant={"outlined"}>
                  {country === "Canada" ? "Province" : "State"}
                </InputLabel>
                <Select
                  labelId="province-label"
                  value={province}
                  onChange={handleProvinceChange}
                  variant={"filled"}
                >
                  {availableProvinces[country].map((elem, index) => (
                    <MenuItem value={elem} key={index}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Company Postal Code */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item md={4} lg={4} sm={10} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <CssTextField
                inputProps={{ maxLength: 7 }}
                label={
                  country === "Canada" ? "Postal Code (no spaces)" : "Zip Code"
                }
                fullWidth={true}
                variant="filled"
                value={postalCode}
                onChange={handlePostalCodeChange}
              />
            </Box>
          </Grid>
        </Grid>
        {/* next button */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={1}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={updateStep}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Next
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const uploadCompanyLogo = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Upload your company logo
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <WhiteTextTypography
                className={classes.passwordReqs}
                style={{ color: "#858585" }}
              >
                This image will be used to display data related to your company.
              </WhiteTextTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4}>
            <input
              type="file"
              onChange={handleLogoChange}
              style={{ display: "none" }}
              ref={logoInput}
            />
            <Box
              m={2}
              justifyContent="center"
              onClick={clickLogoInput}
              alignItems="center"
              display="flex"
              className={classes.imageContainer}
            >
              {companyLogo === null ? (
                <div className={classes.imageWrapper}>
                  <Box
                    style={{
                      background: "#858585",
                      padding: "4rem",
                      borderRadius: "8rem",
                    }}
                  >
                    <PersonOutlineOutlinedIcon fontSize="large" />
                  </Box>
                  <GreenTypography>
                    <CameraAltOutlinedIcon
                      className={classes.imageIcon}
                      fontSize="large"
                      style={{
                        backgroundColor: "#f09637",
                        borderRadius: "7rem",
                        width: "3rem",
                        height: "3rem",
                        padding: "0.5rem",
                      }}
                    />
                  </GreenTypography>
                </div>
              ) : (
                <div className={classes.imageWrapper}>
                  <img
                    src={companyLogo}
                    className={classes.avatarImage}
                    style={{ borderRadius: "7rem" }}
                  />
                  <GreenTypography>
                    <EditIcon
                      className={classes.imageIcon}
                      fontSize="large"
                      style={{
                        backgroundColor: "#f09637",
                        borderRadius: "7rem",
                        width: "3rem",
                        height: "3rem",
                        padding: "0.5rem",
                      }}
                    />
                  </GreenTypography>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* Move next button */}
        <Grid container item md={12}>
          <Grid item sm={1} md={4}>
            <Box m={2}></Box>
          </Grid>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <button
                onClick={updateStep}
                style={{
                  backgroundColor: "green",
                  padding: "0.7rem 2rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Next
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
        {/* Skip next button */}
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4} style={{ width: "100%" }}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
              margin="0"
            >
              <button
                onClick={() => {
                  updateStep();
                  setCompanyLogo(null);
                }}
                style={{
                  backgroundColor: "transparent",

                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                }}
              >
                Skip
              </button>
              <Snackbar
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
              >
                <Alert onClose={() => setSnackOpen(false)} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const plan1Features = [
    "Mobile and Web App",
    "Job-site Safety Program",
    // "Alerts and notifications",
    "Timesheet approval ",
    "Transparent Geofencing data",
    "File and Note Uploads",
    "Customer Service",
  ];

  const plan2Features = [
    // "Everything in Tradespecifix",
    "Cost Accounting",
    "Track project Progress",
    "Project Activity feed",
    "Break and delivery time management",
    "Live customer service with PMO services",
    "",
  ];

  const plan1Card = () => {
    return (
      <Card
        className={
          classes.planCard +
          (chosenPlan.id === availablePlans.tradespecifix ||
          chosenPlan.id === availablePlans.tradespecifixUS
            ? ` ${classes.planOneChosen}`
            : "")
        }
        style={{
          background: "transparent",
          border:
            chosenPlan.id === availablePlans.tradespecifix ||
            chosenPlan.id === availablePlans.tradespecifixUS
              ? "3px solid #4994EC"
              : "3px solid #323231",
          borderRadius: "5px",
        }}
      >
        <WhiteTextTypography className={classes.cardTitle} gutterBottom>
          Tradespecifix
        </WhiteTextTypography>

        {/* <WhiteTextTypography color="textSecondary">
          For businesses just getting started with managing all their projects
          in one platform.
        </WhiteTextTypography> */}
        <Typography style={{ "font-weight": "bold", color: "#4994EC" }}>
          2 WEEKS FREE
        </Typography>
        <WhiteTextTypography className={classes.cardSubtitle}>
          INCLUDES
        </WhiteTextTypography>
        <List className={classes.root}>
          {plan1Features.map((feature) => {
            const labelId = `checkbox-list-label-${feature}`;
            return (
              <ListItem
                key={feature}
                role={undefined}
                dense
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={feature}
                  style={{ color: "white" }}
                />
              </ListItem>
            );
          })}
        </List>
        <hr style={{ background: "#323231", height: "1px" }} />
        <Typography variant="h5" component="h2" style={{ color: "#4994EC" }}>
          $34.99 / month after trial
        </Typography>
        <WhiteTextTypography className={classes.cardSubtitle}>
          0-99 Users at $4.99/user monthly
        </WhiteTextTypography>
        <Box m={1} justifyContent="center" alignItems="center" display="flex">
          <Button
            onClick={
              userCountry === "US"
                ? () =>
                    setChosenPlan({
                      id: availablePlans.tradespecifixUS,
                      name: "TradeSpecifix",
                    })
                : () =>
                    setChosenPlan({
                      id: availablePlans.tradespecifix,
                      name: "TradeSpecifix",
                    })
            }
            variant={
              chosenPlan.id === availablePlans.tradespecifix ||
              chosenPlan.id === availablePlans.tradespecifixUS
                ? "filled"
                : "outlined"
            }
            disabled={
              chosenPlan.id === availablePlans.tradespecifix ||
              chosenPlan.id === availablePlans.tradespecifixUS
            }
            style={{
              backgroundColor: "#4994EC",
              padding: "0.7rem 1rem",
              fontWeight: "bold",
              color: "white",
              border: "none",
              borderRadius: "24px",
              width: "100%",
            }}
          >
            {chosenPlan.id === availablePlans.tradespecifix ||
            chosenPlan.id === availablePlans.tradespecifixUS ? (
              <DoneIcon />
            ) : (
              `Select`
            )}
          </Button>
        </Box>
      </Card>
    );
  };

  const plan2Card = () => {
    return (
      <Card
        className={
          classes.planCard +
          (chosenPlan.id === availablePlans.tradespecifixPro ||
          chosenPlan.id === availablePlans.tradespecifixProUS
            ? ` ${classes.planTwoChosen}`
            : "")
        }
        style={{
          background: "transparent",
          border:
            chosenPlan.id === availablePlans.tradespecifixPro ||
            chosenPlan.id === availablePlans.tradespecifixProUS
              ? "3px solid #F09637"
              : "3px solid #323231",
          borderRadius: "5px",
        }}
      >
        <WhiteTextTypography className={classes.cardTitle} gutterBottom>
          Tradespecifix Pro
        </WhiteTextTypography>
        {/* <Typography color="textSecondary">
          For established businesses that want to manage all their projects in
          one platform.
        </Typography> */}
        <Typography style={{ "font-weight": "bold", color: "#F09637" }}>
          2 WEEKS FREE
        </Typography>
        <WhiteTextTypography className={classes.cardSubtitle}>
          INCLUDES EVERYTHING IN TRADESPECIFIX PLUS:
        </WhiteTextTypography>
        <List className={classes.root}>
          {plan2Features.map((feature) => {
            const labelId = `checkbox-list-label-${feature}`;
            return (
              <ListItem
                key={feature}
                role={undefined}
                dense
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <ListItemIcon
                  style={{ visibility: feature ? "inherit" : "hidden" }}
                >
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={feature}
                  style={{ color: "white" }}
                />
              </ListItem>
            );
          })}
        </List>
        <hr style={{ background: "#323231", height: "1px" }} />
        <Typography variant="h5" component="h2" style={{ color: "#F09637" }}>
          $64.99 / month after trial
        </Typography>
        <WhiteTextTypography className={classes.cardSubtitle}>
          100+ users at $3.99 / user monthly
        </WhiteTextTypography>
        <Box m={1} justifyContent="center" alignItems="center" display="flex">
          <Button
            onClick={
              userCountry === "US"
                ? () =>
                    setChosenPlan({
                      id: availablePlans.tradespecifixProUS,
                      name: "TradeSpecifix Pro",
                    })
                : () =>
                    setChosenPlan({
                      id: availablePlans.tradespecifixPro,
                      name: "TradeSpecifix Pro",
                    })
            }
            variant={
              chosenPlan.id === availablePlans.tradespecifixPro ||
              chosenPlan.id === availablePlans.tradespecifixProUS
                ? "filled"
                : "outlined"
            }
            disabled={
              chosenPlan.id === availablePlans.tradespecifixPro ||
              chosenPlan.id === availablePlans.tradespecifixProUS
            }
            style={{
              backgroundColor: "#F09637",
              padding: "0.7rem 1rem",
              fontWeight: "bold",
              color: "white",
              border: "none",
              borderRadius: "24px",
              width: "100%",
            }}
          >
            {chosenPlan.id === availablePlans.tradespecifixPro ||
            chosenPlan.id === availablePlans.tradespecifixProUS ? (
              <DoneIcon />
            ) : (
              `Select`
            )}
          </Button>
        </Box>
      </Card>
    );
  };

  const BillingInfo = () => {
    return (
      <Grid container justify="center" alignItems="center">
        {/* <Grid item md={12} >
                    <Box m={1} justifyContent="center"
                        alignItems="center" display="flex">
                        <WhiteTextTypography variant="h4" className={classes.stepDescription}>
                            Organize your team
                        </WhiteTextTypography>
                    </Box>
                </Grid> */}
        <Grid item md={12}>
          <Box
            m={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
            className={classes.stepDescription}
          >
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Pick a plan that best suits your business
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container item md={12} style={{ justifyContent: "center" }}>
          <Grid item sm={10} md={4}>
            <Box
              m={2}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <GreenTypography className={classes.passwordReqs}>
                Both plans include a 2 week trial.
              </GreenTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={12} lg={6} md={6} style={{ width: "100%" }}>
          <Box ml={6} mr={6} mt={1} mb={1} style={{ margin: "0.5rem" }}>
            {plan1Card()}
          </Box>
        </Grid>
        <Grid item sm={12} lg={6} md={6} style={{ width: "100%" }}>
          <Box ml={6} mr={6} mt={1} mb={1} style={{ margin: "0.5rem" }}>
            {plan2Card()}
          </Box>
        </Grid>
        {/* <Grid item xs={12} lg={3} md={3}>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <WhiteTextTypography variant="h6" className={classes.stepSubtitle}>
                            Payment Information
                        </WhiteTextTypography>
                    </Box>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <CssTextField label="Name on Card" fullWidth={true} variant="outlined" value={nameOnCard} onChange={handleNameOnCardChange} />
                    </Box>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <CssTextField label="Card Number" fullWidth={true} variant="outlined" value={cardNumber} onChange={handleCardNumberChange} />
                    </Box>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <CssTextField label="Expiry Date" fullWidth={true} variant="outlined" value={expiryDate} onChange={handleExpiryDateChange} />
                    </Box>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <CssTextField label="Security Code" fullWidth={true} variant="outlined" value={securityCode} onChange={handleSecurityCodeChange} />
                    </Box>
                    <Box m={2} justifyContent="center"
                        alignItems="center" display="flex">
                        <button onClick={getStripeToken}
                            style={{
                                backgroundColor: "green", padding: "0.7rem 1rem",
                                fontWeight: "bold", color: "white", border: "none", borderRadius: "4px"
                            }}>
                            Next
                        </button>
                    </Box>
                </Grid> */}
        <Grid item md={6} display="flex" justifyContent="center">
          <Box
            m={1}
            justifyContent="center"
            alignItems="flex-end"
            display="flex"
          >
            <CssTextField
              className="promo-code"
              label="Promo Code"
              variant="filled"
              onChange={handlePromoCodeChange}
              value={promoCode}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          {stripeToken == "" ? (
            <Box
              className="payment-button"
              m={2}
              justifyContent="center"
              alignItems="flex-start"
              display="flex"
              style={{ width: "100%" }}
            >
              <StripeCheckout
                token={onToken}
                stripeKey={process.env.STRIPE_API_KEY}
                style={{ width: "100%" }}
              >
                {chosenPlan.id == "" ? (
                  <button
                    disabled={true}
                    style={{
                      backgroundColor: "transparent",
                      padding: "1rem",
                      fontWeight: "bold",
                      cursor: "not-allowed",
                      color: "var(--trade-neutral)",
                      border: "2px solid var(--trade-neutral)",
                      borderRadius: "40px",
                      width: "100%",
                    }}
                  >
                    Please Select a Plan
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "green",
                      padding: "1rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "var(--trade-font)",
                      border: "none",
                      borderRadius: "40px",
                      width: "100%",
                    }}
                  >
                    Proceed to Payment
                  </button>
                )}
              </StripeCheckout>
            </Box>
          ) : (
            <Box
              m={2}
              justifyContent="flex-start"
              alignItems="flex-start"
              display="flex"
            >
              <button
                style={{
                  backgroundColor: "green",
                  padding: "1rem",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                }}
                onClick={() => setStep(step + 1)}
              >
                Proceed to Review
              </button>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  };

  const reviewPage = () => {
    return isLoading ? (
      <Grid container>
        <Grid item md={12}>
          <Box
            mt={8}
            mb={2}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <WhiteTextTypography
              variant="h4"
              className={classes.stepDescription}
            >
              Please wait while we set up your user and company.
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid container item md={12} className={classes.progressBar}>
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <Box m={8}>
              <ProgressBar color="primary" />
            </Box>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
        <Grid item md={12}>
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => setSnackOpen(false)}
          >
            <Alert onClose={() => setSnackOpen(false)} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    ) : (
      <Grid container justify="center" alignItems="center">
        <Grid item md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              Review your information
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          lg={6}
          md={6}
          display="flex"
          justifyContent="center"
          style={{ width: "100%", height: "30rem", marginBottom: "1rem" }}
        >
          <Box
            ml={6}
            mr={6}
            mt={1}
            mb={1}
            style={{ width: "100%", margin: "0", marginBottom: "1rem" }}
          >
            <Card
              className={classes.reviewCard}
              style={{
                background: "transparent",
                border: "3px solid #323231",
                borderRadius: "5px",
                height: "30rem",
              }}
            >
              <WhiteTextTypography variant="h5">User Info</WhiteTextTypography>
              <hr />
              <WhiteTextTypography className={classes.cardTitle} gutterBottom>
                Full Name
              </WhiteTextTypography>
              <WhiteTextTypography color="textSecondary">
                {firstName} {lastName}
                {/* Diego Ramirez */}
              </WhiteTextTypography>
              <WhiteTextTypography className={classes.cardTitle} gutterBottom>
                Email
              </WhiteTextTypography>
              <WhiteTextTypography color="textSecondary">
                {
                  email
                  //"diegojramirezs@gmail.com"
                }
              </WhiteTextTypography>
              <WhiteTextTypography
                color="textSecondary"
                style={{ visibility: "hidden" }}
              >
                Dummy
              </WhiteTextTypography>
              <WhiteTextTypography
                color="textSecondary"
                style={{ visibility: "hidden" }}
              >
                Dummy
              </WhiteTextTypography>
              <Grid container justifyContent="center">
                <Grid item sm={10} md={4}>
                  <input
                    type="file"
                    onChange={handleAvatarChange}
                    style={{ display: "none" }}
                    ref={avatarInput}
                  />
                  <Box
                    m={2}
                    justifyContent="center"
                    onClick={clickAvatarInput}
                    alignItems="center"
                    display="flex"
                    className={classes.imageContainer}
                  >
                    {avatar === null ? (
                      <div className={classes.imageWrapper}>
                        <Box
                          style={{
                            background: "#858585",
                            padding: "4rem",
                            borderRadius: "8rem",
                          }}
                        >
                          <PersonOutlineOutlinedIcon fontSize="large" />
                        </Box>
                        <GreenTypography>
                          <CameraAltOutlinedIcon
                            className={classes.imageIcon}
                            fontSize="large"
                            style={{
                              backgroundColor: "#f09637",
                              borderRadius: "7rem",
                              width: "3rem",
                              height: "3rem",
                              padding: "0.5rem",
                            }}
                          />
                        </GreenTypography>
                      </div>
                    ) : (
                      <div className={classes.imageWrapper}>
                        <img
                          src={avatar}
                          className={classes.avatarImage}
                          style={{ borderRadius: "7rem" }}
                        />
                        <EditIcon
                          className={classes.imageIcon}
                          fontSize="large"
                          style={{
                            backgroundColor: "#f09637",
                            borderRadius: "7rem",
                            width: "3rem",
                            height: "3rem",
                            padding: "0.5rem",
                          }}
                        />
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item md={12} style={{ width: "100%" }}>
                  <Box
                    m={1}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    display="flex"
                    style={{ width: "100%" }}
                  >
                    <button
                      onClick={() => setStep(0)}
                      style={{
                        backgroundColor: "green",
                        padding: "0.7rem 1rem",
                        fontWeight: "bold",
                        color: "white",
                        border: "none",
                        borderRadius: "24px",
                        width: "100%",
                      }}
                    >
                      Edit
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          lg={6}
          md={6}
          display="flex"
          justifyContent="center"
          style={{ width: "100%", heigth: "30rem" }}
        >
          <Box
            ml={6}
            mr={6}
            mt={1}
            mb={1}
            style={{ width: "100%", margin: "0", marginBottom: "1rem" }}
          >
            <Card
              className={classes.reviewCard}
              style={{
                background: "transparent",
                border: "3px solid #323231",
                borderRadius: "5px",
                height: "30rem",
              }}
            >
              <WhiteTextTypography variant="h5">
                Company Info
              </WhiteTextTypography>
              <hr />
              <WhiteTextTypography className={classes.cardTitle} gutterBottom>
                Company Name
              </WhiteTextTypography>
              <WhiteTextTypography color="textSecondary">
                {
                  companyName
                  //"Tradespecifix"
                }
              </WhiteTextTypography>
              <WhiteTextTypography className={classes.cardTitle} gutterBottom>
                Company Address
              </WhiteTextTypography>
              <WhiteTextTypography color="textSecondary">
                {
                  companyAddress
                  //"1 Robson St, Vancouver, BC, Canada"
                }
              </WhiteTextTypography>
              <WhiteTextTypography className={classes.cardTitle} gutterBottom>
                chosen plan
              </WhiteTextTypography>
              <WhiteTextTypography color="textSecondary">
                {chosenPlan.name}
              </WhiteTextTypography>
              <Grid container justifyContent="center">
                <Grid item sm={10} md={4}>
                  <input
                    type="file"
                    onChange={handleLogoChange}
                    style={{ display: "none" }}
                    ref={logoInput}
                  />
                  <Box
                    m={2}
                    justifyContent="center"
                    onClick={clickLogoInput}
                    alignItems="center"
                    display="flex"
                    className={classes.imageContainer}
                  >
                    {companyLogo === null ? (
                      <div className={classes.imageWrapper}>
                        <Box
                          style={{
                            background: "#858585",
                            padding: "4rem",
                            borderRadius: "8rem",
                          }}
                        >
                          <PersonOutlineOutlinedIcon fontSize="large" />
                        </Box>
                        <GreenTypography>
                          <CameraAltOutlinedIcon
                            className={classes.imageIcon}
                            fontSize="large"
                            style={{
                              backgroundColor: "#f09637",
                              borderRadius: "7rem",
                              width: "3rem",
                              height: "3rem",
                              padding: "0.5rem",
                            }}
                          />
                        </GreenTypography>
                      </div>
                    ) : (
                      <div className={classes.imageWrapper}>
                        <img
                          src={companyLogo}
                          className={classes.avatarImage}
                          style={{ borderRadius: "7rem" }}
                        />
                        <EditIcon
                          className={classes.imageIcon}
                          fontSize="large"
                          style={{
                            backgroundColor: "#f09637",
                            borderRadius: "7rem",
                            width: "3rem",
                            height: "3rem",
                            padding: "0.5rem",
                          }}
                        />
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item md={12} style={{ width: "100%" }}>
                  <Box
                    m={1}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    display="flex"
                    style={{ width: "100%" }}
                  >
                    <button
                      onClick={() => setStep(2)}
                      style={{
                        backgroundColor: "green",
                        padding: "0.7rem 1rem",
                        fontWeight: "bold",
                        color: "white",
                        border: "none",
                        borderRadius: "24px",
                        width: "100%",
                      }}
                    >
                      Edit
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
        <Grid item sm={12} md={5} style={{ width: "100%" }}>
          <Box
            mt={2}
            justifyContent="center"
            alignItems="center"
            display="flex"
            style={{ width: "100%" }}
          >
            <button
              style={{
                backgroundColor: "green",
                padding: "0.7rem 1rem",
                fontWeight: "bold",
                color: "white",
                border: "none",
                borderRadius: "24px",
                width: "100%",
              }}
              onClick={createUser}
            >
              Complete Registration
            </button>
            <Snackbar
              open={snackOpen}
              autoHideDuration={5000}
              onClose={() => setSnackOpen(false)}
            >
              <Alert onClose={() => setSnackOpen(false)} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const UserCreatedComponent = () => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} lg={12} md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h4"
              className={classes.stepDescription}
            >
              Please verify your email address.
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <Box m={1} justifyContent="center" alignItems="center" display="flex">
            <WhiteTextTypography
              variant="h5"
              className={classes.stepDescription}
            >
              An email has been sent to the you. Please click on the link you
              received to verify it.
            </WhiteTextTypography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={2} justifyContent="center" alignItems="center" display="flex">
            <button
              onClick={updateStep}
              style={{
                backgroundColor: "green",
                padding: "0.7rem 2rem",
                fontWeight: "bold",
                color: "white",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Go to Main Site
            </button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const StepWrapper = () => {
    return (
      <div
        style={{
          backgroundColor: "var(--trade-background)",
          minHeight: "100%",
          width: "100%",
          position: "absolute",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {progressIndicator()}
        {showStep()}
      </div>
    );
  };

  return StepWrapper();
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (...args) => {
      return dispatch(auth.register(...args));
    },
    saveAvatar: (worker_id, avatar) => {
      return dispatch(workers.updateAvatar(worker_id, avatar));
    },
    saveCompanyLogo: (company_id, logo) => {
      return dispatch(companies.saveCompanyLogo(company_id, logo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);
