import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import "./WorkerTimeSheetApproval.css";
import { time_logs } from "actions";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";

var moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));

function FormDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [timeIn, setTimeIn] = React.useState("2017-05-24T10:30");

  const [timeOut, setTimeOut] = React.useState("2017-05-24T10:30");

  const [timeInError, setTimeInError] = React.useState(false);

  const [timeOutError, setTimeOutError] = React.useState(false);

  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    if (worker_info !== undefined) {
      setTimeIn(moment(worker_info.start).format("YYYY-MM-DDTHH:mm"));
      setTimeOut(moment(worker_info.end).format("YYYY-MM-DDTHH:mm"));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const time_log_id = worker_info.id;
    const time_in = moment(timeIn);
    const time_out = moment(timeOut);
    if (error === null) {
      props.rejectTimelog(time_log_id, time_in, time_out);
      handleClose();
      window.location.reload();
    }
  };

  const timeInChange = (event) => {
    const date1 = new Date(event.target.value);
    const date2 = new Date(timeOut);

    const diff = date2 - date1;

    if (diff < 0) {
      setError("Time In cannot be after Time Out");
      setTimeInError(true);
      return;
    } else {
      setError(null);
      setTimeInError(false);
      setTimeIn(event.target.value);
    }
  };

  const timeOutChange = (event) => {
    const date1 = new Date(timeIn);
    const date2 = new Date(event.target.value);

    const diff = date2 - date1;
    if (diff < 0) {
      setError("Time Out cannot be before Time in");
      setTimeOutError(true);
      return;
    } else {
      setError(null);
      setTimeOutError(false);
      setTimeOut(event.target.value);
    }
  };
  const { worker_info } = props;

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        style={{ color: "white", backgroundColor: "var(--trade-tertiary)" }}
      >
        No
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" align="center">
          Edit Time Sheet
        </DialogTitle>
        <DialogContent>
          <div className="worker-timesheet-approval-container">
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local"
                label="Time In"
                type="datetime-local"
                defaultValue={timeIn}
                onChange={timeInChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                error={timeInError}
              />
            </form>
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local"
                label="Time Out"
                type="datetime-local"
                defaultValue={timeOut}
                onChange={timeOutChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                error={timeOutError}
              />
            </form>
          </div>
          <Box m={2} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ textTransform: "uppercase" }}>{error}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTimelog: (id, start, end) => {
      return dispatch(time_logs.updateTimelog(id, start, end));
    },
    rejectTimelog: (id, start, end) => {
      return dispatch(time_logs.rejectTimelog(id, start, end));
    }
  };
};

export default connect(null, mapDispatchToProps)(FormDialog);
