import {
    container,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import fabStyle from "assets/jss/tradespecifix/fab";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const projectStyle = {
    ...buttonGroupStyle,
    ...buttonStyle,
    ...customSelectStyle,
    ...fabStyle,
    ...customCheckboxRadioSwitch,
    cardTitle,
    container: {
        ...container,
    },
    progress: {
        marginBottom: "0px",
    },
    cardHeader: {
        marginBottom: "20px"
    },
    archived: {
        backgroundColor: "#f2f2f2",
        color: "rgba(0, 0, 0, 0.5)",
    },
    buttonMargin: {
        marginBottom: "5px",
        display: "block",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    justifyEnd: {
        justifyContent: "end",
    },
    alignItemsCenter: {
        alignItems: 'center',
    }
};

export default projectStyle;
