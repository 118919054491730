import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { auth, workers } from "actions";
import indexRoutes from "routes/index";
import { geolocated } from "react-geolocated";
import Spinner from "react-bootstrap/Spinner";

class RootContainerComponent extends Component {
  state = {
    coords: { longitude: null, latitude: null },
  };

  componentDidMount() {
    this.props.loadUser();
  }

  static differentCoords(a, b) {
    return a.latitude !== b.latitude && a.longitude !== b.longitude;
  }

  shouldComponentUpdate(nextProps, prev) {
    if (
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated ||
      nextProps.auth.user !== this.props.auth.user ||
      nextProps.coords !== this.props.coords ||
      nextProps.isGeolocationAvailable !== this.props.isGeolocationAvailable ||
      nextProps.isGeolocationEnabled !== this.props.isGeolocationEnabled
    ) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.coords &&
      RootContainerComponent.differentCoords(
        this.state.coords,
        nextProps.coords
      )
    ) {
      this.setState({ coords: nextProps.coords });
    }
    if (
      this.props.isGeolocationEnabled &&
      nextProps.isGeolocationEnabled === false
    ) {
      this.props.gpsRefused();
    } else if (
      this.props.isGeolocationEnabled === false &&
      nextProps.isGeolocationEnabled === true
    ) {
      this.props.gpsAccepted();
    }
  }

  spinnerContainer = () => {
    return (
      <div className="spinnerContainer" style={{ height: "100vh" }}>
        <Spinner
          animation="border"
          variant="warning"
          style={{ marginTop: "50vh" }}
        />
      </div>
    );
  };

  PrivateRoute = ({ component: ChildComponent, ...rest }) => {
    const { isLoading, isAuthenticated, isLocked, user } = this.props.auth;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLoading && localStorage.token) {
            return this.spinnerContainer();
          } else if (!localStorage.token) {
            return <Redirect to="/login" />;
          } else if (isLocked) {
            return <Redirect to="/lock" />;
          } else if (
            isAuthenticated &&
            user &&
            props.location.pathname !== "/project-selection"
          ) {
            // User is logged in, not looking at the project selection
            if (
              (user.last_time_log?.end !== null ||
                user.last_time_log?.safety_meeting_read === false) &&
              props.location.pathname !== "/projects/add" &&
              user.last_time_log?.type !== "break" &&
              user.last_time_log?.type !== "delivery"
            ) {
              // redirect to project selection if there's no active timelog and path is different from /projects/add
              return <Redirect to="/project-selection" />;
            } else if (
              user.last_time_log !== null &&
                user.last_time_log?.end === null &&
              user.last_time_log.type === "break" &&
              props.location.pathname !== "/on-break"
            ) {
              // redirect to break page if last timelog is break type
              return <Redirect to="/on-break" />;
            } else if (
              user.last_time_log !== null &&
              user.last_time_log?.end === null &&
              user.last_time_log.type === "delivery" &&
              props.location.pathname !== "/on-delivery"
            ) {
              // redirect to break page if last timelog is break type
              return <Redirect to="/on-delivery" />;
            } else if (
                user.last_time_log !== null &&
                user.last_time_log.type !== "delivery" &&
                user.last_time_log.type !== "break" &&
                (props.location.pathname === "/on-break" || props.location.pathname === '/on-delivery')
            ) {
              // if we are on the break or delivery page and our current time log is not a break or delivery time log
              // we redirect to homepage
              return <Redirect to="/dashboard" />;
            } else {
              return <ChildComponent {...props} />;
            }
          } else {
            return <ChildComponent {...props} />;
          }
        }}
      />
    );
  };

  render() {
    let { PrivateRoute } = this;

    // <Route component={NotFound} />
    return (
      <BrowserRouter>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.public) {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            } else {
              return (
                <PrivateRoute
                  path={prop.path}
                  component={prop.component}
                  key={"menu-" + key}
                />
              );
            }
          })}
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    },
    updatePosition: (latitude, longitude) => {
      return dispatch(workers.updatePosition(latitude, longitude));
    },
    gpsRefused: () => {
      return dispatch(workers.gpsRefused());
    },
    gpsAccepted: () => {
      return dispatch(workers.gpsAccepted());
    },
  };
};

const geolocatedOptions = {
  positionOptions: { enableHighAccuracy: true, maximumAge: 0 },
  userDecisionTimeout: 10000,
  watchPosition: true,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(geolocated(geolocatedOptions)(RootContainerComponent));
