import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ax } from "utils";
import { connect } from "react-redux";
import { files } from "actions";
import { makeStyles } from "@material-ui/core/styles";
import IconSVG from "../svgs/IconSVG";

import "./FileList.css";

const moment = require("moment"); // require

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "99%",
    height: "300px",
    backgroundColor: "#000000",
    marginTop: 10,
  },
  nextButton: {
    backgroundColor: "#000000",
    padding: "0.7rem",
    color: "white",
    margin: "1rem auto",
    textAlign: "center",
  },
}));

function FileList(props) {
  const { item, type, project, fileCreated, updateFile } = props;
  const [totalItems, setTotalItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(null);
  const classes = useStyles();

  const fetchData = () => {
    const endpoint =
      "/api/project-files/?project_id=" +
      project.id +
      "&file_type=document&limit=10";
    ax.get(endpoint)
      .then((res) => {
        setTotalItems(res.data.results);
        setNext(res.data.next);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [fileCreated, props.currentProject]);

  const fetchMoreData = async () => {
    ax.get(next)
      .then((res) => {
        console.log(res.data.results);
        setTotalItems([...totalItems, ...res.data.results]);
        setNext(res.data.next);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LoadMoreButton = () => {
    return next ? (
      <Button
        className={classes.nextButton}
        variant="contained"
        color="primary"
        style={{ padding: "0.8rem 1.1rem" }}
        onClick={fetchMoreData}
      >
        Load More
      </Button>
    ) : null;
  };

  const open_file = (file) => {
    window.open(file.file, "_blank");
  };

  let itemList;
  if (totalItems) {
    itemList = totalItems.map((file) => {
      const dateCreated = moment(file.date_created).format("MM/DD/YYYY");
      const extention = file.file.substring(
        file.file.lastIndexOf(".") + 1,
        file.file.length
      );
      if (file.file_type === "document") {
        return (
          <Grid key={file.id} container spacing={2} className="file-grid">
            <Grid item xs={4}>
              <Box
                mt={1}
                justifyContent="center"
                display="flex"
                alignContent="center"
              >
                <ListItem
                  style={{
                    padding: 0,
                    color: "var(--trade-primary)",
                    cursor: "pointer",
                  }}
                >
                  <ListItemText onClick={() => open_file(file)}>
                    <IconSVG icon={extention} fill="white" />
                    {file.name}
                    <p className="author-name">
                      Created by: {file.author_full_name}
                    </p>
                    {file.restricted && (
                      <p className="restricted-notice">
                        <i>Restricted File</i>
                      </p>
                    )}
                  </ListItemText>
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                mt={1}
                justifyContent="center"
                display="flex"
                alignContent="center"
              >
                <ListItem style={{ padding: 0, color: "#ffffff" }}>
                  <ListItemText>{dateCreated}</ListItemText>
                </ListItem>
              </Box>
            </Grid>
            {props.currentUser.role !== "WORKER" ? (
              <Grid item xs={2}>
                <Box
                  mt={1}
                  justifyContent="center"
                  display="flex"
                  alignContent="center"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="restricted-checkbox"
                          onChange={() =>
                            updateFile(file.name, file.id, !file.restricted)
                          }
                          defaultChecked={file.restricted}
                        />
                      }
                    />
                  </FormGroup>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        );
      } else {
        return null;
      }
    });
  }

  return (
    <div>
      {loading ? (
        <div className="spinnerContainer">
          <h2 style={{ color: "white" }}>Loading Files...</h2>
        </div>
      ) : null}
      {totalItems.length ? (
        <Grid container spacing={2} className="file-grid">
          <Grid item xs={4}>
            <Box
              mt={1}
              justifyContent="center"
              display="flex"
              alignContent="center"
            >
              <ListItem
                style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
              >
                <ListItemText>Name</ListItemText>
              </ListItem>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              mt={1}
              justifyContent="center"
              display="flex"
              alignContent="center"
            >
              <ListItem
                style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
              >
                <ListItemText>Date Added</ListItemText>
              </ListItem>
            </Box>
          </Grid>
          {props.currentUser.role !== "WORKER" ? (
            <Grid item xs={2}>
              <Box
                mt={1}
                justifyContent="center"
                display="flex"
                alignContent="center"
              >
                <ListItem
                  style={{ padding: 0, color: "#FFFF", fontWeight: "bold" }}
                >
                  <ListItemText>Restricted</ListItemText>
                </ListItem>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      <div className="projectTimeSheetWrapper" id="style-5">
        {!totalItems.length && !loading && (
          <h1 className="no-files-message">No Files Uploaded</h1>
        )}
        {itemList}
        {totalItems.length <= 10 || next ? <LoadMoreButton /> : null}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    project: state.projects.item,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFile: (name, file_id, restricted) => {
      return dispatch(files.updateFile(name, file_id, restricted));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
